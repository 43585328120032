import styled from 'styled-components';
import media from 'utils/media';
import { Times } from 'styled-icons/fa-solid';

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(83, 86, 90, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-top: 7px solid ${(props) => props.theme.brandColor1};
  display: flex;
  flex-direction: row;
  height: auto;
  align-items: stretch;
  position: relative;
  max-height: 88vh;
  overflow-y: auto;
  ${media.tablet`
        width: 80vw;
        flex-direction: column;
    `}
  ${media.phone`
        width: 90vw;
        flex-direction: column;
    `}
`;

export const ContainerCarousel = styled.div`
  display: flex;
  min-width: 344px;
  min-height: 460px;
  height: auto;
  ${media.tablet`
        min-width: 80vw;
        width: 80vw;
        height: 106.97vw;
    `}
  ${media.phone`
        min-width: 90vw;
        width: 90vw;
        height: 120.34vw;
    `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-size: cover;
  background-repeat: no-repeat;
  flex: 1;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 36px 17px;
  box-sizing: border-box;
  ${media.tablet`
        width: 80vw;
    `}
  ${media.phone`
        width: 90vw;
    `}
`;

export const TextTitle = styled.h1`
  font-family: MontserratBold;
  font-size: 39px;
  margin: 0;
  color: ${(props) => props.theme.brandColor1};
  font-weight: 400;
  text-align: left;
  max-width: 343px;
  ${media.phone`
        font-size: 26px;
        max-width: 80%;
    `}
`;

export const TextValue = styled.h2`
  font-family: MontserratMedium;
  font-size: 39px;
  margin: 0;
  color: #707070;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-top: 24px;
  max-width: 343px;
  ${media.phone`
        font-size: 26px;
        max-width: 100%;
    `}
  & > span {
    font-family: MontserratLight;
    margin-left: 8px;
    font-size: 22px;
    ${media.phone`
            font-size: 16px;
        `}
  }
`;

export const TextSubValue = styled.h4`
  font-family: MontserratLight;
  font-size: 22px;
  margin: 0;
  color: #707070;
  font-weight: 400;
  display: flex;
  text-align: left;
  max-width: 343px;
  ${media.phone`
        font-size: 16px;
        max-width: 100%;
    `}
  & > span {
    font-family: MontserratBold;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    margin-left: 7px;
    & > span {
      font-family: MontserratLight;
      font-size: 15px;
      margin-left: 5px;
      ${media.phone`
                font-size: 12px;
            `}
    }
  }
`;

export const TextDescription = styled.p`
  font-family: 'MontserratLight';
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  color: #707070;
  text-align: left;
  margin-top: 28px;
  max-width: 343px;
  ${media.phone`
        font-size: 16px;
        max-width: 80%;
    `}
`;

export const SubText = styled.span`
  font-family: 'MontserratLight';
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  margin-top: 24px;
  margin-left: 10px;
  color: #707070;
  text-align: left;
  max-width: 343px;
  ${media.phone`
        font-size: 12px;
        max-width: 100%;
    `}
`;

export const ButtonItem = styled.a`
  transition: 175ms;
  margin: 0;
  margin-left: 16px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 36px;
  border-radius: 40px;
  outline: none !important;
  background: none;
  background-color: ${(props) => props.theme.brandColor1};
  color: ${(props) => props.theme.brandColor2};
  font-family: 'MontserratLight';
  font-size: 25px;
  border: none;
  font-weight: 400;
  box-sizing: border-box;
  width: auto;
  align-self: flex-start;
  text-decoration: none;
  ${media.phone`
        font-size: 18px;
        padding: 11px 28px;
    `}
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.brandColor1}66;
  }
`;

export const CloseButton = styled.button`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 6px;
  background: none !important;
  outline: none !important;
  border: none !important;
  z-index: 19;
  &:hover {
    cursor: pointer;
  }
`;

export const CloseIcon = styled(Times)`
  color: #707070;
  size: 28px;
  font-weight: 400;
`;

import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import {
  Container,
  ContentWrapper,
  ActionsWrapper,
  ButtonDisabledReasonText,
} from './styles';
import SchedulingWarning from 'components/SchedulingWarning';
import { useAlert } from 'react-alert';
import { verifyUserPermission } from 'utils/functions/user';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import {
  AccountService,
  BeneficiaryService,
  TokenService,
  TransfersService,
  BankService,
} from 'banking-service';
import { revertBrazilianCurrencyToFloat } from 'utils/functions/currency';
import TransferForm from 'components/TransferForm';
import TransferResumeList from 'components/TransferForm/TransferResumeList';
import TransferFeedback from 'components/TransferForm/TransferFeedback';
import Button from 'components/Button';
import PasswordVerification from 'components/PasswordVerification';
import { hasActiveToken } from 'utils/functions/token';
import {
  initialInternalTransferData,
  initialExternalTransferData,
} from './initialValues';
import TokenActivationModal from 'components/TokenActivationModal';
import ContentLoader from 'react-content-loader';
import { Delete } from 'styled-icons/material/Delete';
import styled, { useTheme } from 'styled-components';

import { needsToActivateToken } from 'utils/functions/token';
import { useConfigContext } from 'providers/client-config-provider';

const TOPAZIO_BANKCODE = '082';

const TRANSFER_TYPES = {
  TOPAZIO: 'TOPAZIO',
  OTHER_BANKS: 'OTHER_BANKS',
};

const STEPS = {
  FIRST: 'FORM_FILL',
  SECOND: 'FORM_REVIEW',
  THIRD: 'FEEDBACK',
};

function Transfers({ name = 'Transferências', ...props }) {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState({ screen: false, token: false });

  const [tokenModalType, setModalTokenType] = useState('');

  const [
    showNeedsToActivateTokenModal,
    hideNeedsToActivateTokenModal,
  ] = useModal(
    () => (
      <ReactModal
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        isOpen
      >
        <TokenActivationModal
          backButtonText={resolveBackButtonText()}
          onActiveClick={() => {
            props.history.push('/services/token');
          }}
          onBackHomeClick={resolveBackClick}
          hideModalCallback={hideNeedsToActivateTokenModal}
          type={tokenModalType}
        />
      </ReactModal>
    ),
    [tokenModalType]
  );

  const resolveBackButtonText = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      return 'CANCELARA';
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      return 'CANCELAR';
    } else {
      return 'VOLTAR PARA HOME';
    }
  };

  const resolveBackClick = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      hideNeedsToActivateTokenModal();
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      hideNeedsToActivateTokenModal();
    } else {
      props.history.push('/');
    }
  };

  useEffect(() => {
    const fetchTokens = async () => {
      setIsLoading((loading) => ({ ...loading, token: true }));
      setError('');
      try {
        const res = await TokenService.getInfo();
        let tokenRequiredAction = needsToActivateToken({
          data: [...res.filter((e) => e.status !== 'Inativo')],
        });
        if (tokenRequiredAction) {
          setModalTokenType(tokenRequiredAction);
          showNeedsToActivateTokenModal();
        }
      } catch (e) {}
      setIsLoading((loading) => ({ ...loading, token: false }));
    };
    fetchTokens();
  }, []);

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const { userPermissions } = useSwitchAccountDataContext();

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(
    false
  );
  const [buttonDisabledReason, setButtonDisabledReason] = useState(false);
  const [
    internalTransferConfirmationInfos,
    setInternalTransferConfirmationInfos,
  ] = useState({});

  const [selectedType, setSelectedType] = useState(TRANSFER_TYPES.OTHER_BANKS);

  //TOPAZIO STATES
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [formFilledData, setFormFilledData] = useState({
    ...initialInternalTransferData,
  });

  const [banks, setBanks] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [formFilledDataOtherBanks, setFormFilledDataOtherBanks] = useState({
    ...initialExternalTransferData,
  });

  const [formErrors, setFormErrors] = useState({});
  const [formTouched, setFormTouched] = useState({});

  const [formErrorsOtherBanks, setFormErrorsOtherBanks] = useState({});
  const [formTouchedOtherBanks, setFormTouchedOtherBanks] = useState({});

  useEffect(() => {
    if (
      verifyUserPermission({
        permission: 'TRANSFERS',
        arrOfPermissions: AccountService.account.permissions,
      })
    ) {
      setSelectedType(TRANSFER_TYPES.OTHER_BANKS);
      setFormFilledDataOtherBanks({ ...initialExternalTransferData });
    } else if (
      verifyUserPermission({
        permission: 'TRANSFERS',
        arrOfPermissions: AccountService.account.permissions,
      })
    ) {
      setSelectedType(TRANSFER_TYPES.OTHER_BANKS);
      setFormFilledDataOtherBanks({
        ...initialExternalTransferData,
        ownershipLabel: 'Mesma titularidade',
        ownership: 'sameOwner',
      });
    } else {
      setSelectedType(TRANSFER_TYPES.TOPAZIO);
    }
  }, []);

  useEffect(() => {
    if (
      verifyUserPermission({
        permission: 'TRANSFERS',
        arrOfPermissions: AccountService.account.permissions,
      }) &&
      formFilledDataOtherBanks.bank === TOPAZIO_BANKCODE
    ) {
      clearInternalForm();
      clearExternalForm();

      if (
        verifyUserPermission({
          permission: 'TRANSFERS',
          arrOfPermissions: AccountService.account.permissions,
        })
      ) {
        setFormFilledDataOtherBanks({ ...initialExternalTransferData });
      } else {
        setFormFilledDataOtherBanks({
          ...initialExternalTransferData,
          ownershipLabel: 'Mesma titularidade',
          ownership: 'sameOwner',
        });
      }
      setSelectedType(TRANSFER_TYPES.TOPAZIO);
    }
  }, [formFilledDataOtherBanks.bank]);

  const clearInternalForm = () => {
    setFormFilledData({ ...initialInternalTransferData });
    setFormErrors({});
    setFormTouched({});
  };

  const clearExternalForm = () => {
    setFormFilledDataOtherBanks({ ...initialExternalTransferData });
    setFormErrorsOtherBanks({});
    setFormTouchedOtherBanks({});
  };

  useEffect(() => {
    const fetchBeneficiaryBasedOnAccount = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));

      try {
        const res = await BeneficiaryService.fetchBeneficiaryBasedOnAccount({
          branch: formFilledData.branch,
          account: formFilledData.account + formFilledData.digit,
        });

        setFormFilledData((val) => ({
          ...val,
          hasSelected: true,
          beneficiary: {
            ...val.beneficiary,
            name: res.name,
            personType: res.personType,
            taxIdentifier: res.taxIdentifier,
          },
        }));
      } catch (e) {
        alert.error(
          e && e.error.length > 0
            ? e.error[0].message
            : 'Ocorreu um erro inesperado. Verifique sua conexão'
        );
      }
      setIsLoading((loading) => ({ ...loading, screen: false }));
    };

    if (
      formTouched.branch &&
      !formErrors.branch &&
      formTouched.account &&
      !formErrors.account &&
      formTouched.digit &&
      !formErrors.digit
    ) {
      fetchBeneficiaryBasedOnAccount();
    }
  }, [
    formFilledData.branch,
    formFilledData.account,
    formFilledData.digit,
    formTouched.branch,
    formTouched.account,
    formTouched.digit,
  ]);

  const [step, setStep] = useState(STEPS.FIRST);
  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const res = await BeneficiaryService.getBeneficiaries();
        setBeneficiaries(res);
      } catch (e) {
        setError(e.error);
      }
    };

    const fetchBanks = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));

      try {
        const res = await BankService.getBanks();
        setBanks(res);
      } catch (e) {
        setError(e.error);
      }
      setIsLoading((loading) => ({ ...loading, screen: false }));
    };

    const fetchPurposes = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));

      try {
        const res = await TransfersService.getPurposes();
        setPurposes(res);
      } catch (e) {
        setError(e.error);
      }
      setIsLoading((loading) => ({ ...loading, screen: false }));
    };

    if (step === STEPS.FIRST) {
      fetchBeneficiaries();
    }
    if (selectedType === TRANSFER_TYPES.OTHER_BANKS && step === STEPS.FIRST) {
      fetchBanks();
      fetchPurposes();
    }
  }, [selectedType]);

  const [balance, setBalance] = useState(null);
  useEffect(() => {
    const fetchAccountBalance = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));

      setError('');
      try {
        const res = await AccountService._getBalance();
        setBalance(AccountService.account.balance.available);
      } catch (e) {
        setError(
          'Não conseguimos recuperar todos os seus saldos. Por favor, recarregue a página.'
        );
      }
      setIsLoading((loading) => ({ ...loading, screen: false }));
    };

    const fetchTokenInfo = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));

      try {
        const res = await TokenService.getInfo();
        setToken(hasActiveToken({ data: res }));
      } catch (e) {
        setToken(null);
      }
      setIsLoading(false);
    };

    fetchAccountBalance();

    if (step === STEPS.SECOND) {
      fetchTokenInfo();
    }
  }, [step]);

  const sendInternalTransfer = async ({ password, otp }) => {
    const payload = TransfersService.setNewInternalTransfer({
      credit: {
        account: formFilledData.account + formFilledData.digit,
        branch: formFilledData.branch,
        digit: formFilledData.digit,
        type: null,
      },
      beneficiary: {
        name: formFilledData.beneficiary.name,
        personType:
          formFilledData.beneficiary.taxIdentifier.replace(/\D/g, '').length >
          11
            ? 'PJ'
            : 'PF',
        taxIdentifier: formFilledData.beneficiary.taxIdentifier.replace(
          /\D/g,
          ''
        ),
      },
      date: formFilledData.date,
      value: revertBrazilianCurrencyToFloat(formFilledData.amount),
      comment: formFilledData.comment,
      purpose: formFilledData.purpose,
      tag: formFilledData.tag,
    });

    const res = await TransfersService.postConfirmInternalTransferWithScheduling(
      { payload, password, otp }
    );
    const document =
      formFilledData.beneficiary.taxIdentifier ||
      formFilledData.beneficiary.document;
    try {
      await BeneficiaryService.saveBeneficiary({
        accountNoDigit: formFilledData.account,
        digitAccount: formFilledData.digit,
        branch: formFilledData.branch,
        bankCode: '082',
        name: formFilledData.beneficiary.name,
        sameOwnership: false,
        account: formFilledData.account + formFilledData.digit,
        registerType: 'DE',
        taxIdentifier: document.replace(/\D/g, ''),
        document: document.replace(/\D/g, ''),
        personType: document.replace(/\D/g, '').length > 11 ? 'PJ' : 'PF',
      });
    } catch (e) {}
    setInternalTransferConfirmationInfos({
      ...payload,
      transactionId: res.id,
    });
    setStep(STEPS.THIRD);
  };

  const sendExternalTransferSameOwner = async ({ password, otp }) => {
    const payload = TransfersService.setNewExternalTransferSameOwner({
      sameOwnership: formFilledDataOtherBanks.ownership === 'sameOwner',
      operationType: formFilledDataOtherBanks.operationType,
      bank: formFilledDataOtherBanks.bank,
      beneficiary: {
        name: AccountService.account.name,
        personType:
          AccountService.account.taxIdentifier.length > 11 ? 'PJ' : 'PF',
        taxIdentifier: AccountService.account.taxIdentifier,
      },
      credit: {
        account:
          formFilledDataOtherBanks.account + formFilledDataOtherBanks.digit,
        branch: formFilledDataOtherBanks.branch,
        digit: formFilledDataOtherBanks.digit,
        type: null,
      },
      date: formFilledDataOtherBanks.date,
      value: revertBrazilianCurrencyToFloat(formFilledDataOtherBanks.amount),
      comment: formFilledDataOtherBanks.comment,
      purpose: formFilledDataOtherBanks.purpose,
      tag: formFilledDataOtherBanks.tag,
    });

    const res = await TransfersService.postConfirmExternalTransferSameOwnerWithScheduling(
      { payload, password, otp }
    );

    try {
      await BeneficiaryService.saveBeneficiary({
        accountNoDigit: formFilledDataOtherBanks.account,
        digitAccount: formFilledDataOtherBanks.digit,
        branch: formFilledDataOtherBanks.branch,
        bankCode: formFilledDataOtherBanks.bank,
        sameOwnership: true,
        account:
          formFilledDataOtherBanks.account + formFilledDataOtherBanks.digit,
        registerType: 'DD',
        taxIdentifier: AccountService.account.taxIdentifier,
        document: AccountService.account.taxIdentifier,
        personType:
          AccountService.account.taxIdentifier.length > 11 ? 'PJ' : 'PF',
      });
    } catch (e) {}
    setInternalTransferConfirmationInfos({
      ...payload,
      transactionId: res.id,
      //date: moment(resOfReceipt.transactionDate).format('DD/MM/YYYY'),
    });
    setStep(STEPS.THIRD);
  };

  const sendExternalTransferOtherOwner = async ({ password, otp }) => {
    const payload = TransfersService.setNewExternalTransferOtherOwner({
      sameOwnership: formFilledDataOtherBanks.ownership === 'sameOwner',
      operationType: formFilledDataOtherBanks.operationType,
      bank: formFilledDataOtherBanks.bank,
      credit: {
        account:
          formFilledDataOtherBanks.account + formFilledDataOtherBanks.digit,
        branch: formFilledDataOtherBanks.branch,
        digit: formFilledDataOtherBanks.digit,
        type: null,
      },
      beneficiary: {
        name: formFilledDataOtherBanks.beneficiary.name,
        personType:
          formFilledDataOtherBanks.taxIdentifier.replace(/\D/g, '').length > 11
            ? 'PJ'
            : 'PF',
        taxIdentifier: formFilledDataOtherBanks.taxIdentifier.replace(
          /\D/g,
          ''
        ),
      },
      date: formFilledDataOtherBanks.date,
      value: revertBrazilianCurrencyToFloat(formFilledDataOtherBanks.amount),
      comment: formFilledDataOtherBanks.comment,
      purpose: formFilledDataOtherBanks.purpose,
      tag: formFilledDataOtherBanks.tag,
    });

    const res = await TransfersService.postConfirmExternalTransferOtherOwnerWithScheduling(
      { payload, password, otp }
    );

    const document =
      formFilledDataOtherBanks.taxIdentifier ||
      formFilledDataOtherBanks.beneficiary.document;

    try {
      await BeneficiaryService.saveBeneficiary({
        name: formFilledDataOtherBanks.beneficiary.name,
        accountNoDigit: formFilledDataOtherBanks.account,
        digitAccount: formFilledDataOtherBanks.digit,
        branch: formFilledDataOtherBanks.branch,
        bankCode: formFilledDataOtherBanks.bank,
        account:
          formFilledDataOtherBanks.account + formFilledDataOtherBanks.digit,
        registerType: 'DE',
        taxIdentifier: document.replace(/\D/g, ''),
        document: document.replace(/\D/g, ''),
        personType: document.replace(/\D/g, '').length > 11 ? 'PJ' : 'PF',
      });
    } catch (e) {}

    setInternalTransferConfirmationInfos({
      ...payload,
      transactionId: res.id,
      //date: moment(resOfReceipt.transactionDate).format('DD/MM/YYYY'),
    });
    setStep(STEPS.THIRD);
  };

  const confirmTransfer = async ({ password, otp }) => {
    setIsLoading((loading) => ({ ...loading, screen: true }));

    try {
      try {
        if (selectedType === TRANSFER_TYPES.TOPAZIO) {
          await sendInternalTransfer({ password, otp });
        } else if (formFilledDataOtherBanks.ownership === 'sameOwner') {
          await sendExternalTransferSameOwner({ password, otp });
        } else {
          await sendExternalTransferOtherOwner({ password, otp });
        }
      } catch (e) {
        setIsLoading((loading) => ({ ...loading, screen: false }));
        setError(e);
      }
    } catch (e) {
      setError(
        e.error && e.error.length > 0
          ? e.error[0]
          : 'Ocorreu um erro na verificação do seu token'
      );
    }
    setIsLoading((loading) => ({ ...loading, screen: false }));
  };

  const fetchReceipt = async ({ identifier }) => {
    try {
      const res = await TransfersService.fetchReceipt({
        identifier: identifier,
      });
      return res;
    } catch (e) {
      return { authentication: '', transactionDate: moment() };
    }
  };

  const [token, setToken] = useState(false);

  const theme = useTheme()

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal
        onRequestClose={hideModal}
        style={{
          overlay: {
            zIndex: '11',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            backgroundColor: theme.Cor_base_marca_01,
          },
        }}
        isOpen
      >
        <PasswordVerification
          hasToken={!!token}
          onConfirmButtonClick={async ({ password, otp }) => {
            hideModal();
            await confirmTransfer({ password, otp });
          }}
        />
      </ReactModal>
    ),
    [!!token, formFilledData, formFilledDataOtherBanks, selectedType]
  );

  useEffect(() => {
    let valueToCompare =
      selectedType === TRANSFER_TYPES.TOPAZIO
        ? formFilledData
        : formFilledDataOtherBanks;
    if (Number(revertBrazilianCurrencyToFloat(valueToCompare.amount)) < 1) {
      setIsContinueButtonDisabled(true);
      setButtonDisabledReason('* O valor mínimo de transfêrencia é de R$ 1,00');
    } else if (
      moment().isSame(valueToCompare.date, 'day') &&
      revertBrazilianCurrencyToFloat(valueToCompare.amount) > balance &&
      AccountService.account.profileType !== 'OPERATOR'
    ) {
      setIsContinueButtonDisabled(true);
      setButtonDisabledReason('* Saldo insuficiente para o pagamento');
    } else if (!valueToCompare.date) {
      setIsContinueButtonDisabled(true);
      setButtonDisabledReason(
        '* Forneça, obrigatóriamente, uma data de efetivação'
      );
    } else {
      setIsContinueButtonDisabled(false);
      setButtonDisabledReason('');
    }
  }, [balance, formFilledData, formFilledDataOtherBanks]);

  const resolveButtonDisability = () => {
    if (selectedType === TRANSFER_TYPES.TOPAZIO) {
      return (
        !formFilledData.hasSelected ||
        isContinueButtonDisabled ||
        Object.values(formErrors).find((e) => e === true) ||
        formFilledData.amount === '' ||
        Number(revertBrazilianCurrencyToFloat(formFilledData.amount)) < 1 ||
        formFilledData.amount === 'R$ 0,00'
      );
    } else if (
      selectedType === TRANSFER_TYPES.OTHER_BANKS &&
      formFilledDataOtherBanks.ownership === 'sameOwner'
    ) {
      return (
        isContinueButtonDisabled ||
        !!Object.values(formErrorsOtherBanks).find((e) => e === true) ||
        formFilledDataOtherBanks.amount === '' ||
        Number(
          revertBrazilianCurrencyToFloat(formFilledDataOtherBanks.amount)
        ) < 1 ||
        formFilledDataOtherBanks.amount === 'R$ 0,00' ||
        formFilledDataOtherBanks.bank === '' ||
        formFilledDataOtherBanks.branch === '' ||
        formFilledDataOtherBanks.account === '' ||
        formFilledDataOtherBanks.digit === '' ||
        formFilledDataOtherBanks.purpose === '' ||
        formFilledDataOtherBanks.comment === ''
      );
    } else if (
      selectedType === TRANSFER_TYPES.OTHER_BANKS &&
      formFilledDataOtherBanks.ownership === 'other'
    ) {
      return (
        isContinueButtonDisabled ||
        !!Object.values(formErrorsOtherBanks).find((e) => e === true) ||
        formFilledDataOtherBanks.amount === '' ||
        formFilledDataOtherBanks.amount === 'R$ 0,00' ||
        Number(
          revertBrazilianCurrencyToFloat(formFilledDataOtherBanks.amount)
        ) < 1 ||
        formFilledDataOtherBanks.beneficiary.name === '' ||
        formFilledDataOtherBanks.taxIdentifier === '' ||
        formFilledDataOtherBanks.bank === '' ||
        formFilledDataOtherBanks.branch === '' ||
        formFilledDataOtherBanks.account === '' ||
        formFilledDataOtherBanks.digit === '' ||
        formFilledDataOtherBanks.purpose === '' ||
        formFilledDataOtherBanks.comment === ''
      );
    } else {
      return false;
    }
  };

  const resolveData = () => {
    if (selectedType === TRANSFER_TYPES.TOPAZIO) {
      return { ...formFilledData, name: formFilledData.beneficiary.name };
    } else if (
      selectedType === TRANSFER_TYPES.OTHER_BANKS &&
      formFilledDataOtherBanks.ownership === 'sameOwner'
    ) {
      return {
        ...formFilledDataOtherBanks,
        taxIdentifier: AccountService.account.taxIdentifier,
        name: AccountService.account.name,
      };
    } else {
      return {
        ...formFilledDataOtherBanks,
        name: formFilledDataOtherBanks.beneficiary.name,
      };
    }
  };

  const [warningMessage, setWarningMessage] = useState(null);

  useEffect(() => {
    if (selectedType === TRANSFER_TYPES.TOPAZIO) {
      if (moment(formFilledData.date).isAfter(moment(), 'day')) {
        setWarningMessage(
          `Sua transferência será agendada para ${moment(
            formFilledData.date
          ).format('DD/MM/YYYY')}`
        );
      } else {
        setWarningMessage(null);
      }
    } else {
      if (moment(formFilledDataOtherBanks.date).isAfter(moment(), 'day')) {
        setWarningMessage(
          `Sua transferência será agendada para ${moment(
            formFilledDataOtherBanks.date
          ).format('DD/MM/YYYY')}`
        );
      } else {
        setWarningMessage(null);
      }
    }
  }, [selectedType, formFilledData.date, formFilledDataOtherBanks.date]);

  const [hasDirted, setHasDirted] = useState(false);
  useEffect(() => {
    const fetchNextValidDate = async () => {
      let selectedDate =
        selectedType === TRANSFER_TYPES.TOPAZIO
          ? formFilledData.date
          : formFilledDataOtherBanks.date;
      setIsLoading((loading) => ({ ...loading, dateCheck: true }));
      try {
        const res = await TransfersService.fetchNextValidDate({
          type: selectedType,
          date: selectedDate,
        });
      } catch (e) {
        if (e && e.message) {
          setHasDirted(true);
          alert.error(e.message, { timeout: 10000 });
          if (selectedType === TRANSFER_TYPES.TOPAZIO) {
            setFormFilledData((formFilledData) => ({
              ...formFilledData,
              date: moment(e.nextWorkDay),
            }));
          } else {
            setFormFilledDataOtherBanks((formFilledDataOtherBanks) => ({
              ...formFilledDataOtherBanks,
              date: moment(e.nextWorkDay),
            }));
          }
        } else {
          alert.error('Não conseguimos verificar a disponibilidade deste dia');
        }
      }
      setIsLoading((loading) => ({ ...loading, dateCheck: false }));
    };

    if (!hasDirted) {
      fetchNextValidDate();
    }
  }, [selectedType, formFilledData.date, formFilledDataOtherBanks.date]);

  const handleRemoveClick = async ({ id }) => {
    hideFavoritesModal();
    setIsLoading((loading) => ({ ...loading, token: true }));
    setError('');
    try {
      const res = await BeneficiaryService.deleteBeneficiary({ id });
      setBeneficiaries((val) => val.filter((e) => e._id !== id));
      alert.success('Beneficiário excluído com sucesso');
    } catch (e) {
      setError({ message: 'Não foi possível excluir o beneficiário' });
    }
    setIsLoading((loading) => ({ ...loading, token: false }));
  };

  const [showFavoritesModal, hideFavoritesModal] = useModal(
    () => (
      <ReactModal
        onRequestClose={hideFavoritesModal}
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '10px 40px',
          },
        }}
        isOpen
      >
        <FavoritesModal
          handleRemoveClick={handleRemoveClick}
          beneficiaries={beneficiaries}
        />
      </ReactModal>
    ),
    [beneficiaries]
  );

  const handleFavoritesClick = () => {
    showFavoritesModal();
  };

  return (
    <LoadingSpinner
      isLoading={isLoading.screen || isLoading.token || isLoading.dateCheck}
    >
      <Container>
        <ScreenTitle title={name}></ScreenTitle>
        <ContentWrapper>
          <CurrentAccountValue
            title={'saldo conta corrente'}
            value={balance}
          ></CurrentAccountValue>
          <ActionsWrapper>
            {step === STEPS.FIRST ? (
              <Fragment>
                <TransferForm
                  isSchedulingEnabled={true}
                  data={formFilledData}
                  onChangeFormErrors={({ errors }) => setFormErrors(errors)}
                  onChangeFormValues={({ values }) => {
                    setHasDirted(false);
                    setFormFilledData(values);
                  }}
                  onChangeFormTouched={({ touched }) => {
                    setFormTouched(touched);
                  }}
                  formTouched={formTouched}
                  formErrors={formErrors}
                  beneficiaries={beneficiaries}
                  handleFavoritesClick={handleFavoritesClick}
                  banks={banks}
                  purposes={purposes}
                  beneficiaries={beneficiaries}
                  dataOtherBanks={formFilledDataOtherBanks}
                  formTouchedOtherBanks={formTouchedOtherBanks}
                  formErrorsOtherBanks={formErrorsOtherBanks}
                  onChangeFormErrorsOtherBanks={({ errors }) =>
                    setFormErrorsOtherBanks(errors)
                  }
                  onChangeFormValuesOtherBanks={({ values }) => {
                    setHasDirted(false);
                    setFormFilledDataOtherBanks(values);
                  }}
                  onChangeFormTouchedOtherBanks={({ touched }) =>
                    setFormTouchedOtherBanks(touched)
                  }
                  userPermissions={userPermissions}
                  step={step}
                  selectedType={selectedType}
                  onSelectedTypeChange={(val) => {
                    setSelectedType(val);
                  }}
                />
                <ButtonDisabledReasonText id="buttonDisabledReasonId">
                  {buttonDisabledReason}
                </ButtonDisabledReasonText>
                <br />
                {warningMessage ? (
                  <SchedulingWarning message={warningMessage} />
                ) : null}
                <br />
                <Button
                  id="firstStepContinueButtonId"
                  disabled={
                    isLoading.screen ||
                    isLoading.token ||
                    isLoading.dateCheck ||
                    resolveButtonDisability()
                  }
                  title={'Continuar'}
                  onClick={() => {
                    setStep(STEPS.SECOND);
                  }}
                  style={{ marginBottom: '25px' }}
                ></Button>
              </Fragment>
            ) : step === STEPS.SECOND ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <TransferResumeList
                  data={resolveData()}
                  selectedType={selectedType}
                />
                <br />
                {warningMessage ? (
                  <SchedulingWarning message={warningMessage} />
                ) : null}
                <br />
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '20px',
                  }}
                >
                  <Button
                    disabled={
                      isLoading.screen || isLoading.token || isLoading.dateCheck
                    }
                    onClick={() => {
                      setStep(STEPS.FIRST);
                    }}
                    id="secondStepBackButtonId"
                    title={'Voltar'}
                  ></Button>
                  <Button
                    disabled={
                      isLoading.screen || isLoading.token || isLoading.dateCheck
                    }
                    onClick={() => showModal()}
                    id="secondStepContinueButtonId"
                    title={'Continuar'}
                  ></Button>
                </div>
              </div>
            ) : step === STEPS.THIRD ? (
              <TransferFeedback
                isSchedulingEnabled={true}
                aditionalInformations={internalTransferConfirmationInfos}
                data={resolveData()}
                selectedType={selectedType}
              />
            ) : null}
          </ActionsWrapper>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Transfers;

const FavoriteContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 5px 5px 0px;
  justify-content: center;
  align-items: flex-start;
  max-width: 30vw;
  font-family: 'SourceSansPro', Arial, sans-serif;
  max-height: 600px;
`;

const Title = styled.h1`
  font-size: 16px;
  align-self: center;
  margin-bottom: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: ${(props) => props.theme.brandColor3};
  margin: 0px;
  margin-bottom: 50px;
  margin-top: 30px;
`;

const Divisor = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.brandColor5};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 20px 10px 10px;
`;

const Name = styled.label`
  margin: 0px;
  font-size: 11px;
  text-transform: uppercase;
`;

const Description = styled.label`
  margin: 0px;
  font-size: 9px;
  color: ${(props) => props.theme.brandColor5};
`;

const Wrapper = styled.div`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const CancelIcon = styled(Delete)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: ${(props) => props.theme.danger};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const FavoritesModal = ({ beneficiaries = [], handleRemoveClick }) => {
  return (
    <FavoriteContainer>
      <Title>GERENCIAR FAVORITOS</Title>
      <Divisor />
      <Wrapper
        style={{
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {beneficiaries.map((e, i) => (
          <>
            <Item key={i} {...e} handleRemoveClick={handleRemoveClick} />
            <Divisor />
          </>
        ))}
      </Wrapper>
    </FavoriteContainer>
  );
};

const Item = ({
  _id,
  name,
  bankDescription,
  branch,
  account,
  handleRemoveClick,
  ...props
}) => {
  const theme = useTheme()
  return (
    <ItemContainer>
      <Name>{name}</Name>
      <RowWrapper
        style={{
          width: '100%',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Description
            style={{
              color: theme.brandColor1,
              fontSize: '11px',
              marginRight: '5px',
            }}
          >
            {bankDescription}
          </Description>
          <Description
            style={{ color: theme.brandColor3 }}
          >{`Agência: ${branch} - Conta: ${account}`}</Description>
        </div>
        <CancelIcon
          onClick={() => {
            handleRemoveClick({ id: _id });
          }}
        />
      </RowWrapper>
      {/*       <RowWrapper>

      </RowWrapper> */}
    </ItemContainer>
  );
};

import styled from 'styled-components';

import media from 'utils/media';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import { AddCircle } from 'styled-icons/material/AddCircle';

const defaultFontFamily = '"SourceSansPro", Arial, sans-serif';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.brandColor5};
  padding: 30px 60px 20px 60px;
  ${media.phone`
    padding: 50px 10px 10px 10px;
  `};
  font-family: ${defaultFontFamily};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
  padding: ${props => (props.personType === 'PJ' ? '0px 0px' : '40px 0px')};
`;

export const Divisor = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${props => props.theme.brandColor5};
`;

export const CustomButton = styled.button`
  display: flex;
  border: none;
  outline: none;
  margin: 0;
  align-self: flex-end;
  text-transform: underline;
  color: ${props => props.theme.brandColor1};
  align-items: center;
  cursor: pointer;
  font-family: 'SourceSansPro', Arial, sans-serif;
  background-color: ${props => props.theme.brandColor5};
  font-weight: bold;
  font-size: 14px;

  :disabled {
    opacity: 0.4;
  }
`;

export const EditIcon = styled(Edit)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

export const AddIcon = styled(AddCircle)`
  width: 26px;
  height: 26px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

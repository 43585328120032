import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import ReactTable from "react-table";


import {
  BalanceWrapper,
  FiltersWrapper,
  Divisor,
  PeriodLabel,
  AccountSelectionWrapper,
  DownloadIcon,
  IconWrapper,
  ColoredValue,
} from "./styles";
import FilterButton from "components/FilterButton";
import FilterDatePicker from 'components/FilterDatePicker';


const columns = [
  {
    Header: "Data de Solicitação",
    accessor: "requestedDate",
    Cell: props => (
      <div style={{ textAlign: 'center' }}>
        <span>{props.value}</span>
      </div>
    )
  },
  {
    Header: "Intervalo",
    accessor: "startDate",
    Cell: props => (
      <div style={{ textAlign: 'center' }}>
        <span>{`${props.original.startDate} até ${props.original.endDate}`}</span>
      </div>
    )
  },
  {
    Header: "Status",
    accessor: "available",
    Cell: props => {
      return (
        <div style={{ textAlign: 'center', }}>
          <ColoredValue status={props.value}>{props.value === '1' ? 'Disponível' : 'Em geração'}</ColoredValue>
        </div>
      )
    },
  },
  {
    Header: "Download",
    accessor: "onClick",
    Cell: props => (
      <div style={{ textAlign: 'center', }}>
        <IconWrapper disabled={props.original.available !== '1'} onClick={() => props.value({ file: props.original.file })}>
          <DownloadIcon isDisabled={props.original.available !== '1'} />
        </IconWrapper>
      </div>
    ),
  },
];

function ControlledComponent({
  data,
  onFilterChanged,
  filterOptions,
  isPeriodSelectionEnabled,
  periodLabel = { startDate: null, endDate: null },
}) {
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);


  function renderPeriodLabelText({ startDate, endDate }) {
    if ((!startDate || !endDate)) {
      return ''
    }
    else {
      return `${moment(startDate).format('DD/MM/YY')} - ${moment(endDate).format('DD/MM/YY')}`;
    }
  }


  return (
    <BalanceWrapper>
      <AccountSelectionWrapper isSelected>
      </AccountSelectionWrapper>

      <FiltersWrapper>
        {filterOptions.map((e, i) => (
          <FilterButton
            key={i}
            isSelected={selectedFilter === e}
            timeInDays={e}
            onClick={() => {
              setSelectedFilter(e);
              onFilterChanged({ startDate: moment().subtract(e, 'day'), endDate: moment() });
            }}
          />
        ))}
        {isPeriodSelectionEnabled && (
          <FilterDatePicker
            disAllowFutureDates
            style={{ padding: 5, marginLeft: 10, border: "1px solid #eee" }}
            isSelected={false}
            firesWithButton
            onDateChange={({ startDate, endDate }) => { onFilterChanged({ startDate, endDate }) }}
          />
        )}
      </FiltersWrapper>
      <Divisor />
      <PeriodLabel>Período: {renderPeriodLabelText(periodLabel)}</PeriodLabel>
      <ReactTable
        style={{ display: 'flex', flex: 1, width: '100%' }}
        noDataText="Nenhum dado disponível"
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={data ? data.length : 0}
      />

    </BalanceWrapper>
  );
}

export default ControlledComponent;

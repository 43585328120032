import React from 'react';
import { useConfigContext } from 'providers/client-config-provider';
import { CustomSelect, AsynchronousSelect } from './styles';


function SelectInput({ style, isAsync, ...props }) {
    
    const { clientConfig } = useConfigContext();
        const customStyles = {
            control: (base, state) => ({
                ...base,
                'min-height': '20px',
                borderRadius: '2px',
                border: clientConfig ? `solid 1px ${clientConfig.brandColor5}` : `solid 1px #ccc`,
            }),
            valueContainer: (base, state) => ({ ...base, fontSize: '12px', height: '20px', 'min-height': '22px' }),
            indicatorsContainer: (base, state) => ({ ...base, height: '20px', 'min-height': '22px' })
        };

    if (isAsync) {
        return (
            <div style={{ ...style, marginTop: '5px' }}>
                <AsynchronousSelect
                    styles={customStyles}
                    {...props} />
            </div>
        )
    }

    else {
        return (
            <div style={{ ...style, marginTop: '5px' }}>
                <CustomSelect
                    styles={customStyles}
                    {...props} />
            </div>
        )
    }

}


export default SelectInput;
export const resolveOperationName = ({ operation }) => {
  if (operation === 'PAYMENT') {
    return 'PAGAMENTO';
  } else if (operation === 'TRANSFER') {
    return 'TRANSFERÊNCIA';
  } else if (operation === 'INTERNAL_TRANSFER') {
    return 'TRANSFERÊNCIA INTERNA';
  } else {
    return operation;
  }
};

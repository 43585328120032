import React, { useState, useEffect } from 'react';

import {
  Container,
  ItemContainer,
  PortfolioName,
  TopLabel,
  ButtonsWrapper,
} from './styles';
import Button from 'components/Button';
import { OUTSTANDING_PAYMENTS_TEXT } from 'constants/outstanding-resume-texts';
import { resolveOperationName } from 'containers/OutstandingPayments/utils';

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

const resolveApprovalsText = (approvals) => {
  if (approvals.length > 0) {
    return `(${approvals.map((e) => e.user.name).join(' | ')})`;
  }
  return '';
};

function OutstandingResumeList({ data = [] }) {
  const [parsedData, setParsedDate] = useState([]);

  useEffect(() => {
    const parsedDate = OUTSTANDING_PAYMENTS_TEXT.map((e) => {
      if (e.fieldName === 'service') {
        return {
          field: e.title,
          value: resolveOperationName({ operation: data[e.fieldName] }),
        };
      } else if (e.fieldName === 'qtyApprovals') {
        return {
          field: e.title,
          value: `APROVADA POR ${pad(data.qtyApprovals, 2)} DE ${pad(
            data.requiredApprovals,
            2
          )} APROVADORES ${resolveApprovalsText(data.approvals)}
          `,
        };
      }
      return {
        field: e.title,
        value: data[e.fieldName],
      };
    });
    setParsedDate(parsedDate);
  }, [data]);
  return (
    <Container>
      {parsedData.map((e) => (
        <ItemContainer id="outstandingInformationItemId">
          <PortfolioName>{e.field}</PortfolioName>
          <PortfolioName style={{ maxWidth: '50%' }}>{e.value}</PortfolioName>
        </ItemContainer>
      ))}
    </Container>
  );
}

export default OutstandingResumeList;

import React from 'react';
import Checkbox from 'components/Checkbox';
import moment from 'moment';
import { handleCurrency } from 'utils/functions/currency';
import { applyCnpjMask } from 'utils/masks';

import {
  RowWrapper,
  ItemContainer,
  ColumnWrapper,
  Title,
  Value,
  FieldsWrapper,
} from './styles';

export const TableHeader = ({ data, checked, outstadingDetails, onSelect }) => {
  return (
    <ItemContainer
      style={{
        backgroundColor: '#d9d9d9',
        pointerEvents: 'none',
        minHeight: '60px',
      }}
    >
      <FieldsWrapper isChecked={checked}>
        <ColumnWrapper
          style={{ maxWidth: '20px', padding: '0px 20px', width: '100%' }}
          onClick={onSelect}
        >
          {<Checkbox checked={checked} />}
        </ColumnWrapper>
        <ColumnWrapper style={{ flex: 1 }}>
          <Title>Credenciadora</Title>
        </ColumnWrapper>
        <ColumnWrapper style={{ flex: 1 }}>
          <Title>Bandeira</Title>
        </ColumnWrapper>
        <ColumnWrapper style={{ flex: 1 }}>
          <Title>Vencimento</Title>
        </ColumnWrapper>
        <ColumnWrapper style={{ flex: 1.5 }}>
          <Title>Valor Disponível</Title>
        </ColumnWrapper>
      </FieldsWrapper>
    </ItemContainer>
  );
};

export const TableItem = ({ data, checked, outstadingDetails, onSelect }) => {
  return (
    <ItemContainer
      style={{
        backgroundColor: 'white',
        pointerEvents: 'none',
      }}
    >
      <FieldsWrapper isChecked={checked}>
        <ColumnWrapper
          style={{
            maxWidth: '20px',
            padding: '0px 20px',
          }}
          onClick={() => onSelect({ id: data.id, checked: !checked })}
        >
          {<Checkbox checked={checked} />}
        </ColumnWrapper>
        <RowWrapper
          style={{ borderBottom: '1px solid #d4d4d4', padding: '15px 0px' }}
        >
          <ColumnWrapper style={{ flex: 1 }}>
            <Value style={{ paddingBottom: '0px' }}>{data.acquirer}</Value>
            <Value>{applyCnpjMask(data.document)}</Value>
          </ColumnWrapper>
          <ColumnWrapper style={{ flex: 1 }}>
            <Value>{data.creditCardBrand}</Value>
          </ColumnWrapper>
          <ColumnWrapper style={{ flex: 1 }}>
            <Value>{moment(data.dueDate).format('DD/MM/YYYY')}</Value>
          </ColumnWrapper>
          <ColumnWrapper style={{ flex: 1.5 }}>
            <Value> {handleCurrency({ value: data.value })}</Value>
          </ColumnWrapper>
        </RowWrapper>
      </FieldsWrapper>
    </ItemContainer>
  );
};

import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import useMobileDetect from 'use-mobile-detect-hook';

import { Setup, TokenService } from 'banking-service';
import moment from 'moment';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAuthDataContext } from 'providers/auth-provider';
import { useConfigContext } from 'providers/client-config-provider';
import { HttpService } from 'banking-service/utils/HttpService';
import DigitalKeyboardNumeric from 'components/DigitalKeyboardNumeric';
import { UserService, AccountService, SensediaService } from 'banking-service';
import qs from 'query-string';
import MoboDownload from 'components/MoboDownload';
import storage from 'utils/functions/storage';
import { useTheme } from 'styled-components';

import {
  Container,
  LoginHeadingText,
  TabIcon,
  BackButton,
  FormHeading,
  Logo,
  LoginContainer,
  FormTab,
  TabContainer,
  InputLabel,
  Wrapper,
  InputContainer,
  FormWrapper,
  InputWrapper,
  Input,
  FormButton,
  LoginButton,
  ForgotPasswordText,
  RedefinePasswordContainer,
  RulesText,
  InputItemContainer,
  KeyboardIcon,
  InputContainerBackground,
} from './styles';

const initialFirstLoginFormData = {
  step: null,
  currentAccessPassword: '',
  newAccessPassword: '',
  newAccessPasswordConfirmation: '',
  currentTransactionPassword: '',
  newTransactionPassword: '',
  newTransactionPasswordConfirmation: '',
};

const initialRecoveryFormData = {
  step: null,
  email: '',
  user: '',
  branch: '',
  account: '',
};

let ROUTE = null;

let keyboardChangeFuction = () => { };
let keyboardChangeOptionFuction = () => { };

function Login(props) {
  const alert = useAlert();

  const theme = useTheme();

  const [username, setUsername] = useState('');
  const [otp, setOtp] = useState('');
  const [onLoginProperties, setOnLoginProperties] = useState({});

  const [usernameParams, setUsernameParams] = useState('');
  const [password, setPassword] = useState('');
  const [optionList, setOptionList] = useState([]);
  const [isToggleSelected, setIsToggleSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { clientConfig } = useConfigContext();

  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [keyboardPlaceholder, setKeyboardPlaceholder] = useState('');
  const [keyboardCurrentValue, setKeyboardCurrentValue] = useState(null);
  const [keyboardCurrentOptions, setKeyboardCurrentOptions] = useState([]);

  const [mustValidateOtp, setMustValidateOtp] = useState(false);

  const [firstLoginForm, setFirstLoginForm] = useState({
    ...initialFirstLoginFormData,
  });
  const [recoveryForm, setRecoveryForm] = useState({
    ...initialRecoveryFormData,
  });

  if (!ROUTE) {
    ROUTE = props.location.pathname;
  }

  useEffect(() => {
    const usernameParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).username;
    if (usernameParams) {
      setUsernameParams(usernameParams);
    }

    const forgotPasswordDemand =
      qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).action === 'forgotPassword';

    if (forgotPasswordDemand) {
      setRecoveryForm((recoveryForm) => ({
        ...initialRecoveryFormData,
        step: 'FIRST',
      }));
    }
  }, [props.location.search]);

  const {
    isLoggedIn,
    onLogin,
    expirationCallbacks,
    expirationCallbacksToTest,
    tokenErrorCallback,
  } = useAuthDataContext();
  useEffect(() => {
    if (!usernameParams) {
      const loginHistory = storage.getItem('loginHistory');
      setUsername(loginHistory ? loginHistory.username || '' : '');
      setIsToggleSelected(
        loginHistory ? loginHistory.isToggleSelected || false : false
      );
    } else {
      setUsername(usernameParams);
    }
  }, [usernameParams]);

  function setKeyboardInfos(
    changeFnctn = () => { },
    event = null,
    placeholder = '',
    currentValue = null,
    changeOptionsFnctn = () => { },
    currentOptionValue = []
  ) {
    keyboardChangeFuction = (value) => changeFnctn(value, event);
    setKeyboardPlaceholder(placeholder);
    setKeyboardCurrentValue(currentValue);
    setKeyboardCurrentOptions(currentOptionValue);
    keyboardChangeOptionFuction = (value) => changeOptionsFnctn(value, event);
  }

  async function setupUser() {
    Setup(Number(process.env.REACT_APP_API_ENUM), { tokenErrorCallback });

    const resSecondLogin = await UserService.login(
      username,
      optionList,
      clientConfig.partner.clientId
    );

    if (resSecondLogin.success) {
      HttpService.defaultHeaders = {
        Authorization: resSecondLogin.res.jwt,
        access_token: resSecondLogin.res.accessToken,
        client_id: clientConfig.partner.clientId,
        'Content-Type': 'application/json',
      };
      storage.setItem(
        'sensedia',
        { access_token: resSecondLogin.res.accessToken },
        moment().add(50, 'minutes')
      );

      if (ROUTE === '/login/tokenExpirationTest') {
        UserService.user.auth.callbacks = expirationCallbacksToTest.map((e) =>
          setTimeout(e.function, e.time)
        );
        UserService.setExpirationTimeCallbacks({
          callbacks: expirationCallbacksToTest,
        });
      } else {
        UserService.setExpirationTimeCallbacks({
          callbacks: expirationCallbacks,
        });
      }

      const { branch, account } = AccountService.accounts[0];
      storage.setItem('fromLogin', true);
      if (resSecondLogin.res.mustValidateOtp) {
        setMustValidateOtp(true);
        setOnLoginProperties({
          ...UserService.user,
          username,
          token: resSecondLogin.res.jwt,
          isLoggedIn: true,
          account,
          branch,
          lastLoginAt: moment(),
          tokenExpiration: UserService._getExpirationTokenTime(),
          accounts: AccountService.accounts,
        });
      } else {
        onLogin({
          ...UserService.user,
          username,
          token: resSecondLogin.res.jwt,
          isLoggedIn: true,
          account,
          branch,
          lastLoginAt: moment(),
          tokenExpiration: UserService._getExpirationTokenTime(),
          accounts: AccountService.accounts,
        });
      }
    } else {
      setIsLoading(false);

      if (
        resSecondLogin.res.error &&
        resSecondLogin.res.error === 'ERROR_LOGIN_01'
      ) {
        alert.error('Usuário e/ou senha inválido(s)');
      } else if (
        resSecondLogin.res.error ===
        'Usuário bloqueado devido a muitas tentativas incorretas. Acesse Recuperar Senha ou entre em contato com o SAC.'
      ) {
        alert.info(
          'Sua senha expirou. Faça o novo cadastramento das suas senhas em "Esqueci minha senha" ou entre em contato com o SAC',
          { timeout: 15000 }
        );
      } else if (resSecondLogin.res.error === 'Usuário bloqueado') {
        alert.error('Usuário bloqueado.');
      } else if (
        resSecondLogin.res.error &&
        resSecondLogin.res.error === 'ERROR_LOGIN_02'
      ) {
        setFirstLoginForm((firstLoginForm) => ({
          ...firstLoginForm,
          step: 'FIRST',
        }));
      } else if (resSecondLogin.res.error) {
        alert.error(resSecondLogin.res.error);
      } else if (resSecondLogin.res.message) {
        alert.error(resSecondLogin.res.message);
      } else if (resSecondLogin.res.message) {
        alert.error(resSecondLogin.res.message);
      } else {
        alert.error('Ocorreu um erro inesperado. Verifique sua conexãoo');
      }
    }
  }

  function handleHistory() {
    storage.setItem('loginHistory', {
      username: isToggleSelected ? username : '',
      isToggleSelected,
    });
  }

  async function handleSubmitOtp(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      await TokenService.validateOtpOnLogin({ otp });
      onLogin({ ...onLoginProperties });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);

      alert.error(e.message);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (validates()) {
        await setupUser();
        setIsLoading(false);
        handleHistory();
      } else {
        setIsLoading(false);
        alert.error('Usuário ou senha incorretos');
      }
    } catch (e) {
      alert.error(e.message);
    }
  }

  function validates() {
    if (!optionList || !optionList.length) return false;
    return true;
  }

  const detectMobile = useMobileDetect();

  if (detectMobile.isMobile()) return <MoboDownload />;

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  const resolveFirstLoginButtonDisability = () => {
    const {
      step,
      accessCurrent,
      access,
      accessAgain,
      transactionCurrent,
      transaction,
      transactionAgain,
    } = firstLoginForm;
    if (step === 'FIRST') {
      return accessCurrent === '' || access === '' || access !== accessAgain;
    } else {
      return (
        accessCurrent === '' ||
        access === '' ||
        access !== accessAgain ||
        transactionCurrent === '' ||
        transaction === '' ||
        transactionAgain !== transaction
      );
    }
  };

  const resolveRecoveryButtonDisability = () => {
    const { user, email, branch, account } = recoveryForm;

    return user === '' || email === '' || branch === '' || account === '';
  };

  const containsLettersAndSpecial = (value) => !/^\d+$/.test(value);

  const handleFirstFormChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (value !== '' && containsLettersAndSpecial(value)) {
      return;
    }
    setFirstLoginForm((firstFormLogin) => ({
      ...firstFormLogin,
      [name]: value,
    }));
  };

  const handleRecoveryFormChange = (e) => {
    const { name, value } = e.target;
    setRecoveryForm((recoveryForm) => ({
      ...recoveryForm,
      [name]: value,
    }));
  };

  const handleFirstLoginButtonClick = async (e) => {
    e.preventDefault();
    const { step } = firstLoginForm;
    if (step === 'FIRST') {
      setFirstLoginForm((firstLoginForm) => ({
        ...firstLoginForm,
        step: 'SECOND',
      }));
    } else {
      setIsLoading(true);
      try {
        const {
          access_token,
          refresh_token,
        } = await SensediaService.getAccessToken({
          clientId: clientConfig.partner.clientId,
          clientSecret: clientConfig.partner.clientSecret,
        });

        storage.setItem(
          'sensedia',
          { access_token, refresh_token },
          moment().add(50, 'minutes')
        );

        const res = await UserService.changeFirstLoginPasswords({
          ...firstLoginForm,
          document: username,
          clientId: clientConfig.partner.clientId,
          accessToken: access_token,
        });

        alert.success('Senhas alteradas com sucesso');
        setFirstLoginForm({ ...initialFirstLoginFormData });
      } catch (e) {
        alert.error(
          e.error && e.error.length > 0
            ? e.error[0].field === 'access'
              ? `Acesso: ${e.error[0].message}`
              : e.error[0].field === 'transaction'
                ? `Transação: ${e.error[0].message} `
                : e.error[0].message
            : e.message
              ? e.message
              : 'Ocorreu um erro inesperado. Verifique sua conexão'
        );
      }
      setIsLoading(false);
    }
  };

  const handleForgotButtonClick = async (e) => {
    e.preventDefault();
    const { user, email, branch, account } = recoveryForm;
    Setup(Number(process.env.REACT_APP_API_ENUM), {});
    setIsLoading(true);
    try {
      const {
        access_token,
        refresh_token,
      } = await SensediaService.getAccessToken({
        clientId: clientConfig.partner.clientId,
        clientSecret: clientConfig.partner.clientSecret,
      });

      storage.setItem(
        'sensedia',
        { access_token, refresh_token },
        moment().add(50, 'minutes')
      );

      const res = await UserService.forgotPassword(
        email,
        user,
        branch.replace(/^0+/, ''),
        account,
        clientConfig.partner.clientId,
        access_token
      );

      alert.success(
        'Solicitação de Troca de Senha enviada com sucesso. Verifique seu e-mail.'
      );
      setRecoveryForm({ ...initialRecoveryFormData });
    } catch (e) {
      alert.error(
        e.error && e.error.length > 0
          ? e.error[0].message
          : e.message
            ? e.message
            : 'Ocorreu um erro inesperado. Verifique sua conexão'
      );
    }
    setIsLoading(false);
  };

  if (recoveryForm.step) {
    return (
      <LoadingSpinner isLoading={isLoading}>
        <Container loginImage={clientConfig.images.backgroundImageOnLogin}>
          <Logo
            src={clientConfig.images.brandNameImageOnLogin}
            alt={clientConfig.vars.appName}
          />
          <LoginContainer>
            <Wrapper>
              <FormWrapper>
                <LoginHeadingText>
                  Preencha com suas informações
                  <br />
                  para recuperação de senha:
                </LoginHeadingText>
                <InputContainer>
                  <InputContainerBackground />
                  <FormHeading>Esqueci minha Senha</FormHeading>
                  <InputWrapper marginBottom>
                    <InputLabel primary>E-mail *</InputLabel>
                    <Input
                      id="forgortEmailInput"
                      name="email"
                      onChange={handleRecoveryFormChange}
                      placeholder="Digite o E-mail"
                      value={recoveryForm.email}
                    />
                  </InputWrapper>
                  <InputWrapper marginBottom>
                    <InputLabel primary>Usuário *</InputLabel>
                    <Input
                      id="forgotUserInput"
                      name="user"
                      onChange={handleRecoveryFormChange}
                      placeholder="Digite o Usuário"
                      value={recoveryForm.user}
                    />
                  </InputWrapper>
                  <InputWrapper marginBottom>
                    <InputLabel primary>Agência *</InputLabel>
                    <Input
                      id="forgotBranchInput"
                      name="branch"
                      onChange={handleRecoveryFormChange}
                      placeholder="Digite a agência *"
                      value={recoveryForm.branch}
                      type={'number'}
                    />
                  </InputWrapper>
                  <InputWrapper marginBottom>
                    <InputLabel primary>Conta (com dígito) *</InputLabel>
                    <Input
                      id="forgotAccountInput"
                      name="account"
                      onChange={handleRecoveryFormChange}
                      placeholder="Digite o Número da Conta com dígito*"
                      value={recoveryForm.account}
                      type={'number'}
                    />
                  </InputWrapper>
                  <div style={{ display: 'flex' }}>
                    <BackButton
                      onClick={() => {
                        setRecoveryForm((recoveryForm) => ({
                          ...initialRecoveryFormData,
                        }));
                      }}
                      title="VOLTAR"
                    ></BackButton>
                    <FormButton
                      onClick={handleForgotButtonClick}
                      disabled={resolveRecoveryButtonDisability()}
                      title={'ENVIAR'}
                    ></FormButton>
                  </div>
                </InputContainer>
              </FormWrapper>
            </Wrapper>
          </LoginContainer>
        </Container>
      </LoadingSpinner>
    );
  }

  if (firstLoginForm.step) {
    return (
      <LoadingSpinner isLoading={isLoading}>
        <Container loginImage={clientConfig.images.backgroundImageOnLogin}>
          <Logo
            src={clientConfig.images.brandNameImageOnLogin}
            alt={clientConfig.vars.appName}
          />
          <LoginContainer>
            <Wrapper>
              {firstLoginForm.step === 'FIRST' ? (
                <FormWrapper>
                  <TabContainer>
                    <FormTab active>
                      <h3>PASSO 01</h3>
                      <p>alterar senha de acesso</p>
                    </FormTab>
                    <FormTab>
                      <h3>PASSO 02</h3>
                      <p>alterar senha de transação</p>
                    </FormTab>
                  </TabContainer>
                  <RedefinePasswordContainer>
                    <FormHeading>Alterar Senha de Acesso</FormHeading>
                    <InputWrapper
                      style={{ marginBottom: '30px', marginTop: '15px' }}
                    >
                      <InputLabel primary>
                        Senha de Acesso Provisória *
                      </InputLabel>
                      <Input
                        id="accessCurrentInput"
                        name="currentAccessPassword"
                        onChange={handleFirstFormChange}
                        placeholder="(Consulte seu e-mail para ver a senha provisória)"
                        value={firstLoginForm.currentAccessPassword}
                        type={'password'}
                        minLength={8}
                        maxLength={8}
                      />
                      <RulesText>
                        Deve conter 8 dígitos conforme recebido por e-mail
                        (apenas números)
                      </RulesText>
                    </InputWrapper>
                    <InputWrapper style={{ marginBottom: '30px' }}>
                      <InputLabel primary>Nova Senha de Acesso *</InputLabel>
                      <Input
                        id="accessInput"
                        name="newAccessPassword"
                        onChange={handleFirstFormChange}
                        placeholder="Digite a nova Senha de Acesso"
                        value={firstLoginForm.newAccessPassword}
                        type="password"
                        minLength={8}
                        maxLength={8}
                      />
                      <RulesText>
                        Deve conter 8 dígitos (apenas números)
                      </RulesText>
                    </InputWrapper>
                    <InputWrapper style={{ marginBottom: '20px' }}>
                      <InputLabel primary>
                        Repita a nova Senha de Acesso *
                      </InputLabel>
                      <Input
                        id="accessAgainInput"
                        name="newAccessPasswordConfirmation"
                        onChange={handleFirstFormChange}
                        value={firstLoginForm.newAccessPasswordConfirmation}
                        placeholder="Repita a nova Senha de Acesso"
                        type="password"
                        minLength={8}
                        maxLength={8}
                      />
                      <RulesText>
                        Deve conter 8 dígitos (apenas números)
                      </RulesText>
                    </InputWrapper>
                    {/* <BackButton
                          onClick={(e) => {
                            e.preventDefault();
                            console.log({ initialFirstLoginFormData })
                            setFirstLoginForm((firstLoginForm) => ({
                              ...initialFirstLoginFormData,
                            }));
                          }}
                        title="VOLTAR"
                      > 
                      </BackButton>*/}
                    <FormButton
                      onClick={handleFirstLoginButtonClick}
                      disabled={resolveFirstLoginButtonDisability()}
                      title={'CONTINUAR >'}
                    ></FormButton>
                  </RedefinePasswordContainer>
                </FormWrapper>
              ) : (
                <FormWrapper>
                  <TabContainer>
                    <FormTab
                      wasDone
                      onClick={() => {
                        setFirstLoginForm((firstLoginForm) => ({
                          ...firstLoginForm,
                          step: 'FIRST',
                        }));
                      }}
                    >
                      <h3>PASSO 01</h3>
                      <p>alterar senha de acesso</p>
                      <TabIcon />
                    </FormTab>
                    <FormTab active>
                      <h3>PASSO 02</h3>
                      <p>alterar senha de transação</p>
                    </FormTab>
                  </TabContainer>
                  <RedefinePasswordContainer>
                    <FormHeading>Alterar Senha de Transação</FormHeading>
                    <InputWrapper
                      style={{ marginBottom: '30px', marginTop: '15px' }}
                    >
                      <InputLabel primary>
                        Senha de Transação Provisória *
                      </InputLabel>
                      <Input
                        id="transactionCurrentInput"
                        name="currentTransactionPassword"
                        onChange={handleFirstFormChange}
                        placeholder="(Consulte seu e-mail para ver a senha provisória)"
                        value={firstLoginForm.currentTransactionPassword}
                        type={'password'}
                        minLength={4}
                        maxLength={4}
                      />
                      <RulesText>
                        Deve conter 4 dígitos conforme recebido por e-mail
                        (apenas números)
                      </RulesText>
                    </InputWrapper>
                    <InputWrapper style={{ marginBottom: '30px' }}>
                      <InputLabel primary>Nova Senha de Transação *</InputLabel>
                      <Input
                        id="transactionInput"
                        name="newTransactionPassword"
                        onChange={handleFirstFormChange}
                        placeholder="Digite a nova Senha de Transação"
                        type="password"
                        value={firstLoginForm.newTransactionPassword}
                        minLength={4}
                        maxLength={4}
                      />
                      <RulesText>
                        Deve conter 4 dígitos (apenas números)
                      </RulesText>
                    </InputWrapper>
                    <InputWrapper style={{ marginBottom: '20px' }}>
                      <InputLabel primary>
                        Repita a nova Senha de Transação *
                      </InputLabel>
                      <Input
                        id="transactionAgainInput"
                        name="newTransactionPasswordConfirmation"
                        onChange={handleFirstFormChange}
                        value={
                          firstLoginForm.newTransactionPasswordConfirmation
                        }
                        placeholder="Repita a nova Senha de Transação"
                        type="password"
                        minLength={4}
                        maxLength={4}
                      />
                      <RulesText>
                        Deve conter 4 dígitos (apenas números)
                      </RulesText>
                    </InputWrapper>
                    <div style={{ display: 'flex' }}>
                      <BackButton
                        onClick={(e) => {
                          e.preventDefault();
                          setFirstLoginForm((firstLoginForm) => ({
                            ...firstLoginForm,
                            step: 'FIRST',
                          }));
                        }}
                        title="VOLTAR"
                      ></BackButton>
                      <FormButton
                        onClick={handleFirstLoginButtonClick}
                        disabled={resolveFirstLoginButtonDisability()}
                        title={'CONCLUIR'}
                      ></FormButton>
                    </div>
                  </RedefinePasswordContainer>
                </FormWrapper>
              )}
            </Wrapper>
          </LoginContainer>
        </Container>
      </LoadingSpinner>
    );
  }

  if (mustValidateOtp) {
    return (
      <LoadingSpinner isLoading={isLoading}>
        <Container loginImage={clientConfig.images.backgroundImageOnLogin}>
          <Logo
            src={clientConfig.images.brandNameImageOnLogin}
            alt={clientConfig.vars.appName}
          />
          <LoginContainer isLogin>
            <Wrapper>
              <LoginHeadingText>
                Olá, {UserService.user.name}!
                <br />
                Insira seu token para acesso total
                <br />
                ao Internet Banking
              </LoginHeadingText>{' '}
              <FormWrapper onSubmit={handleSubmitOtp}>
                <InputContainer>
                  <InputContainerBackground />
                  <InputWrapper marginBottom>
                    <InputLabel primary>Digite o Token</InputLabel>
                    <Input
                      id="otpInput"
                      primary
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Digite seu token"
                      value={otp}
                      bottomSpacing
                    />
                  </InputWrapper>

                  <LoginButton
                    id="otpButton"
                    disabled={otp.length < 6}
                    type="submit"
                    style={{ marginTop: '5px' }}
                  >
                    ENTRAR
                  </LoginButton>
                  <p
                    style={{
                      alignItems: 'center',
                      color: 'grey',
                      fontSize: '14px',
                      fontFamily: 'MontserratLight',
                      margin: '30px 0px',
                      marginLeft: '15px',
                      maxWidth: '350px',
                    }}
                  >
                    Não possui Token atribuído ou está atribuído a outro
                    dispositivo? Entre em contato conosco no{' '}
                    <a
                      style={{
                        color: theme.Cor_base_marca_01,
                      }}
                      href="https://www.bancotopazio.com.br/atendimento"
                      target="_blank"
                    >
                      SAC
                    </a>
                  </p>
                </InputContainer>
              </FormWrapper>
            </Wrapper>
          </LoginContainer>
        </Container>
      </LoadingSpinner>
    );
  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container loginImage={clientConfig.images.backgroundImageOnLogin}>
        <Logo
          src={clientConfig.images.brandNameImageOnLogin}
          alt={clientConfig.vars.appName}
        />
        <LoginContainer isLogin>
          <Wrapper>
            <LoginHeadingText>
              Olá!
              <br />
              Preencha seus dados para
              <br />
              acessar o Internet Banking
            </LoginHeadingText>
            <FormWrapper onSubmit={handleSubmit}>
              <InputContainer>
                <InputContainerBackground />
                <InputWrapper marginBottom>
                  <InputLabel primary>Usuário</InputLabel>
                  <Input
                    id="usernameInput"
                    primary
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Digite seu usuário"
                    value={username}
                    bottomSpacing
                  />
                </InputWrapper>
                <InputWrapper marginBottom>
                  <InputLabel primary>Senha</InputLabel>
                  <InputItemContainer>
                    <Input
                      style={{ fontSize: '22px' }}
                      id="passwordInput"
                      primary
                      onChange={(e) => setPassword(e.target.value)}
                      onSelect={(e) => {
                        e.target.selectionStart = e.target.selectionEnd;
                      }}
                      placeholder="Digite sua senha"
                      type="password"
                      readOnly
                      maxLength="8"
                      value={password}
                      isFocused={keyboardVisible}
                      hasIcon
                      onFocus={(e) => {
                        setKeyboardInfos(
                          (value) => {
                            if (value === undefined || value === null) return;
                            setPassword(value);
                          },
                          e,
                          'Digite sua senha',
                          password,
                          (value) => {
                            if (value === undefined || value === null) return;
                            setOptionList(value);
                          },
                          optionList
                        );
                        setKeyboardVisible(true);
                      }}
                    />
                    <KeyboardIcon />
                  </InputItemContainer>
                </InputWrapper>
                <DigitalKeyboardNumeric
                  placeholder={keyboardPlaceholder}
                  currentValue={keyboardCurrentValue}
                  closeAction={() => {
                    setKeyboardInfos();
                    setKeyboardVisible(false);
                  }}
                  currentOptionsItem={keyboardCurrentOptions}
                  setArrayPassword={(value) =>
                    keyboardChangeOptionFuction(value)
                  }
                  onChange={(value) => keyboardChangeFuction(value)}
                  visible={keyboardVisible}
                />
                <LoginButton
                  id="loginButton"
                  disabled={
                    username === '' || !optionList || optionList.length < 256
                  }
                  activeKeyboard={keyboardVisible}
                  type="submit"
                >
                  ACESSAR CONTA
                </LoginButton>
                <ForgotPasswordText
                  onClick={() =>
                    setRecoveryForm((recoveryForm) => ({
                      ...recoveryForm,
                      step: 'FIRST',
                    }))
                  }
                  id="forgotPasswordButton"
                >
                  Esqueci minha senha
                </ForgotPasswordText>
              </InputContainer>
            </FormWrapper>
          </Wrapper>
        </LoginContainer>
      </Container>
    </LoadingSpinner>
  );
}

export default withRouter(Login);

import styled from "styled-components";
import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 50px;
  background-color: ${props => props.theme.Cor_base_marca_01};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: ${props => props.theme.Cor_base_marca_03};
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.Cor_base_marca_03};
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${props => props.theme.Cor_base_marca_01};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.Cor_base_marca_01};
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid ${props => props.theme.Cor_base_marca_03};
    border-radius: 50%;
    background-color: ${props => props.theme.Cor_auxiliar_03};

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: ${props => props.theme.Cor_base_marca_01};
  }

  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px ${props => props.theme.Cor_base_marca_01};
    -moz-box-shadow: 0px 0px 3px 2px ${props => props.theme.Cor_base_marca_01};
    box-shadow: 0px 0px 2px 3px ${props => props.theme.Cor_base_marca_01};
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px ${props => props.theme.Cor_base_marca_03};
    -moz-box-shadow: 0px 0px 5px 5px ${props => props.theme.Cor_base_marca_03};
    box-shadow: 0px 0px 5px 5px ${props => props.theme.Cor_base_marca_03};
  }

`;

export const FormWrapper = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 50px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => (props.marginBottom ? "50px" : "0px")};
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 25px;
  font-size: 16px;
  text-align: center;
  border-width: 0px 0px 3px 0px;
  color: ${props => props.theme.Cor_auxiliar_01};
  background-color: transparent;
  margin: 0;
  border-color: ${props => props.theme.Cor_auxiliar_01}55;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 200;
    color: ${props => props.theme.Cor_auxiliar_01};
    margin-bottom: 0px;
  }
  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: 200;
    color: ${props => props.theme.Cor_auxiliar_01};
    margin-bottom: 0px;
  }
`;

export const ConfirmButton = styled.button`
  margin-top: 65px;
  border-radius: 2px;
  background-color: ${props => props.theme.Cor_base_marca_01};
  align-self: center;
  width: 100%;
  ${media.phone`
    width: 80%;
 `}
  padding: 15px;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.Cor_auxiliar_01};
  border: 0;
  text-transform: uppercase;
  border-radius: 30px;

  :focus {
    outline: 0px;
  }

  :hover:not(:disabled) {
    cursor: pointer;
  }

  :disabled {
    opacity: 0.6;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const Header1 = styled.span`
  margin: 0px;
  font-weight: 200;
  text-align: center;
  color: ${props => props.theme.Cor_auxiliar_01};
  font-size: 12px;
  font-family: "SourceSansPro", Arial, sans-serif;
`;

import React from 'react';
import moment from 'moment';
import {
  ModalContainer,
  BackButton,
  Title,
  HeaderWrapper,
  Text,
  ActionsWrapper,
  GreenButton
} from './styles';

import { ModalWrapper } from 'components/CustomModal';

const LIMIT_IN_MONTHS = 11;

function TokenActivationModal({
  onActiveClick,
  onBackHomeClick,
  hideModalCallback,
  onConfirmClick,
  lastUpdate,
  differenceInMonths,
  backButtonText
}) {
  return (
    <ModalWrapper>
      <ModalContainer id="tokenExpirationDiv">
        <HeaderWrapper>
          <Title type={'error'}>{'Atualização Cadastral'}</Title>
        </HeaderWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}
        >
          <Text style={{ marginBottom: '5px' }}>Prezado Cliente,</Text>
          <br />

          <Text style={{ marginBottom: '0px' }}>
            Para que continue utilizando nossos serviços você precisa manter os
            cadastros PJ e PF atualizados.
          </Text>
          <Text>
            Portanto, o Banco Topázio solicita que você atualize seus dados
            {differenceInMonths > LIMIT_IN_MONTHS
              ? ' o mais breve possível'
              : ` até ${moment(lastUpdate)
                  .add(1, 'year')
                  .format('DD/MM/YYYY')}`}
            .
          </Text>
          <br />
          <Text style={{ marginBottom: '20px' }}>
            <strong>
              Caso você não seja o administrador da conta, pedimos a gentileza
              que informe ao responsável.
            </strong>
          </Text>
          <br />
          <Text>Agradecemos a colaboração.</Text>
        </div>

        <ActionsWrapper>
          <BackButton style={{ marginRight: '25px' }} onClick={onConfirmClick}>
            {'ATUALIZAR CADASTRO'}
          </BackButton>

          <BackButton
            onClick={() => {
              hideModalCallback();
            }}
          >
            {'Cancelar'}
          </BackButton>
        </ActionsWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default TokenActivationModal;

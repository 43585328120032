import styled from 'styled-components';
import { Close } from 'styled-icons/material';

export const KeyboardMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  height: 0px;
  transition: 300ms;
  background-color: transparent;
  /* min-width: ${({ layout }) =>
    layout === 'numeric' ? '200px' : '515px'}; */
  height: ${({ height }) => height}px;
  ${({ visible }) =>
    visible
      ? ''
      : `
    opacity: 0;
    pointer-events: none;
  `}
`;
export const KeyboardInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  & .keyboard-main-container-custom {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    & .hg-button > span {
      vertical-align: middle;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const KeyboardInput = styled.input`
  flex: 1;
  height: 35px;
  color: #333;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  padding: 12px;
  padding-left: 16px;
  border: none !important;
  outline: none !important;
`;

export const KeyboardCloseButton = styled.a`
  width: 60px;
  height: 59px;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.225s;
  background-color: #fff;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.066);
  }
`;

export const CloseIcon = styled(Close)`
  color: #666;
  width: 50%;
  font-weight: 400;
`;

export const BottomOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 35%;
`;

export const BottomButton = styled.a`
  display flex;
  justify-content: center;
  align-items: center;
  padding: 14px 8px;
  font-size: 20px;
  width: 100%;
  /* min-height: 36px; */
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.Cor_base_marca_01};
  height: auto;
  text-decoration: none !important;
  transition: 225ms;
  font-weight: 200;
  border-radius: 6px;
  &:hover {
    cursor: pointer;
  }
`;

export const ConfirmButton = styled(BottomButton)`
  background-color: ${(props) =>
    props.theme.login.numericInputConfirmationButton.backgroundColor};
  color: ${(props) => props.theme.login.numericInputConfirmationButton.color};
  /* border-bottom-right-radius: 6px; */
  border-radius: 6px;
  margin-top: 8px;
  border: ${(props) =>
    `1px solid ${props.theme.login.numericInputConfirmationButton.borderColor}`};
`;

export const ClearButton = styled(BottomButton)`
  background-color: ${(props) =>
    props.theme.login.numericInputClearButton.backgroundColor};
  color: ${(props) => props.theme.login.numericInputClearButton.color};
  /* border-bottom-left-radius: 6px; */
  border-radius: 6px;
  border: ${(props) =>
    `1px solid ${props.theme.login.numericInputClearButton.borderColor}`};
`;

export const KeysMainContainer = styled.div`
  width: 64%;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const KeysLineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;

export const KeyItem = styled.a`
  display flex;
  justify-content: center;
  background-color: ${(props) =>
    props.theme.login.numericInput.backgroundColor};
  align-items: center;
  padding: 18px 8px;
  border: ${(props) =>
    `1px solid ${props.theme.login.numericInput.borderColor}`};
  margin-right: 6px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.login.numericInput.color};
  width: 100%;
  height: auto;
  text-decoration: none !important;
  transition: 225ms;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:active {
    opacity: 0.58;
  }
`;

import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import PrintableWrapper from 'components/PrintableWrapper';
import { InvestmentService } from 'banking-service';
import { CSVLink, CSVDownload } from 'react-csv';
import { useConfigContext } from 'providers/client-config-provider';
import {
  Container,
  ContentWrapper,
  Label,
  Title,
  Value,
  NoItemsAvailableLabel,
  Wrapper,
  ResumeContainer,
  SectionWrapper,
  TextsWrapper,
  HeaderCardWrapper,
  BoxContainer,
  BoxTitle,
  BoxValue,
  QueryFieldLabel,
  DownloadIconPDF,
  DownloadIconCSV,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import { handleCurrency } from 'utils/functions/currency';
import CustomDatePicker from 'components/CustomDatePicker';
import RadioButton from 'components/RadioButton';

const QueryBox = ({ form, onChangeForm }) => {
  return (
    <ResumeContainer style={{ padding: '15px 0px', marginBottom: '30px' }}>
      <CustomDatePicker
        date={form.date}
        onChange={(val) => onChangeForm({ ...form, date: val })}
        placeholder="Data"
        disAllowFutureDates
        style={{ alignItems: 'center', marginLeft: '30px' }}
      />
      {/*       <div style={{ display: 'flex', flexDirection: 'column', flex: 3, justifyContent: 'center', alignItems: 'flex-start' }}>
        <QueryFieldLabel>Condição de Resgate</QueryFieldLabel>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <RadioButton
            onSelect={(group, value) => { onChangeForm({ ...form, radioButtonValue: value }) }}
            labelStyle={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}
            style={{ marginRight: '10px' }}
            value=''
            group='COND_RESGATE'
            label={'Todos'}
            checked={form.radioButtonValue === ''}
          />
          <RadioButton
            onSelect={(group, value) => { onChangeForm({ ...form, radioButtonValue: value }) }}
            labelStyle={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}
            style={{ marginRight: '10px' }}
            value={1}
            group='COND_RESGATE'
            label={'Com liquidez'}
            checked={form.radioButtonValue === 1}
          />
          <RadioButton
            onSelect={(group, value) => { onChangeForm({ ...form, radioButtonValue: value }) }}
            labelStyle={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}
            style={{ marginRight: '10px' }}
            value={2}
            group='COND_RESGATE'
            label={'Sem liquidez'}
            checked={form.radioButtonValue === 2}
          />
        </div>
      </div> */}
    </ResumeContainer>
  );
};

const Box = ({ isCard, title, value }) => {
  return (
    <BoxContainer isCard={isCard}>
      <BoxTitle isCard={isCard}>{title}</BoxTitle>
      <BoxValue isCard={isCard}>{value}</BoxValue>
    </BoxContainer>
  );
};

const ResumeItem = ({
  finalBalance,
  grossProfitability,
  iof,
  incomeTaxValue,
  available,
}) => (
  <ResumeContainer>
    <SectionWrapper>
      <TextsWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Title>Saldo Total Bruto:</Title>
        <Title>Total Rentabilidade:</Title>
        <Title>Total Impostos:</Title>
      </TextsWrapper>
      <TextsWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginLeft: '10px',
        }}
      >
        <Value>{handleCurrency({ value: finalBalance })}</Value>
        <Value>{handleCurrency({ value: grossProfitability })}</Value>
        <Value>{handleCurrency({ value: incomeTaxValue })}</Value>
      </TextsWrapper>
    </SectionWrapper>
    <SectionWrapper
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        isCard={false}
        title={'SALDO TOTAL LÍQUIDO'}
        value={handleCurrency({ value: available })}
      />
    </SectionWrapper>
  </ResumeContainer>
);

const CardItem = ({
  paperName,
  emissionDate,
  dueDate,
  indexPercentage,
  index,
  investment,
  finalBalance,
  grossProfitability,
  incomeTaxValue,
  available,
  isPrintable,
}) => (
  <ResumeContainer isCard>
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <HeaderCardWrapper>
        <Value style={{ marginRight: '30px' }}>{paperName}</Value>
        <BoxTitle style={{ fontSize: '12px', marginRight: '10px' }} isCard>
          Emissão:{' '}
        </BoxTitle>
        <Value style={{ marginRight: '40px' }}>
          {moment(emissionDate).format('DD/MM/YYYY')}
        </Value>
        <BoxTitle style={{ fontSize: '12px', marginRight: '10px' }} isCard>
          Vencimento:{' '}
        </BoxTitle>
        <Value style={{}}>{moment(dueDate).format('DD/MM/YYYY')}</Value>
      </HeaderCardWrapper>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <SectionWrapper
          hiddenOnSmallScreen={!isPrintable}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            isCard
            title={'Taxa/Indexador:'}
            value={`${indexPercentage}% ${index}`}
          />
        </SectionWrapper>
        <SectionWrapper
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            isCard
            title={'Aplicação:'}
            value={handleCurrency({ value: investment })}
          />
        </SectionWrapper>
        <SectionWrapper
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            isCard
            title={'Rendimentos:'}
            value={handleCurrency({ value: grossProfitability })}
          />
        </SectionWrapper>
        <SectionWrapper
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            isCard
            title={'Bruto:'}
            value={handleCurrency({ value: finalBalance })}
          />
        </SectionWrapper>
        <SectionWrapper
          hiddenOnSmallScreen={!isPrintable}
          style={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            isCard
            title={'Impostos:'}
            value={handleCurrency({ value: incomeTaxValue })}
          />
        </SectionWrapper>
        <SectionWrapper
          style={{ flex: 1.5, justifyContent: 'center', alignItems: 'center' }}
        >
          <Box title={'LÍQUIDO'} value={handleCurrency({ value: available })} />
        </SectionWrapper>
      </div>
    </div>
  </ResumeContainer>
);

function InvesmentsBalance({ section = 'Investimentos', name = 'Saldo' }) {
  const alert = useAlert();

  const componentRef = useRef();
  const { clientConfig } = useConfigContext();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [form, setForm] = useState({ date: moment(), radioButtonValue: '' });

  const [isLoading, setIsLoading] = useState(false);

  const [resume, setResume] = useState({});
  const [operations, setOperations] = useState([]);

  useEffect(() => {
    const fetchinvestiments = async () => {
      setIsLoading(true);
      setError('');
      try {
        const res = await InvestmentService.getNewBalance({
          date: form.date,
          liquidity: form.radioButtonValue,
        });
        setResume({ ...res.resume });
        setOperations([
          ...res.products.sort(function(a, b) {
            const x = new Date(a.emissionDate).getTime();
            const y = new Date(b.emissionDate).getTime();
            return x < y ? 1 : -1;
          }),
        ]);
      } catch (e) {
        setResume({});
        setOperations([]);
      }
      setIsLoading(false);
    };
    fetchinvestiments();
  }, [form.date, form.radioButtonValue]);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Wrapper>
              <QueryBox
                onChangeForm={(val) => setForm({ ...val })}
                form={form}
              />
            </Wrapper>
            {operations.length === 0 ? (
              <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
                Nenhum Papel de Investimento encontrado
              </NoItemsAvailableLabel>
            ) : (
              <div>
                <Wrapper>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '25px',
                      width: '100%',
                    }}
                  >
                    <Label style={{}}>
                      Resumo do total das aplicações em{' '}
                      <strong>{form.date.format('DD/MM/YYYY')}</strong>
                    </Label>
                    <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                      <CSVLink
                        style={{ alignSelf: 'flex-end', margin: 'auto' }}
                        filename={`EXTRATO INVESTIMENTOS REFERENTE À ${form.date.format(
                          'DD/MM/YYYY'
                        )} - EMITIDO EM ${moment().format(
                          'DD/MM/YYYY - HH:mm'
                        )} - ${clientConfig.vars.appName}.csv`}
                        data={[
                          ...operations.map((e) => ({
                            ...e,
                            emissionDate: moment(e.emissionDate).format(
                              'YYYY-MM-DD'
                            ),
                            dueDate: moment(e.dueDate).format('YYYY-MM-DD'),
                            index: `${e.indexPercentage}% ${e.index}`,
                            investment: `${e.investment
                              .toString()
                              .replace('.', ',')}`,
                            grossProfitability: `${e.grossProfitability
                              .toString()
                              .replace('.', ',')}`,
                            incomeTaxValue: `${e.incomeTaxValue
                              .toString()
                              .replace('.', ',')}`,
                            available: `${e.available
                              .toString()
                              .replace('.', ',')}`,
                          })),
                        ]}
                        separator={';'}
                        headers={[
                          { label: 'Título', key: 'paperId' },
                          { label: 'Descrição', key: 'paperName' },
                          { label: 'Emissão', key: 'emissionDate' },
                          { label: 'Vencimento', key: 'dueDate' },
                          { label: 'Taxa/Indexador', key: 'index' },
                          { label: 'Aplicação', key: 'investment' },
                          { label: 'Bruto', key: 'grossProfitability' },
                          { label: 'Impostos', key: 'incomeTaxValue' },
                          { label: 'Líquido', key: 'available' },
                        ]}
                      >
                        <DownloadIconCSV
                          style={{
                            display: !operations ? 'none' : 'initial',
                            marginRight: '5px',
                          }}
                        />
                      </CSVLink>
                      <ReactToPrint
                        style={{ alignSelf: 'flex-end', margin: 'auto' }}
                        trigger={() => <DownloadIconPDF />}
                        content={() => componentRef.current}
                      />
                    </div>
                  </div>
                  <ResumeItem {...resume} />
                </Wrapper>
                <Wrapper style={{ marginTop: '30px' }}>
                  <Label style={{ marginBottom: '25px' }}>
                    Saldo detalhado
                  </Label>
                  {operations.map((e) => (
                    <CardItem style={{ marginBottom: '20px' }} {...e} />
                  ))}
                </Wrapper>

                <div style={{ display: 'none' }}>
                  <PrintableWrapper
                    topLabelText={`EXTRATO DE INVESTIMENTOS ${
                      form.radioButtonValue === 1
                        ? '(COM LIQUIDEZ)'
                        : form.radioButtonValue === 2
                        ? '(SEM LIQUIDEZ)'
                        : ''
                    }`}
                    ref={componentRef}
                    receiptContactInformations={
                      clientConfig.vars.receiptContactInformations
                    }
                    logo={clientConfig.images.receiptsLogo}
                  >
                    <div style={{ display: 'block', flexDirection: 'column' }}>
                      <Wrapper>
                        <Label style={{}}>
                          Resumo do total das aplicações em{' '}
                          <strong>{form.date.format('DD/MM/YYYY')}</strong>
                        </Label>
                        <ResumeItem {...resume} />
                      </Wrapper>
                      <Wrapper style={{ marginTop: '30px' }}>
                        <Label style={{ marginBottom: '25px' }}>
                          Saldo detalhado
                        </Label>
                        {operations.map((e) => (
                          <CardItem
                            style={{ marginBottom: '20px' }}
                            {...e}
                            isPrintable={true}
                          />
                        ))}
                      </Wrapper>
                    </div>
                  </PrintableWrapper>
                </div>
              </div>
            )}
          </div>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default InvesmentsBalance;

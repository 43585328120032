import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(83, 86, 90, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Modal = styled.div`
  min-width: 574px;
  background-color: #fff;
  padding: 38px 52px 60px;
  color: #53565a;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
  margin-top: 24.255vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

export const TextTitle = styled.h4`
  font-family: 'MontserratMedium';
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  letter-spacing: 0.22px;
  color: #53565a;
  margin-bottom: 36px;
`;

export const TextDescription = styled.p`
  font-family: 'MontserratXLight';
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  letter-spacing: 0.22px;
  color: #53565a;
  align-self: flex-start;
  text-align: left;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 33px;
`;

export const ButtonItem = styled.button`
  transition: 175ms;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  min-width: 98px;
  min-height: 40px;
  border-radius: 20px;
  outline: none !important;
  background: none;
  background-color: #fff;
  border: 1px solid ${(props) => (props.cancel ? '#fac762' : '#2caa3b')};
  color: ${(props) => (props.cancel ? '#fac762' : '#2caa3b')};
  margin: 0;
  margin-right: ${(props) => (props.cancel ? '100px' : '0')};
  font-family: 'MontserratMedium';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.22px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.cancel ? 'rgb(250, 199 ,98, 0.11)' : 'rgb(44, 170, 59, 0.11)'};
  }
`;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { AccountService } from 'banking-service';
import { useModal } from 'react-modal-hook';
import { Collapse } from 'react-collapse';
import {
  Container,
  ContentWrapper,
  ModalContainer,
  CloseButton,
  ItemContainer,
  ColumnWrapper,
  Title,
  Value,
  ColoredValue,
  NoItemsAvailableLabel,
  IsOpenedIcon,
  IsNotOpenedIcon,
  Wrapper,
  TableLabel,
  FieldsWrapper,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import ReactModal from 'react-modal';
import TokenActivation from 'components/TokenActivation';
import { handleCurrency } from 'utils/functions/currency';
import CreditResumeList from 'components/CreditResumeList';

function Credit({ section = 'Crédito', name = 'Capital de Giro' }) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);
  const [cronImage, setCronImage] = useState(null);
  const [isPingingToken, setIsPingingToken] = useState(null);

  const [contracts, setContracts] = useState([]);

  const Item = ({
    contractInfo,
    contractResumeListData,
    contractDetailedData,
  }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
      <ItemContainer>
        <FieldsWrapper>
          <ColumnWrapper>
            <Title>Contrato</Title>
            <Value>{contractInfo.identifier}</Value>
          </ColumnWrapper>
          <ColumnWrapper style={{ flex: 3 }}>
            <Title>Descr.</Title>
            <Value>
              {contractInfo.product.length > 50
                ? `${contractInfo.product.substring(0, 50)}...`
                : contractInfo.product}
            </Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title>Início</Title>
            <Value>{moment(contractInfo.startDate).format('DD/MM/YYYY')}</Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title>Vencimento</Title>
            <Value>{moment(contractInfo.endDate).format('DD/MM/YYYY')}</Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title>Parcel.</Title>
            <Value>{`${contractInfo.currentInstallment}/${contractInfo.installments}`}</Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title>Total Financ.</Title>
            <ColoredValue colored>
              {handleCurrency({ value: contractInfo.financedTotal })}
            </ColoredValue>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title style={{ width: '150px' }}>Saldo devedor atualizado</Title>
            <ColoredValue colored>
              {handleCurrency({ value: contractInfo.balanceTotal })}
            </ColoredValue>
          </ColumnWrapper>
          <ColumnWrapper>
            {isOpened && (
              <IsOpenedIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpened((isOpened) => !isOpened)}
              />
            )}
            {!isOpened && (
              <IsNotOpenedIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpened((isOpened) => !isOpened)}
              />
            )}
          </ColumnWrapper>
        </FieldsWrapper>
        <div style={{ width: '100%' }}>
          <Collapse style={{ width: '100%' }} isOpened={isOpened}>
            <CreditResumeList
              contractResumeListData={contractResumeListData}
              contractDetailedData={contractDetailedData}
            />
          </Collapse>
        </div>
      </ItemContainer>
    );
  };

  useEffect(() => {
    const fetchContracts = async () => {
      setIsLoading(true);
      setError('');
      try {
        const res = await AccountService._getContracts();

        setContracts([...res]);
      } catch (e) {}
      setIsLoading(false);
    };

    fetchContracts();
  }, []);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          {contracts.length === 0 ? (
            <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
              Nenhum contrato encontrado
            </NoItemsAvailableLabel>
          ) : (
            <Wrapper>
              <TableLabel style={{ alignSelf: 'flex-start' }}>
                CONTRATOS
              </TableLabel>
              {contracts.map((e) => {
                return (
                  <Item
                    contractInfo={{ ...e.contractInfo }}
                    contractResumeListData={{ ...e.contractInfo }}
                    contractDetailedData={[...e.entries]}
                  />
                );
              })}
            </Wrapper>
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Credit;

import React from 'react';

import { RowWrapper, ColumnWrapper, Text } from '../common';
import { RevisionIcon, UpdatedIcon, UnupdatedIcon } from './styles';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const GeneralStatusComponent = ({ documentsStatus }) => (
  <RowWrapper
    style={{
      cursor: 'pointer',
      width: '100%'
    }}
  >
    {documentsStatus.status === 'revision' ? (
      <>
        <RevisionIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.alert }}>
          {' '}
          Documentação em análise
        </Text>
      </>
    ) : documentsStatus.status === 'update' ||
      !documentsStatus.needCadastralUpdate ? (
      <>
        <UpdatedIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.brandColor1 }}>
          {' '}
          Cadastro Atualizado
        </Text>
      </>
    ) : documentsStatus.needCadastralUpdate ? (
      <>
        <UnupdatedIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.danger }}>
          {' '}
          Cadastro Desatualizado
        </Text>
      </>
    ) : (
      <>
        <RevisionIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.brandColor1 }}>
          Status Indefinido
        </Text>
      </>
    )}
  </RowWrapper>
);

export default GeneralStatusComponent;

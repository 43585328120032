import React, { useState, useEffect } from 'react';
import { AnticipationService } from 'banking-service';
import { withRouter } from 'react-router-dom';

import { Container, ContentWrapper, NoItemsAvailableLabel } from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import SchedulesProcessing from './SchedulesProcessing';
import ScheduleList from './ScheduleList';

function Schedules({
  section = 'Antecipação de Recebíveis',
  name = {
    ready: 'Agenda',
    notReady: 'Agenda indisponível no momento',
  },
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);
  const [schedulesReady, setSchedulesReady] = useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [scheduleId, setScheduleId] = useState(null);

  useEffect(() => {
    const fetchScheduleStatus = async () => {
      // Enquanto rota de verificação não estiver pronta, trocar entre em false e true para acessar telas de agenda e agendas indisponiveis
      // setSchedulesReady(
      //   props.location.search === '?FOR_TESTING_schedules_ready=true'
      // );
      setIsLoading(true);
      setError('');
      try {
        const res = await AnticipationService.getSchedules();
        if (!res) {
          setSchedulesReady(null);
          setScheduleList([]);
          setScheduleId(null);
          setIsLoading(false);
          return;
        }
        setSchedulesReady(res.status === 'COMPLETED');
        setScheduleList(res.schedules);
        setScheduleId(res.id);
      } catch (e) {
        console.log({ e });
        setSchedulesReady(null);
        setScheduleList([]);
        setScheduleId(null);
      }
      setIsLoading(false);
    };
    fetchScheduleStatus();
  }, []);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle
          section={section}
          title={!isLoading && !schedulesReady ? name.notReady : name.ready}
        ></ScreenTitle>
        <ContentWrapper>
          {isLoading ? (
            ''
          ) : schedulesReady === null ? (
            <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
              Ops! Tivemos um problema ao carregar as informações. Tente
              novamente em instantes.
            </NoItemsAvailableLabel>
          ) : !schedulesReady ? (
            <SchedulesProcessing {...props} />
          ) : (
            <ScheduleList
              scheduleListReq={scheduleList}
              scheduleId={scheduleId}
              {...props}
            />
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default withRouter(Schedules);

import moment from 'moment';
import { pipe } from "utils/common";


const TYPES = {
  CREDIT: "C",
  DEBIT: "D"
};

const isAValidNumber = value => {
  return typeof value === "number";
};

const resolveSignal = ({ value, type }) => {
  if (!type) return { value };
  switch (type.toUpperCase()) {
    case TYPES.CREDIT:
      return { value: Math.abs(value) };
    case TYPES.DEBIT:
      return { value: Math.abs(value) * -1 };
    default:
      return {
        value
      };
  }
};

const insertCurrency = ({ value }) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value);
};

const handleCurrency = ({ value, type }) => {
  if (!isAValidNumber(value)) return "INDISP.";
  return resolveCurrency({ value, type });
};

const resolveCurrency = pipe(
  resolveSignal,
  insertCurrency
);

const formatOnlyNumbersAndDates = value => {
  return moment(value, 'YYYY-MM-DD', true).isValid() ? moment(value).format('DD/MM/YYYY') : typeof value === "string" ? value : handleCurrency({ value });
};

function revertBrazilianCurrencyToFloat(stringValue) {
  return parseFloat(stringValue.replace(/\D/g, '') / 100).toFixed(2);


}

function convertBrazilianCurrencyToFloat(stringValue) {
  const newValue = stringValue.replace('R$', '').trim();
  return parseFloat(newValue.split('.').join('').replace(',', '.'));
}

function convertFloatToBrazilianCurrency(value, cashSign=true) {
  let formatedValue = '';
  if (typeof value === 'number') {
    value = parseFloat(value.toFixed(2));
    if (value === Math.round(value)) {
      value *= 100;
    }
    value = value.toString();
    if (value.split('.')[1] && value.split('.')[1].length === 1) {
      value += '0';
    }
  }
  value = value.replace(/[^\d]+/g,'');
  if (value.length) value = parseFloat(value).toString();
  const size = value.length;
  switch(size) {
    case 0:
      formatedValue = `0,00`;
    break;
    case 1:
      formatedValue = `0,0${value}`;
    break;
    case 2:
      formatedValue = `0,${value}`;
    break;
    default:
      let mainValue = value.substr(0,size-2);
      const decimals = value.substr(size-2);
      for (let i = mainValue.length-3; i > 0; i-=3) {
        mainValue = mainValue.substr(0,i)+
        '.'+
        mainValue.substr(i);
      }
      formatedValue = mainValue+','+decimals;
  }
  if (cashSign)
    formatedValue = 'R$ '+formatedValue;
  return formatedValue;
}

export { handleCurrency, formatOnlyNumbersAndDates, revertBrazilianCurrencyToFloat, convertFloatToBrazilianCurrency, convertBrazilianCurrencyToFloat };

import "array-flat-polyfill";
import moment from "moment";

const parseTransactions = data => {
  if (!data || typeof data !== "object") return [];

  const parsedBalances = data.balances.map((e, i) => {
    if (e.value !== null)
      return {
        date: moment(e.date).format("DD/MM"),
        dateWithYear: moment(e.date).format("YYYY-MM-DD"),
        value: e.value,
        bold: true,
        description: i === 0 ? "Saldo final" : "Saldo anterior"
      };
  });

  const parsedEntries = data.entries.map(e => [
    e.firstElement ? undefined :
      {
        date: moment(e.date).format("DD/MM"),
        dateWithYear: moment(e.date).format("YYYY-MM-DD"),
        value: e.balance,
        bold: true,
        description: e.balanceInfo || "Saldo do dia"
      },
    e.data
      ? e.data.map(el => ({
        ...el,
        date: moment(el.date).format("DD/MM"),
        dateWithYear: moment(e.date).format("YYYY-MM-DD"),
      }))
      : undefined
  ]);

  return [parsedBalances[0], ...parsedEntries, parsedBalances[1]]
    .flat(2)
    .filter(e => e !== undefined);
};

export { parseTransactions };





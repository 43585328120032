import styled from 'styled-components';



export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0auto;
    width: 100%;
    flex: 1;
    height: 100vh;
    background-color: ${props => props.theme.brandColor3};

    img {
        max-width: 160px;
    }
`;
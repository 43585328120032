export const tokenActivationReturns = {
  SHOULD_UNBLOCK_TOKEN: 'SHOULD_UNBLOCK_TOKEN',
  SHOULD_REQUEST_TOKEN_TO_SUPPORT: 'SHOULD_REQUEST_TOKEN_TO_SUPPORT',
};

export const hasActiveToken = ({ data }) => {
  if (!data || typeof data !== 'object') return undefined;

  return data.find((el) => el.status === 'Ativo');
};

export const hasInactiveToken = ({ data }) => {
  if (!data || typeof data !== 'object') return undefined;
  return data.find((el) => el.status === 'Inativo');
};

export const hasBlockedToken = ({ data }) => {
  if (!data || typeof data !== 'object') return undefined;
  return data.some((el) => el.status === 'Bloqueado (1a ativação)');
};

export const needsToActivateToken = ({ data }) => {
  if (!data || typeof data !== 'object') return undefined;
  const hasAnyActivatedToken = data.some((el) => el.status === 'Ativo');

  if (hasAnyActivatedToken) {
    return false;
  } else if (data.some((el) => el.status === 'Bloqueado (1a ativação)')) {
    return tokenActivationReturns.SHOULD_UNBLOCK_TOKEN;
  } else {
    return tokenActivationReturns.SHOULD_REQUEST_TOKEN_TO_SUPPORT;
  }

  return !hasAnyActivatedToken;
};

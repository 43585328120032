import React from 'react';
import {
  ModalContainer,
  CloseButton,
  Title,
  HeaderWrapper,
  Text,
  AttentionMessage,
  ActionsWrapper,
  GreenButton,
  ExtraButton
} from './styles';

function CommonError({
  title,
  name,
  message,
  attentionMessage,
  isCloseButtonDisabled,
  type,
  onGreenButtonClick,
  hideModalCallback,
  greenButtonText,
  extraButtonText,
  onExtraButtonClick
}) {
  return (
    <ModalContainer id="tokenExpirationDiv">
      <HeaderWrapper>
        <Title type={type}>{title}</Title>
        {!isCloseButtonDisabled && (
          <CloseButton size={30} onClick={hideModalCallback} />
        )}
      </HeaderWrapper>
      <Text>
        {name ? <strong>{name}, </strong> : ''}
        {message}
      </Text>
      {attentionMessage && (
        <AttentionMessage>
          <strong>Atenção: </strong>
          {attentionMessage}
        </AttentionMessage>
      )}
      <ActionsWrapper>
        <GreenButton
          id="tokenExpirationLogoutButton"
          onClick={() => {
            onGreenButtonClick();
            hideModalCallback();
          }}
        >
          {greenButtonText}
        </GreenButton>
      </ActionsWrapper>
      {extraButtonText && (
        <ExtraButton
          id="tokenExpirationKeepSessionButton"
          onClick={() => {
            hideModalCallback();
            onExtraButtonClick();
          }}
        >
          {extraButtonText}
        </ExtraButton>
      )}
    </ModalContainer>
  );
}

export default CommonError;

import React, { useState, useEffect } from 'react';
import { Container, ContentWrapper, ActionsWrapper } from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import FilterDatePicker from 'components/FilterDatePicker';
import InvestmentPortfolioList from 'components/InvestmentPortfolioList';
import { InvestmentService, AccountService } from 'banking-service';
import moment from 'moment';

const initialDates = {
  startDate: moment().subtract(30, 'day'),
  endDate: moment(),
};
const initialInvestmentsData = { products: [] };

function InvestmentsBalanceDetailed({
  section = 'Investimentos',
  name = 'Extrato das Aplicações',
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  const [balance, setBalance] = useState(null);
  useEffect(() => {
    const fetchAccountBalance = async () => {
      setIsLoading((loading) => ({ ...loading, balance: true }));
      setError('');
      try {
        const res = await AccountService._getBalance();
        setBalance(AccountService.account.balance.available);
      } catch (e) {}
      setIsLoading((loading) => ({ ...loading, balance: false }));
    };
    fetchAccountBalance();
  }, []);

  const [query, setQuery] = useState(initialDates);
  const [investments, setInvestments] = useState({ ...initialInvestmentsData });
  useEffect(() => {
    const fetchInvestments = async () => {
      setIsLoading((loading) => ({ ...loading, investments: true }));
      setError('');
      try {
        const res = await InvestmentService.fetchNewInvestments(query);
        setIsLoading((loading) => ({ ...loading, investments: false }));
        setInvestments({ products: [...res] });
      } catch (e) {
        setInvestments({ ...initialInvestmentsData });
      }
      setIsLoading((loading) => ({ ...loading, investments: false }));
    };
    if (query) fetchInvestments();
  }, [query]);

  return (
    <LoadingSpinner isLoading={isLoading.balance || isLoading.investments}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <CurrentAccountValue
            title={'saldo conta corrente'}
            value={balance}
          ></CurrentAccountValue>
          <ActionsWrapper>
            <FilterDatePicker
              disAllowFutureDates
              initialDates={initialDates}
              onDateChange={(newQuery) => setQuery(newQuery)}
              showDefaultInputIcon
              firesWithButton
            ></FilterDatePicker>
          </ActionsWrapper>
          <InvestmentPortfolioList data={investments}></InvestmentPortfolioList>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default InvestmentsBalanceDetailed;

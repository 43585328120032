import React, { useState } from 'react';
import { AccountService } from 'banking-service';
import InternalTransferForm from 'components/TransferForm/InternalTransferForm';
import ExternalTransferForm from 'components/TransferForm/ExternalTransferForm';

import { verifyUserPermission } from 'utils/functions/user';
import { addBankDescription } from 'utils/transformers/beneficiaries';
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  TransferTypeToggleLeft,
  TransferTypeToggleRight,
  TogglesWrapper,
  TimeInformationText,
  TopLabel,
} from './styles';

const TRANSFER_TYPES = {
  TOPAZIO: 'TOPAZIO',
  OTHER_BANKS: 'OTHER_BANKS',
};

const STEPS = {
  FIRST: 'FORM_FILL',
  SECOND: 'FORM_REVIEW',
};

function TransferForm({
  data,
  dataOtherBanks,

  selectedType,
  onSelectedTypeChange,
  beneficiaries,
  banks,
  purposes,

  formTouched,
  formErrors,
  onChangeFormErrors,
  onChangeFormValues,
  onChangeFormTouched,

  formTouchedOtherBanks,
  formErrorsOtherBanks,
  onChangeFormErrorsOtherBanks,
  onChangeFormValuesOtherBanks,
  onChangeFormTouchedOtherBanks,
  handleFavoritesClick,
  isSchedulingEnabled,
}) {
  const { clientConfig } = useConfigContext();

  return (
    <Container>
      <TogglesWrapper>
        <TransferTypeToggleLeft
          isDisabled={
            !verifyUserPermission({
              permission: 'TRANSFERS',
              arrOfPermissions: AccountService.account.permissions,
            })
          }
          onClick={() => onSelectedTypeChange(TRANSFER_TYPES.TOPAZIO)}
          isSelected={selectedType === TRANSFER_TYPES.TOPAZIO}
        >
          {`CONTAS ${clientConfig.vars.appShortName.toUpperCase()}`}
        </TransferTypeToggleLeft>
        <TransferTypeToggleRight
          isDisabled={
            !verifyUserPermission({
              permission: 'TRANSFERS',
              arrOfPermissions: AccountService.account.permissions,
            }) &&
            !verifyUserPermission({
              permission: 'TRANSFERS',
              arrOfPermissions: AccountService.account.permissions,
            })
          }
          onClick={() => onSelectedTypeChange(TRANSFER_TYPES.OTHER_BANKS)}
          isSelected={selectedType === TRANSFER_TYPES.OTHER_BANKS}
        >
          OUTROS BANCOS
        </TransferTypeToggleRight>
      </TogglesWrapper>
      <TimeInformationText>
        Transferências para outros bancos podem ser realizadas em dias úteis das{' '}
        <strong>9h às 17h</strong> (horário de Brasília).
      </TimeInformationText>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '30px',
          width: '50%',
        }}
      >
        {selectedType === TRANSFER_TYPES.TOPAZIO && (
          <InternalTransferForm
            isSchedulingEnabled={isSchedulingEnabled}
            data={data}
            beneficiaries={addBankDescription({ beneficiaries, banks })}
            formTouched={formTouched}
            formErrors={formErrors}
            banks={banks}
            handleFormChanges={onChangeFormValues}
            handleFormErrorsChanges={onChangeFormErrors}
            handleFormTouchedChanges={onChangeFormTouched}
            handleFavoritesClick={handleFavoritesClick}
          />
        )}
        {selectedType === TRANSFER_TYPES.OTHER_BANKS && (
          <ExternalTransferForm
            isSchedulingEnabled={isSchedulingEnabled}
            data={dataOtherBanks}
            banks={banks}
            purposes={purposes}
            beneficiaries={addBankDescription({ beneficiaries, banks })}
            formErrors={formErrorsOtherBanks}
            formTouched={formTouchedOtherBanks}
            handleFormChanges={onChangeFormValuesOtherBanks}
            handleFormErrorsChanges={onChangeFormErrorsOtherBanks}
            handleFormTouchedChanges={onChangeFormTouchedOtherBanks}
            handleFavoritesClick={handleFavoritesClick}
          />
        )}
      </div>
    </Container>
  );
}

export default TransferForm;

import React, { useState, useEffect, useContext } from 'react';
import { AnticipationService } from 'banking-service';
import {
  Container,
  ContentWrapper,
  NoItemsAvailableLabel,
  ContentContainer,
  Text,
  SimulationListCont,
  OutherContainer,
  InfoListCont,
  ActionsContainer,
  CancelText,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import SimulationItem from 'components/SimulationItem';
import InfoCard from 'components/InfoCard';
import SecondaryButton from 'components/Button/Secondary';
import ConfirmationModal from 'components/ConfirmationModal';
import { AntecipationContext } from 'providers/anticipation-provider';
import {
  convertBrazilianCurrencyToFloat,
  convertFloatToBrazilianCurrency,
} from 'utils/functions/currency';

function Schedules({
  section = 'Antecipação de Recebíveis',
  name = 'Simulação',
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);
  const [simulationList, setSimulationList] = useState([]);
  const [simulationInfo, setSimulationInfo] = useState(null);

  const { simulation, setSimulation } = useContext(AntecipationContext);

  const [totalAmount, setTotalAmount] = useState('R$ 0,00');
  const [selectedAmount, setSelectedAmount] = useState('R$ 0,00');
  const [leftAmount, setLeftAmount] = useState('R$ 0,00');

  const [confirmationVisible, setConfirmationVisible] = useState(false);

  const handleClickSwitch = (stt, key) => {
    setSimulationList(
      simulationList.map((item) => {
        if (key !== item.key) return item;
        return { ...item, acceptState: stt };
      })
    );
  };

  const handleConfirmSimulation = async () => {
    setIsLoading(true);
    try {
      if (
        !simulationList ||
        !simulationList.length ||
        simulationList.every((item) => !item.acceptState) ||
        !simulationInfo
      )
        return;
      await AnticipationService.sendAntecipationRequest(
        simulationList
          .filter((item) => item.acceptState)
          .map((item) => item.id),
        simulationInfo.id
      );
      setIsLoading(false);
      props.history.push('/anticipation/success');
    } catch (e) {
      alert.error(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleFinishSimulation = async () => {
    setConfirmationVisible(true);
  };

  const getTotalSchedules = () => {
    let amountTotal = 0;
    let amountSelected = 0;
    simulationList.forEach((item) => {
      amountTotal += item.finalValue;
      if (item.acceptState) {
        amountSelected += item.finalValue;
      }
    });
    setTotalAmount(convertFloatToBrazilianCurrency(amountTotal));
    setSelectedAmount(convertFloatToBrazilianCurrency(amountSelected));
    setLeftAmount(
      convertFloatToBrazilianCurrency(amountTotal - amountSelected)
    );
  };

  useEffect(() => {
    setSimulationInfo(simulation);
    setSimulationList(
      simulation.simulations
        ? simulation.simulations.map((item) => ({
            ...item,
            acceptState: true,
            key: item.id,
            title: item.acreditor,
            tax: `${simulation.dailyRate.toFixed(3)}%`,
            finalValue: item.amountSelected - item.discount,
            totalValue: item.amountSelected,
            schedulesAmount: `${item.qtySelected} de ${item.qtyAvailable}`,
            discount: item.discount,
          }))
        : []
    );
    setConfirmationVisible(false);
  }, []);

  useEffect(() => {
    getTotalSchedules();
  }, [simulationList]);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <OutherContainer>
        <Container>
          <ScreenTitle section={section} title={name}></ScreenTitle>
          <ContentWrapper>
            {false === null ? (
              <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
                Ops! Tivemos um problema ao carregar as informações. Tente
                novamente em instantes.
              </NoItemsAvailableLabel>
            ) : (
              <ContentContainer>
                <Text>
                  Aque está um resumo da sua simulação. Confira o valor por
                  credenciadora, aceite ou rejeite sua simulação para enviar sua
                  solicitação.
                </Text>
                <SimulationListCont>
                  {simulationList.map((item) => (
                    <SimulationItem
                      title={item.title}
                      finalValue={convertFloatToBrazilianCurrency(
                        item.finalValue
                      )}
                      schedulesAmount={item.schedulesAmount}
                      totalValue={convertFloatToBrazilianCurrency(
                        item.totalValue
                      )}
                      tax={item.tax}
                      discount={convertFloatToBrazilianCurrency(item.discount)}
                      onSwitch={(stt, itemKey) =>
                        handleClickSwitch(stt, itemKey)
                      }
                      acceptState={item.acceptState}
                      itemKey={item.key}
                      key={item.key}
                    />
                  ))}
                </SimulationListCont>
              </ContentContainer>
            )}
          </ContentWrapper>
          <InfoListCont>
            <InfoCard
              title={'Valor Total da Simulação'}
              value={selectedAmount}
              style={{ marginRight: '32px' }}
              filled
            />
          </InfoListCont>
          <ActionsContainer>
            <SecondaryButton
              onClick={() => props.history.push('/anticipation/schedules')}
              title={'Refazer simulação'}
              mediumFont
              outline
            />
            <SecondaryButton
              mediumFont
              title={'Antecipar agora'}
              onClick={() => handleFinishSimulation()}
              disabled={simulationList.every((item) => !item.acceptState)}
            />
          </ActionsContainer>
        </Container>
        <CancelText>
          Se você não deseja mais receber nossas ofertas exclusivas,{' '}
          <a>solicite cancelamento</a> de sua autorização.
        </CancelText>
      </OutherContainer>
      {confirmationVisible && (
        <ConfirmationModal
          title={'ENVIAR SOLICITAÇÕES DE ANTECIPAÇÂO'}
          description={
            <>
              Você gostaria de enviar esta simulação para antecipar?
              <br />A partir da confirmação, você não poderá fazer mais
              alterações
            </>
          }
          cancelAction={() => setConfirmationVisible(false)}
          confirmAction={handleConfirmSimulation}
        />
      )}
    </LoadingSpinner>
  );
}

export default Schedules;

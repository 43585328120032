import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import { Wrapper, UploadIcon, RemoveIcon, Text, AddIcon } from './styles';
import { useAlert } from 'react-alert';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const MAX_N_OF_FILES = 5;
const MAX_TOTAL_FILES_SIZE = 11 * 1024 * 1024;

const ALLOWED_EXTENSIONS = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];

async function fileListToBase64(fileList) {
  // create function which return resolved promise
  // with data:base64 string
  function getBase64(file) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = ev => {
        resolve({
          file,
          filename: file.name,
          base64: ev.target.result.split(',')[1]
        });
      };
      reader.readAsDataURL(file);
    });
  }
  // here will be array of promisified functions
  const promises = [];

  // loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(getBase64(fileList[i]));
  }

  // array with base64 strings
  return await Promise.all(promises);
}

const isValidDrop = ({ acceptedFiles, selectedFiles }) => {
  if (
    !selectedFiles.map(e => e.type).every(e => ALLOWED_EXTENSIONS.includes(e))
  ) {
    return {
      result: false,
      message: `Arquivo não permitido. Extensões permitidas ${ALLOWED_EXTENSIONS.map(
        e => e.split('/')[1]
      ).join(', ')}`
    };
  } else if (acceptedFiles.length + selectedFiles.length > MAX_N_OF_FILES) {
    return {
      result: false,
      message: `O Número máximo de arquivos é ${MAX_N_OF_FILES} `
    };
  } else if (
    acceptedFiles.map(e => e.file.size).reduce((a, b) => a + b, 0) +
      selectedFiles.map(e => e.size).reduce((a, b) => a + b, 0) >
    MAX_TOTAL_FILES_SIZE
  ) {
    return {
      result: false,
      message: `Tamanho máximo dos arquivos deve ser ${(
        MAX_TOTAL_FILES_SIZE / 1000000
      ).toFixed(0)}MB`
    };
  } else {
    return {
      result: true
    };
  }
};

export default function Dropzone({ style, acceptedFiles, onChange }) {
  const alert = useAlert();

  const onDrop = useCallback(
    async selectedFiles => {
      const verifyFiles = isValidDrop({ acceptedFiles, selectedFiles });

      if (!verifyFiles.result) {
        alert.show(verifyFiles.message);
        return;
      }

      const filesOnBase64 = await fileListToBase64(selectedFiles);

      onChange([...acceptedFiles, ...filesOnBase64]);
    },
    [acceptedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleRemoveItem = index => {
    onChange(acceptedFiles.filter((e, i) => index !== i));
  };

  if (!acceptedFiles) {
    return null;
  }

  return (
    <Wrapper
      style={{
        ...style,
        alignSelf: 'center',
        backgroundColor: isDragActive ? theme.brandColor5 : theme.brandColor2
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {acceptedFiles.length > 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {acceptedFiles.map((e, i) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Text style={{ color: theme.customGray3 }}>{e.file.name}</Text>
              <RemoveIcon
                onClick={event => {
                  event.stopPropagation();
                  handleRemoveItem(i);
                }}
                style={{ marginLeft: '20px' }}
              />
            </div>
          ))}
          <AddIcon
            onClick={event => {
              //event.stopPropagation();
              //handleRemoveItem(i);
            }}
            style={{ marginLeft: '20px' }}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <UploadIcon style={{ marginRight: '10px' }} />
          <Text>
            Solte os arquivos para anexá-los ou{' '}
            <strong>selecione no computador</strong>
          </Text>
        </div>
      )}
    </Wrapper>
  );
}

export const TOPAZIO_TEXT = [
    {
        title: "FAVORECIDO",
        fieldName: 'name',
    },
    {
        title: "AGÊNCIA (S/DÍGITO)",
        fieldName: 'branch',
    },
    {
        title: "CONTA",
        fieldName: 'account',
    },
    {
        title: "Dígito",
        fieldName: 'digit',
    },
    {
        title: "DATA DA TRANSFERÊNCIA",
        fieldName: 'date',
    },
    {
        title: 'MOTIVO',
        fieldName: 'comment'
    },
    {
        title: "VALOR",
        fieldName: 'amount',
    },
];

export const OTHERBANKS_TEXT = [
    {
        title: 'TIPO DE TRANSFERENCIA',
        fieldName: 'operationType'
    },
    {
        title: 'TITULARIDADE',
        fieldName: 'ownershipLabel'
    },
    {
        title: 'BANCO',
        fieldName: 'bankDescription'
    },
    {
        title: "FAVORECIDO",
        fieldName: 'name',
    },
    {
        title: "CPF/CNPJ",
        fieldName: 'taxIdentifier',
    },
    {
        title: "AGÊNCIA (S/DÍGITO)",
        fieldName: 'branch',
    },
    {
        title: "CONTA",
        fieldName: 'account',
    },
    {
        title: "Dígito",
        fieldName: 'digit',
    },
    {
        title: "DATA DA TRANSFERÊNCIA",
        fieldName: 'date',
    },
    {
        title: 'FINALIDADE',
        fieldName: 'purpose'
    },
    {
        title: 'MOTIVO',
        fieldName: 'comment'
    },
    {
        title: "VALOR",
        fieldName: 'amount',
    },
];
import React from "react";
import { Container, Text, BoldedText } from "./styles";
import { parseText } from "utils/functions/text";

function WelcomeTitle({ name, personType }) {
  return (
    <Container>

      <Text id="welcomeTitleText">Olá<BoldedText>{name
        ? `, ${parseText({
          text: name ? name.toLowerCase().substr(0, name.indexOf(" ")) : name,
          limit: 25
        })}! `
        : "! "}</BoldedText>
        Você está na sua conta <BoldedText>{personType === 'PF' ? 'Pessoa Física' : 'Pessoa Jurídica'}</BoldedText></Text>
    </Container>
  );
}

export default WelcomeTitle;

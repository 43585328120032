import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Container, TabDescription } from './styles';

import {
  Dropdown,
  Menu,
  Item,
  Trigger,
  Separator,
} from '@zendeskgarden/react-dropdowns';
import { useTheme } from 'styled-components';

function SectionItem({
  userPermissions,
  id,
  title,
  options,
  route,
  Icon,
  isSelected,
  personType,
  isDisabled,
  profileType,
}) {
  const theme = useTheme();

  if (options && options.length > 0) {
    return (
      <Dropdown
        popperModifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'viewport',
          },
        }}
        onSelect={(value) => {}}
      >
        <Trigger>
          <Container
            id={id}
            personType={personType}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={() => {}}
          >
            <Icon
              style={{
                color: isSelected
                  ? theme.home.sideBarItemIconWhenSelected.color
                  : theme.home.sideBarItemIcon.color,
              }}
              size={24}
            />
            <TabDescription isSelected={isSelected} personType={personType}>
              {title}
            </TabDescription>
          </Container>
        </Trigger>
        <Menu placement="start-bottom">
          {options
            .filter((el) =>
              el.requires.every((val) =>
                userPermissions.map((perm) => perm.role).includes(val)
              )
            )
            .filter((e) => !(e.isPJDisallowed && personType === 'PJ'))
            .filter(
              (e) => !(e.isDisabledForOperators && profileType === 'OPERATOR')
            )

            .map((e, i) => (
              <Link
                id={e.id}
                key={i}
                to={e.route}
                style={{ textDecoration: 'none' }}
              >
                <Item value={e.route}>{e.text}</Item>
                {i !== options.length - 1 && <Separator />}
              </Link>
            ))}
        </Menu>
      </Dropdown>
    );
  } else {
    return (
      <Link to={route} style={{ textDecoration: 'none' }}>
        <Container
          id={id}
          personType={personType}
          isSelected={isSelected}
          isDisabled={isDisabled}
          onClick={() => {}}
        >
          <Icon
            style={{
              color: isSelected
                ? theme.home.sideBarItemIconWhenSelected.color
                : theme.home.sideBarItemIcon.color,
              minWidth: '24px',
            }}
            size={24}
          />
          <TabDescription isSelected={isSelected}>{title}</TabDescription>
        </Container>
      </Link>
    );
  }
}

export default withRouter(SectionItem);

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;


export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  margin-top: 0;
  margin-bottom: 30px;
`;
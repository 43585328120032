import styled from 'styled-components';
import Select from 'react-select';
import { Close } from 'styled-icons/evil/Close';
import { ShareBox } from 'styled-icons/remix-fill/ShareBox';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const Title = styled.label`
  margin: 0;
  font-family: 'MontserratBold';
  font-size: 18px;
  margin-bottom: 60px;
  line-height: 16px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 16.422vw;
  align-self: flex-start;
  color: #707070;
`;

export const Text = styled.label`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  max-width: 31.965vw;

  strong {
    font-family: 'MontserratMedium';
  }
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckText = styled.a`
  font-family: 'MontserratXLight';
  font-size: 12px;
  margin-left: 10px;
  line-height: 12px;
  color: #707070 !important;
  max-width: 30.499vw;
  text-transform: uppercase;
  text-decoration: none !important;
  &:hover {
    cursor: pointer;
  }
`;

export const BtnContinue = styled.button`
  padding: 16px 40px;
  border-radius: 40px;
  background: none !important;
  background-color: ${(props) => props.theme.Cor_base_marca_01} !important;;
  margin-top: 28px;
  width: auto;
  font-size: 12px;
  line-height: 14px;
  font-family: 'MontserratLight';
  color: #fff;
  transition: 225ms ease-in;
  opacity: 1;
  ${(props) => (!props.disabled ? '' : 'opacity: 0.6;')}
  pointer-events: ${(props) => (!props.disabled ? 'all' : 'none')};
  outline: none !important;
  border: none !important;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

import styled from 'styled-components';
import React from 'react';
import { Home } from 'styled-icons/boxicons-solid/Home';
import { MoneyCheckAlt } from 'styled-icons/fa-solid/MoneyCheckAlt';
import { BarChartAlt2 } from 'styled-icons/boxicons-solid/BarChartAlt2';
import { Transfer } from 'styled-icons/boxicons-regular/Transfer';
import { Barcode } from 'styled-icons/icomoon/Barcode';
import { FileInvoice } from 'styled-icons/fa-solid/FileInvoice';
import { Tools } from 'styled-icons/octicons/Tools';
import { AttachMoney } from 'styled-icons/material/AttachMoney';
import { CalendarCheck } from 'styled-icons/boxicons-solid/CalendarCheck';
import { FolderOpen } from 'styled-icons/boxicons-solid/FolderOpen';
import { Calendar } from 'styled-icons/boxicons-solid/Calendar';
import { Barcode as CashIn } from 'styled-icons/remix-line/Barcode';
import { HandHoldingUsd as Receivable } from 'styled-icons/fa-solid/HandHoldingUsd';

const HomeIcon = styled(Home)``;
const AccountIcon = styled(MoneyCheckAlt)``;
const SecurityAccountIcon = styled(MoneyCheckAlt)``;
const CreditIcon = styled(AttachMoney)``;
const InvestimentIcon = styled(BarChartAlt2)``;
const TransferIcon = styled(Transfer)``;
const BarcodeIcon = styled(Barcode)``;
const PendingApprovalsIcon = styled(CalendarCheck)``;
const InvoiceIcon = styled(FileInvoice)``;
const ScheduledIcon = styled(Calendar)``;
const ServiceIcon = styled(Tools)``;
const FilesIcon = styled(FolderOpen)``;
const CashInIcon = styled(CashIn)``;
const ReceivableIcon = styled(Receivable)``;

export const sections = [
  {
    id: 'homeButton',
    route: '/',
    referenceRoute: '/',
    title: 'início',
    Icon: HomeIcon,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['ACCOUNT'],
  },
  {
    id: 'accountButton',
    route: '/account',
    referenceRoute: [
      '/account',
      '/account/account-balance',
      '/account/account-balance-detailed'
    ],
    title: 'Conta Corrente',
    Icon: AccountIcon,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['AT_LEAST_ONE_CHILD'],
    options: [
      {
        id: 'accountBalanceButton',
        route: '/account/account-balance',
        text: 'Saldo',
        requires: ['ACCOUNT'],
      },
      {
        id: 'accountBalanceDetailedButton',
        route: '/account/account-balance-detailed',
        text: 'Extrato',
        requires: ['ACCOUNT'],
      },
    ],
  },
  {
    id: 'securityAccount',
    route: '/security-account',
    referenceRoute: [
      '/security-account',
      '/security-account/account-balance',
      '/security-account/account-balance-detailed',
      '/security-account/payment',
      '/security-account/withdraw'
    ],
    title: 'conta garantida',
    Icon: SecurityAccountIcon,
    isDisabled: false,
    isPFAllowed: false,
    isPJAllowed: true,
    requires: ['AT_LEAST_ONE_CHILD'],
    options: [
      {
        id: 'accountBalanceButton',
        route: '/security-account/account-balance',
        text: 'Saldo',
        requires: ['SECURITY_ACCOUNT'],
      },
      {
        id: 'accountBalanceDetailedButton',
        route: '/security-account/account-balance-detailed',
        text: 'Extrato',
        requires: ['SECURITY_ACCOUNT'],
      },
      {
        id: 'accountBalanceButton',
        route: '/security-account/payment',
        text: 'Pagamento',
        requires: ['SECURITY_ACCOUNT'],
      },
      {
        id: 'accountBalanceDetailedButton',
        route: '/security-account/withdraw',
        text: 'Saque ',
        requires: ['SECURITY_ACCOUNT'],
      },
    ],
  },
  {
    id: 'creditButtonMenuId',
    route: '/credit/contract-history',
    referenceRoute: '/credit/contract-history',
    title: 'crédito',
    Icon: CreditIcon,
    isPFAllowed: false,
    isPJAllowed: true,
    requires: ['AT_LEAST_ONE_CHILD'],
    options: [
      {
        id: 'creditButtonId',
        route: '/credit/contract-history',
        text: 'Crédito',
        requires: ['WORKING_CAPITAL'],
      },
    ],
  },
  {
    id: 'anticipationButtonId',
    route: '/anticipation/wellcome',
    referenceRoute: '/anticipation/wellcome',
    title: 'Antecipação Recebíveis',
    Icon: ReceivableIcon,
    isPFAllowed: false,
    isPJAllowed: true,
    requires: ['ANTICIPATION'],
  },
  {
    id: 'investmentsButton',
    route: '/investments',
    referenceRoute: [
      '/investments',
      '/investments/balance',
      '/investments/balance-detailed',
    ],
    title: 'Investimento',
    Icon: InvestimentIcon,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['AT_LEAST_ONE_CHILD'],
    options: [
      {
        id: 'investmentsBalanceButtonId',
        route: '/investments/balance',
        text: 'Saldo',
        requires: ['INVESTMENTS'],
      },
      {
        id: 'investmentsBalanceDetailedButtonId',
        route: '/investments/balance-detailed',
        text: 'Extrato',
        requires: ['INVESTMENTS'],
      },
    ],
  },
  {
    id: 'paymentsButton',
    route: '/payments',
    referenceRoute: '/payments',
    title: 'Pagamentos',
    Icon: BarcodeIcon,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['PAYMENTS'],
  },
  {
    id: 'transfersButton',
    route: '/transfers',
    referenceRoute: '/transfers',
    title: 'transferências',
    Icon: TransferIcon,
    isDisabled: false,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['TRANSFERS'],
  },
  {
    id: 'receiptsButton',
    route: '/receipts',
    referenceRoute: '/receipts',
    title: 'comprovantes',
    Icon: InvoiceIcon,
    isDisabled: false,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['ACCOUNT'],
  },
  {
    id: 'scheduledOperationsButton',
    route: '/scheduled-operations',
    referenceRoute: '/scheduled-operations',
    title: 'Agendamentos',
    Icon: ScheduledIcon,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['SCHEDULINGS'],
  },
  {
    id: 'cashinButton',
    route: '/cashin',
    referenceRoute: '/cashin',
    title: 'Emissão de Boleto',
    Icon: CashInIcon,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['CASHIN'],
  },
  {
    id: 'pendingApprovalsButton',
    route: '/outstanding-payments',
    referenceRoute: '/outstanding-payments',
    title: 'aprovações pendentes',
    Icon: PendingApprovalsIcon,
    isDisabled: false,
    isPFAllowed: false,
    isPJAllowed: true,
    requires: ['APPROVALS'],
  },
  {
    id: 'filesButton',
    route: '/files',
    referenceRoute: [
      '/files',
      '/files/request',
      '/files/consult',
      '/files/offline-statement/request',
      '/files/offline-statement/consult',
    ],
    title: 'Arquivos',
    Icon: FilesIcon,
    isDisabled: false,
    isPFAllowed: false,
    isPJAllowed: true,
    requires: ['AT_LEAST_ONE_CHILD'],
    options: [
      {
        id: 'requestButtonId',
        route: '/files/request',
        text: 'Solicitar CNAB',
        requires: ['CNAB'],
      },
      {
        id: 'consultButtonId',
        route: '/files/consult',
        text: 'Consultar CNAB',
        requires: ['CNAB'],
      },
      {
        id: 'offlineStatementRequestButtonId',
        route: '/files/offline-statement/request',
        text: 'Solicitar Extrato',
        requires: ['OFFLINE_STATEMENT'],
      },
      {
        id: 'offlineStatementConsultButtonId',
        route: '/files/offline-statement/consult',
        text: 'Consultar Extrato',
        requires: ['OFFLINE_STATEMENT'],
      },
    ],
  },
  {
    id: 'servicesButton',
    route: '/services',
    referenceRoute: [
      '/services',
      '/services/operators',
      '/services/token',
      '/services/close-account',
      '/services/report-income',
    ],
    title: 'Serviços',
    Icon: ServiceIcon,
    isDisabled: false,
    isPFAllowed: true,
    isPJAllowed: true,
    requires: ['AT_LEAST_ONE_CHILD'],
    options: [
      {
        id: 'operatorsButtonId',
        route: '/services/operators',
        text: 'Operadores',
        requires: ['ACCOUNT'],
        isDisabledForOperators: true,
      },
      {
        id: 'tokenButtonId',
        route: '/services/token',
        text: 'Token',
        requires: ['ACCOUNT'],
      },
      {
        id: 'closeAccountButton',
        route: '/services/close-account',
        text: 'Encerrar minha conta',
        requires: ['ACCOUNT_CLOSING'],
      },
      {
        id: 'reportIncome',
        route: '/services/report-income',
        text: 'Informe de Rendimentos',
        requires: ['REPORT_INCOME'],
        isPJDisallowed: true,
      },
    ],
  },
];

import React from 'react';
import { AccountService } from 'banking-service';
import moment from 'moment';

import { TopLabel, PeriodLabel, InformationsLabel } from './styles';

class PrintableWrapper extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'block',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '40px',
          marginTop: '0px',
          marginBottom: '0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '50px',
          }}
        >
          <img
            style={{
              width: this.props.receiptContactInformations.logoWidth,
              height: this.props.receiptContactInformations.logoHeight,
              marginTop: '20px',
            }}
            src={this.props.logo}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <InformationsLabel style={{}}>
              <strong>
                {this.props.receiptContactInformations.header.line01}
              </strong>
            </InformationsLabel>
            <InformationsLabel>
              {this.props.receiptContactInformations.header.line02}
            </InformationsLabel>
            <InformationsLabel>
              {this.props.receiptContactInformations.header.line03}
            </InformationsLabel>
            <InformationsLabel>
              {this.props.receiptContactInformations.header.line04}
            </InformationsLabel>
            <InformationsLabel>
              {this.props.receiptContactInformations.header.line05}
            </InformationsLabel>
            <InformationsLabel>
              {this.props.receiptContactInformations.header.line06}
            </InformationsLabel>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <TopLabel>{this.props.topLabelText}</TopLabel>
          <PeriodLabel style={{ alignSelf: 'center' }}>
            Extrato emitido em: {moment().format('DD/MM/YYYY - HH:mm')}
          </PeriodLabel>

          <PeriodLabel style={{ alignSelf: 'center' }}>
            {this.props.periodLabel}
          </PeriodLabel>
          <PeriodLabel style={{ fontSize: '12px', alignSelf: 'center' }}>
            <strong>CLIENTE: </strong>
            {AccountService.account.name}
          </PeriodLabel>
          <PeriodLabel
            style={{
              fontSize: '12px',
              marginBottom: '30px',
              alignSelf: 'center',
            }}
          >
            <strong>AG/CONTA: </strong>
            {AccountService.account.branch} / {AccountService.account.account}
          </PeriodLabel>
        </div>
        {this.props.children}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px',
          }}
        >
          <InformationsLabel style={{}}>
            {'Em caso de dúvidas entre em contato com a gente:'}
          </InformationsLabel>
          <InformationsLabel style={{}}>
            {this.props.receiptContactInformations.footer.line01}
          </InformationsLabel>

          <InformationsLabel style={{}}>
            {this.props.receiptContactInformations.footer.line02}
          </InformationsLabel>
        </div>
      </div>
    );
  }
}

export default PrintableWrapper;

import React, { useState, useMemo, useCallback } from 'react';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';

export const useModalWithData = modalFactory => {
  const [modalData, setModalData] = useState(undefined);
  const modalComponent = useMemo(() => modalFactory(modalData), [modalData]);
  const [_showModal, hideModal] = useModal(modalComponent, [modalData]);

  const showModal = useCallback(data => {
    setModalData(data);
    _showModal();
  });
  return [showModal, hideModal];
};

export const ModalWrapper = ({ contentStyle, children }) => (
  <ReactModal
    style={{
      overlay: {
        zIndex: '11',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      },
      content: {
        ...contentStyle,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
      }
    }}
    isOpen
  >
    {children}
  </ReactModal>
);

import React, { useState, useEffect, useCallback, Fragment } from 'react';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import {
  AccountService,
  UserService,
  TokenService,
  WorkingCapitalService,
  AnticipationService,
} from 'banking-service';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import {
  Container,
  ContentWrapper,
  SecondRowWrapper,
  BannersWrapper,
} from './styles';
import { verifyUserPermission, resolvePersonType } from 'utils/functions/user';
import LoadingSpinner from 'components/LoadingSpinner';
import { useAlert } from 'react-alert';
import WelcomeTitle from 'components/WelcomeTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import AccountBalance from 'components/AccountBalance';
import Banner from 'components/Banner';
import banners from 'constants/banners';
import ACCOUNT_TYPES from 'constants/accountTypes';
import imagesLoaded from 'imagesloaded';
import storage from 'utils/functions/storage';
import WorkingCapitalModal from 'components/WorkingCapitalModal';
import { useConfigContext } from 'providers/client-config-provider';

import TokenActivationModal from 'components/TokenActivationModal';

import { needsToActivateToken } from 'utils/functions/token';

const filterOptions = [3, 15, 30];

function Home({
  verifyUserDocumentsCallback,
  verifyShouldShowModals,
  ...props
}) {
  const alert = useAlert();
  const { clientConfig } = useConfigContext();

  const [tokenModalType, setModalTokenType] = useState('');
  const [
    workingCapitalBannerVisible,
    setWorkingCapitalBannerVisible,
  ] = useState(false);
  const [workingCapitalBannerValue, setWorkingCapitalBannerValue] = useState(0);

  const [
    showNeedsToActivateTokenModal,
    hideNeedsToActivateTokenModal,
  ] = useModal(
    () => (
      <ReactModal
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        isOpen
      >
        <TokenActivationModal
          backButtonText={resolveBackButtonText()}
          onActiveClick={() => {
            props.history.push('/services/token');
          }}
          onBackHomeClick={resolveBackClick}
          hideModalCallback={hideNeedsToActivateTokenModal}
          type={tokenModalType}
        />
      </ReactModal>
    ),
    [tokenModalType]
  );

  const resolveBackButtonText = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      return 'CANCELAR';
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      return 'CANCELAR';
    } else {
      return 'VOLTAR PARA HOME';
    }
  };

  const resolveBackClick = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      hideNeedsToActivateTokenModal();
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      hideNeedsToActivateTokenModal();
    } else {
      props.history.push('/');
    }
  };

  const {
    hasUpdatedPermissions,
    selectedAccountIndex,
    isLoading: globalLoading,
  } = useSwitchAccountDataContext();
  const { accounts } = AccountService;
  const { user } = UserService;

  const [isLoading, setIsLoading] = useState({
    balance: false,
    linkedAccount: false,
    transactions: false,
    antecipationOptIn: false,
  });

  const [statusOptIn, setStatusOptIn] = useState(null);

  const fetchTokens = async () => {
    setError('');
    try {
      const res = await TokenService.getInfo();
      let tokenRequiredAction = needsToActivateToken({
        data: [...res.filter((e) => e.status !== 'Inativo')],
      });
      if (tokenRequiredAction) {
        setModalTokenType(tokenRequiredAction);
        showNeedsToActivateTokenModal();
      }
    } catch (e) {}
  };

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error);
  }, [error]);

  const [balance, setBalance] = useState(null);
  const [linkedAccount, setLinkedAccount] = useState({
    hasPermission: false,
    balance: null,
  });
  const [securityAccount, setSecurityAccount] = useState({
    hasPermission: false,
    balance: null,
  });

  const fetchAccount = async () => {
    setIsLoading((loading) => ({ ...loading, balance: true }));
    setError('');
    try {
      const a = await AccountService._getBalance();
      setBalance(AccountService.account.balance.available);
    } catch (e) {
      if (
        e.message !== 'Failed to fetch' &&
        e.message !== 'NetworkError when attempting to fetch resource.'
      )
        setError(
          'Não conseguimos recuperar todos os seus saldos. Por favor, recarregue a página.'
        );
    }
    setIsLoading((loading) => ({ ...loading, balance: false }));
  };

  const fetchLinkedAccount = async () => {
    setIsLoading((loading) => ({ ...loading, linkedAccount: true }));
    setError('');
    try {
      if (
        verifyUserPermission({
          permission: 'LINKED_ACCOUNT',
          arrOfPermissions: AccountService.account.permissions,
        })
      ) {
        const a = await AccountService._getLinkedBalance();
        setLinkedAccount({
          hasPermission: true,
          balance: AccountService.linkedAccount.balance.available,
        });
      } else {
        setLinkedAccount({ hasPermission: false, balance: null });
        if (accountType === ACCOUNT_TYPES.LINKED_ACCOUNT) {
          setAccountType(ACCOUNT_TYPES.CURRENT_ACCOUNT);
        }
      }
    } catch (e) {
      setLinkedAccount({ hasPermission: false, balance: null });
      if (accountType === ACCOUNT_TYPES.LINKED_ACCOUNT) {
        setAccountType(ACCOUNT_TYPES.CURRENT_ACCOUNT);
      }
    }
    setIsLoading((loading) => ({ ...loading, linkedAccount: false }));
  };

  const [query, setQuery] = useState({
    startDate: moment().subtract(filterOptions[0], 'day'),
    endDate: moment(),
  });
  const [transactions, setTransactions] = useState(null);
  const [accountType, setAccountType] = useState(ACCOUNT_TYPES.CURRENT_ACCOUNT);
  const fetchTransactions = async () => {
    setIsLoading((loading) => ({ ...loading, transactions: true }));
    setError('');
    try {
      const a = await AccountService.getTransactions(query, {
        isLinkedAccount: accountType === ACCOUNT_TYPES.LINKED_ACCOUNT,
      });
      setTransactions({
        balances: [
          { ...AccountService.account.transactionsActualBalance },
          { ...AccountService.account.transactionsLastBalance },
        ],
        entries: [...AccountService.account.transactions],
      });
      setIsLoading((loading) => ({ ...loading, transactions: false }));
    } catch (e) {
      setTransactions({ balances: [], entries: [] });
      setIsLoading((loading) => ({ ...loading, transactions: false }));
      if (
        e.message !== 'Failed to fetch' &&
        e.message !== 'NetworkError when attempting to fetch resource.'
      ) {
        setError(
          'Não conseguimos recuperar todas as suas transações. Por favor, recarregue a página.'
        );
      }
    }
  };

  const verifyWorkingCapitalBanner = async (person) => {
    const fromLogin = await storage.getItem('fromLogin');
    const fromChangeAccount = await storage.getItem('fromChangeAccount');
    if ((fromLogin || fromChangeAccount) && person.personType === 'PJ') {
      setTimeout(async () => {
        const userEligibility = await WorkingCapitalService.verifyUserEligibility();
        const userValue = await WorkingCapitalService.getUserSimlationAmount();
        if (
          userEligibility &&
          userValue &&
          userValue.available_value > 0 &&
          userValue.status_suggested_limit === 'S'
        ) {
          setWorkingCapitalBannerValue(userValue.available_value);
          setWorkingCapitalBannerVisible(true);
        }
        storage.setItem('fromLogin', false);
        storage.setItem('fromChangeAccount', false);
      }, 6000);
    }
  };

  const callVerifyCapitalFromChange = async (person) => {
    const fromLogin = await storage.getItem('fromLogin');
    if (fromLogin) return;
    verifyWorkingCapitalBanner(person);
  };

  const fetchOptinStatus = async () => {
    setIsLoading((loading) => ({ ...loading, antecipationOptIn: true }));
    try {
      const res = await AnticipationService.verify({});
      if (res.hasOptIn === true) {
        props.history.push('/anticipation/schedules');
      } else {
        props.history.push('/anticipation/wellcome');
      }
      setIsLoading((loading) => ({ ...loading, antecipationOptIn: false }));
    } catch (e) {
      console.log({ e });
      props.history.push('/anticipation/wellcome');

      setIsLoading((loading) => ({ ...loading, antecipationOptIn: false }));
    }
    setIsLoading((loading) => ({ ...loading, antecipationOptIn: false }));
  };

  useEffect(() => {
    const fetchAllInformations = async () => {
      await fetchAccount();
      //await fetchTransactions();
      await fetchLinkedAccount();
      if (hasUpdatedPermissions === 0) fetchTokens();
      verifyUserDocumentsCallback();
      if (hasUpdatedPermissions !== 0) verifyShouldShowModals();
    };
    if (hasUpdatedPermissions !== 0) {
      imagesLoaded(document.querySelector('#root'), async function(instance) {
        window.stop();
        fetchAllInformations();
      });
    } else {
      fetchAllInformations();
    }
  }, [hasUpdatedPermissions]);

  useEffect(() => {
    const fetchTransactionsWrapper = async () => {
      fetchTransactions();
    };

    fetchTransactionsWrapper();
  }, [query, accountType]);

  useEffect(() => {
    verifyWorkingCapitalBanner(accounts[selectedAccountIndex]);
  }, []);

  useEffect(() => {
    callVerifyCapitalFromChange(accounts[selectedAccountIndex]);
  }, [selectedAccountIndex]);

  return (
    <LoadingSpinner
      isLoading={
        !globalLoading &&
        (isLoading.balance || isLoading.transactions || isLoading.linkedAccount)
      }
    >
      <Container>
        <WelcomeTitle
          personType={resolvePersonType({
            taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
          })}
          name={user.name}
        ></WelcomeTitle>
        <ContentWrapper>
          <AccountBalance
            data={transactions}
            onFilterChanged={(e) => setQuery(e)}
            filterOptions={filterOptions}
            changedAccountType={(type) => setAccountType(type)}
            hasLinkedAccount={linkedAccount.hasPermission}
            periodLabel={query}
          />
          <SecondRowWrapper>
            <CurrentAccountValue
              title={'saldo conta corrente'}
              value={balance}
            ></CurrentAccountValue>

            {linkedAccount.hasPermission && (
              <Fragment>
                <br />
                <CurrentAccountValue
                  title={'saldo conta vinculada'}
                  value={linkedAccount.balance}
                ></CurrentAccountValue>
              </Fragment>
            )}
            {securityAccount.hasPermission && (
              <Fragment>
                <br />
                <CurrentAccountValue
                  title={'saldo conta garantida'}
                  value={securityAccount.balance}
                ></CurrentAccountValue>
              </Fragment>
            )}
            <BannersWrapper>
              {banners
                .filter((e) =>
                  e.visibleTo.includes(
                    resolvePersonType({
                      taxIdentifier:
                        accounts[selectedAccountIndex].taxIdentifier,
                    })
                  )
                )
                .map((e, i) => {
                  return clientConfig.vars.hasBanner ? (
                    <Banner key={i} {...e} optInAction={fetchOptinStatus} />
                  ) : null;
                })}
            </BannersWrapper>
          </SecondRowWrapper>
        </ContentWrapper>
        {workingCapitalBannerVisible && (
          <WorkingCapitalModal
            avaiableValue={workingCapitalBannerValue}
            cancelAction={() => setWorkingCapitalBannerVisible(false)}
          />
        )}
      </Container>
    </LoadingSpinner>
  );
}

export default Home;

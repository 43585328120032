import styled from 'styled-components';
import { UploadCloud } from 'styled-icons/feather/UploadCloud';
import { Cancel } from 'styled-icons/material/Cancel';
import { AddCircle } from 'styled-icons/material/AddCircle';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  border-radius: 3px;
  border: solid 1px ${props => props.theme.brandColor5};
`;

export const UploadIcon = styled(UploadCloud)`
  width: 25px;
  height: 25px;
  color: ${props => props.theme.info};
`;

export const RemoveIcon = styled(Cancel)`
  width: 20px;
  height: 20px;
  color: ${props => props.theme.danger};
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 14px;
  font-family: 'SourceSansPro', Arial, sans-serif;
  color: ${props => props.theme.customGray};

  strong {
    cursor: pointer;
    color: ${props => props.theme.info};
  }
`;

export const AddIcon = styled(AddCircle)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${props => props.theme.success};
`;

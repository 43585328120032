import React, { useState, useEffect } from 'react';
import { Container, DatePickerWrapper, Button } from './styles';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
require('moment/locale/pt-br.js');

function resolveValidDates(
  day,
  options = {
    disAllowFutureDates: false,
    disAllowPastDates: false,
    disAllowWeekends: false,
  }
) {
  const { disAllowFutureDates, disAllowPastDates, disAllowWeekends } = options;
  if (
    disAllowWeekends &&
    (moment(day).weekday() === 0 || moment(day).weekday() === 6)
  ) {
    return true;
  } else if (disAllowFutureDates && disAllowPastDates) {
    return !(moment().isSame(day, 'day') > 0);
  } else if (disAllowFutureDates) {
    return (
      !(moment().isSame(day, 'day') > 0) &&
      !isInclusivelyBeforeDay(day, moment())
    );
  } else if (disAllowPastDates) {
    return (
      !(moment().isSame(day, 'day') > 0) &&
      isInclusivelyBeforeDay(day, moment())
    );
  }

  return false;
}

function CustomDatePicker({
  isSelected,
  onClick,
  style,
  onChange,
  disAllowFutureDates,
  disAllowPastDates,
  disAllowWeekends,
  ...props
}) {
  const [focused, setFocused] = useState(false);

  return (
    <Container style={style}>
      <DatePickerWrapper onClick={onClick} isSelected={isSelected}>
        <SingleDatePicker
          {...props}
          isOutsideRange={(day) =>
            resolveValidDates(day, {
              disAllowFutureDates,
              disAllowPastDates,
              disAllowWeekends,
            })
          }
          noBorder={true}
          onDateChange={(newDate) => {
            onChange(newDate);
          }} // PropTypes.func.isRequired
          focused={focused} // PropTypes.bool
          onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
          id="editableDueDateFieldId" // PropTypes.string.isRequired,
          small={true}
          showDefaultInputIcon={true}
          hideKeyboardShortcutsPanel={true}
        />
      </DatePickerWrapper>
    </Container>
  );
}

export default CustomDatePicker;

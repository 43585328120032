import styled from 'styled-components';

const fontFamily = `"SourceSansPro", Arial, sans-serif`;

export const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.home.header.backgroundColor};
  flex: 1;
  flex-direction: column;
  padding: 0px 15px;
  cursor: pointer;
  min-width: 200px;
  justify-content: center;
  border-left: 1px solid
    ${(props) =>
      props.personType === 'PF'
        ? props.theme.home.headerIcons.color
        : props.theme.home.headerIcons.color};
`;

export const Name = styled.p`
  font-size: 14px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.home.headerAccountLabel.color};
  margin: 0px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;
export const AccountInformationsWrapper = styled.div`
  display: flex;
`;
export const Agency = styled.p`
  font-size: 12px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.home.headerAccountLabel.color};
  margin: 0px;
  margin-right: 10px;
`;
export const Account = styled.p`
  font-size: 12px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.home.headerAccountLabel.color};
  margin: 0px;
`;
export const LastAccess = styled.p`
  font-size: 10px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.home.headerAccountLabel.color};
  margin: 0px;
`;

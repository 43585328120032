import styled from 'styled-components';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  margin: 0;
  text-transform: uppercase;
`;

export const Subtitle = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 9px;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  margin: 0;
  ${media.phone`
    font-size: 7px;
  `}
`;

export const NormalValues = styled.span`
  right: 20px;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.theme.brandColor3};
`;

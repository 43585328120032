import styled from "styled-components";
import Select from 'react-select';
import { Delete } from 'styled-icons/material/Delete';

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const NoItemsAvailableLabel = styled.span`

    align-self: 'center';
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: ${props => props.theme.brandColor3};
    margin-top: 30px;
    margin-bottom: 30px;
`;


export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 2px;
  background-color: ${props => props.theme.brandColor2};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px ${props => props.theme.brandColor5};
  padding: 0;
  margin: 30px 0px 0px 0px;
  padding: 20px 5px 20px 5px;
  box-sizing: border-box;
  ${media.phone`
    padding: 0px;
  `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 2px;
  justify-content: space-between;
  margin-bottom: 30px;

`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
    

    
`;

export const Title = styled.label`
    margin: 0;
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
`;

export const Value = styled.span`
    margin: 0;
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
`;

export const ColoredValue = styled(Value)`
  color: ${props => props.colored ? props.theme.success : props.status === 'PROCESSANDO' ? props.customOrange2 : props.status === 'AGENDADO' ? props.theme.black : props.status === 'CONCLUIDO' ? props.theme.success : props.status === 'CANCELADO' ? props.theme.danger : props.theme.black};
`;

export const CancelIcon = styled(Delete)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  pointer-events: ${props => props.status === 'AGENDADO' ? 'initial' : 'none'};



  color: ${props => props.theme.brandColor3};
  opacity: ${props => props.status === 'AGENDADO' ? 1 : 0.4};
`;

export const CustomAutocomplete = styled(Select)`
    width: 20%;
    margin-left: 35px;
    margin-right: 15px;
    border: ${props => props.hasErrors ? '1px solid red' : 'none'};
    border-radius: ${props => props.hasErrors ? '5px' : 'none'};
`;


import React, { useState, useEffect } from 'react';

import ReactTable from 'react-table';
import ValueOnTables from 'components/ValueOnTables';
import { useConfigContext } from 'providers/client-config-provider';

function Balance({ data, isPrinting }) {
  const { clientConfig } = useConfigContext();

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      headerClassName: 'rt-th-hide-header',
      maxWidth: 55,
    },
    {
      Header: 'Description',
      accessor: 'description',
      headerClassName: 'rt-th-hide-header',
      style: {
        'white-space': 'unset',
        fontFamily: '"SourceSansPro", Arial, sans-serif',
        color: clientConfig ? clientConfig.brandColor3 : '',
        fontSize: '12px',
      },
    },
    {
      Header: 'Valor',
      accessor: 'value',
      textAlign: 'right',
      maxWidth: 160,

      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
      headerClassName: 'rt-th-hide-header',
    },
  ];

  if (!isPrinting) {
    return (
      <ReactTable
        noDataText="Para ver seu extrato, selecione uma das opções de dias acima"
        showPagination={data && data.length > 300}
        showPageSizeOptions={false}
        data={data}
        previousText={'<'}
        nextText={'>'}
        pageText={''}
        ofText={'de'}
        columns={columns}
        pageSize={data && data.length > 300 ? 300 : data.length}
        getTrProps={(state, rowInfo, column) => {
          return {
            style: {
              backgroundColor:
                rowInfo && rowInfo.original && rowInfo.original.bold
                  ? '#f7f8f9'
                  : 'white',
            },
          };
        }}
      />
    );
  } else {
    return (
      <div style={{ display: 'block', flexDirection: 'column' }}>
        {data.map((e, i) => (
          <div
            key={i}
            style={{
              display: 'flex',
              maxHeight: '28px',
              backgroundColor: e.bold ? '#f7f8f9' : 'white',
              borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)',
              fontSize: '9px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '5px',
              whiteSpace: 'unset',
              fontFamily: 'SourceSansPro, Arial, sans-serif',
              color: 'rgb(65, 69, 73)',
              pageBreakInside: 'avoid',
            }}
          >
            <label style={{ whiteSpace: 'pre', pageBreakInside: 'avoid' }}>
              {e.date} {e.description}
            </label>

            <ValueOnTables
              style={{ maxWidth: 160, pageBreakInside: 'avoid' }}
              value={e.value}
              type={e.type}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default Balance;

import styled, { css } from 'styled-components';
import { AlertTriangle } from 'styled-icons/feather/AlertTriangle';
import media from "utils/media";

const slidein = css`
@keyframes slidein {
    0% { opacity: 0.2 }
    20% { opacity: 0.3}
    40% { opacity: 0.6}
    100% { opacity: 1}
}
`

export const Container = styled.div`
  display: flex;
  width: 60%;
  height: 40px;
  font-size: 12px;
  border: solid 2px ${props => props.theme.alert};
  border-left: solid 7px ${props => props.theme.alert};
  color: ${props => props.theme.brandColor3};
  background-color: ${props => props.theme.brandColor2};
  justify-content: flex-start;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    ${slidein}
    animation: slidein 2.0s;

  ${media.bigDesktop`
    width: 40%;
  `};
  ${media.bigBigDesktop`
    width: 40%;
  `};

`;



export const AlertIcon = styled(AlertTriangle)`
  width: 25px;
  height: 25px;
  color: ${props => props.theme.alert};
  margin: 0px 15px 0px 10px;

`;
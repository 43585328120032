import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ViewButton = styled.button`
  :disabled {
    color: rgb(208, 208, 208);
    cursor: not-allowed;
  }

  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.Cor_base_marca_01};
  font-weight: 700;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
`;

import React from "react";
import { Container } from "./styles";

function Button({ title, disabled, onClick, ...props }) {
  return (
    <Container  {...props} onClick={onClick} disabled={disabled}>{`${title}`}</Container>
  );
}

export default Button;

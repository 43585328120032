import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import { AccountService } from 'banking-service';

export const SwitchAccountDataContext = createContext(null);

const SwitchAccountDataProvider = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [userPermissions, setUserPermissions] = useState([]);
    const [hasUpdatedPermissions, setHasUpdatedPermissions] = useState(0);
    const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);

    const setIsSwitchingAccounts = (value) => {
        setIsLoading(value);
    }

    const switchAccounts = (account) => {
        AccountService._clear();
        AccountService.switchAccount(account);
    }

    const fetchUserPermissions = async () => {
        const res = await AccountService._getUserPermissions();
        setUserPermissions(res);
        setHasUpdatedPermissions(value => (value + 1));
    }

    const switchAccountDataValue = useMemo(() => ({ isLoading, setIsSwitchingAccounts, switchAccounts, fetchUserPermissions, userPermissions, hasUpdatedPermissions, selectedAccountIndex, setSelectedAccountIndex, setHasUpdatedPermissions }), [isLoading, userPermissions, hasUpdatedPermissions, selectedAccountIndex]);

    return <SwitchAccountDataContext.Provider value={switchAccountDataValue} {...props} />;
};

export const useSwitchAccountDataContext = () => useContext(SwitchAccountDataContext);

export default SwitchAccountDataProvider;
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import '../../react-table.css';
import ReactTable from 'react-table';
import { Container, Title, DownloadIconCSV } from './styles';
import ValueOnTables from 'components/ValueOnTables';
import { CSVLink } from 'react-csv';
import { useConfigContext } from 'providers/client-config-provider';

function InvestmentPortfolioTransactions({ portfolioName, data }) {
  const { clientConfig } = useConfigContext();

  const columns = [
    {
      Header: 'Data',
      accessor: 'date',
      Cell: (props) => <span>{moment(props.value).format('DD/MM/YYYY')}</span>,
    },
    {
      Header: 'Saldo Anterior',
      accessor: 'lastBalance',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Aplicação',
      accessor: 'investment',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Taxa/Indexador',
      accessor: 'indexPercentage',
      style: { color: '#26CC3A', textAlign: 'right' },
      Cell: (props) => (
        <span style={{ textAlign: 'right' }}>
          {props.value.toFixed(2)}% {props.original.index}
        </span>
      ),
    },
    {
      Header: 'Rendimentos',
      accessor: 'grossYield',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Resgate/Rep. Juros',
      accessor: 'surrender',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Dif. Resgate',
      accessor: 'surrenderDifference',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Valor IRF',
      accessor: 'incomeTaxValue',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Valor IOF',
      accessor: 'taxFinancialTransactionsValue',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Valor Bloqueado',
      accessor: 'blocked',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Valor Livre',
      accessor: 'available',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
    {
      Header: 'Saldo Final',
      accessor: 'finalBalance',
      Cell: (props) => (
        <ValueOnTables value={props.value} type={props.original.type} />
      ),
    },
  ];

  return (
    <Container>
      <Title>
        Confira o extrato completo da aplicação <strong>{portfolioName}</strong>
      </Title>
      <ReactTable
        style={{ maxHeight: 'none' }}
        noDataText="Nenhum dado disponível"
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={data ? data.length : 0}
        //className="-striped -highlight"
      />
      <CSVLink
        style={{ alignSelf: 'flex-end' }}
        filename={`EXTRATO INVESTIMENTOS - TRANSAÇÕES - REFERENTE À ${moment(
          data[0].date
        ).format('DD/MM/YYYY')} ATÉ ${moment(data[data.length - 1].date).format(
          'DD/MM/YYYY'
        )} - EMITIDO EM ${moment().format(
          'DD/MM/YYYY - HH:mm'
        )} - BANCO_TOPÁZIO.csv`}
        data={
          [
            ...data.map((e) => ({
              ...e,
              date: moment(e.date).format('YYYY-MM-DD'),
              lastBalance: `${e.lastBalance.toString().replace('.', ',')}`,
              investment: `${e.investment.toString().replace('.', ',')}`,
              indexPercentage: `${e.indexPercentage}% ${e.index}`,
              grossYield: `${e.grossYield.toString().replace('.', ',')}`,
              surrender: `${e.surrender.toString().replace('.', ',')}`,
              surrenderDifference: `${e.surrenderDifference
                .toString()
                .replace('.', ',')}`,
              incomeTaxValue: `${e.incomeTaxValue
                .toString()
                .replace('.', ',')}`,
              taxFinancialTransactionsValue: `${e.taxFinancialTransactionsValue
                .toString()
                .replace('.', ',')}`,
              blocked: `${e.blocked.toString().replace('.', ',')}`,
              available: `${e.available.toString().replace('.', ',')}`,
              finalBalance: `${e.finalBalance.toString().replace('.', ',')}`,
            })),
          ] /* [...operations.map(e => ({
          ...e,
          emissionDate: moment(e.emissionDate).format('YYYY-MM-DD'),
          dueDate: moment(e.dueDate).format('YYYY-MM-DD'),
          index: `${e.indexPercentage}% ${e.index}`,
          investment: `${e.investment.toString().replace('.', ',')}`,
          grossProfitability: `${e.grossProfitability.toString().replace('.', ',')}`,
          incomeTaxValue: `${e.incomeTaxValue.toString().replace('.', ',')}`,
          available: `${e.available.toString().replace('.', ',')}`,
        }))] */
        }
        separator={';'}
        headers={columns.map((e) => ({
          label: e.Header,
          key: e.accessor,
        }))}
      >
        <DownloadIconCSV
          style={{ display: !data ? 'none' : 'initial', marginRight: '5px' }}
        />
      </CSVLink>
    </Container>
  );
}

export default InvestmentPortfolioTransactions;

export const FICHA_COMPENSACAO_TEXT = [
    {
        title: "CODIGO DE BARRAS",
        bold: false,
        fieldName: 'digitableLine',
    },
    {
        title: "EMISSOR",
        fieldName: 'assignor',
    },
    {
        title: "DATA DE VENCIMENTO",
        fieldName: 'dueDate',
    },
    {
        title: "NOME DO BENEFICIÁRIO",
        fieldName: 'beneficiaryName',
    },
    {
        title: "CPF/CNPJ DO BENEFICIÁRIO",
        fieldName: 'beneficiaryDocument',
    },
    {
        title: "NOME DO PAGADOR FINAL",
        fieldName: 'draweeName',
    },
    {
        title: "CPF/CNPJ DO PAGADOR FINAL",
        fieldName: 'draweeDocument',
    },
    {
        title: "AGÊNCIA",
        fieldName: 'branch',
    },
    {
        title: "CONTA",
        fieldName: 'account',
    },
    {
        title: "VALOR DO DOCUMENTO",
        fieldName: 'grossValue',
    },
    {
        title: "DESCONTO",
        fieldName: 'discountValue',
    },
    {
        title: "JUROS/MORA",
        fieldName: 'additionalValue',
    },
    {
        title: "MULTA",
        fieldName: 'fineCalculatedValue',
    },
    {
        title: "ENCARGO",
        fieldName: 'additionalTotalValue',
    },
    {
        title: "VALOR A PAGAR",
        fieldName: 'valuePayment',
    },
];


export const CONTA_CONCESSIONARIA_TEXT = [
    {
        title: "DADOS DO PAGAMENTO:",
        bold: true,
    },
    {
        title: "CODIGO DE BARRAS",
        bold: false,
        fieldName: 'digitableLine',
    },
    {
        title: "EMISSOR",
        fieldName: 'assignor',
    },
    {
        title: "DATA DE VENCIMENTO",
        fieldName: 'dueDate',
    },
    {
        title: "NOME DO PAGADOR FINAL",
        fieldName: 'draweeName',
    },
    {
        title: "CPF/CNPJ DO PAGADOR FINAL",
        fieldName: 'draweeDocument',
    },
    {
        title: "AGÊNCIA",
        fieldName: 'branch',
    },
    {
        title: "CONTA",
        fieldName: 'account',
    },
    {
        title: "VALOR A PAGAR",
        fieldName: 'valuePayment',
    },

];
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import {
  Container,
  ContentWrapper,
  AccountBalanceTableWrapper,
  NoItemsAvailableLabel,
  TableLabel,
  Wrapper,
  AccountInformationsWrapper,
  InformationWrapper,
  Title,
  Value,
  AmountInput,
  ButtonDisabledReasonText,
} from './styles';
import { revertBrazilianCurrencyToFloat } from 'utils/functions/currency';
import {
  AccountService,
  TokenService,
  TransfersService,
} from 'banking-service';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/Button';
import ScreenTitle from 'components/ScreenTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import AccountBalanceScreenTable from 'components/AccountBalanceScreenTable';
import TransferResumeList from 'components/TransferForm/TransferResumeList';
import PasswordVerification from 'components/PasswordVerification';
import TransferFeedback from 'components/TransferForm/TransferFeedback';
import { hasActiveToken } from 'utils/functions/token';
import TokenActivationModal from 'components/TokenActivationModal';

import { needsToActivateToken } from 'utils/functions/token';
import { useConfigContext } from 'providers/client-config-provider';

import {
  SecurityAccountBalanceTexts01,
  SecurityAccountBalanceTexts02,
} from 'constants/security-account-balance-table-texts';
import { useTheme } from 'styled-components';

function k(i, callback, data) {
  var v = i.target.value.replace(/\D/g, '');
  //if (v > 25000000) v = 25000000;
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  i.value = v;
  callback({ ...data, amount: `R$ ${i.value}` });
  //callback('amount', `R$ ${i.value}`);
}

const STEPS = {
  FIRST: 'FORM_FILL',
  SECOND: 'FORM_REVIEW',
  THIRD: 'FEEDBACK',
};

function SecurityAccountPayment({
  section = 'Conta Garantida',
  name = 'Pagamento',
  ...props
}) {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState({
    balance: false,
    transactions: false,
    transfer: false,
    token: false,
  });

  const [tokenModalType, setModalTokenType] = useState('');
  const [
    showNeedsToActivateTokenModal,
    hideNeedsToActivateTokenModal,
  ] = useModal(
    () => (
      <ReactModal
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        isOpen
      >
        <TokenActivationModal
          backButtonText={resolveBackButtonText()}
          onActiveClick={() => {
            props.history.push('/services/token');
          }}
          onBackHomeClick={resolveBackClick}
          hideModalCallback={hideNeedsToActivateTokenModal}
          type={tokenModalType}
        />
      </ReactModal>
    ),
    [tokenModalType]
  );

  const resolveBackButtonText = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      return 'CANCELARA';
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      return 'CANCELAR';
    } else {
      return 'VOLTAR PARA HOME';
    }
  };

  const resolveBackClick = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      hideNeedsToActivateTokenModal();
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      hideNeedsToActivateTokenModal();
    } else {
      props.history.push('/');
    }
  };

  useEffect(() => {
    const fetchTokens = async () => {
      setIsLoading((loading) => ({ ...loading, token: true }));
      setError('');
      try {
        const res = await TokenService.getInfo();
        let tokenRequiredAction = needsToActivateToken({
          data: [...res.filter((e) => e.status !== 'Inativo')],
        });
        if (tokenRequiredAction) {
          setModalTokenType(tokenRequiredAction);
          showNeedsToActivateTokenModal();
        }
      } catch (e) {}
      setIsLoading((loading) => ({ ...loading, token: false }));
    };
    fetchTokens();
  }, []);

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [step, setStep] = useState(STEPS.FIRST);

  const [balance, setBalance] = useState(null);
  const [balanceInformations01, setBalanceInformations01] = useState([]);
  const [balanceInformations02, setBalanceInformations02] = useState([]);
  const [token, setToken] = useState(false);
  const [
    internalTransferConfirmationInfos,
    setInternalTransferConfirmationInfos,
  ] = useState({});

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(
    false
  );
  const [buttonDisabledReason, setButtonDisabledReason] = useState(false);

  const [data, setData] = useState({
    amount: '',
  });

  const [touched, setTouched] = useState({
    amount: false,
  });

  const [errors, setErrors] = useState({
    amount: false,
  });

  const theme = useTheme()
  
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal
        onRequestClose={hideModal}
        style={{
          overlay: {
            zIndex: '11',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            backgroundColor: theme.Cor_base_marca_01,
          },
        }}
        isOpen
      >
        <PasswordVerification
          hasToken={!!token}
          onConfirmButtonClick={async ({ password, otp }) => {
            hideModal();
            await confirmTransfer({ password, otp });
          }}
        />
      </ReactModal>
    ),
    [!!token, data, balanceInformations01, balanceInformations02]
  );

  const [noSecurityAccountAvailable, setNoSecurityAccountAvailable] = useState(
    false
  );
  useEffect(() => {
    const mapBalanceInformations01 = (res) => {
      return SecurityAccountBalanceTexts01.map((e) => ({
        ...e,
        value: res[e.fieldName],
      }));
    };

    const mapBalanceInformations02 = (res) => {
      return SecurityAccountBalanceTexts02.map((e) => ({
        ...e,
        value: res[e.fieldName],
      }));
    };

    const fetchSecurityAccountBalance = async () => {
      setIsLoading((loading) => ({ ...loading, transactions: true }));
      setError('');
      try {
        const res = await AccountService._getSecurityBalance();
        //setBalance(res.balanceInformation.balance);
        setBalanceInformations01(
          mapBalanceInformations01({
            limit: res.creditInformation.limit,
            balanceAvailable: res.balanceInformation.balanceAvailable,
          })
        );

        setBalanceInformations02(
          mapBalanceInformations02({
            branchaccount: `${res.securityBranch} / ${res.securityAccount}`,
            index: res.creditInformation.index,
            indexPercentage: `${res.creditInformation.indexPercentage.toFixed(
              2
            )}%`,
            rate: `${res.creditInformation.rate.toFixed(2)}%`,
            balance: res.balanceInformation.balance,
            dueDate: moment(res.creditInformation.dueDate).format('DD/MM/YYYY'),
          })
        );
      } catch (e) {
        e.message &&
        e.message ===
          'Ocorreu um erro de código 99 (Agencia e/ou Conta nao encontrada.) na integração com o legado.'
          ? setNoSecurityAccountAvailable(true)
          : e.message
          ? setError({ message: e.message })
          : setError({ message: 'Ocorreu um erro inesperado' });
      }
      setIsLoading((loading) => ({ ...loading, transactions: false }));
    };

    const fetchAccountBalance = async () => {
      setIsLoading((loading) => ({ ...loading, balance: true }));
      setError('');
      try {
        const res = await AccountService._getBalance();
        setBalance(AccountService.account.balance.available);
        //setBalanceInformations(mapBalanceInformations({ res: AccountService.account.balance }));
      } catch (e) {
        setError({ message: e.message });
      }
      setIsLoading((loading) => ({ ...loading, balance: false }));
    };

    fetchAccountBalance();
    fetchSecurityAccountBalance();
  }, []);

  useEffect(() => {
    if (Number(revertBrazilianCurrencyToFloat(data.amount)) > balance) {
      setIsContinueButtonDisabled(true);
      setButtonDisabledReason('* Saldo Insuficiente');
    } else {
      setIsContinueButtonDisabled(false);
      setButtonDisabledReason('');
    }
  }, [balanceInformations01, data]);

  function resolveButtonDisability() {
    return (
      isContinueButtonDisabled ||
      data.amount === '' ||
      data.amount === 'R$ 0,00'
    );
  }

  function resolveData() {
    return [
      {
        field: 'Agência',
        value: AccountService.account.branch,
      },
      {
        field: 'CONTA',
        value: AccountService.account.account,
      },
      {
        field: 'VALOR',
        value: data.amount,
      },
    ];
  }

  useEffect(() => {
    const fetchTokenInfo = async () => {
      setIsLoading((loading) => ({ ...loading, transfer: true }));
      try {
        const res = await TokenService.getInfo();
        setToken(hasActiveToken({ data: res }));
      } catch (e) {
        setToken(null);
      }
      setIsLoading((loading) => ({ ...loading, transfer: false }));
    };

    if (step === STEPS.SECOND) {
      fetchTokenInfo();
    }
  }, [step]);

  const sendInternalTransfer = async ({ password, otp }) => {
    const payload = {
      debit: {
        account: AccountService.account.account,
        branch: AccountService.account.branch,
      },
      date: moment().format('YYYY-MM-DD'),
      credit: {
        branch: balanceInformations02[0].value.split('/')[0].trim(),
        account: balanceInformations02[0].value.split('/')[1].trim(),
      },
      history: null,
      password,
      value: revertBrazilianCurrencyToFloat(data.amount),
    };

    const res = await AccountService.postConfirmSecurityAccountPayment({
      payload,
      otp,
    });

    setInternalTransferConfirmationInfos({
      ...res,
      authentication: '',
      date: moment().format('DD/MM/YYYY'),
    });
    setStep(STEPS.THIRD);
  };

  const confirmTransfer = async ({ password, otp }) => {
    setIsLoading((loading) => ({ ...loading, transfer: true }));
    try {
      await sendInternalTransfer({ password, otp });
    } catch (e) {
      setIsLoading((loading) => ({ ...loading, transfer: false }));
      setError({
        message:
          e.message || 'Ocorreu um erro inesperado ao processar sua transação',
      });
    }

    setIsLoading((loading) => ({ ...loading, transfer: false }));
  };

  const fetchReceipt = async ({ identifier }) => {
    try {
      const res = await TransfersService.fetchReceipt({
        identifier: identifier,
      });
      return res;
    } catch (e) {
      return { authentication: '', transactionDate: moment() };
    }
  };

  return (
    <LoadingSpinner
      isLoading={
        isLoading.balance ||
        isLoading.transactions ||
        isLoading.transfer ||
        isLoading.token
      }
    >
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          {step === STEPS.FIRST && (
            <Fragment>
              <CurrentAccountValue
                title={'saldo conta corrente'}
                value={balance}
              ></CurrentAccountValue>
              {noSecurityAccountAvailable && (
                <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
                  NÃO FOI LOCALIZADO NENHUM REGISTRO REFERENTE A CONTA GARANTIDA
                </NoItemsAvailableLabel>
              )}
              {!noSecurityAccountAvailable && (
                <AccountBalanceTableWrapper>
                  <AccountBalanceScreenTable
                    data={balanceInformations01}
                  ></AccountBalanceScreenTable>
                </AccountBalanceTableWrapper>
              )}

              <br />
              <br />

              <TableLabel>DADOS DA CONTA</TableLabel>
              {!noSecurityAccountAvailable && (
                <AccountBalanceTableWrapper>
                  <AccountBalanceScreenTable
                    data={balanceInformations02}
                  ></AccountBalanceScreenTable>
                </AccountBalanceTableWrapper>
              )}

              <br />
              <br />

              <TableLabel>DADOS DA OPERAÇÃO</TableLabel>
              {!noSecurityAccountAvailable && (
                <AccountBalanceTableWrapper>
                  <Wrapper>
                    <AccountInformationsWrapper>
                      <InformationWrapper>
                        <Title>Agência (s/ dígito)</Title>
                        <Value value={`${AccountService.account.branch}`} />
                      </InformationWrapper>
                      <InformationWrapper>
                        <Title>Conta</Title>
                        <Value value={`${AccountService.account.account}`} />
                      </InformationWrapper>
                    </AccountInformationsWrapper>
                    <AmountInput
                      id="amountInputId"
                      type="text"
                      name="amount"
                      hasErrors={
                        (errors.amount && touched.amount) ||
                        (data.amount === 'R$ 0,00' && touched.amount)
                      }
                      value={data.amount}
                      onChange={(e) => {
                        setData({ ...data, amount: e.target.value });
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, amount: true });
                        if (data.amount === '') {
                          setErrors({ ...errors, amount: true });
                        } else {
                          setErrors({ ...errors, amount: false });
                        }
                      }}
                      onKeyUp={(e) => k(e, setData, data)}
                      placeholder={'Valor *'}
                    />
                  </Wrapper>
                  <ButtonDisabledReasonText id="buttonDisabledReasonId">
                    {buttonDisabledReason}
                  </ButtonDisabledReasonText>
                  <Button
                    onClick={() => {
                      setStep(STEPS.SECOND);
                    }}
                    disabled={resolveButtonDisability()}
                    style={{
                      alignSelf: 'center',
                      marginTop: '30px',
                      marginBottom: '50px',
                    }}
                    title={'Continuar'}
                  />
                </AccountBalanceTableWrapper>
              )}
            </Fragment>
          )}

          {step === STEPS.SECOND && (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AccountBalanceTableWrapper>
                  <TransferResumeList
                    isSecurityAccountReview
                    data={resolveData()}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '20px',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setStep(STEPS.FIRST);
                      }}
                      id="secondStepBackButtonId"
                      title={'Voltar'}
                    ></Button>
                    <Button
                      onClick={() => showModal()}
                      id="secondStepContinueButtonId"
                      title={'Continuar'}
                    ></Button>
                  </div>
                </AccountBalanceTableWrapper>
              </div>
            </Fragment>
          )}

          {step === STEPS.THIRD && (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <AccountBalanceTableWrapper>
                  <TransferFeedback
                    isSecurityAccountReview
                    aditionalInformations={internalTransferConfirmationInfos}
                    data={resolveData()}
                  />
                </AccountBalanceTableWrapper>
              </div>
            </Fragment>
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default SecurityAccountPayment;

import React from "react";
import { Container } from "./styles";

function SecondaryButton({
  title = '',
  disabled = false,
  onClick = ()=>{},
  outline = false,
  mediumFont = false,
  ...props
}) {
  return (
    <Container
      onClick={onClick}
      disabled={disabled}
      outline={outline}
      mediumFont={mediumFont}
      {...props}
    >
      {`${title}`}
    </Container>
  );
}

export default SecondaryButton;

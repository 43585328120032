export default [
  {
    title: "SALDO VINCULADO",
    subtitle: "(Saldo a compensar de DOC/TED + cheques)",
    fieldName: "future",
  },
  {
    title: "SALDO BLOQUEADO",
    subtitle: "(Saldo que judicialmente não pode ser movimentado)",
    fieldName: "blocked",
  },
  {
    title: "LIMITE",
    subtitle: "(Limite de crédito da conta)",
    fieldName: "securedAccountLimit"
  },
  {
    title: "ENCARGOS",
    subtitle: "(Total de encargos da conta + IOF)",
    fieldName: "totalCharge"
  }
];

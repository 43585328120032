import styled from "styled-components";

export const Container = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: ${({bgColor}) => bgColor ? bgColor : 'transparent'}
`;

export const CarouselItem = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    flex: 1;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    transition: transform 700ms, opacity 800ms;
    transform: translateX(100%);
    opacity: 0;
    z-index: ${({wasActive}) => wasActive ? 16 : 17};
    opacity: ${({active, keepActive}) => active || keepActive ? 1 : 0};
    transform: ${({active, keepActive}) => active || keepActive ? 'translateX(0%)' : 'translateX(100%)'};
`;

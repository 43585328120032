import React from 'react';
import { Overlay, Modal, TextTitle, TextDescription, ButtonsContainer, ButtonItem } from './styles';

const ConfirmationModal = ({
  title,
  description,
  cancelText='cancelar',
  confirmText='confirmar',
  cancelAction=()=>{},
  confirmAction=()=>{},
  ...props
}) => (
  <>
    <Overlay>
      <Modal {...props}>
        <TextTitle>
          {title}
        </TextTitle>
        <TextDescription>
          {description}
        </TextDescription>
        <ButtonsContainer>
          <ButtonItem cancel onClick={cancelAction}>
            {cancelText}
          </ButtonItem>
          <ButtonItem onClick={confirmAction}>
            {confirmText}
          </ButtonItem>
        </ButtonsContainer>
      </Modal>
    </Overlay>
  </>
);

export default ConfirmationModal;
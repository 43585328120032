import React from "react";
import { Container, Title, Description, Link } from "./styles";
import { parseText } from "utils/functions/text";
import { withRouter } from 'react-router-dom';

function Banner({ title, imgUrl, description, link, isInternalLink, type, optInAction = ()=>{}, ...props }) {
  return (
    <Container imgUrl={imgUrl}>
      <Title>
        {title
          ? parseText({
            text: title,
            limit: 30
          })
          : ""}
      </Title>
      <Description>{description}</Description>
      {isInternalLink ?
        (<Link
            onClick={() => {
              type === 'antecipationReceivables' ? (
                optInAction()
              ) :
              props.history.push('/payments');
            }}
          >
            Saiba mais
          </Link>) :
        (
          <Link href={link} target="_blank">
            Saiba mais
      </Link>)}
    </Container>
  );
}

export default withRouter(Banner);

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HttpService } from 'banking-service/utils/HttpService';
import 'react-dates/initialize';
import Login from 'containers/Login';
import { ConfigProvider } from 'providers/client-config-provider.js';
import { AntecipationProvider } from 'providers/anticipation-provider.js';
import AuthDataProvider from 'providers/auth-provider';
import SwitchAccountDataProvider from 'providers/switch-account-provider';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { ModalProvider } from 'react-modal-hook';

import { Setup } from 'banking-service';
import Dashboard from 'containers/Dashboard';
import MobileDetection from 'containers/MobileDetection';
import ModalMessageResolver from 'containers/MessageModalResolver';

// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '100px',
  containerStyle: { fontSize: '12px', opacity: 0.8 },
  // you can also just use 'scale'
  transition: transitions.SCALE
};

//this is a greate comment;//this is a greate comment;//this is a greate comment;//this is a greate comment;//this is a greate comment;//this is a greate comment;//this is a greate comment;//this is a greate comment;

function App() {
  return (
    <SwitchAccountDataProvider>
      <ConfigProvider>
        <ModalProvider>
          <AuthDataProvider>
            <AntecipationProvider>
              <Router>
                <ModalMessageResolver>
                  <AlertProvider
                    id="alertDiv"
                    template={AlertTemplate}
                    {...options}
                  >
                    <Route path="/login" exact component={Login} />
                    <Route
                      path="/login/tokenExpirationTest"
                      exact
                      component={Login}
                    />
                    <MobileDetection>
                      <Switch>
                        <Route path="/" component={Dashboard} />
                      </Switch>
                    </MobileDetection>
                  </AlertProvider>
                </ModalMessageResolver>
              </Router>
            </AntecipationProvider>
          </AuthDataProvider>
        </ModalProvider>
      </ConfigProvider>
    </SwitchAccountDataProvider>
  );
}

export default App;

export const TOPAZIO_TEXT = [
    {
        title: "SITUAÇÃO",
        fieldName: 'situation',
    },
    {
        title: "DE:",
        fieldName: 'debitantName',
    },
    {
        title: "- CPF / CNPJ",
        fieldName: 'debitantTaxIdentifier',
    },
    {
        title: "NOME BENEFICIÁRIO",
        fieldName: 'beneficiaryName',
    },
    {
        title: "CPF / CPNPJ",
        fieldName: 'beneficiaryTaxIdentifier',
    },
    {
        title: "AGÊNCIA (S/DÍGITO)",
        fieldName: 'beneficiaryBranch',
    },
    {
        title: "CONTA",
        fieldName: 'beneficiaryAccount',
    },
    {
        title: "DATA DE PAGAMENTO",
        fieldName: 'transactionDate',
    },
    {
        title: "VALOR",
        fieldName: 'value',
    },
];

export const OTHERBANKS_TEXT = [
    {
        title: "SITUAÇÃO",
        fieldName: 'situation',
    },
    {
        title: "DE:",
        fieldName: 'debitantName',
    },
    {
        title: "- CPF / CNPJ",
        fieldName: 'debitantTaxIdentifier',
    },
    {
        title: "- AGÊNCIA",
        fieldName: 'debitantBranch',
    },
    {
        title: "- CONTA",
        fieldName: 'debitantAccount',
    },
    {
        title: "- BANCO",
        fieldName: 'debitantBankDescription',
    },
    {
        title: "PARA:",
        fieldName: 'beneficiaryName',
    },
    {
        title: "- CPF / CPNPJ",
        fieldName: 'beneficiaryTaxIdentifier',
    },
    {
        title: "- AGÊNCIA",
        fieldName: 'beneficiaryBranch',
    },
    {
        title: "- CONTA",
        fieldName: 'beneficiaryAccount',
    },
    {
        title: "- BANCO",
        fieldName: 'beneficiaryBankDescription',
    },
    {
        title: "DATA DE PAGAMENTO",
        fieldName: 'transactionDate',
    },
    {
        title: "FINALIDADE",
        fieldName: 'purpose',
    },
    {
        title: "VALOR",
        fieldName: 'value',
    },
];
import React, { useState, useEffect, useContext } from 'react';
import { useConfigContext } from 'providers/client-config-provider';
import { Container } from './styles';
import { ThemeContext } from 'styled-components';
import { PulseLoader } from 'react-spinners';

function FirstLoader() {
  const themeContext = useContext(ThemeContext);
  const { clientConfig } = useConfigContext();

  // console.log(clientConfig)
  const colors = clientConfig
    ? [themeContext.brandColor5, themeContext.brandColor1]
    : ['#fff', '#d4d4d4'];

  // console.log(colors);
  useEffect(() => {
    setInterval(() => {
      setColor(colors[Math.floor(Math.random() * colors.length)]);
    }, 1500);
  }, [colors]);

  const [color, setColor] = useState(
    clientConfig ? themeContext.brandColor5 : '#fff'
  );

  return (
    <Container id="firstLoaderDiv">
      {clientConfig && <img src={clientConfig.images.brandNameImageOnLogin} />}
      <br />
      <PulseLoader size={16} color={color} />
    </Container>
  );
}

export default FirstLoader;

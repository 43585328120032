import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  AccountService,
  OutstandingPaymentsService,
  TokenService,
} from 'banking-service';
import { useModal } from 'react-modal-hook';
import { Collapse } from 'react-collapse';
import ValueOnTables from 'components/ValueOnTables';
import ReactModal from 'react-modal';
import Button from 'components/Button';
import PasswordVerification from 'components/PasswordVerification';
import { hasActiveToken } from 'utils/functions/token';

import {
  Container,
  ContentWrapper,
  ItemContainer,
  ColumnWrapper,
  Title,
  Value,
  ColoredValue,
  NoItemsAvailableLabel,
  IsOpenedIcon,
  IsNotOpenedIcon,
  Wrapper,
  TableLabel,
  FieldsWrapper,
  SelectAllButton,
  ButtonDisabledReasonText,
  ButtonsWrapper,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import { handleCurrency } from 'utils/functions/currency';
import OutstandingResumeList from 'components/OutstandingResumeList';
import Checkbox from 'components/Checkbox';

import CurrentAccountValue from 'components/CurrentAccountValue';

import TokenActivationModal from 'components/TokenActivationModal';

import { needsToActivateToken } from 'utils/functions/token';
import { useConfigContext } from 'providers/client-config-provider';

import { resolveOperationName } from './utils';
import { useTheme } from 'styled-components';

const OPERATION_TYPES = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

function OutstandingPayments({ name = 'Aprovações Pendentes', ...props }) {
  const alert = useAlert();
  const theme = useTheme()

  const [isLoading, setIsLoading] = useState({
    balance: false,
    outstandings: false,
    token: false,
  });

  const [tokenModalType, setModalTokenType] = useState('');

  const [
    showNeedsToActivateTokenModal,
    hideNeedsToActivateTokenModal,
  ] = useModal(
    () => (
      <ReactModal
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        isOpen
      >
        <TokenActivationModal
          backButtonText={resolveBackButtonText()}
          onActiveClick={() => {
            props.history.push('/services/token');
          }}
          onBackHomeClick={resolveBackClick}
          hideModalCallback={hideNeedsToActivateTokenModal}
          type={tokenModalType}
        />
      </ReactModal>
    ),
    [tokenModalType]
  );

  const resolveBackButtonText = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      return 'CANCELAR';
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      return 'CANCELAR';
    } else {
      return 'VOLTAR PARA HOME';
    }
  };

  const resolveBackClick = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      hideNeedsToActivateTokenModal();
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      hideNeedsToActivateTokenModal();
    } else {
      props.history.push('/');
    }
  };
  useEffect(() => {
    const fetchTokens = async () => {
      setIsLoading((loading) => ({ ...loading, token: true }));
      setError('');
      try {
        const res = await TokenService.getInfo();
        let tokenRequiredAction = needsToActivateToken({
          data: [...res.filter((e) => e.status !== 'Inativo')],
        });
        if (tokenRequiredAction) {
          setModalTokenType(tokenRequiredAction);
          showNeedsToActivateTokenModal();
        }
      } catch (e) {}
      setIsLoading((loading) => ({ ...loading, token: false }));
    };
    fetchTokens();
  }, []);
  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [outstandingPayments, setOutstandingPayments] = useState([]);
  const [selectedOutstandings, setSelectedOutstandings] = useState([]);

  const Item = ({ data, checked, outstadingDetails, onSelect }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
      <ItemContainer
        style={{
          opacity: !data.canApprove
            ? 0.5
            : selectedOutstandings.length === 0
            ? 1
            : 1,
          pointerEvents: !data.canApprove ? 'none' : 'auto',
        }}
      >
        {
          <FieldsWrapper isChecked={checked}>
            <ColumnWrapper
              style={{ maxWidth: '20px', padding: '0px 20px' }}
              onClick={() => onSelect({ id: data.id, checked: !checked })}
            >
              {<Checkbox checked={checked} />}
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>OPERAÇÃO</Title>
              <Value>{resolveOperationName({ operation: data.service })}</Value>
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>VALOR</Title>
              <ColoredValue colored>
                {handleCurrency({ value: data.value })}
              </ColoredValue>
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>DATA PAG.</Title>
              <Value>{moment(data.operationDate).format('DD/MM/YYYY')}</Value>
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>BENEFICIÁRIO</Title>
              <Value>{`${data.beneficiary.substring(0, 10)} ...`}</Value>
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>SOLICITADA POR</Title>
              <Value>{`${data.userRequest.substring(0, 10)} ...`}</Value>
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>STATUS</Title>
              <Value>{data.statusText}</Value>
            </ColumnWrapper>
            <ColumnWrapper>
              <Title>QTD APROVAÇÕES</Title>
              <Value>{`${pad(data.qtyApprovals, 2)} de ${pad(
                data.requiredApprovals,
                2
              )}`}</Value>
            </ColumnWrapper>
            <ColumnWrapper style={{ pointerEvents: 'auto' }}>
              {isOpened && (
                <IsOpenedIcon
                  onClick={() => setIsOpened((isOpened) => !isOpened)}
                />
              )}
              {!isOpened && (
                <IsNotOpenedIcon
                  onClick={() => setIsOpened((isOpened) => !isOpened)}
                />
              )}
            </ColumnWrapper>
          </FieldsWrapper>
        }
        <div style={{ width: '100%' }}>
          <Collapse style={{ width: '100%' }} isOpened={isOpened}>
            <OutstandingResumeList
              data={{
                ...outstadingDetails,
                value: handleCurrency({ value: outstadingDetails.value }),
                operationDate: moment(outstadingDetails.operationDate).format(
                  'DD/MM/YYYY'
                ),
              }}
            />
          </Collapse>
        </div>
      </ItemContainer>
    );
  };

  const [balance, setBalance] = useState(null);
  useEffect(() => {
    const fetchBalance = async () => {
      setIsLoading((isLoading) => ({ ...isLoading, balance: true }));
      try {
        const res = await AccountService._getBalance();
        setBalance(res.available);
      } catch (e) {
        setError({
          message:
            'Infelizmente não conseguimos carregar seu saldo. Não teremos como continuar esta operação.',
        });
      }
      setIsLoading((isLoading) => ({ ...isLoading, balance: false }));
    };

    const fetchTokenInfo = async () => {
      setIsLoading(true);
      try {
        const res = await TokenService.getInfo();
        setToken(hasActiveToken({ data: res }));
      } catch (e) {
        setToken(null);
      }
      setIsLoading(false);
    };

    fetchBalance();
    fetchTokenInfo();
  }, []);

  useEffect(() => {
    const fetchOutstandingPayments = async () => {
      setIsLoading((isLoading) => ({ ...isLoading, outstandings: true }));
      setError('');
      try {
        const res = await OutstandingPaymentsService.getItems();
        setOutstandingPayments([...res]);
      } catch (e) {
        setOutstandingPayments([]);
      }
      setIsLoading((isLoading) => ({ ...isLoading, outstandings: false }));
    };

    fetchOutstandingPayments();
  }, []);

  const [buttonDisabled, setButtonDisabled] = useState({
    value: false,
    reason: '',
  });
  useEffect(() => {
    const initialButtonDisabled = { value: true, reason: '' };

    if (selectedOutstandings.length === 0) {
      setButtonDisabled({ ...initialButtonDisabled });
    } else if (
      selectedOutstandings
        .filter((e) => e.missingApprovals === 1)
        .reduce(function(acc, e) {
          return acc + e.value;
        }, 0) > balance
    ) {
      setButtonDisabled({
        value: true,
        reason: '* Saldo insuficiente para o pagamento',
      });
    } else {
      setButtonDisabled({ ...initialButtonDisabled, value: false });
    }
  }, [selectedOutstandings]);

  const [operationType, setOperationType] = useState(null);
  const confirmApprovals = async ({ password, otp }) => {
    const fetchOutstandingPayments = async () => {
      setIsLoading((isLoading) => ({ ...isLoading, outstandings: true }));
      setError('');
      try {
        const res = await OutstandingPaymentsService.getItems();
        setOutstandingPayments([...res]);
      } catch (e) {
        setOutstandingPayments([]);
      }
      setIsLoading((isLoading) => ({ ...isLoading, outstandings: false }));
    };

    setIsLoading((isLoading) => ({ ...isLoading, outstandings: true }));

    try {
      if (operationType === OPERATION_TYPES.APPROVE) {
        await OutstandingPaymentsService.approve(
          selectedOutstandings.map((e) => e.id),
          password,
          '',
          otp
        );
        alert.success('Suas transações foram aprovadas com sucesso');
      } else {
        await OutstandingPaymentsService.reject(
          selectedOutstandings[0].id,
          password,
          '',
          otp
        );
        alert.success(
          'As transações selecionadas foram rejeitadas com sucesso'
        );
      }

      setSelectedOutstandings([]);

      setTimeout(() => {
        fetchOutstandingPayments();
        setIsLoading((isLoading) => ({ ...isLoading, outstandings: false }));
      }, 6000);
    } catch (e) {
      setTimeout(() => {
        setIsLoading((isLoading) => ({ ...isLoading, outstandings: false }));
        fetchOutstandingPayments();
        setError({ message: e.message });
      }, 6000);
    }
  };

  const [token, setToken] = useState(false);
  const [showModal, hideModal] = useModal(() => {
    return (
      <ReactModal
        onRequestClose={hideModal}
        style={{
          overlay: {
            zIndex: '11',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            backgroundColor: theme.Cor_base_marca_01,
          },
        }}
        isOpen
      >
        <PasswordVerification
          isOutstandingStyle
          outstandingData={{
            amount: handleCurrency({
              value: selectedOutstandings.reduce(function(acc, e) {
                return acc + e.value;
              }, 0),
            }),
            nOfSelected: selectedOutstandings.length,
            nfOfTotal: outstandingPayments.length,
            operationType,
          }}
          hasToken={!!token}
          onConfirmButtonClick={async ({ password, otp }) => {
            hideModal();
            await confirmApprovals({ password, otp });
          }}
        />
      </ReactModal>
    );
  }, [!!token, outstandingPayments, selectedOutstandings, operationType]);

  return (
    <LoadingSpinner
      isLoading={isLoading.balance || isLoading.outstandings || isLoading.token}
    >
      <Container>
        <ScreenTitle title={name}></ScreenTitle>
        <ContentWrapper>
          <CurrentAccountValue title={'saldo conta corrente'} value={balance} />
          {outstandingPayments.length === 0 ? (
            <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
              Nenhuma transação foi requisitada por um operador
            </NoItemsAvailableLabel>
          ) : (
            <Wrapper>
              {
                <div style={{ alignSelf: 'flex-start', marginBottom: '30px' }}>
                  <TableLabel>TRANSAÇÕES</TableLabel>
                  {
                    <SelectAllButton
                      onClick={() => {
                        if (selectedOutstandings.length > 0) {
                          setSelectedOutstandings([]);
                        } else {
                          setSelectedOutstandings([
                            ...outstandingPayments.filter((e) => e.canApprove),
                          ]);
                        }
                      }}
                    >
                      {selectedOutstandings.length > 0
                        ? 'Desmarcar todas'
                        : 'Selecionar todas'}
                    </SelectAllButton>
                  }
                </div>
              }
              {outstandingPayments.map((e) => {
                return (
                  <Item
                    key={Math.random()}
                    data={{ ...e }}
                    checked={
                      !!selectedOutstandings.find((el) => el.id === e.id)
                    }
                    outstadingDetails={{ ...e }}
                    onSelect={({ id, checked }) => {
                      if (checked) {
                        setSelectedOutstandings((alreadySelecteds) => [
                          ...alreadySelecteds,
                          outstandingPayments.find((e) => e.id === id),
                        ]);
                      } else {
                        setSelectedOutstandings((alreadySelecteds) =>
                          alreadySelecteds.filter((e) => e.id !== id)
                        );
                      }
                    }}
                  />
                );
              })}
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'flex-start',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TableLabel style={{ margin: '50px 0px' }}>
                  VALOR A PAGAR
                </TableLabel>
                <ValueOnTables
                  style={{
                    marginLeft: '15px',
                    fontSize: '12px',
                    fontFamily: '"SourceSansPro",Arial,sans-serif',
                  }}
                  value={selectedOutstandings.reduce(function(acc, e) {
                    return acc + e.value;
                  }, 0)}
                />
              </div>
              <ButtonDisabledReasonText
                style={{ marginBottom: '20px' }}
                id="buttonDisabledReasonId"
              >
                {buttonDisabled.reason}
              </ButtonDisabledReasonText>
              <ButtonsWrapper>
                <Button
                  onClick={() => {
                    setOperationType(OPERATION_TYPES.APPROVE);
                    showModal();
                  }}
                  disabled={
                    buttonDisabled.value ||
                    AccountService.account.profileType === 'OPERATOR'
                  }
                  title={'APROVAR TRANSAÇÕES'}
                />
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    color: theme.Cor_base_marca_01,
                  }}
                  onClick={() => {
                    setOperationType(OPERATION_TYPES.REJECT);
                    showModal();
                  }}
                  disabled={selectedOutstandings.length !== 1}
                  title={'REJEITAR TRANSAÇÕES'}
                />
              </ButtonsWrapper>
            </Wrapper>
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default OutstandingPayments;

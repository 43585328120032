import React from 'react';
import styled from 'styled-components';

const TextInput = styled.input.attrs({ type: 'text', key: 'name' })`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 14px;
  font-weight: 300;
  border-width: 0px 0px 0px 0px;
  border-bottom: ${props =>
    props.hasErrors
      ? 'solid 2px red'
      : props.isDisabled
      ? 'none'
      : `solid 2px ${props.theme.brandColor5}`};
  color: ${props => props.theme.brandColor3};
  background-color: transparent;
  text-align: left;
  padding-left: 8px;
  padding-bottom: 7px;
  padding-top: 5px;
  margin-top: 20px;
  margin: 0;
  text-transform: uppercase;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'initial')};

  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
  :-ms-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
`;

export default TextInput;

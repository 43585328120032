import styled from 'styled-components';
import { Clock } from 'styled-icons/fa-regular/Clock';
import { Copy } from 'styled-icons/icomoon/Copy';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  font-family: 'SourceSansPro', Arial, sans-serif;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const HeaderText = styled.span`
  color: ${(props) => props.theme.brandColor3};
`;

export const ClockIcon = styled(Clock)`
  width: 35px;
  height: 35px;
  margin-right: 15px;
  color: ${(props) => props.theme.Cor_base_marca_01};
`;

export const PhraseText = styled.span`
  strong {
    color: ${(props) => props.theme.alert};
  }
`;

export const PaymentIdentifierWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 25px;
  justify-content: center;
  align-items: center;
`;

export const PaymentIdentifierText = styled.span`
  strong {
    color: ${(props) => props.theme.brandColor3};
  }
`;

export const CopyToCliboardIcon = styled(Copy)`
  width: 25px;
  height: 25px;
  margin-left: 10px;
  color: ${(props) => props.theme.Cor_base_marca_01};
  cursor: pointer;
`;

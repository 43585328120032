import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 8;
  flex-direction: column;
  margin-top: 20px;
`;

export const NoItemsAvailableLabel = styled.span`

    align-self: 'center';
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: ${props => props.theme.brandColor3};
    margin-top: 30px;
    margin-bottom: 30px;
`
import React from 'react';
import {
  Container,
  TextItem,
  ActionContainer,
  AcceptButton,
  InformationIcon,
  Tooltip,
  TooltipText,
  SimulationNetValueContainer,
  SimulationNetValueText,
} from './styles';
import { RowWrapper } from 'containers/Transfers';
import './tooltip.css';

function SimulationItem({
  title = '',
  finalValue = '',
  schedulesAmount = '',
  totalValue = '',
  tax = '',
  discount = '',
  onSwitch = () => {},
  acceptState = true,
  itemKey = '',
  ...props
}) {
  return (
    <Container {...props} key={'inner-' + itemKey}>
      <div className="simulation-item-head">
        <TextItem>
          <span>{title}</span>
        </TextItem>
        <ActionContainer>
          <span>aceitar</span>
          <div>
            <AcceptButton
              active={acceptState}
              onClick={() => onSwitch(true, itemKey)}
            >
              Sim
            </AcceptButton>
            <AcceptButton
              active={!acceptState}
              decline
              onClick={() => onSwitch(false, itemKey)}
            >
              Não
            </AcceptButton>
          </div>
        </ActionContainer>
      </div>
      <div className="simulation-item-body">
        <TextItem>
          <span>Agendas Selecionadas</span>
          <span className="simulation-text-value">{schedulesAmount}</span>
        </TextItem>
        <TextItem>
          <span>Valor das agendas</span>
          <span className="simulation-text-value">{totalValue}</span>
        </TextItem>
        <TextItem>
          <RowWrapper style={{}}>
            <TextItem>
              <span style={{ margin: '0px' }}>Taxa diária</span>
            </TextItem>
            <Tooltip>
              <TooltipText>
                A taxa diária efetiva pode variar para cada agenda, dependendo
                da quantidade de dias restantes até o vencimento da mesma
              </TooltipText>
              <InformationIcon id="dailyTaxToolTipId"></InformationIcon>
            </Tooltip>
          </RowWrapper>
          <span className="simulation-text-value">{tax}</span>
        </TextItem>
        <TextItem>
          <span>Desconto</span>
          <span className="simulation-text-value">{discount}</span>
        </TextItem>
      </div>
      <SimulationNetValueContainer>
        <SimulationNetValueText>
          Valor líquido da simulação: {finalValue}
        </SimulationNetValueText>
      </SimulationNetValueContainer>
    </Container>
  );
}

export default SimulationItem;

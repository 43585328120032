import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Container,
  ContentWrapper,
  AccountBalanceTableWrapper,
  NoItemsAvailableLabel,
  TableLabel,
} from './styles';
import { AccountService } from 'banking-service';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import AccountBalanceScreenTable from 'components/AccountBalanceScreenTable';
import {
  SecurityAccountBalanceTexts01,
  SecurityAccountBalanceTexts02,
  SecurityAccountBalanceTexts03,
} from 'constants/security-account-balance-table-texts';

function SecurityAccountBalance({
  section = 'Conta Garantida',
  name = 'Extrato',
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  const [balance, setBalance] = useState(null);
  const [balanceInformations01, setBalanceInformations01] = useState([]);
  const [balanceInformations02, setBalanceInformations02] = useState([]);
  const [balanceInformations03, setBalanceInformations03] = useState([]);

  const [noSecurityAccountAvailable, setNoSecurityAccountAvailable] = useState(
    false
  );
  useEffect(() => {
    const mapBalanceInformations01 = (res) => {
      return SecurityAccountBalanceTexts01.map((e) => ({
        ...e,
        value: res[e.fieldName],
      }));
    };

    const mapBalanceInformations02 = (res) => {
      return SecurityAccountBalanceTexts02.map((e) => ({
        ...e,
        value: res[e.fieldName],
      }));
    };

    const mapBalanceInformations03 = (res) => {
      return SecurityAccountBalanceTexts03.map((e) => ({
        ...e,
        value: res[e.fieldName],
      }));
    };

    const fetchAccountBalance = async () => {
      setIsLoading((loading) => ({ ...loading, transactions: true }));
      setError('');
      try {
        const res = await AccountService._getSecurityBalance();
        setBalance(res.balanceInformation.balance);
        setBalanceInformations01(
          mapBalanceInformations01({
            limit: res.creditInformation.limit,
            balanceAvailable: res.balanceInformation.balanceAvailable,
          })
        );

        setBalanceInformations02(
          mapBalanceInformations02({
            branchaccount: `${res.securityBranch} / ${res.securityAccount}`,
            index: res.creditInformation.index,
            indexPercentage: `${res.creditInformation.indexPercentage.toFixed(
              2
            )}%`,
            rate: `${res.creditInformation.rate.toFixed(2)}%`,
            balance: res.balanceInformation.balance,
            initialDate: moment(res.creditInformation.initialDate).format(
              'DD/MM/YYYY'
            ),
            dueDate: moment(res.creditInformation.dueDate).format('DD/MM/YYYY'),
            status: res.creditInformation.status,
          })
        );

        setBalanceInformations03(
          mapBalanceInformations03({
            balance: res.balanceInformation.balance,
            interest: res.balanceInformation.interest,
            taxFinancialValue: res.balanceInformation.taxFinancialValue,
            totalFine: res.balanceInformation.fine,
            grossTotalBalance: res.balanceInformation.grossTotalBalance,
            balanceAvailable: res.balanceInformation.balanceAvailable,
          })
        );
      } catch (e) {
        e.error &&
        e.error.length > 0 &&
        e.error[0].message ===
          'Ocorreu um erro de código 99 (Agencia e/ou Conta nao encontrada.) na integração com o legado.'
          ? setNoSecurityAccountAvailable(true)
          : e.error && e.error.length > 0
          ? setError(e.error[0])
          : setError({ message: 'Ocorreu um erro inesperado' });
      }
      setIsLoading(false);
    };
    fetchAccountBalance();
  }, []);

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <CurrentAccountValue
            title={'saldo conta garantida'}
            value={balance}
          ></CurrentAccountValue>
          {noSecurityAccountAvailable && (
            <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
              NÃO FOI LOCALIZADO NENHUM REGISTRO REFERENTE A CONTA GARANTIDA
            </NoItemsAvailableLabel>
          )}
          {!noSecurityAccountAvailable && (
            <AccountBalanceTableWrapper>
              <AccountBalanceScreenTable
                data={balanceInformations01}
              ></AccountBalanceScreenTable>
            </AccountBalanceTableWrapper>
          )}

          <br />
          <br />

          <TableLabel>DADOS DA CONTA</TableLabel>
          {!noSecurityAccountAvailable && (
            <AccountBalanceTableWrapper>
              <AccountBalanceScreenTable
                data={balanceInformations02}
              ></AccountBalanceScreenTable>
            </AccountBalanceTableWrapper>
          )}

          <br />
          <br />

          <TableLabel>SALDO DETALHADO</TableLabel>
          {!noSecurityAccountAvailable && (
            <AccountBalanceTableWrapper>
              <AccountBalanceScreenTable
                data={balanceInformations03}
              ></AccountBalanceScreenTable>
            </AccountBalanceTableWrapper>
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default SecurityAccountBalance;

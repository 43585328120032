import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Text = styled.span`
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 1.5px;
  text-align: left;
  color: ${props => props.theme.default.pageTitle.color};

  strong {
    font-weight: 600;
    text-transform: uppercase;
  }
`;

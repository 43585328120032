import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';

import {
  BalanceWrapper,
  AccountSelectionWrapper,
  DownloadIcon,
  IconWrapper,
  ColoredValue,
} from './styles';

const columns = [
  {
    Header: 'Data de Solicitação',
    accessor: 'requestedDate',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{moment(props.original.createdAt).format('DD/MM/YYYY')}</span>
      </div>
    ),
  },
  {
    Header: 'Intervalo',
    accessor: 'startDate',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{`${moment(props.original.startDate).format(
          'DD/MM/YYYY'
        )} até ${moment(props.original.endDate).format('DD/MM/YYYY')}`}</span>
      </div>
    ),
  },
  {
    Header: 'Status',
    accessor: 'available',
    Cell: (props) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <ColoredValue status={props.original.status}>
            {props.original.status === 'FINISHED' ? 'DISPONÍVEL' : 'EM GERAÇÃO'}
          </ColoredValue>
        </div>
      );
    },
  },
  {
    Header: 'Download',
    accessor: 'onClick',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        {props.original.status === 'FINISHED' &&
        props.original.needsPartition ? (
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            {props.original.partitions.map((e, i) => (
              <div
                style={{
                  display: 'flex',
                  textAlign: 'center',
                  flexDirection: 'column',
                  padding: '5px',
                }}
              >
                {
                  <IconWrapper
                    title={`Período de ${moment(e.startDate).format(
                      'DD/MM/YYYY'
                    )} à ${moment(e.endDate).format('DD/MM/YYYY')}`}
                    onClick={() => {
                      alert(e);
                      props.value({ file: props.original.id, partition: e });
                    }}
                  >
                    <DownloadIcon alt="any" />
                  </IconWrapper>
                }
                {props.original.partitions.length > 1 && (
                  <span style={{ fontSize: '7.5px' }}>Semana {i + 1}</span>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <IconWrapper
              disabled={props.original.status !== 'FINISHED'}
              onClick={() => props.value({ file: props.original.id })}
            >
              <DownloadIcon isDisabled={props.original.status !== 'FINISHED'} />
            </IconWrapper>
          </div>
        )}
      </div>
    ),
  },
];

function ControlledComponent({ data }) {
  return (
    <BalanceWrapper>
      <AccountSelectionWrapper isSelected></AccountSelectionWrapper>

      <ReactTable
        style={{ display: 'flex', flex: 1, width: '100%' }}
        noDataText="Nenhum dado disponível"
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={data ? data.length : 0}
      />
    </BalanceWrapper>
  );
}

export default ControlledComponent;

import React from 'react';
import { Container, TextTitle, TextValue } from './styles';

function InfoCard({ title = '', value = '', filled = false, ...props }) {
  return (
    <Container {...props} filled={filled}>
      <TextTitle filled={filled}>{title}</TextTitle>
      <TextValue filled={filled}>{value}</TextValue>
    </Container>
  );
}

export default InfoCard;

import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Modal from 'react-modal';

import {
  Container,
  FormWrapper,
  InputWrapper,
  Input,
  ConfirmButton,
  HeaderWrapper,
  Header1,
} from './styles';

function PasswordVerification({
  onConfirmButtonClick,
  isVisible,
  hasToken,
  onModalClose,
  isOutstandingStyle,
  outstandingData,
}) {
  const themeContext = useContext(ThemeContext);

  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    if (validates()) {
      onConfirmButtonClick({ password, otp });
    } else {
    }
  }

  function validates() {
    return true;
  }

  useEffect(() => {}, []);

  return (
    <Container>
      <HeaderWrapper>
        <Header1>VALIDE SEUS DADOS PARA</Header1>
        <Header1 style={{ marginBottom: '15px' }}>
          AUTENTICAR A TRANSAÇÃO
        </Header1>
        {isOutstandingStyle && (
          <Header1 style={{ marginBottom: '5px' }}>{`Você está ${
            outstandingData.operationType === 'APPROVE'
              ? 'aprovando'
              : 'rejeitando'
          } ${outstandingData.nOfSelected} de ${
            outstandingData.nfOfTotal
          } transações`}</Header1>
        )}
        {isOutstandingStyle && (
          <Header1>{`Valor total: ${outstandingData.amount}`}</Header1>
        )}
      </HeaderWrapper>
      <FormWrapper onSubmit={handleSubmit}>
        <InputWrapper marginBottom>
          <Input
            id="passwordVerificationInput"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha de transação"
            type="password"
          />
        </InputWrapper>
        {hasToken && (
          <InputWrapper>
            <Input
              id="otpVerificationInput"
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Informe seu token"
            />
          </InputWrapper>
        )}
        <ConfirmButton
          id="confirmPaymentButton"
          disabled={password === '' || (hasToken && otp === '')}
          type="submit"
        >
          CONFIRMAR
        </ConfirmButton>
      </FormWrapper>
    </Container>
  );
}

export default PasswordVerification;

import moment from 'moment';

export const initialInternalTransferData = {
    branch: '',
    account: '',
    digit: '',
    amount: '',
    date: moment(),
    name: '',
    beneficiary: '',
    comment: '',
    purpose: null,
    tag: null,
};

export const initialExternalTransferData = {
    operationType: 'ted',
    ownershipLabel: 'Outra titularidade',
    ownership: 'other',
    bank: '',
    bankDescription: '',
    branch: '',
    account: '',
    digit: '',
    amount: '',
    purpose: '',
    comment: '',
    tag: null,
    date: moment(),
    beneficiary: { name: '' },
    taxIdentifier: '',
    hasSelected: false,
};

export const initialExternalTransferErrors =
    { branch: false, account: false, digit: false, comment: false, };

export const initialExternalTransferTouched = { branch: false, account: false, digit: false };
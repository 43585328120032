import React, { useState, useEffect } from 'react';
import {
  Container,
  ContentWrapper,
  ActionsWrapper,
  TextLabel,
  TableLabel,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/Button';

import ScreenTitle from 'components/ScreenTitle';
import FilterDatePicker from 'components/FilterDatePicker';
import InvestmentPortfolioList from 'components/InvestmentPortfolioList';
import { OfflineStatementService } from 'banking-service';
import moment from 'moment';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const initialDates = {
  startDate: moment().subtract(30, 'day'),
  endDate: moment(),
};

function OfflineStatementRequest({
  section = 'Arquivos',
  name = 'Solicitar Extrato',
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState(initialDates);

  const handleSubmitButton = async () => {
    console.log('submiting');
    setIsLoading(true);
    setError('');
    try {
      const res = await OfflineStatementService.requestDocument(query);
      alert.success(
        'Extrato requisitado com sucesso. Verifique o resultado na aba Consultar.',
        { timeout: 0 }
      );
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  function resolveButtonDisability() {
    return !query || !query.startDate || !query.endDate;
  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <ActionsWrapper>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '40px',
              }}
            >
              <TableLabel style={{ marginTop: '3px' }}>
                SELECIONE UMA DATA:{' '}
              </TableLabel>
              <FilterDatePicker
                allowedRangeInDays={31}
                disAllowFutureDates
                style={{}}
                initialDates={initialDates}
                onDateChange={(newQuery) => setQuery(newQuery)}
                showDefaultInputIcon
              ></FilterDatePicker>
            </div>
            <TextLabel
              style={{
                color: theme.black,
                marginTop: '40px',
                fontSize: '10px',
              }}
            >
              * Período máximo de solicitação de 1 mês
            </TextLabel>
            <Button
              onClick={handleSubmitButton}
              disabled={resolveButtonDisability()}
              style={{ alignSelf: 'center', marginTop: '30px' }}
              title={'Solicitar'}
            />
          </ActionsWrapper>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default OfflineStatementRequest;

/*
{
  "data": {
      "status": 200,
      "code": 0,
      "success": true,
      "response": {
          "message": "The request entity is within the limits defined by server",
          "orientation": "Please request this process by the event endpoint"
      },
      "message": "Successfully executed...!!",
      "dateTime": "2021-11-03T13:31:33.206Z"
  }
}
*/

/*
{
    "data": {
        "status": 200,
        "code": 0,
        "success": true,
        "response": {
            "message": "The request entity is larger than limits defined by server",
            "orientation": "Please request this process by the partition endpoint"
        },
        "message": "Successfully executed...!!",
        "dateTime": "2021-11-03T13:32:52.696Z"
    }
}
*/

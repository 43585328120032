import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import Modal from "react-modal";
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  HeaderWrapper,
  Header1
} from "./styles";

function TokenActivation({ cronImage }) {
  const { clientConfig } = useConfigContext();

  return (
    <Container>
      <HeaderWrapper>
        <Header1 style={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>ATIVAR TOKEN</Header1>
        <br />
        <br />
        {clientConfig && (
          <>
            <Header1>Abra o aplicativo {clientConfig.vars.brandHandle === 'M' ? 'do' : 'da'} {clientConfig.vars.appName}, toque no seu perfil no topo e selecione
                  a opção <strong>"Token"</strong>.</Header1>
            <br />
            <Header1>
              Posicione o celular para a leitura
      da imagem e aguarde a ativação do dispositivo.
            </Header1>
          </>
        )}
      </HeaderWrapper>
      <br />
      <br />
      <img src={`data:image/jpeg;base64,${cronImage}`} />
    </Container >
  );
}

export default TokenActivation;

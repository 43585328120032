import styled from "styled-components";
import BackgroundArrows from 'assets/images/bg_arrows.png';
import GooglePlayImage from 'assets/images/GooglePlay.png';
import AppleStoreImage from 'assets/images/AppleStore.png';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 100px 15px 15px 15px;
  text-align: center;
  font-family: "SourceSansPro", Arial, sans-serif;
`;

export const HelloMessage = styled.span`
  color: ${props => props.theme.Cor_base_marca_01};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
`;

export const PhraseOne = styled.span`
  color: ${props => props.theme.brandColor3};
  font-size: 20px;
  margin-bottom: 20px;
`;

export const PhraseTwo = styled.span`
  color: ${props => props.theme.Cor_base_marca_01};
  font-size: 17px;
  margin-bottom: 25px;
`;

export const ByeMessage = styled.span`
  color: ${props => props.theme.brandColor3};
  font-size: 16px;
  margin-bottom: 5px;
`

export const Logo = styled.img`
  width: 85px;
  height: 30px;
  margin-left: 80px;
  margin-bottom: 50px;
`;

export const DownloadPhrase = styled.span`
  color: ${props => props.theme.brandColor3};
  font-size: 20px;
  margin-bottom: 30px;
`;

export const StoresBackground = styled.div`
  display: flex;
  flex: 1;
`;

export const GooglePlay = styled.img.attrs({
  src: GooglePlayImage,
})`
  width: 120px;
  height: 40px;
  margin-right: 10px;
  margin-top: 35px;
`;

export const AppleStore = styled.img.attrs({
  src: AppleStoreImage,
})`
  width: 120px;
  height: 40px;
  margin-left: 10px;
  margin-top: 35px;
`;
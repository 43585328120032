import React from 'react';
import ReactModal from "react-modal";

import { useModal } from "react-modal-hook";
import { Container, PortfolioName, VisualizeTransactionsButton, ModalContainer, CloseButton } from './styles';
import InvestmentPortfolioTransactions from '../InvestmentPortfolioTransactions';
import { mockedInvestments } from 'stories/mocks';


function ModalContent({ onCloseClick, children }) {
    return (
        <ModalContainer>
            <CloseButton size={20} onClick={onCloseClick} />
            {children}
        </ModalContainer>
    )
};


function InvestmentPortfolioItem({ data }) {
    const [showModal, hideModal] = useModal(() => (
        <ReactModal isOpen>
            <ModalContent onCloseClick={hideModal}>
                <InvestmentPortfolioTransactions portfolioName={data.description} data={data.product} />
            </ModalContent>
        </ReactModal>
    ), [data]);

    return (
        <Container>
            <PortfolioName>{data.description}</PortfolioName>
            <VisualizeTransactionsButton onClick={() => showModal()}>VER EXTRATO</VisualizeTransactionsButton>
        </Container>
    )
}

export default InvestmentPortfolioItem;
import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { AccountService } from 'banking-service';
import { verifyUserPermission } from 'utils/functions/user';
import ACCOUNT_TYPES from 'constants/accountTypes';
import LoadingSpinner from 'components/LoadingSpinner';
import { Container, ContentWrapper } from './styles';
import ScreenTitle from 'components/ScreenTitle';
import AccountBalance from 'components/AccountBalance';
import { mockedBalance } from 'stories/mocks';

const filterOptions = [30, 60];

function AccountBalanceDetailed({
  section = 'Conta Corrente',
  name = 'Extrato',
}) {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState({ transactions: false });

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [balance, setBalance] = useState(null);
  const [linkedAccount, setLinkedAccount] = useState({
    hasPermission: false,
    balance: null,
  });

  const [query, setQuery] = useState({
    startDate: moment().subtract(filterOptions[0], 'day'),
    endDate: moment(),
  });
  const [transactions, setTransactions] = useState(null);
  const [accountType, setAccountType] = useState(ACCOUNT_TYPES.CURRENT_ACCOUNT);
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading((loading) => ({ ...loading, transactions: true }));
      setError('');
      try {
        const a = await AccountService.getTransactions(query, {
          isLinkedAccount: accountType === ACCOUNT_TYPES.LINKED_ACCOUNT,
        });
        setTransactions({
          balances: [
            { ...AccountService.account.transactionsActualBalance },
            { ...AccountService.account.transactionsLastBalance },
          ],
          entries: [...AccountService.account.transactions],
        });
        setIsLoading((loading) => ({ ...loading, transactions: false }));
      } catch (e) {
        setTransactions({ balances: [], entries: [] });
        setIsLoading((loading) => ({ ...loading, transactions: false }));
        setError(e.error && e.error.length > 0 ? e.error[0] : '');
      }
    };
    fetchTransactions();
  }, [query, accountType]);

  useEffect(() => {
    const verifyLinkedAccount = async () => {
      setIsLoading((loading) => ({ ...loading, linkedAccount: true }));
      setError('');
      try {
        if (
          verifyUserPermission({
            permission: 'LINKED_ACCOUNT',
            arrOfPermissions: AccountService.account.permissions,
          })
        ) {
          const a = await AccountService._getLinkedBalance();
          setLinkedAccount({ hasPermission: true });
        } else {
          setLinkedAccount({ hasPermission: false, balance: null });
        }
      } catch (e) {
        setLinkedAccount({ hasPermission: false, balance: null });
      }
      setIsLoading((loading) => ({ ...loading, linkedAccount: false }));
    };
    verifyLinkedAccount();
  }, []);

  return (
    <LoadingSpinner isLoading={isLoading.transactions}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <AccountBalance
            isPeriodSelectionEnabled
            data={transactions}
            onFilterChanged={(e) => setQuery(e)}
            filterOptions={filterOptions}
            periodLabel={query}
            changedAccountType={(type) => setAccountType(type)}
            hasLinkedAccount={linkedAccount.hasPermission}
          />
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default AccountBalanceDetailed;

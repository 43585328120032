import styled from 'styled-components';

const fontFamily = `"SourceSansPro", Arial, sans-serif`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 0px;

  align-items: flex-start;
  height: 70px;
`;

export const Name = styled.p`
  font-size: 14px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.brandColor3};
  margin: 0px;
  margin-bottom: 6px;
  text-transform: uppercase;
`;
export const AccountInformationsWrapper = styled.div`
  display: flex;
  margin-bottom: 2px;
`;
export const Agency = styled.p`
  font-size: 12px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.brandColor3};
  margin: 0px;
  margin-right: 10px;
`;
export const Account = styled.p`
  font-size: 12px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.brandColor3};
  margin: 0px;
`;
export const LastAccess = styled.p`
  font-size: 10px;
  font-family: ${fontFamily};
  color: ${(props) => props.theme.brandColor3};
  margin: 0px;
`;

import React, { useRef } from 'react';
import { ArrowDropDownIcon, Text, RowWrapper } from './styles';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export const FilterButton = ({
  themeContext,
  buttonText,
  headerText,
  valueText,
  Icon,
  child,
}) => {
  const componentRef = useRef();

  return (
    <RowWrapper style={{ marginRight: '30px' }}>
      <Popup
        ref={componentRef}
        trigger={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: themeContext.Cor_base_marca_01,
              color: 'white',
              padding: '4px 12px',
              borderRadius: '20px',
              fontSize: '10px',
            }}
          >
            <Icon />

            <span
              style={{
                height: '12px',
                marginTop: '1px',
                fontFamily: 'MontserratLight',
                margin: '0px 10px',
              }}
            >
              {buttonText}
            </span>
            <ArrowDropDownIcon />
          </div>
        }
        position="bottom start"
      >
        <div
          style={{
            margin: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Text style={{ fontSize: '12px' }}>{headerText}</Text>
            <Text
              onClick={() => componentRef.current.close()}
              style={{ fontSize: '12px', cursor: 'pointer' }}
            >
              <strong>X</strong>
            </Text>
          </div>
          <Text style={{ marginTop: '10px', fontSize: '12px' }}>
            <strong>{valueText}</strong>
          </Text>
          <br />
          {child}
        </div>
      </Popup>
    </RowWrapper>
  );
};

export const OTHERBANKS_TEXT = [
  {
    title: 'SITUAÇÃO',
    fieldName: 'situation',
  },
  {
    title: 'DE:',
    fieldName: 'clientName',
  },
  {
    title: '- CPF / CNPJ',
    fieldName: 'clientDocument',
  },
  {
    title: '- Agência',
    fieldName: 'clientBranch',
  },
  {
    title: '- Conta',
    fieldName: 'clientAccountNumber',
  },
  {
    title: '- BANCO',
    fieldName: 'clientIspb',
  },
  {
    title: 'PARA:',
    fieldName: 'beneficiaryName',
  },
  {
    title: '- CPF / CNPJ',
    fieldName: 'beneficiaryTaxIdentifier',
  },
  {
    title: '- Agência',
    fieldName: 'thirdPartBranch',
  },
  {
    title: '- Conta',
    fieldName: 'thirdPartAccountNumber',
  },
  {
    title: '- BANCO',
    fieldName: 'thirdPartIspb',
  },
  {
    title: 'CHAVE PIX',
    fieldName: 'thirdPartKey',
  },
  {
    title: 'DATA DE PAGAMENTO',
    fieldName: 'date',
  },
  {
    title: 'VALOR',
    fieldName: 'value',
  },
];

import styled, { css } from "styled-components";

import media from "utils/media";
import theme from "constants/theme";
import { useConfigContext } from 'providers/client-config-provider';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 9;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
`;

export const InputWrapper = styled.div`
  width: 50%;
  margin-bottom: ${props => (props.marginBottom ? "50px" : "0px")};
`;

export const Input = styled.input`
  display: flex;
  width: 50%;
  height: 25px;
  font-size: 16px;
  align-self: center;
  text-align: center;

  border-width: 0px 0px 3px 0px;
  color: black;
  background-color: transparent;
  margin: 0;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  :-ms-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
  }
`;

const slidein = css`
@keyframes slidein {
    0% { margin-left:-2000px; }
    20% { margin-left:-2000px; }
    40% { margin-left:0px; font-size: 13px ;color: ${theme.alert} }
    100% { margin-left:0px; font-size: 14px ; color: ${theme.alert} }
}
`


export const ReviewLabel = styled.span`
    font-family: 'SourceSansPro, Arial, sans-serif';
    margin-bottom: 25px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${props => props.theme.alert};
    text-align: left;
    align-self: flex-start;

    ${slidein}
    animation: slidein 3s;
`;


export const ButtonDisabledReasonText = styled.span`
    font-size: 18px;
    margin-top: 25px;
    font-family: 'SourceSansPro, Arial, sans-serif';
    color: ${props => props.theme.alert};
`

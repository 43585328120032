import "array-flat-polyfill";

const parsePurposes = ({ data }) => {

    if (!data || typeof data !== "object") return [];

    return data.map(e => ({
        value: e.code,
        label: `${e.description}`
    }));

}

export { parsePurposes };





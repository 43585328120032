import styled from 'styled-components';
import Select from 'react-select';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';
import { HandHoldingUsd as Receivable } from 'styled-icons/fa-solid/HandHoldingUsd';
import { Calendar } from 'styled-icons/boxicons-solid/Calendar';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'initial')};
`;

export const Text = styled.label`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 14px;
  line-height: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;

  strong {
    font-family: 'MontserratMedium';
  }
`;

export const SmallText = styled.label`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 12px;
  line-height: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;

  strong {
    font-family: 'MontserratMedium';
  }
`;

export const CustomAutocomplete = styled(Select)`
  width: 20%;
  flex: 1;
  border: ${(props) => (props.hasErrors ? '1px solid red' : 'none')};
  border-radius: ${(props) => (props.hasErrors ? '5px' : 'none')};
`;

export const CustomAutocompleteStyles = (theme) => ({
  option: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    border: 0,
    borderRadius: '3px',
    width: 300,
    borderBottom: `3px solid ${theme.Cor_base_marca_01}`,
    '&:focus': {
      outline: 'none',
      '-webkit-tap-highlight-color': 'none',
    },
    '&:hover': {
      outline: 'none',
    },
    height: '35px',
    minHeight: '35px',
  }),
  singleValue: (provided, state) => {
    return { ...provided };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: theme.Cor_base_marca_01,
    '&:hover': {
      color: theme.Cor_base_marca_01,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    width: 0,
  }),
});

export const Title = styled.label`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_03};
  padding: 5px 0;
`;

export const Value = styled.span`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_03};
  padding: 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ColoredValue = styled(Value)`
  color: ${(props) =>
    props.colored ? props.theme.success : props.theme.black};
`;

export const IsOpenedIcon = styled(ArrowDropUp)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${(props) => props.theme.Cor_base_marca_03};
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
  display: flex;
  align-self: center;
  width: 20px;
  color: ${(props) => props.theme.Cor_base_marca_02};
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.Cor_base_marca_03};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 20px;
`;

export const TableLabel = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 17px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_03};
  text-transform: uppercase;
  align-self: flex-start;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-bottom: 30px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  padding: 10px 0px;
  border-radius: 2px;
  justify-content: space-between;
`;

export const SelectAllButton = styled.a`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 10px;
  margin-top: 0;
  text-decoration: underline;
  margin-bottom: 17px;
  margin-left: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_01};
  text-transform: uppercase;
  align-self: flex-start;
  cursor: pointer;
`;

export const ButtonDisabledReasonText = styled.span`
  font-size: 18px;
  margin-top: 25px;
  font-family: 'SourceSansPro, Arial, sans-serif';
  color: ${(props) => props.theme.alert};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ReceivableIcon = styled(Receivable)`
  width: 12px;
  margin-right: 2px;
`;

export const CalendarIcon = styled(Calendar)`
  width: 12px;
  margin-right: 2px;
`;

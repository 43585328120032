import "array-flat-polyfill";

const parseBeneficiaries = ({ data, filter }) => {
    if (!data || typeof data !== "object") return [];

    let filteredDate = [];

    if (!filter) {
        filteredDate = [...data];
    } else if (filter.onlyTopazio) {
        filteredDate = data.filter(e => e.bankCode === '082');
    } else if (filter.notTopazio) {
        if (filter.ownership === 'sameOwner') {
            filteredDate = data.filter(e => e.bankCode !== '082').filter(e => e.registerType === 'DD')
        }
        else {
            filteredDate = data.filter(e => e.bankCode !==  '082').filter(e => e.registerType === 'DE')

        }

    } else {
        filteredDate = [...data];
    }

    if (filter.ownership === 'sameOwner') {
        return filteredDate.map(e => ({
            value: { ...e },
            label: `Minha conta no ${e.bankDescription}`
        }));

    }

    if (filter.ownership === 'other') {
        return filteredDate.map(e => ({
            value: { ...e },
            label: `${e.name.substring(0, 45)}... - ${e.bankDescription}`
        }));

    }

    return filteredDate.map(e => ({
        value: { ...e },
        label: e.name
    }));

}

const addBankDescription = ({ beneficiaries, banks }) => {
    return beneficiaries.map(beneficiary => {
        const bank = banks.find(bank => bank.code === beneficiary.bankCode);
        return ({
            ...beneficiary,
            bankDescription: bank ? bank.name : '',
            taxIdentifier: beneficiary.document
        });
    })
}

export { parseBeneficiaries, addBankDescription };





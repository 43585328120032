import React, { createContext, useState } from 'react';

export const AntecipationContext = createContext();

export function AntecipationProvider({children}) {
    const [simulation, setSimulation] = useState(null);

    return (
        <AntecipationContext.Provider value={{ simulation, setSimulation }} >
            {children}
        </AntecipationContext.Provider>
    )
}
import styled from 'styled-components';
import { Close } from 'styled-icons/evil/Close';
import { AlertCircle } from 'styled-icons/feather/AlertCircle';

export const PortfolioName = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
 `;

export const VisualizeTransactionsButton = styled.button`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    text-align: right;
    border: 0;
    color: ${props => props.theme.brandColor1};
    background: none;
    padding: 0;
    float: right;
 `;

export const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
 `;

export const CloseButton = styled(Close)`
    align-self: flex-end;
`;

export const AlertIcon = styled(AlertCircle)`
    margin-bottom: 25px;
`;

export const Text = styled.span`
    font-family: 'source-bold, source-regular, source-semibold';
    font-size: '16px';
    color: ${props => props.theme.brandColor3};
    margin-bottom: 25px;
`;

export const RedButton = styled.button`
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 88px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 2px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    color: ${props => props.theme.danger};
`;
import React, { useState, useEffect } from "react";
import { AccountService } from 'banking-service';
import {
  Container,
  ContentWrapper,
  WhiteSpace,
  FormWrapper,
  HeaderLabel,
  ReasonInput,
  ActionsWrapper,
} from "./styles";
import { useAlert } from "react-alert";
import LoadingSpinner from "components/LoadingSpinner";
import ScreenTitle from "components/ScreenTitle";
import Button from "components/Button";
import ReactModal from "react-modal";
import { useModal } from "react-modal-hook";
import CommonError from 'components/CommonError';



function CloseAccount({ section = 'Serviços', name = " Encerramento de Conta" }) {
  const alert = useAlert();

  const [error, setError] = useState("");
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);
  const [isLoading, setIsLoading] = useState(false);

  const [textArea, setTextArea] = useState('');

  const [showModal, hideModal] = useModal(() => (
    <ReactModal
      style={{
        overlay: {
          zIndex: '11',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }
      }}
      isOpen>
      <CommonError
        title={'Confirmação'}
        //message={'Você tem certeza que deseja solicitar o cancelamento da sua conta?'}
        attentionMessage={'Você tem certeza que deseja solicitar o cancelamento da sua conta?'}
        greenButtonText='ENCAMINHAR SOLICITAÇÃO'
        onGreenButtonClick={async () => { hideModal(); await onSubmitClick() }}
        hideModalCallback={hideModal} />
    </ReactModal>
  ), [textArea]);

  async function onSubmitClick() {
    setIsLoading(true);
    setError("");
    try {
      const res = await AccountService.closeAccount({ message: textArea });
      setTextArea('');
      alert.success('Sua solicitação foi enviada com sucesso. Por favor, aguarde nosso contato.');

    } catch (e) {
      setError(e && e.error && e.error.length > 0 ? e.error[0] : { message: 'Um erro inesperado aconteceu. Por favor, tente novamente.' });
    }
    setIsLoading(false);
  };


  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <WhiteSpace>
            <FormWrapper>
              <HeaderLabel>Prezado cliente, favor preencher abaixo o motivo do cancelamento.</HeaderLabel>
              <ReasonInput value={textArea} onChange={e => setTextArea(e.target.value)} maxLength="1500" />
              <ActionsWrapper>
                <Button onClick={showModal} disabled={textArea.length === 0} style={{ padding: '12px 100px 12px 100px', maxWidth: '300px' }} title={'ENCAMINHAR'} />
                <br />
              </ActionsWrapper>
            </FormWrapper>
          </WhiteSpace>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default CloseAccount;

import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const AccountBalanceTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 15;
  border-radius: 2px;
  background-color: ${props => props.theme.brandColor2};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px ${props => props.theme.brandColor5};
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 0px 5px;
  ${media.phone`
    padding: 0px;
  `}
`;

export const NoItemsAvailableLabel = styled.span`

    align-self: 'center';
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: ${props => props.theme.brandColor3};
    margin-top: 30px;
    margin-bottom: 30px;
`;

export const TableLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 17px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  justify-content: space-between;
`;

export const AccountInformationsWrapper = styled.div`
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;

`;

export const InformationWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;
`;

export const CustomInput = styled.input`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;

  border-width: 0px 0px 1px 0px;
  color: #414549;
  background-color: transparent;

  :disabled{
      color: #9b9b9b;
  }

  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #414549;
    margin-bottom: 0px;
  }
  :-ms-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #414549;
    margin-bottom: 0px;
  }
`;

export const Title = styled.label`
  color: #9b9b9b;
  align-self: flex-start;
  margin-bottom: 10px;
`;

export const Value = styled(CustomInput).attrs({ disabled: true })`
  align-self: flex-start;

`;

export const AmountInput = styled.input`
 display: flex;
 align-self: flex-end;
 max-width: 300px;
 margin-right: 50px;
  flex: 1;
  height: 25px;
  font-size: 16px;
  border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.brandColor5};

  border-width: 0px 0px 1px 0px;
  color: ${props => props.theme.brandColor3};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
  :-ms-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
`;


export const ButtonDisabledReasonText = styled.span`
    font-size: 18px;
    margin-top: 25px;
    font-family: 'SourceSansPro, Arial, sans-serif';
    color: ${props => props.theme.alert};
    align-self: center;
`


import React, { useState, useEffect } from 'react';

import { Container, NoItemsAvailableLabel } from './styles';
import InvestmentPortfolioItem from './InvestmentPortfolioItem';
import { parseInvestments } from 'utils/transformers/investments';

function InvestmentsPortfolioList({ data }) {
    const [parsedProducts, setParsedProducts] = useState([]);

    useEffect(() => {
        const parsedProducts = parseInvestments({ data });
        setParsedProducts(parsedProducts);
    }, [data]);

    if (parsedProducts.length === 0) {
        return (
            <Container>
                <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>Nenhum produto disponível</NoItemsAvailableLabel>
            </Container>
        )
    }


    return (
        <Container>
            {parsedProducts.map(e => (
                <InvestmentPortfolioItem data={e} />
            ))}
        </Container>
    )

}

export default InvestmentsPortfolioList;
import React, { useState, useEffect } from 'react';
import { Container, DatePickerWrapper, Button } from './styles';
import 'react-dates/lib/css/_datepicker.css';
import {
  DateRangePicker,
  isInclusivelyBeforeDay,
  isInclusivelyAfterDay,
  isAfterDay,
  isBeforeDay,
} from 'react-dates';
import moment from 'moment';
require('moment/locale/pt-br.js');

function resolveValidDates(
  day,
  { disAllowFutureDates, focusedInput, allowedRangeInDays, startDate, endDate }
) {
  if (allowedRangeInDays) {
    if (focusedInput === 'endDate') {
      if (moment(startDate).format('DD/MM/YYYY') === day.format('DD/MM/YYYY')) {
        return false;
      }
      return (
        isInclusivelyAfterDay(
          day,
          moment(startDate).add(allowedRangeInDays, 'days')
        ) ||
        isInclusivelyBeforeDay(day, moment(startDate)) ||
        (disAllowFutureDates && !isInclusivelyBeforeDay(day, moment()))
      );
    }
  }
  if (disAllowFutureDates) {
    return !isInclusivelyBeforeDay(day, moment());
  }
  return false;
}

function FilterDatePicker({
  isSelected,
  onClick,
  showDefaultInputIcon,
  style,
  onDateChange,
  initialDates,
  firesWithButton,
  disAllowFutureDates,
  allowedRangeInDays,
  startDatePlaceholderText = 'Iní.',
  endDatePlaceholderText = 'Fim',
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState('');

  useEffect(() => {
    if (initialDates) {
      setStartDate(initialDates.startDate);
      setEndDate(initialDates.endDate);
    }
  }, [initialDates]);

  return (
    <Container style={style}>
      <DatePickerWrapper onClick={onClick} isSelected={isSelected}>
        <DateRangePicker
          minimumNights={0}
          isOutsideRange={(day) =>
            resolveValidDates(day, {
              disAllowFutureDates,
              focusedInput,
              allowedRangeInDays,
              startDate,
              endDate,
            })
          }
          hideKeyboardShortcutsPanel={true}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
          startDateAriaLabel=""
          small
          showDefaultInputIcon={showDefaultInputIcon}
          noBorder
          numberOfMonths={1}
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
            if (!firesWithButton) {
              onDateChange({ startDate, endDate });
            }
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
        />
        {firesWithButton && (
          <Button
            onClick={() => onDateChange({ startDate, endDate })}
            disabled={!startDate || !endDate}
          >
            FILTRAR
          </Button>
        )}
      </DatePickerWrapper>
    </Container>
  );
}

export default FilterDatePicker;

export const initialGeneralData = {
  name: '',
  taxIdentifier: '',
  genderCode: 1,
  cbo: '',
  birthDate: '',
  birthCity: '',
  birthState: null,
  birthCountry: { value: 'Brasil', label: 'Brasil' },
  maritalStatusCode: { value: 4, label: 'Solteiro' },
  spouseName: ''
};

export const initialFiliation = {
  motherName: '',
  fatherName: ''
};

export const initialResidentialAddress = {
  countryResidenceCode: { value: 'Brasil', label: 'Brasil' },
  zipCode: '',
  uf: null,
  city: '',
  neighborhood: '',
  address: '',
  number: '',
  complement: ''
};

export const initialContact = {
  email: '',
  phoneNumber: '',
  ramal: ''
};

export const initialReferences = {
  references: [
    {
      data: {
        referenceType: {
          value: '1',
          label: '1 - Bancária'
        }
      },
      touched: {}
    }
  ]
};

export const initialDocumentInformations = {
  documents: [
    {
      data: {
        documentType: {
          value: 'RG',
          label: 'RG'
        }
      },
      touched: {}
    }
  ]
};

export const initialFormData = {
  ...initialGeneralData,
  ...initialFiliation,
  ...initialResidentialAddress,
  ...initialContact,
  ...initialReferences,
  ...initialDocumentInformations
};

export const initialFormFiles = [];

export const initialFormErrors = {
  ...Object.keys(initialFormData).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {})
};

export const initialFormTouched = {
  ...Object.keys(initialFormData).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {})
};

import React, { useRef } from "react";
import {
  Container,
  ItemContainer,
  Title,
  Value,
  AmountInput,
  AmountInputSelect,
  AmountContainer,
  EditableAlert,
  EditableAlertImg,
  EditableAlertContainer,
  EditableAlertText,
} from "./styles";
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';

import * as Yup from 'yup';
import CustomDatePicker from 'components/CustomDatePicker';

import { formatOnlyNumbersAndDates, revertBrazilianCurrencyToFloat } from "utils/functions/currency";
import { resolveBankslipInformations } from "utils/transformers/payments";

import editIcon from "assets/images/edit-icon.png";
import helpIcon from "assets/images/icn-help.png";

function k(i, callback) {
  var v = i.target.value.replace(/\D/g, '');
  //if (v > 25000000) v = 25000000;
  v = (v / 100).toFixed(2) + '';
  v = v.replace(".", ",");
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
  i.value = v;
  callback('amount', `R$ ${i.value}`);
}

function j(i) {
  var z = i;
  //if (z > 25000000) z = 25000000;
  z = (z / 100).toFixed(2) + '';
  z = z.replace(".", ",");
  z = z.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
  z = z.replace(/(\d)(\d{3}),/g, "$1.$2,");
  return `R$ ${z}`;
}

const PaymentFormSchema = Yup.object().shape({
  amount: Yup.string()
    .required('Required'),
});



function BoletoInformations({
  data,
  editables,
  initialValuePayment,
  onPaymentValueChange,
  initialDueDate,
  onDueDateChange,
  initialCurrentDate,
  onCurrentDateChange,
  isReviewType,
  isSchedulingEnabled,
  orinalBankSlip,
  isSecondStep = false,
  paymentValue,
  setPaymentValue,
  theme,
}) {
  const transformedData = resolveBankslipInformations({
    data,
    editables,
    initialValuePayment,
    onPaymentValueChange,
    initialDueDate,
    onDueDateChange,
    initialCurrentDate,
    onCurrentDateChange,
    isSchedulingEnabled,
  });
  let editableValue = false;
  // if (
  //   (
  //     (orinalBankSlip.length === 47 && parseFloat(orinalBankSlip.slice(37, 47)) <= 0)
  //     || orinalBankSlip.length === 36
  //   )
  //   && !orinalBankSlip.startsWith('8')
  // ) {
  //   editableValue = true;
  // }
  if (isSecondStep) {
    if (data && data.bankSlip && data.bankSlip.allowsChangeValueStatus !== 3 && !orinalBankSlip.startsWith('8')) {
      editableValue = true;
      // if (data.bankSlip.allowsChangeValueStatus === 4) {
      //   onPaymentValueChange(data.bankSlip.minValuePayment);
      // } else {
      // }
    }
  }

  return (
    <>
      {(isSecondStep && editableValue) && (
        <EditableAlert>
          <EditableAlertImg src={helpIcon} />
          <EditableAlertContainer>
            <EditableAlertText>
              <b>Atenção:</b> Alguns boletos como por exemplo cartão de crédito podem vir sem valor definido pois você escolhe quanto pagar (juros podem ocorrer). Verifique o campo <b>Valor a Pagar</b> antes de continuar
            </EditableAlertText>
          </EditableAlertContainer>
        </EditableAlert>
      )}
      <Container isReviewType={isReviewType}>
        {transformedData.map(e => (
          <Item
            {...e}
            isReviewType={isReviewType}
            isSchedulingEnabled={isSchedulingEnabled}
            isSecondStep={isSecondStep}
            editableValue={editableValue}
            changeStatus={data.bankSlip.allowsChangeValueStatus}
            minValuePayment={data.bankSlip.minValuePayment}
            maxValuePayment={data.bankSlip.maxValuePayment}
            paymentValue={paymentValue}
            setPaymentValue={setPaymentValue}
            theme={theme}
            onPaymentValueChange={onPaymentValueChange}
          />
        ))}
      </Container>
    </>
  )
}

export default BoletoInformations;


function Item({
  title,
  value,
  bold,
  editable,
  fieldName,
  initialValue,
  onValueChange,
  isReviewType,
  isSchedulingEnabled,
  isSecondStep = false,
  editableValue = false,
  changeStatus = 3,
  minValuePayment = null,
  maxValuePayment = null,
  paymentValue,
  setPaymentValue,
  theme,
  onPaymentValueChange,
}) {

  const amountInputRef = useRef(null);

  return (
    <>
      <ItemContainer isReviewType={isReviewType} id={'paymentInformationFieldId'} bold={bold}>
        <Title isReviewType={isReviewType}>{title}</Title>
        {((editable && fieldName === 'valuePayment') || (fieldName === 'valuePayment' && editableValue)) && isSecondStep ? (
          <Formik
            style={{ alignSelf: 'flex-end' }}
            initialValues={{
              amount: j(initialValue * 100),
            }}
            validationSchema={PaymentFormSchema}
          >
            {({ values, errors, setFieldTouched, submitForm, isSubmitting, touched, setFieldValue, handleSubmit, }) => (
              <Form>
                <AmountContainer>
                  <img
                    src={editIcon}
                    style={{
                      width: '14px',
                      height: '14px',
                      cursor: 'pointer'
                    }}
                    onClick={changeStatus !== 4 ? () => amountInputRef.current.focus() : () => { }}
                  />
                  {
                    changeStatus !== 4 ? (<>
                      <AmountInput
                        id={'editableAmountFieldId'}
                        type="text"
                        name="amount"
                        hasErrors={(errors.amount && touched.amount) || (values.amount === 'R$ 0,00' && touched.amount)}
                        value={values.amount}
                        maxLength={18}
                        onChange={(e) => { setFieldValue('amount', e.target.value); setFieldTouched('amount', true); onValueChange(revertBrazilianCurrencyToFloat(e.target.value)) }}
                        onKeyUp={(e) => k(e, setFieldValue)}
                        placeholder={'Valor *'}
                        ref={amountInputRef}
                      />
                    </>) : (<>
                      <AmountInputSelect
                        styles={{
                          option: (provided, { isDisabled, isFocused, isSelected }) => ({
                            ...provided,
                            textTransform: 'uppercase',
                            backgroundColor: isDisabled ? undefined : (
                              isSelected ? theme.Cor_base_marca_01 : (
                                isFocused ? theme.Cor_base_marca_fade_02 : undefined
                              )
                            ),
                            fontSize: '14px',
                            ':active': {
                              ...provided[':active'],
                              backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
                            }
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            textTransform: 'uppercase',
                            height: '25px',
                            width: '350px',
                            fontSize: '14px',
                          })
                        }}
                        name="select-valor"
                        value={paymentValue}
                        placeholder={'Selecione o valor a pagar'}
                        options={[
                          {
                            value: 'regular',
                            label: `${formatOnlyNumbersAndDates(value)} - Valor do Documento`
                          },
                          {
                            value: 'min',
                            label: `${formatOnlyNumbersAndDates(minValuePayment)} - Valor mínimo`
                          }
                        ]}
                        onChange={(val) => {
                          setPaymentValue(val);
                          if (!val) return;
                          const newValue = val.value === 'regular' ? value : (val.value === 'min' ? minValuePayment : null);
                          onPaymentValueChange(newValue);
                        }}
                      />
                    </>)
                  }
                </AmountContainer>
              </Form>
            )
            }

          </Formik>
        ) :
          editable && fieldName === 'dueDate' ? (
            <Formik
              style={{ alignSelf: 'flex-end' }}
              initialValues={{
                dueDate: initialValue ? moment(initialValue) : null,
              }}
              validationSchema={PaymentFormSchema}
            >
              {({ values, errors, setFieldTouched, submitForm, isSubmitting, touched, setFieldValue, handleSubmit }) => (
                <Form style={{ marginRight: '7px' }}>
                  <CustomDatePicker
                    placeholder="Vencimento"
                    id={'editableDueDateFieldId'}
                    date={values.dueDate}
                    openDirection={'up'}
                    onChange={val => { setFieldValue('dueDate', val); onValueChange(val) }}
                    name={'dueDate'} />
                </Form>
              )
              }

            </Formik>
          ) : isSchedulingEnabled && editable && fieldName === 'currentDate' ? (
            <Formik
              style={{ alignSelf: 'flex-end' }}
              initialValues={{
                currentDate: initialValue ? moment(initialValue) : null,
              }}
              validationSchema={PaymentFormSchema}
            >
              {({ values, errors, setFieldTouched, submitForm, isSubmitting, touched, setFieldValue, handleSubmit }) => (
                <Form style={{ marginRight: '7px' }}>
                  <CustomDatePicker
                    disAllowPastDates
                    disAllowWeekends
                    placeholder="Pagamento"
                    id={'editableCurrentDateFieldId'}
                    date={values.currentDate}
                    openDirection={'up'}
                    onChange={val => { setFieldValue('currentDate', val); onValueChange(val) }}
                    name={'currentDate'}
                  />
                </Form>
              )
              }

            </Formik>
          ) :
            (
              <Value>{bold ? '' : formatOnlyNumbersAndDates(
                isSecondStep && changeStatus === 4 && fieldName === 'valuePayment' ? minValuePayment : value
              )}</Value>
            )
        }

      </ItemContainer >
      {
        fieldName === 'grossValue' && (changeStatus !== 3) ? (
          <>
            {
              changeStatus !== 1 || (changeStatus === 1 && !!minValuePayment) ? (
                <ItemContainer isReviewType={isReviewType} id={'miPaymentInformationFieldId'} bold={bold}>
                  <Title isReviewType={isReviewType}>Pagamento mínimo</Title>
                  <Value>{bold ? '' : formatOnlyNumbersAndDates(minValuePayment)}</Value>
                </ItemContainer>
              ) : ''
            }
            {
              (changeStatus === 2 || (changeStatus === 1 && !!maxValuePayment)) ? (
                <ItemContainer isReviewType={isReviewType} id={'maxPaymentInformationFieldId'} bold={bold}>
                  <Title isReviewType={isReviewType}>Pagamento máximo</Title>
                  <Value>{bold ? '' : formatOnlyNumbersAndDates(maxValuePayment)}</Value>
                </ItemContainer>
              ) : ''
            }
          </>
        ) : ''
      }
    </>
  )
}
import styled from "styled-components";

const fontFamily = `"SourceSansPro", Arial, sans-serif`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px;
  opacity: ${props => props.isSelected ? 0.5 : 1};
  align-items: flex-start;
  cursor: pointer;
  pointer-events: ${props => props.isSelected ? 'none' : 'initial'};

`;

export const Name = styled.p`
  font-size: 14px;
  font-family: ${fontFamily};
  color: ${props => props.theme.brandColor3};
  margin: 0px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;
export const AccountInformationsWrapper = styled.div`
  display: flex;
  margin-bottom: 7px;
`;
export const Agency = styled.p`
  font-size: 12px;
  font-family: ${fontFamily};
  color: ${props => props.theme.brandColor3};
  margin: 0px;
  margin-right: 10px;
`;
export const Account = styled.p`
  font-size: 12px;
  font-family: ${fontFamily};
  color: ${props => props.theme.brandColor3};
  margin: 0px;
`;
export const ProfileInformations = styled.p`
  font-size: 10px;
  font-family: ${fontFamily};
  color: ${props => props.theme.brandColor3};
  margin: 0px;
`;

import React, { useState, useEffect } from "react";
import { useAlert } from 'react-alert';
import moment from 'moment';
import { CNABService } from 'banking-service';
import ACCOUNT_TYPES from 'constants/accountTypes';
import LoadingSpinner from "components/LoadingSpinner";
import { Container, ContentWrapper } from "./styles";
import ScreenTitle from "components/ScreenTitle";
import ControlledComponent from './ControlledComponent';

const filterOptions = [30, 60];

function Consult({ section = 'Arquivos', name = "Consulta Extrato Padrão CNAB240" }) {

  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [query, setQuery] = useState({ startDate: moment().subtract(filterOptions[0], 'day'), endDate: moment() });
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const fetchDocuments = async () => {
      setIsLoading(true);
      setError("");
      try {
        const res = await CNABService.fetchDocuments(query);
        setDocuments([...res]);
      } catch (e) {
        setDocuments([]);
        setError(e.message);
      }
      setIsLoading(false)

    }
    fetchDocuments();
  }, [query]);


  function mountTxtStructure({ data }) {
    let allContent = null;
    let fileToDownload = null;
    fileToDownload = { ...data };
    let lotDetails = '';
    fileToDownload.lotDetails.forEach(element => {
      lotDetails += element + '\r\n';
    });
    allContent = (fileToDownload.header + '\r\n');
    allContent += fileToDownload.lotHeader + '\r\n';
    allContent += lotDetails;
    allContent += fileToDownload.lotTrailer + '\r\n'
    allContent += fileToDownload.trailer;

    setTimeout(() => {
      downloadFile({ filename: 'CNAB240', content: allContent });
    }, 300);
  }


  function downloadFile({ filename, content }) {
    {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }

  async function handleFileDownload({ file }) {
    setIsLoading(true);
    setError("");
    try {
      const res = await CNABService.fetchDocument({ file });
      mountTxtStructure({ data: res });
    } catch (e) {
      setError(e.message);
    }
    setIsLoading(false)
  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <ControlledComponent
            isPeriodSelectionEnabled
            data={documents.map(e => ({ ...e, onClick: handleFileDownload }))}
            onFilterChanged={e => setQuery(e)}
            filterOptions={filterOptions}
            periodLabel={query}
          />
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Consult;

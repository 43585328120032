import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.filled
      ? props.theme.anticipation.simulationItemInfoCard.color
      : '#fff'};
  border-radius: 5px;
  border: ${(props) =>
    props.filled
      ? `2px solid ${props.theme.anticipation.simulationItemInfoCard.color}`
      : `2px solid ${props.theme.Cor_base_marca_01}`};
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 48px;
  width: 19.795vw;
  max-width: 19.795vw;
  min-height: 78px;
  height: auto;
  word-break: break-word;
`;

export const TextTitle = styled.span`
  font-family: 'MontserratBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: ${(props) => (props.filled ? '#fff' : '#707070')};
`;

export const TextValue = styled(TextTitle)`
  margin-top: 8px;
`;

import React, { Fragment, useState } from 'react';
import {
  ModalContainer,
  BackButton,
  Title,
  HeaderWrapper,
  Text,
  ActionsWrapper,
  GreenButton,
} from './styles';

import { ModalWrapper } from 'components/CustomModal';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

const btnPrimaryStyle = {
  fontSize: '12px',
  padding: '15px 40px',
  marginRight: '0px',
  height: '40px',
  marginBottom: '15px',
};

const btnOffStyle = {
  fontSize: '12px',
  padding: '15px 40px',
  marginRight: '0px',
  height: '40px',
  marginBottom: '15px',
  color: '#51c3c7',
  border: 'solid 2px #51c3c7',
  backgroundColor: 'white',
};

function CarnavalModal({
  hideModal,
  onAnyButtonClick,
  cancelText,
  hideConfirm,
  children,
  title,
  body,
  buttons,
}) {
  const [isSelectBoxChecked, setSelectBoxChecked] = useState(false);

  return (
    <ModalWrapper contentStyle={{ maxWidth: '600px' }}>
      <ModalContainer id="confirmModalDiv">
        <HeaderWrapper>
          <Title>{title}</Title>
          <Title style={{ cursor: 'pointer' }} onClick={hideModal}>
            X
          </Title>
        </HeaderWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            fontFamily: '"SourceSansPro", Arial, sans-serif',
            fontSize: '14px',
            color: 'rgb(169, 170, 172)',
            margin: '0px 0px 3px 0px',
          }}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>

        {children}

        <ActionsWrapper
          style={{
            flexDirection: 'column',
            alignSelf: 'center',
            marginBottom: '-15px',
          }}
        >
          {buttons.map((e) => (
            <Fragment>
              <Button
                title={e.text}
                style={
                  e.color === 'btn-primary' ? btnPrimaryStyle : btnOffStyle
                }
                onClick={() => {
                  onAnyButtonClick({ action: e.action });
                }}
              ></Button>
            </Fragment>
          ))}
        </ActionsWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default CarnavalModal;

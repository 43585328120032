import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.brandColor5};
  height: 100%;
  width: 100%;
  flex: 9;
  overflow: auto;
`;

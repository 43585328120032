import styled from 'styled-components';
import { RefreshCcw } from 'styled-icons/feather/RefreshCcw';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const RefreshIcon = styled(RefreshCcw)`
  text-align: center;
  width: 25px;
  height: 25px;
  color: ${(props) => props.theme.success};
  cursor: pointer;
`;

import styled from 'styled-components';
import { Close } from 'styled-icons/evil/Close';


export const Container = styled.div`
    display: flex;
    flex: 1;
    max-height: 20px;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 19px;
    padding-bottom: 13px;
    padding-right: 12px;
    background-color: ${props => props.theme.brandColor2};
    margin: 0;
    border: 0;
    color: ${props => props.theme.brandColor3};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid ${props => props.theme.brandColor5};
 `;

export const PortfolioName = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
 `;

export const VisualizeTransactionsButton = styled.button`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    text-align: right;
    border: 0;
    color: ${props => props.theme.brandColor1};
    background: none;
    padding: 0;
    float: right;
 `;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
 `;

export const CloseButton = styled(Close)`
    align-self: flex-end;
`;
import React from 'react';
import {
    Overlay,
    Modal,
    ContainerCarousel,
    ContainerContent,
    TextTitle,
    TextValue,
    TextSubValue,
    TextDescription,
    ImageContainer,
    ButtonItem,
    SubText,
    CloseButton,
    CloseIcon
} from './styles';
import Carousel from 'components/Carousel';
import { handleCurrency } from 'utils/functions/currency';

import workinCapitalBannerOne from "assets/images/working_capital/banner_01.png";
import workinCapitalBannerTwo from "assets/images/working_capital/banner_02.png";
import workinCapitalBannerThree from "assets/images/working_capital/banner_03.png";
import workinCapitalBannerFour from "assets/images/working_capital/banner_04.png";

const WorkingCapitalModal = ({
    cancelAction=()=>{},
    confirmAction=()=>{},
    avaiableValue=0,
    ...props
}) => (
<>
    <Overlay>
        <Modal {...props}>
            <ContainerContent>
                <TextTitle>
                    Você tem crédito pré-aprovado de:
                </TextTitle>
                <TextValue>
                    {handleCurrency({ value: avaiableValue })}<span>(*)</span>
                </TextValue>
                <TextSubValue>
                    em até <span> 18X <span> (*)</span></span>
                </TextSubValue>
                <TextDescription>
                    Soluções financeiras ágeis e simplificadas, que criam novos horizontes para o seu negócio.
                </TextDescription>
                <ButtonItem href="https://www.bancotopazio.com.br/para-sua-empresa/credito/capital-de-giro" target="_blank">
                    SIMULE AGORA
                </ButtonItem>
                <SubText>
                    (*) crédito sujeito a análise
                </SubText>
            </ContainerContent>
            <ContainerCarousel>
                <Carousel
                    contentArray={[
                        <ImageContainer imgUrl={workinCapitalBannerOne} />,
                        <ImageContainer imgUrl={workinCapitalBannerTwo} />,
                        <ImageContainer imgUrl={workinCapitalBannerThree} />,
                        <ImageContainer imgUrl={workinCapitalBannerFour}/>
                    ]}
                />
            </ContainerCarousel>
            <CloseButton onClick={cancelAction}>
                <CloseIcon />
            </CloseButton>
        </Modal>
    </Overlay>
</>
);

export default WorkingCapitalModal;
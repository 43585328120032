import moment from 'moment';
import { conformToMask } from 'react-text-mask';
import {
  brazilianStates,
  mariageStatus,
  referenceTypes,
  productTypes,
  documentTypes
} from './options';
import {
  cpfMask,
  dateMask,
  phoneMask,
  cepMask,
  cnpjMask
} from './components/MaskedInput/masks';
import { revertBrazilianCurrencyToFloat } from 'utils/functions/currency';

export const parseUserInformations = ({ data, banks }) => {
  const taxIdentifier = conformToMask(data.taxIdentifier, cpfMask, {
    guide: false
  }).conformedValue;

  const maritalStatusCode =
    mariageStatus.find(e => e.value === data.maritalStatusCode) ||
    mariageStatus[3];
  const cbo = {
    value: {
      cbo: data.cboOrdinal,
      name: data.cboTitle,
      family: data.cboFamily || '0',
      familyTitle: data.cboFamilyTitle || 'NAO DEFINIDO ANTERIORMENTE'
    },
    label: data.cboTitle
  };
  const phoneNumber = `(${data.areaCode || ''}) ${data.telephone || ''}`;

  const birthCountryCode = {
    value: { name: 'Brasil', id: data.birthCountryCode },
    label: 'Brasil'
  };

  const birthState = brazilianStates.find(e => e.label === data.birthState);
  const birthCity = {
    value: {
      id: data.birthCityCode,
      city: data.birthCity
    },
    label: data.birthCity
  };
  const birthDate = moment(data.birthDate).format('DD/MM/YYYY');

  const zipCode = conformToMask(data.zipCode, cepMask, {
    guide: false
  }).conformedValue;

  const countryResidenceCode = {
    value: { name: 'Brasil', id: data.countryResidenceCode },
    label: 'Brasil'
  };
  const department = brazilianStates.find(e => e.value.uf === data.department);

  const city = {
    value: {
      id: data.city,
      city: data.city
    },
    label: data.city
  };

  const references = data.references
    ? data.references.map(e => ({
        data: {
          ...e,
          document: e.document || '',
          referenceType: referenceTypes.find(
            el => el.value === e.referenceType
          ),
          bank: banks.find(el => el.value.code == e.bankId),
          product: productTypes.find(el => el.value === e.product),
          phoneNumber: `(${e.areaCode || ''}) ${e.telephone || ''}`
        },
        touched: {},
        errors: resolveErrors({ ...e, document: e.document || '' })
      }))
    : [];

  const documents = data.documents
    ? data.documents
        .filter(e => e.documentType !== 'CPF')
        .map(e => ({
          data: {
            ...e,
            documentType: documentTypes.find(el => el.value === e.documentType),
            emissionDate: moment(e.emissionDate).format('DD/MM/YYYY'),
            documentValidity: moment(e.documentValidity).format('DD/MM/YYYY')
          },
          touched: {},
          errors: resolveErrors(e)
        }))
    : [];

  return {
    ...data,
    taxIdentifier,
    maritalStatusCode,
    cbo,
    phoneNumber,
    city,
    birthDate,
    birthCountryCode,
    birthState,
    birthCity,
    zipCode,
    countryResidenceCode,
    department,
    references,
    documents
  };
};

export const parsePjInformations = ({ data, banks }) => {
  const document = conformToMask(data.document, cnpjMask, {
    guide: false
  }).conformedValue;

  const openingDate = moment(data.openingDate).format('DD/MM/YYYY');

  const contitutionDate = moment(data.contitutionDate).format('DD/MM/YYYY');

  const zipCode = conformToMask(data.zipCode, cepMask, {
    guide: false
  }).conformedValue;

  const phoneNumber = `(${data.areaCode || ''}) ${data.telephone || ''}`;

  const countryResidenceCode = {
    value: { name: 'Brasil', id: data.countryResidenceCode },
    label: 'Brasil'
  };

  const department = brazilianStates.find(
    e => e.value.department === data.state
  );

  const city = {
    value: {
      id: data.city,
      city: data.city
    },
    label: data.city
  };

  /*   const birthState = brazilianStates.find(e => e.label === data.birthState);
  const birthCity = {
    value: {
      id: data.birthCityCode,
      city: data.birthCity
    },
    label: data.birthCity
  };
  const birthDate = moment(data.birthDate).format('DD/MM/YYYY');


 */

  const references = data.references
    ? data.references.map(e => ({
        data: {
          ...e,
          document: e.document || '',
          referenceType: referenceTypes.find(
            el => el.value === e.referenceType
          ),
          errors: resolveErrors(e),
          bank: banks.find(el => el.value.code == e.bankId),
          product: productTypes.find(el => el.value === e.product),
          phoneNumber: `(${e.areaCode}) ${e.telephone}`
        },
        touched: {},
        errors: {}
      }))
    : [];

  /*   const documents = data.documents
    ? data.documents
        .filter(e => e.documentType !== 'CPF')
        .map(e => ({
          data: {
            ...e,
            documentType: documentTypes.find(el => el.value === e.documentType),
            emissionDate: moment(e.emissionDate).format('DD/MM/YYYY'),
            documentValidity: moment(e.documentValidity).format('DD/MM/YYYY')
          }
        }))
    : []; */

  return {
    ...data,
    document,
    openingDate,
    contitutionDate,
    phoneNumber,
    department,
    city,
    countryResidenceCode,
    zipCode,
    references
    /*     documents */
  };
};

export function resolvePayloadPF({ data, files }) {
  const MAX_CBO_ORD_SIZE = 6;
  const taxIdentifier = data.taxIdentifier.replace(/\D/g, '');

  const maritalStatusCode = data.maritalStatusCode.value;

  const sanitizedPhoneNumber = {
    areaCode: Number(data.phoneNumber.replace(/\D/g, '').substring(0, 2)),
    telephone: data.phoneNumber.replace(/\D/g, '').substring(2)
  };

  const birthCountryCode = data.birthCountryCode.value.id;
  const birthState = data.birthState.label;
  const birthCity = data.birthCity.value.city;
  const birthCityCode = data.birthCity.value.id;
  const birthDate = moment(data.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const countryResidenceCode = data.countryResidenceCode.value.id;
  const department = data.department.value.uf;
  //const uf = data.department.value.uf;
  const city = data.city.value.city;

  const cboOrdinal = data.cbo.value.cbo;
  const cboTitle = data.cbo.value.name;

  const cboFamily =
    data.cbo.value.cbo.length === MAX_CBO_ORD_SIZE
      ? data.cbo.value.cbo.substring(0, data.cbo.value.cbo.length - 2)
      : data.cbo.value.family;

  const cboFamilyTitle = data.cbo.value.familyTitle;

  const references = data.references.map(e => {
    if (e.data.referenceType.label === '1 - Bancária') {
      return {
        referenceType: e.data.referenceType.value,
        bankId: e.data.bank.value.code.toString(),
        product: e.data.product.value,
        branchId: e.data.branchId,
        accountId: e.data.accountId
      };
    } else if (e.data.referenceType.label === '2 - Comercial') {
      return {
        referenceType: e.data.referenceType.value,
        name: e.data.name,
        document:
          e.data.document === '' ? '' : e.data.document.replace(/\D/g, ''),
        extensionNumber: e.data.extensionNumber,
        areaCode: Number(e.data.phoneNumber.replace(/\D/g, '').substring(0, 2)),
        telephone: e.data.phoneNumber.replace(/\D/g, '').substring(2)
      };
    } else if (e.data.referenceType.label === '3 - Pessoal') {
      return {
        referenceType: e.data.referenceType.value,
        name: e.data.name,
        document:
          e.data.document === '' ? '' : e.data.document.replace(/\D/g, ''),

        extensionNumber: e.data.extensionNumber,
        areaCode: Number(e.data.phoneNumber.replace(/\D/g, '').substring(0, 2)),
        telephone: e.data.phoneNumber.replace(/\D/g, '').substring(2)
      };
    } else return {};
  });

  const documents = data.documents.map(e => {
    if (e.data.documentType.label === 'RG') {
      return {
        documentType: e.data.documentType.value,
        document: e.data.document,
        emissionDate: moment(e.data.emissionDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        issuingCompany: e.data.issuingCompany
      };
    } else if (e.data.documentType.label === 'CNH') {
      return {
        documentType: e.data.documentType.value,
        document: e.data.document,
        emissionDate: moment(e.data.emissionDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        issuingCompany: e.data.issuingCompany,
        documentValidity: moment(e.data.documentValidity, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      };
    } else if (e.data.documentType.label === 'Comprovante de Endereço') {
      return {
        documentType: e.data.documentType.value,
        emissionDate: moment(e.data.emissionDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      };
    } else return {};
  });

  const zipCode = data.zipCode.replace(/\D/g, '');
  const filesNameArr = files.map((e, i) =>
    resolveFilename({ type: 'PF', index: i, filename: e.filename })
  );

  const parsedFields = {
    ...data,
    taxIdentifier,
    maritalStatusCode,
    ...sanitizedPhoneNumber,
    birthCountryCode,
    birthState,
    birthState,
    birthCity,
    birthCityCode,
    birthDate,
    zipCode,
    countryResidenceCode,
    //uf,
    department,
    city,
    cboOrdinal,
    cboTitle,
    cboFamily,
    cboFamilyTitle,
    references,
    documents,
    files: filesNameArr
  };

  const {
    cbo,
    phoneNumber,
    hasInitializedData,
    cboFamilyId,
    openingDate,

    //casos a ver
    uf,
    //end casos a ver
    _id,
    mustSendCER,
    mustSendCNH,
    isEditingMode,
    isChecked,
    ...sanitizedPayload
  } = parsedFields;

  return sanitizedPayload;
}

export function resolvePayloadPJ({ data, files }) {
  const billing = Number(revertBrazilianCurrencyToFloat(data.billing));

  const sanitizedPhoneNumber = {
    areaCode: Number(data.phoneNumber.replace(/\D/g, '').substring(0, 2)),
    telephone: data.phoneNumber.replace(/\D/g, '').substring(2)
  };

  const countryResidenceCode = data.countryResidenceCode.value.id;
  const department = data.state.label;
  const city = data.city.value.city;
  /*   const openingDate = moment(data.openingDate, 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  ); */
  const contitutionDate = moment(data.contitutionDate, 'DD/MM/YYYY').format(
    'YYYY-MM-DD'
  );

  const references = data.references.map(e => {
    if (e.data.referenceType.label === '1 - Bancária') {
      return {
        referenceType: e.data.referenceType.value,
        bankId: e.data.bank.value.code.toString(),
        product: e.data.product.value,
        branchId: e.data.branchId,
        accountId: e.data.accountId
      };
    } else if (e.data.referenceType.label === '2 - Comercial') {
      return {
        referenceType: e.data.referenceType.value,
        name: e.data.name,
        document:
          e.data.document === '' ? '' : e.data.document.replace(/\D/g, ''),
        extensionNumber: e.data.extensionNumber,
        areaCode: Number(e.data.phoneNumber.replace(/\D/g, '').substring(0, 2)),
        telephone: e.data.phoneNumber.replace(/\D/g, '').substring(2)
      };
    } else if (e.data.referenceType.label === '3 - Pessoal') {
      return {
        referenceType: e.data.referenceType.value,
        name: e.data.name,
        document:
          e.data.document === '' ? '' : e.data.document.replace(/\D/g, ''),

        extensionNumber: e.data.extensionNumber,
        areaCode: Number(e.data.phoneNumber.replace(/\D/g, '').substring(0, 2)),
        telephone: e.data.phoneNumber.replace(/\D/g, '').substring(2)
      };
    } else return {};
  });

  const zipCode = data.zipCode.replace(/\D/g, '');
  const filesNameArr = files.map((e, i) =>
    resolveFilename({ type: 'PJ', index: i, filename: e.filename })
  );

  const parsedFields = {
    ...data,
    billing,
    department,
    contitutionDate,
    ...sanitizedPhoneNumber,
    countryResidenceCode,
    city,
    zipCode,
    references,
    files: filesNameArr
  };

  const {
    phoneNumber,
    hasInitializedData,
    cnaeDescription,
    //casos a ver
    uf,
    branch,
    openingDate,
    document,
    taxIdentifier,
    //end casos a ver
    _id,
    isEditingMode,
    isChecked,
    ...sanitizedPayload
  } = parsedFields;

  return sanitizedPayload;
}

export const hasToUpdateAndHasCERButHasnTouched = ({ data }) => {
  const CERDocument = data.documents.find(
    e => e.data.documentType.value === 'CER'
  );
  return (
    data.mustSendCER &&
    !!CERDocument &&
    (!!!CERDocument.touched ||
      !Object.entries(CERDocument.touched).some(
        ([key, value]) => value === true
      ))
  );
};

export const hasToUpdateAndHasCNHButHasnTouched = ({ data }) => {
  const CNHDocument = data.documents.find(
    e => e.data.documentType.value === 'CNH'
  );
  return (
    data.mustSendCNH &&
    !!CNHDocument &&
    (!!!CNHDocument.touched ||
      !Object.entries(CNHDocument.touched).some(
        ([key, value]) => value === true
      ))
  );
};

export const hasTouchedFieldButHasnAddedFile = ({ data, files }) => {
  return (
    data.documents.some(
      e =>
        e.touched &&
        Object.entries(e.touched).some(([key, value]) => value === true)
    ) &&
    files.length <
      data.documents.filter(
        e =>
          e.touched &&
          Object.entries(e.touched).some(([key, value]) => value === true)
      ).length
  );
};

export const hasToSendCERAndHasntAddedDocument = ({ data }) => {
  return (
    data.mustSendCER &&
    !data.documents.some(e => e.data.documentType.value === 'CER')
  );
};

export const hasTouchedAnyFieldButHasnAddedFile = ({ touched, files }) => {
  return (
    Object.entries(touched).some(([key, value]) => value === true) &&
    files.length === 0
  );
};

export const hasToSendCNHAndHasntAddedDocument = ({ data }) => {
  return (
    data.mustSendCNH &&
    !data.documents.some(e => e.data.documentType.value === 'CNH')
  );
};

export const hasAtLeastOneReference = ({ data }) => {
  return data.references.length > 0;
};

export const hasAtLeastOneDocument = ({ data }) => {
  return data.documents.length > 0;
};

export const resolveErrors = data => {
  console.log('resolveErrors', data);
  return Object.entries(data)
    .map(([key, value]) => {
      if (
        value === '' ||
        value === null ||
        (key === 'phoneNumber' && value === '() ')
      ) {
        return {
          [key]: true
        };
      } else {
        return {
          [key]: false
        };
      }
    })
    .reduce((a, b) => Object.assign(a, b), {});
};

export const fieldsThatRequireDocumentSubmition = [
  'tradingName',
  'cnae',
  'corporateName',
  'contitutionDate',
  'billing'
];

export const resolveFilename = ({ type, index, filename }) =>
  `${type}_FILE_${index}.${filename.split('.')[1]}`;

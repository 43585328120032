import styled from 'styled-components';

import { Cancel } from 'styled-icons/material/Cancel';
import { CheckCircle } from 'styled-icons/boxicons-solid/CheckCircle';
import { Alert } from 'styled-icons/octicons/Alert';

export const UnupdatedIcon = styled(Cancel)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.danger};
`;

export const RevisionIcon = styled(Alert)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.alert};
`;

export const UpdatedIcon = styled(CheckCircle)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

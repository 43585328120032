import styled from 'styled-components';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
export const TogglesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.Cor_auxiliar_03};
`;

export const TransferTypeToggle = styled.button`
  display: inline-block;
  border-radius: 30px;
  width: 250px;
  border: solid 2px ${(props) => props.theme.Cor_base_marca_01};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${(props) =>
    props.isSelected ? props.theme.Cor_auxiliar_01 : props.theme.Cor_base_marca_01};
  text-transform: uppercase;
  height: 40px;
  transition: 300ms ease-out;
  outline: none;
  background-color: ${(props) =>
    props.isSelected ? props.theme.Cor_base_marca_01 : props.theme.Cor_auxiliar_01};
  cursor: pointer;
`;

export const TransferTypeToggleLeft = styled(TransferTypeToggle)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;

export const TransferTypeToggleRight = styled(TransferTypeToggle)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const NameInput = styled.input`
  :disabled {
    opacity: 0.5;
  }
  display: flex;
  height: 25px;
  font-size: 16px;

  border-width: 0px 0px 1px 0px;
  color: ${(props) => props.theme.Cor_base_marca_03};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  :-ms-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  display: flex;
  width: 300px;
  border-bottom-color: ${(props) =>
    props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
  margin-top: 50px;
`;

export const DocumentInput = styled.input`
  :disabled {
    opacity: 0.5;
  }
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;

  border-width: 0px 0px 1px 0px;
  color: ${(props) => props.theme.Cor_base_marca_03};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  :-ms-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  border-bottom-color: ${(props) =>
    props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-bottom: 60px;
  border-bottom: 1px solid ${(props) => props.theme.Cor_auxiliar_03};
`;

export const PermissionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 50px;
  width: 70%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 33%;
  margin-bottom: 50px;
  align-self: flex-start;
`;

export const Title = styled.span`
  width: 100%;
  background: transparent;
  padding: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_03};
  margin-bottom: 5px;
`;

export const SectionItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: '10px 0px 10px 0px';
  margin: 0;

  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'initial')};
`;

export const ItemDescription = styled.span`
  color: ${(props) => props.theme.Cor_base_marca_03};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-left: 4px;
  margin-top: 2px;
`;

export const PasswordLabel = styled.span`
  color: ${(props) => props.theme.Cor_base_marca_03};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 10px;
`;

export const AccountSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`;

export const CollapseContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 15px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
  margin: 30px 0px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  border-radius: 2px;
  justify-content: space-between;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Value = styled.span`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_03};
  padding: 5px 0;
`;

export const IsOpenedIcon = styled(ArrowDropUp)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${(props) => props.theme.Cor_base_marca_03};
`;

export const IsNotOpenedIcon = styled(ArrowDropDown)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${(props) => props.theme.Cor_base_marca_03};
`;

import React from "react";
import { Container, Text } from "./styles";
import { parseText } from "utils/functions/text";

function ScreenTitle({ section, title }) {
  return (
    <Container id='contentNameId'>
      <Text>
        {section ? `${section} > ` : ''}<strong>{title}</strong>
      </Text>
    </Container>
  );
}

export default ScreenTitle;

import React, { useState, useEffect } from 'react';

import {
    Container,
    ItemContainer,
    PortfolioName,
    TopLabel,
    CheckMarkIcon,
    AlertIcon,
    AditionalInformationsWrapper,
    InformationWrapper,
    InformationLabel,
    InformationValue,

} from './styles';
import Button from 'components/Button';
import { resolveTransfersResumeInformations } from 'utils/transformers/transfers';
import { useTheme } from 'styled-components';


function TransferFeedback({ data = [], aditionalInformations, selectedType, isSecurityAccountReview, isSchedulingEnabled }) {

    const [parsedData, setParsedDate] = useState([]);
  
    const theme = useTheme();

    useEffect(() => {
        if (isSecurityAccountReview) {
            setParsedDate([...data]);
        } else {
            setParsedDate(resolveTransfersResumeInformations({ data, selectedType }))
        }
    },
        [data]
    )
    return (
        <Container>
            <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'flex-start', marginTop: '10px',
                marginBottom: '0px',
            }}>
                <CheckMarkIcon />
                <TopLabel>{`SUA SOLICITAÇÃO DE ${isSecurityAccountReview? 'OPERAÇÃO' : 'TRANSFERÊNCIA'} FOI EFETUADA COM SUCESSO E ESTÁ EM ANÁLISE`}</TopLabel>

            </div>
            <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'flex-start', marginTop: '15px',
                marginBottom: '0px',
            }}>
                <AlertIcon />
                <TopLabel style={{ color: theme.alert }}>{`UMA VEZ EFETIVADA, SUA ${isSecurityAccountReview? 'OPERAÇÃO' : 'TRANSFERÊNCIA'} ESTARÁ DISPONÍVEL NO MENU`} <strong>{`${isSecurityAccountReview? 'CONTA GARANTIDA - EXTRATO' : 'COMPROVANTES'}`}</strong></TopLabel>


            </div>

            <div style={{ display: 'flex', flexDirection: 'column', padding: '30px 100px', width: '80%' }}>
                {parsedData.map(e => (
                    <ItemContainer id="transferInformationItemId" isAmountField={e.field === 'VALOR'}>
                        <PortfolioName isAmountField={e.field === 'VALOR'}>{e.field}</PortfolioName>
                        <PortfolioName isAmountField={e.field === 'VALOR'}>{e.value}</PortfolioName>
                    </ItemContainer>
                ))}
                <AditionalInformationsWrapper>
                    {!isSchedulingEnabled && (
                        <>
                            <InformationWrapper>
                                <InformationLabel>EFETIVAÇÃO DO PAGAMENTO</InformationLabel>
                                <InformationValue>{aditionalInformations.date}</InformationValue>
                            </InformationWrapper>
                            <InformationWrapper>
                                <InformationLabel>IDENTIFICADOR INTERNO</InformationLabel>
                                <InformationValue id="transactionIdentifierLabelId">{aditionalInformations.identifier}</InformationValue>
                            </InformationWrapper>
                        </>)}
                    <InformationWrapper>
                        <InformationLabel>TRANSAÇÃO</InformationLabel>
                        <InformationValue id="transactionAuthenticationLabelId">{aditionalInformations.transactionId}</InformationValue>
                    </InformationWrapper>
                </AditionalInformationsWrapper>
            </div>
        </Container>

    )
}

export default TransferFeedback;
import React, { useState, useEffect } from 'react';

import {
  Container,
  ContentWrapper,
  Title,
  Text,
  Wrapper,
  BottomInfo,
  BtnContinue,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';

function AntecipationSuccess({ section = 'Antecipação de Recebíveis', name = 'Solicitação Enviada', ...props }) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const successIlustration = require('../../../assets/images/anticipation_receivables/antecipation_sucess.png');

  const [isLoading, setIsLoading] = useState(false);

  const handleNewRequest = async () => {
    props.history.push('/anticipation/schedules');
  };

  const handleRequestsReview = async () => {
  };

  useEffect(() => {
  }, []);

  const Content = () => (
    <Wrapper style={{padding: '40px'}}>
      <Title>Sua solicitação foi enviada com sucesso</Title>
      <div style={{display: 'flex', padding: '5px 0 20px'}}>
        <img
          src={successIlustration}
          style={{
            width: '28.079vw',
            height: 'auto',
            maxWidth: '475px'
          }}
          alt="Antecipação de recebíveis - Solicitação enviada com sucesso"
        /> 
        <Wrapper style={{marginLeft: '52px', alignItems: 'flex-start'}}>
          <Text style={{marginTop: '64px'}}>
            <strong>Agora é conosco!</strong>
          </Text>
          <Text>
            Sua solicitação já esta sendo processada e em breve você será avisado do progresso.
          </Text>
          <BtnContinue
            style={{marginTop: '50px'}}
            onClick={handleNewRequest}
          >
            Fazer nova solicitação
          </BtnContinue>
          {/* Ação temporariamente escondida até funcionalidade estar pronta */}
          {/* <BtnContinue
            onClick={handleRequestsReview}
            outline
          >
            Ver minhas solicitações
          </BtnContinue> */}
        </Wrapper>
      </div>
        {/* Ação temporariamente escondida até funcionalidade estar pronta */}
      {/* <BottomInfo>
        Se você não deseja mais receber nossas ofertas exclusivas,{' '}
        <a>solicite cancelamento</a>
        {' '}de sua autorização.
      </BottomInfo> */}
    </Wrapper>
  );

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <Content />
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default AntecipationSuccess;

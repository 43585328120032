import React, { Fragment, useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';

import { Container, Separator } from './styles';
import SectionItem from 'components/SectionItem';
import { sections } from 'constants/sections';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import { resolvePersonType } from 'utils/functions/user';
import { AccountService } from 'banking-service';
import { withRouter } from 'react-router-dom';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const MyLoader = () => (
  <ContentLoader
    height={324}
    width={236}
    speed={2}
    primaryColor={theme.brandColor5}
    secondaryColor={theme.brandColor5}
  >
    <rect x="126" y="40" rx="0" ry="0" width="0" height="0" />
    <rect x="13" y="21" rx="0" ry="0" width="46" height="43" />
    <rect x="71" y="33" rx="0" ry="0" width="134" height="15" />
    <rect x="12" y="78" rx="0" ry="0" width="46" height="43" />
    <rect x="70" y="90" rx="0" ry="0" width="134" height="15" />
    <rect x="125" y="157" rx="0" ry="0" width="0" height="0" />
    <rect x="12" y="138" rx="0" ry="0" width="46" height="43" />
    <rect x="70" y="150" rx="0" ry="0" width="134" height="15" />
    <rect x="11" y="195" rx="0" ry="0" width="46" height="43" />
    <rect x="69" y="207" rx="0" ry="0" width="134" height="15" />
    <rect x="123" y="270" rx="0" ry="0" width="0" height="0" />
    <rect x="10" y="251" rx="0" ry="0" width="46" height="43" />
    <rect x="68" y="263" rx="0" ry="0" width="134" height="15" />
    <rect x="9" y="308" rx="0" ry="0" width="46" height="43" />
    <rect x="67" y="320" rx="0" ry="0" width="134" height="15" />
  </ContentLoader>
);

function TabBar({ location }) {
  const [isSelected, setIsSelected] = useState('');
  const {
    hasUpdatedPermissions,
    userPermissions,
    selectedAccountIndex,
  } = useSwitchAccountDataContext();

  const { accounts } = AccountService;

  useEffect(() => {
    setIsSelected('/');
  }, [hasUpdatedPermissions]);

  useEffect(() => {
    setIsSelected(location.pathname);
  }, [location]);

  const handleVerifyActive = (route, selectedRoute) => {
    if (typeof route === 'string') return route === selectedRoute;
    if (!Array.isArray(route)) return false;
    return route.some((currentRoute => currentRoute === selectedRoute))
  }

  function handleSectionFiltering(section) {
    if (section.requires.includes('AT_LEAST_ONE_CHILD')) {
      let shouldShow = false;
      section.options.forEach((e) => {
        if (
          e.requires.some((val) =>
            userPermissions.map((perm) => perm.role).includes(val)
          )
        ) {
          shouldShow = true;
        }
      });
      return shouldShow;
    } else {
      return section.requires.some((val) =>
        userPermissions.map((perm) => perm.role).includes(val)
      );
    }
  }

  if (userPermissions.length === 0) {
    return (
      <Container>
        <MyLoader />
      </Container>
    );
  }

  return (
    <Container>
      {sections
        .filter((el) => handleSectionFiltering(el))
        .map((e, i) => (
          <Fragment key={i}>
            <SectionItem
              id={e.id}
              userPermissions={userPermissions}
              personType={resolvePersonType({
                taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
              })}
              isSelected={handleVerifyActive(e.referenceRoute, isSelected)}
              title={e.title}
              Icon={e.Icon}
              route={e.route}
              options={e.options}
              isDisabled={e.isDisabled}
              profileType={accounts[selectedAccountIndex].profileType}
              onClick={() => {
                setIsSelected(e.route);
              }}
            />
            <Separator />
          </Fragment>
        ))}
    </Container>
  );
}

export default withRouter(TabBar);

import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  height: 40px;
  width: ${props => props.sm ? 0.5 : 1};
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.Cor_base_marca_01};
  border-radius: 20px;
  background-color: ${props => props.theme.Cor_base_marca_01};
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.Cor_auxiliar_01};
  padding-top: 12px;
  padding-bottom: 8px;
  transition: 300ms ease-in;
  margin-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 12px 50px 12px 50px;

  :focus {
    outline: 0px;
  }

  :hover {
    opacity: 0.7;
  }

  :disabled {
    opacity: 0.5;
  }
`;

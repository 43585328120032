import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import { ModalContainer, CloseButton, AlertIcon, Text } from './styles';
import { withRouter } from 'react-router-dom';

import { useAuthDataContext } from 'providers/auth-provider';

import CommonError from 'components/CommonError';

function ModalMessageResolver({ children, ...props }) {
  const { tokenExpirationMessage } = useAuthDataContext();

  const [modalProps, setModalProps] = useState(null);
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal
        style={{
          overlay: {
            zIndex: '11',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
          }
        }}
        isOpen
      >
        <CommonError {...modalProps} />
      </ReactModal>
    ),
    [modalProps]
  );

  useEffect(() => {
    if (tokenExpirationMessage) {
      if (props.location.pathname === '/login') {
        tokenExpirationMessage.onGreenButtonClick();
        hideModal();
      } else {
        setModalProps({
          ...tokenExpirationMessage,
          hideModalCallback: hideModal
        });
        showModal();
      }
    }
  }, [tokenExpirationMessage, props.location.pathname]);

  return children;
}

export default withRouter(ModalMessageResolver);

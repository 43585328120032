import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const WhiteSpace = styled.div`
  display: flex;
  background: ${props => props.theme.brandColor2};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 2px;
  height: 100%;
  flex-direction: column;
  width: 100%;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const HeaderLabel = styled.span`
  margin: 0 0 30px;
  font-weight: 300;
  font-family: "SourceSansPro", Arial, sans-serif;
`;

export const ReasonInput = styled.textarea`
    border: 1px solid ${props => props.theme.brandColor3};
    resize: none;
    width: 100%;
    height: 192px;
    padding: 10px;
    margin: 0 0 50px;
    outline: none;
    line-height: 24px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`; 
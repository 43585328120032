import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  HttpService,
  PaymentService,
  TokenService,
  AccountService,
} from 'banking-service';
import MaskedInput, { conformToMask } from 'react-text-mask';
import LoadingSpinner from 'components/LoadingSpinner';
import { hasActiveToken } from 'utils/functions/token';

import { useAlert } from 'react-alert';
import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import PasswordVerification from 'components/PasswordVerification';
import PaymentFeedback from 'components/PaymentFeedback';
import SchedulingWarning from 'components/SchedulingWarning';

import {
  Container,
  ContentWrapper,
  Wrapper,
  ButtonDisabledReasonText,
  Input,
  ReviewLabel,
} from './styles';
import ScreenTitle from 'components/ScreenTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import BoletoInformations from 'components/BoletoInformations';
import Button from 'components/Button';
import {
  barcodeMaskFichaComp,
  barcodeMaskConcessionaria,
} from 'constants/masks';

import TokenActivationModal from 'components/TokenActivationModal';
import { useConfigContext } from 'providers/client-config-provider';

import { needsToActivateToken } from 'utils/functions/token';
import { useTheme } from 'styled-components';

const STEPS = {
  FIRST: 'BAR_CODE_INSERTION',
  SECOND: 'BANKSLIP_RETRIVAL',
  THIRD: 'BANKSLIP_REVIEW',
  FORTH: 'FEEDBACK',
};

function FirstStepRender({
  code,
  onCodeChange,
  onContinueClick,
  mock,
  setMock,
}) {
  const [mask, setMask] = useState(false);

  function handleChange(event) {
    const { value } = event.target;

    if (value.startsWith('8') && value.length === 48 && !value.includes(' ')) {
      const conformedNumber = conformToMask(value, barcodeMaskConcessionaria, {
        guid: false,
      });
      setMask(barcodeMaskConcessionaria);
      onCodeChange(conformedNumber.conformedValue);
    } else if (
      !value.startsWith('8') &&
      value.length === 47 &&
      !value.includes(' ')
    ) {
      const conformedNumber = conformToMask(value, barcodeMaskFichaComp, {
        guid: false,
      });
      setMask(barcodeMaskFichaComp);
      onCodeChange(conformedNumber.conformedValue);
    } else {
      //define which mask to use
      if (value.startsWith('8')) {
        setMask(barcodeMaskConcessionaria);
      } else if (value === '') {
        setMask(false);
      } else {
        setMask(barcodeMaskFichaComp);
      }
      onCodeChange(value);
    }
  }

  return (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        marginTop: '70px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <MaskedInput
        mask={mask}
        guide={false}
        onChange={handleChange}
        placeholder="Número do Documento"
        render={(ref, props) => (
          <Input
            value={code}
            id="barcodeInput"
            ref={(Input) => ref(Input)}
            {...props}
          />
        )}
      />
      {Number(process.env.REACT_APP_API_ENUM) !== 0 ? (
        <Button
          style={{ marginTop: '30px' }}
          title={mock ? 'Não quero mockar' : 'Quero mockar'}
          onClick={() => {
            setMock(!mock);
          }}
        ></Button>
      ) : null}
      <Button
        style={{ marginTop: '40px' }}
        id="barcodeSubmitButtonInput"
        onClick={() => onContinueClick()}
        title="Continuar"
        disabled={
          code.startsWith('8') ? code.length !== 51 : !code.length
        }
      />
    </div>
  );
}

function SecondStepRender({
  isContinueButtonDisabled,
  buttonDisabledReason,
  editables,
  initialValuePayment,
  onPaymentValueChange,
  initialDueDate,
  onDueDateChange,
  initialCurrentDate,
  onCurrentDateChange,
  warningMessage,
  data = {},
  onContinueClick,
  onBackClick,
  originalBankslipCode: orinalBankSlip,
  selectedPaymentValue: paymentValue,
  setSelectedPaymentValue: setPaymentValue,
}) {
  const theme = useTheme()
  return (
    <div
      id="bankslipInformationsDiv"
      style={{
        flexDirection: 'column',
        display: 'flex',
        marginTop: '30px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <BoletoInformations
        isSchedulingEnabled={true}
        editables={editables}
        initialValuePayment={initialValuePayment}
        onPaymentValueChange={onPaymentValueChange}
        initialDueDate={initialDueDate}
        onDueDateChange={onDueDateChange}
        initialCurrentDate={initialCurrentDate}
        onCurrentDateChange={onCurrentDateChange}
        data={data}
        orinalBankSlip={orinalBankSlip}
        isSecondStep
        paymentValue={paymentValue}
        setPaymentValue={setPaymentValue}
        theme={theme}
      />
      <br />
      <br />
      {warningMessage && <SchedulingWarning message={warningMessage} />}
      <ButtonDisabledReasonText id="buttonDisabledReasonId">
        {buttonDisabledReason}
      </ButtonDisabledReasonText>
      <div style={{ display: 'flex', marginTop: '40px' }}>
        <Button
          id="bankslipInformationsBackButton"
          onClick={onBackClick}
          style={{ marginLeft: '20px' }}
          title="Voltar"
        />
        <Button
          id="bankslipInformationsContinueButton"
          disabled={isContinueButtonDisabled || (
            data.bankSlip.allowsChangeValueStatus !== 4 ? false : !paymentValue
          )}
          onClick={onContinueClick}
          title="Continuar"
        />
      </div>
    </div>
  );
}

function ThirdStepRender({
  data = {},
  initialValuePayment,
  initialDueDate,
  initialCurrentDate,
  editables,
  onContinueClick,
  onBackClick,
  isContinueButtonDisabled,
  hasToken,
  warningMessage,
}) {
  const theme = useTheme()
  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal
        onRequestClose={hideModal}
        style={{
          overlay: {
            zIndex: '11',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            backgroundColor: theme.Cor_base_marca_01,
          },
        }}
        isOpen
      >
        <PasswordVerification
          hasToken={hasToken}
          onConfirmButtonClick={({ password, otp }) => {
            hideModal();
            onContinueClick({ password, otp });
          }}
        />
      </ReactModal>
    ),
    [hasToken]
  );

  return (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        marginTop: '30px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <ReviewLabel>
        CONFIRME OS DADOS DA SUA SOLICITAÇÃO DE PAGAMENTO ABAIXO:
      </ReviewLabel>
      <BoletoInformations
        isSchedulingEnabled={true}
        isReviewType
        data={{
          ...data,
          bankSlip: {
            ...data.bankSlip,
            dueDate: initialDueDate,
            currentDate: initialCurrentDate,
            valuePayment: Number(parseFloat(initialValuePayment).toFixed(2)),
          },
        }}
        initialValuePayment={initialValuePayment}
        initialDueDate={initialDueDate}
        initialCurrentDate={initialCurrentDate}
        editables={editables}
      />
      <br />
      <br />
      {warningMessage && <SchedulingWarning message={warningMessage} />}
      <div style={{ display: 'flex', marginTop: '40px' }}>
        <Button
          id="bankslipInformationsResumeBackButton"
          onClick={onBackClick}
          style={{ marginLeft: '20px' }}
          title="Voltar"
        />
        <Button
          id="bankslipInformationsResumeContinueButton"
          disabled={isContinueButtonDisabled}
          onClick={() => {
            showModal();
          }}
          title="Confirmar"
        />
      </div>
    </div>
  );
}

function ForthStepRender({ onButtonClick, transactionId }) {
  return (
    <div
      style={{
        flexDirection: 'column',
        display: 'flex',
        marginTop: '30px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <PaymentFeedback
        onButtonClick={onButtonClick}
        transactionId={transactionId}
      />
    </div>
  );
}

function PaymentsWithScheduling({ name = 'Pagamentos', ...props }) {
  const [isLoading, setIsLoading] = useState({
    screen: false,
    token: false,
    dateCheck: false,
  });
  const [step, setStep] = useState(STEPS.FIRST);
  const [bankslipCode, setBankslipCode] = useState('');
  const [originalBankslipCode, setOriginalBankslipCode] = useState('');
  const [bankslipFirstInformations, setBankslipFirstInformations] = useState(
    {}
  );
  const [paymentId, setPaymentId] = useState('');
  const [initialValuePayment, setInitialValuePayment] = useState(0);
  const [initialDueDate, setInitialDueDate] = useState(moment());
  const [initialCurrentDate, setInitialCurrentDate] = useState(moment());

  const [selectedPaymentValue, setSelectedPaymentValue] = useState(null);
  const [editables, setEditables] = useState({
    isValuePaymentEditable: false,
    isDueDateEditable: false,
    isCurrentDateEditable: true,
  });
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState({
    dueDate: false,
    currentDate: false,
    amount: false,
  });
  const [buttonDisabledReason, setButtonDisabledReason] = useState({
    dueDate: '',
    currentDate: '',
    amount: '',
  });
  const [token, setToken] = useState(false);

  const alert = useAlert();

  const [tokenModalType, setModalTokenType] = useState('');

  const [
    showNeedsToActivateTokenModal,
    hideNeedsToActivateTokenModal,
  ] = useModal(
    () => (
      <ReactModal
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        isOpen
      >
        <TokenActivationModal
          backButtonText={resolveBackButtonText()}
          onActiveClick={() => {
            props.history.push('/services/token');
          }}
          onBackHomeClick={resolveBackClick}
          hideModalCallback={hideNeedsToActivateTokenModal}
          type={tokenModalType}
        />
      </ReactModal>
    ),
    [tokenModalType]
  );

  const resolveBackButtonText = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      return 'CANCELARA';
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      return 'CANCELAR';
    } else {
      return 'VOLTAR PARA HOME';
    }
  };

  const resolveBackClick = () => {
    const { profile } = AccountService.account;

    if (props.location.pathname === '/') {
      hideNeedsToActivateTokenModal();
    } else if (props.location.pathname !== '/' && profile === 'TK') {
      hideNeedsToActivateTokenModal();
    } else {
      props.history.push('/');
    }
  };

  useEffect(() => {
    const fetchTokens = async () => {
      setIsLoading((loading) => ({ ...loading, token: true }));
      setError('');
      try {
        const res = await TokenService.getInfo();
        let tokenRequiredAction = needsToActivateToken({
          data: [...res.filter((e) => e.status !== 'Inativo')],
        });
        if (tokenRequiredAction) {
          setModalTokenType(tokenRequiredAction);
          showNeedsToActivateTokenModal();
        }
      } catch (e) { }
      setIsLoading((loading) => ({ ...loading, token: false }));
    };
    fetchTokens();
  }, []);

  const [error, setError] = useState('');

  const [mock, setMock] = useState(false);

  useEffect(() => {
    if (error) alert.error(error.message, { timeout: 8000 });
  }, [error]);

  async function onFirstStepContinueClick() {
    setIsLoading((loading) => ({ ...loading, screen: true }));
    try {
      if (Number(process.env.REACT_APP_API_ENUM) !== 0) {
        HttpService.mock = mock;
      }

      let bankSlipCorrected = bankslipCode.replace(/\D/g, '');
      if (!bankSlipCorrected.startsWith('8') && bankSlipCorrected.length < 47) {
        while (bankSlipCorrected.length < 47) {
          bankSlipCorrected += '0';
        }
      }

      let res = await PaymentService.consultBankslip({
        bankslipCode: bankSlipCorrected.replace(/\D/g, ''),
      });

      setOriginalBankslipCode(bankslipCode.replace(/\D/g, ''));

      setBankslipFirstInformations(res.data);
      setEditables((val) => ({ ...val, ...res.editables }));
      setInitialValuePayment(res.data.bankSlip.valuePayment);
      setInitialDueDate(res.data.bankSlip.dueDate);
      setBankslipCode('');
      setStep(STEPS.SECOND);
      if (res.data.bankSlip.allowsChangeValueStatus === 4) {
        setInitialValuePayment(0);
        setSelectedPaymentValue(null);
      }
    } catch (e) {
      if (e.error && e.error.message === '') {
        setError({
          message:
            'O código de barras informado é inválido. Por gentileza, verifique-o e tente novamente.',
        });
      } else {
        setError(e);
      }
    }
    setIsLoading((loading) => ({ ...loading, screen: false }));
  }

  function onSecondStepBackClick({ }) {
    setStep(STEPS.FIRST);
  }

  function onSecondStepContinueClick({ }) {
    setStep(STEPS.THIRD);
  }

  function onThirdStepBackClick({ }) {
    setStep(STEPS.SECOND);
  }

  async function onThirdStepContinueClick({ password, otp }) {
    try {
      setTimeout(() => {
        const el = document.getElementById('topContentId');
        el.scrollIntoView({ behavior: 'smooth' });
      }, 250);

      setTimeout(async () => {
        setIsLoading((loading) => ({ ...loading, screen: true }));
        try {
          const bankSlipInfo = bankslipFirstInformations.bankSlip;
          const res = await PaymentService.postConfirmPaymentWithScheduling(
            {
              ...bankSlipInfo,
              dueDate: initialDueDate,
              paymentDate: initialCurrentDate,
              additionalFineValue: bankSlipInfo.additionalFineValue === null ? 0 : bankSlipInfo.additionalFineValue,
              discount: bankSlipInfo.discount === null ? 0 : bankSlipInfo.discount,
              totalRebateValue: bankSlipInfo.totalRebateValue === null ? 0 : bankSlipInfo.totalRebateValue,
              additionalInterestValue: bankSlipInfo.additionalInterestValue === null ? 0 : bankSlipInfo.additionalInterestValue
            },
            bankslipFirstInformations.transaction,
            Number(parseFloat(initialValuePayment).toFixed(2)),
            'Pagamento',
            password,
            'IB',
            otp
          );
          try {
            setPaymentId(res.id);
          } catch (e) {
            setPaymentId(null);
          }
          setIsLoading((loading) => ({ ...loading, screen: false }));

          setStep(STEPS.FORTH);
        } catch (e) {
          setIsLoading((loading) => ({ ...loading, screen: false }));
          setError(e);
        }
      }, 1000);
    } catch (e) {
      setError(
        e.error && e.error.length > 0
          ? e.error[0]
          : 'Ocorreu um erro na verificação do seu token'
      );
    }
    setIsLoading((loading) => ({ ...loading, screen: false }));
  }

  const [balance, setBalance] = useState(null);
  useEffect(() => {
    const fetchBalance = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));
      try {
        const res = await AccountService._getBalance();
        setBalance(res.available);
      } catch (e) {
        setError({
          message:
            'Infelizmente não conseguimos carregar seu saldo. Não teremos como continuar esta operação.',
        });
        setStep(STEPS.FIRST);
      }
      setIsLoading((loading) => ({ ...loading, screen: false }));
    };

    const fetchTokenInfo = async () => {
      setIsLoading((loading) => ({ ...loading, screen: true }));
      try {
        const res = await TokenService.getInfo();
        setToken(hasActiveToken({ data: res }));
      } catch (e) {
        setToken(null);
      }
      setIsLoading((loading) => ({ ...loading, screen: false }));
    };

    const el = document.getElementById('topContentId');
    el.scrollIntoView({ behavior: 'smooth' });

    fetchBalance();

    if (step === STEPS.THIRD) {
      fetchTokenInfo();
    }
  }, [step]);

  useEffect(() => {
    if (!initialDueDate) {
      setIsContinueButtonDisabled((val) => ({ ...val, dueDate: true }));
      setButtonDisabledReason((val) => ({
        ...val,
        dueDate:
          '* Você deve, obrigatóriamente, fornecer uma data de vencimento',
      }));
    } else {
      setIsContinueButtonDisabled((val) => ({ ...val, dueDate: false }));
      setButtonDisabledReason((val) => ({ ...val, dueDate: '' }));
    }
  }, [initialDueDate]);

  useEffect(() => {
    if (!initialCurrentDate) {
      setIsContinueButtonDisabled((val) => ({ ...val, currentDate: true }));
      setButtonDisabledReason((val) => ({
        ...val,
        currentDate:
          '* Você deve, obrigatóriamente, fornecer uma data de pagamento',
      }));
    } else {
      setIsContinueButtonDisabled((val) => ({ ...val, currentDate: false }));
      setButtonDisabledReason((val) => ({ ...val, currentDate: '' }));
    }
  }, [initialCurrentDate]);

  useEffect(() => {
    if (Number(initialValuePayment) <= 0.0) {
      setIsContinueButtonDisabled((val) => ({ ...val, amount: true }));
      setButtonDisabledReason((val) => ({
        ...val,
        amount: bankslipFirstInformations && bankslipFirstInformations.bankSlip && bankslipFirstInformations.bankSlip.allowsChangeValueStatus === 4 && !selectedPaymentValue ?
          '* Você deve, obrigatóriamente, fornecer um valor de pagamento'
          : '* O valor escolhido para pagamento deve ser maior que R$ 0,00',
      }));
    } else if (
      moment().isSame(initialCurrentDate, 'day') &&
      Number(initialValuePayment) > balance &&
      AccountService.account.profileType !== 'OPERATOR'
    ) {
      setIsContinueButtonDisabled((val) => ({ ...val, amount: true }));
      setButtonDisabledReason((val) => ({
        ...val,
        amount: '* Saldo insuficiente para o pagamento',
      }));
    } else if (
      /*     else if (Number(initialValuePayment) > balance) {
          setIsContinueButtonDisabled(val => ({ ...val, amount: true, }));
          setButtonDisabledReason(val => ({ ...val, amount: '* Saldo insuficiente para o pagamento', }));
        } */
      bankslipFirstInformations.bankSlip &&
      (
        editables.isValuePaymentEditable ||
        bankslipFirstInformations.bankSlip.allowsChangeValueStatus === 2 ||
        bankslipFirstInformations.bankSlip.allowsChangeValueStatus === 4
      )
    ) {
      if (
        bankslipFirstInformations.bankSlip.allowsChangeValueStatus === 2 ? (
          Number(initialValuePayment) >
          Number(bankslipFirstInformations.bankSlip.maxValuePayment)
        ) : false
      ) {
        setIsContinueButtonDisabled((val) => ({ ...val, amount: true }));
        setButtonDisabledReason((val) => ({
          ...val,
          amount:
            '* O valor escolhido para pagamento é superior ao valor máximo do boleto',
        }));
      } else if (
        Number(initialValuePayment) <
        Number(bankslipFirstInformations.bankSlip.minValuePayment)
      ) {
        setIsContinueButtonDisabled((val) => ({ ...val, amount: true }));
        setButtonDisabledReason((val) => ({
          ...val,
          amount:
            '* O valor escolhido para pagamento é inferior ao valor mínimo do boleto',
        }));
      } else {
        setIsContinueButtonDisabled((val) => ({ ...val, amount: false }));
        setButtonDisabledReason((val) => ({ ...val, amount: '' }));
      }
    } else {
      setIsContinueButtonDisabled((val) => ({ ...val, amount: false }));
      setButtonDisabledReason((val) => ({ ...val, amount: '' }));
    }
  }, [initialValuePayment, initialCurrentDate, selectedPaymentValue, bankslipFirstInformations]);

  const [warningMessage, setWarningMessage] = useState(null);
  useEffect(() => {
    if (moment().diff(initialCurrentDate, 'days') < 0) {
      setWarningMessage(
        `Seu pagamento será agendado para ${moment(initialCurrentDate).format(
          'DD/MM/YYYY'
        )}`
      );
    } else {
      setWarningMessage(null);
    }
  }, [initialCurrentDate]);

  const [hasDirted, setHasDirted] = useState(false);
  useEffect(() => {
    const fetchNextValidDate = async () => {
      let selectedDate = initialCurrentDate;
      setIsLoading((loading) => ({ ...loading, dateCheck: true }));
      try {
        const res = await PaymentService.fetchNextValidDate({
          type: 'PAYMENT',
          date: selectedDate,
        });
      } catch (e) {
        if (e && e.message) {
          setHasDirted(true);
          alert.error(e.message, { timeout: 10000 });
          setInitialCurrentDate(moment(e.nextWorkDay));
        } else {
          alert.error('Não conseguimos verificar a disponibilidade deste dia');
        }
      }
      setIsLoading((loading) => ({ ...loading, dateCheck: false }));
    };

    if (!hasDirted && step === STEPS.SECOND) {
      fetchNextValidDate();
    }
  }, [initialCurrentDate, step]);

  return (
    <LoadingSpinner
      isLoading={isLoading.screen || isLoading.token || isLoading.dateCheck}
    >
      <Container id="topContentId">
        <ScreenTitle title={name} />
        <ContentWrapper>
          <CurrentAccountValue title={'saldo conta corrente'} value={balance} />
          <Wrapper>
            {step === STEPS.FIRST && (
              <FirstStepRender
                mock={mock}
                setMock={(val) => setMock(val)}
                code={bankslipCode}
                onCodeChange={(val) => {
                  setBankslipCode(val);
                }}
                onContinueClick={onFirstStepContinueClick}
              />
            )}
            {step === STEPS.SECOND && (
              <SecondStepRender
                onDueDateChange={(val) => {
                  setInitialDueDate(val);
                }}
                onCurrentDateChange={(val) => {
                  setHasDirted(false);
                  setInitialCurrentDate(val);
                }}
                onPaymentValueChange={(val) => setInitialValuePayment(val)}
                initialValuePayment={initialValuePayment}
                initialDueDate={initialDueDate}
                initialCurrentDate={initialCurrentDate}
                editables={editables}
                data={bankslipFirstInformations}
                onBackClick={onSecondStepBackClick}
                onContinueClick={onSecondStepContinueClick}
                isContinueButtonDisabled={
                  isLoading.screen ||
                  isLoading.token ||
                  isLoading.dateCheck ||
                  (isContinueButtonDisabled.amount ||
                    isContinueButtonDisabled.dueDate ||
                    isContinueButtonDisabled.currentDate)
                }
                buttonDisabledReason={
                  isContinueButtonDisabled.amount
                    ? buttonDisabledReason.amount
                    : isContinueButtonDisabled.dueDate
                      ? buttonDisabledReason.dueDate
                      : buttonDisabledReason.currentDate
                }
                warningMessage={warningMessage}
                originalBankslipCode={originalBankslipCode}
                selectedPaymentValue={selectedPaymentValue}
                setSelectedPaymentValue={setSelectedPaymentValue}
              />
            )}

            {step === STEPS.THIRD && (
              <ThirdStepRender
                editables={{
                  isValuePaymentEditable: false,
                  isDueDateEditable: false,
                  isCurrentDateChange: false,
                }}
                initialValuePayment={initialValuePayment}
                data={bankslipFirstInformations}
                onBackClick={onThirdStepBackClick}
                onContinueClick={onThirdStepContinueClick}
                isContinueButtonDisabled={
                  isLoading.screen ||
                  isLoading.token ||
                  isLoading.dateCheck ||
                  (isContinueButtonDisabled.amount ||
                    isContinueButtonDisabled.dueDate ||
                    isContinueButtonDisabled.currentDate)
                }
                initialDueDate={initialDueDate}
                initialCurrentDate={initialCurrentDate}
                hasToken={!!token}
                warningMessage={warningMessage}
              />
            )}
            {step === STEPS.FORTH && (
              <ForthStepRender
                transactionId={paymentId}
                onButtonClick={() => {
                  props.history.push('/');
                }}
              />
            )}
          </Wrapper>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}
export default withRouter(PaymentsWithScheduling);

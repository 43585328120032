import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Logo from 'assets/images/logo-topazio-receipt.png';
import { resolveTransfersResumeInformations } from 'utils/transformers/transfers-receipt';
import { handleCurrency } from 'utils/functions/currency';
import ReactToPrint from 'react-to-print';
import { ouvidoriaInformations } from 'constants/ouvidoriaInformations';
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  ContentWrapper,
  ItemContainer,
  PortfolioName,
  TopLabel,
  CheckMarkIcon,
  AditionalInformationsWrapper,
  TransactionDescription,
  InformationWrapper,
  InformationLabel,
  InformationValue,
  DownloadIcon,
} from './styles';

const TRANSFER_TYPES = {
  TOPAZIO: 'IBTR',
  SAME_OWNER: 'IBTD',
  OTHER_BANKS: 'IBTC',
};

function TransferReceipt({ data, selectedType }) {
  const componentRef = useRef();
  const [parsedData, setParsedDate] = useState([]);
  const { clientConfig } = useConfigContext();

  useEffect(() => {
    let subParsedData;
    subParsedData = {
      ...data,
      debitantName: data.debitant.name,
      debitantTaxIdentifier: data.debitant.taxIdentifier,
      debitantBankDescription: `${data.debitant.bankCode} - ${data.debitant.bankDescription}`,
      debitantBranch: data.debitant.branch,
      debitantAccount: data.debitant.account,
      beneficiaryName: data.beneficiary.name,
      beneficiaryTaxIdentifier: data.beneficiary.taxIdentifier,
      beneficiaryBankDescription: `${data.beneficiary.bankCode} - ${data.beneficiary.bankDescription}`,
      beneficiaryBranch: data.beneficiary.branch,
      beneficiaryAccount: data.beneficiary.account,
      date: moment(data.date).format('DD/MM/YYYY'),
      value: handleCurrency({ value: data.value }),
    };
    setParsedDate(
      resolveTransfersResumeInformations({
        data: subParsedData,
        selectedType: 'IBTC',
      })
    );
  }, [data]);

  class RenderReceipt extends React.Component {
    render() {
      return (
        <ContentWrapper id="capture">
          <img
            style={{ width: '160px', height: '68px', marginTop: '20px' }}
            src={clientConfig.images.receiptsLogo}
          />
          <p>{clientConfig.vars.appSigner}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              marginTop: '10px',
              marginBottom: '0px',
            }}
          >
            <CheckMarkIcon />
            <TopLabel>COMPROVANTE DE TRANSAÇÃO</TopLabel>
          </div>
          <TransactionDescription>
            {data.operationTypeDescription}
          </TransactionDescription>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '30px 100px',
              width: '100%',
            }}
          >
            {parsedData.map((e) => (
              <ItemContainer
                id="transferInformationItemId"
                isAmountField={e.field === 'VALOR'}
              >
                <PortfolioName isAmountField={e.field === 'VALOR'}>
                  {e.field}
                </PortfolioName>
                <PortfolioName isAmountField={e.field === 'VALOR'}>
                  {e.value}
                </PortfolioName>
              </ItemContainer>
            ))}
            <AditionalInformationsWrapper>
              <InformationWrapper>
                <InformationLabel>EFETIVAÇÃO DO PAGAMENTO</InformationLabel>
                <InformationValue>
                  {data.transactionDate
                    ? moment(data.transactionDate).format('DD/MM/YYYY')
                    : 'NÃO DISPONÍVEL'}
                </InformationValue>
              </InformationWrapper>
              <InformationWrapper>
                <InformationLabel>IDENTIFICADOR</InformationLabel>
                <InformationValue id="transactionIdentifierLabelId">
                  {data.identifier}
                </InformationValue>
              </InformationWrapper>
              <InformationWrapper>
                <InformationLabel>AUTENTICAÇÃO</InformationLabel>
                <InformationValue id="transactionAuthenticationLabelId">
                  {data.authentication}
                </InformationValue>
              </InformationWrapper>
            </AditionalInformationsWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'start',
              marginTop: '15px',
            }}
          >
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.baseLine}
            </InformationValue>
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.line01}
            </InformationValue>
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.line02}
            </InformationValue>
            {/* {ouvidoriaInformations.map((e) => (
              <InformationValue style={{ fontSize: '10px' }}>
                {e}
              </InformationValue>
            ))} */}
          </div>
        </ContentWrapper>
      );
    }
  }

  return (
    <Container>
      <ReactToPrint
        style={{ alignSelf: 'flex-end' }}
        trigger={() => <DownloadIcon />}
        content={() => componentRef.current}
      />
      <RenderReceipt ref={componentRef} />
    </Container>
  );
}

export default TransferReceipt;

import React from 'react';
import {
  ModalContainer,
  BackButton,
  Title,
  HeaderWrapper,
  Text,
  ActionsWrapper,
  GreenButton
} from './styles';

import { ModalWrapper } from 'components/CustomModal';

import Button from 'components/Button';

function ConfirmModal({
  onCancelClick,
  onConfirmClick,
  cancelText,
  confirmText,
  title,
  type,
  hideConfirm,
  messageLineOne,
  messageLineTwo,
  children
}) {
  return (
    <ModalWrapper contentStyle={{ maxWidth: '600px' }}>
      <ModalContainer id="confirmModalDiv">
        <HeaderWrapper>
          <Title type={type || 'error'}>{title}</Title>
        </HeaderWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center'
          }}
        >
          <Text style={{ marginBottom: '5px' }}>{messageLineOne}</Text>
          {messageLineTwo && (
            <>
              <br />
              <Text style={{ marginBottom: '5px' }}>{messageLineTwo}</Text>
            </>
          )}

          <br />
        </div>

        {children}

        <ActionsWrapper>
          <Button
            title={confirmText}
            style={{ marginRight: cancelText ? '25px' : '0px' }}
            onClick={() => {
              onConfirmClick();
              hideConfirm();
            }}
          ></Button>

          {onCancelClick &&
            <BackButton
              onClick={() => {
                onCancelClick();
                hideConfirm();
              }}
            >
              {cancelText}
            </BackButton>}
        </ActionsWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default ConfirmModal;

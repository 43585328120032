import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ThemeProvider } from 'styled-components';
import { AccountService } from 'banking-service';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import FirstLoader from 'components/FirstLoader';
import ConfigErrorModal from 'components/ConfigErrorModal';
import Head from 'components/Head';
import partners from 'envs/partners';
import storage from 'utils/functions/storage';
import { validateColorsStructure } from 'utils/validators/colors-structure-validator';

const resolvePartnerConfigsAccordingToDomain = async ({ hostname }) => {
  const partner = partners.find((ele) => ele.hostname === hostname);

  const { default: partnerVariables } = await import(
    `envs/vars/${partner.name}-vars`
  );
  const { clientId, clientSecret } =
    Number(process.env.REACT_APP_API_ENUM) === 0
      ? partnerVariables.keysPrd
      : partnerVariables.keysHlg;

  const { keysHlg, keysPrd, ...restVars } = partnerVariables;

  const { default: colors } = await import(
    `envs/colors/${partner.name}-colors`
  );

  if (!validateColorsStructure(colors.pjColors)) {
    throw new Error('The client colors configurations is missing some fields');
  }

  const { default: brandNameImageOnLogin } = await import(
    `envs/images/${partner.name}/brand-name-image-on-login.png`
  );
  const { default: backgroundImageOnLogin } = await import(
    `envs/images/${partner.name}/background-image-on-login.png`
  );

  const { default: headerLogoImageOnHomePj } = await import(
    `envs/images/${partner.name}/header-logo-image-on-home-pj.png`
  );

  const { default: headerLogoImageOnHomePf } = await import(
    `envs/images/${partner.name}/header-logo-image-on-home-pf.png`
  );

  const { default: receiptsLogo } = await import(
    `envs/images/${partner.name}/receipts-logo.png`
  );

  const { default: favIcon } = await import(
    `envs/images/${partner.name}/favicon.png`
  );

  const configs = {
    clientId,
    clientSecret,
    pjColors: colors.pjColors,
    pfColors: colors.pfColors,
    images: {
      brandNameImageOnLogin,
      backgroundImageOnLogin,
      favIcon,
      headerLogoImageOnHomePj,
      headerLogoImageOnHomePf,
      receiptsLogo,
    },
    vars: { ...restVars },
    partner: { ...mock.partner, clientId, clientSecret },
  };

  return configs;
};

const mock = {
  partner: {
    name: 'Banco Topázio',
    slogan: 'O seu melhor parceiro',
    contact: 'https://www.bancotopazio.com.br/atendimento/',
    brand_handle: 'M',
  },
};

const ConfigContext = createContext({});
const useConfigContext = () => useContext(ConfigContext);

const ConfigProvider = ({ children }) => {
  const [clientConfig, setClientConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { isLoading: isAccountChanging } = useSwitchAccountDataContext();
  const [profileColors, setProfileColors] = useState('PF');

  const resolvePersonType = () => {
    if (!AccountService.account.taxIdentifier) return 'PF';
    return AccountService.account.taxIdentifier.length > 11 ? 'PJ' : 'PF';
  };

  const resolveColorsProfile = (personType) =>
    personType === 'PF' ? 'pfColors' : 'pjColors';

  useEffect(() => {
    if (!isAccountChanging) {
      setProfileColors(resolvePersonType());
    }
  }, [isAccountChanging]);

  const fetchConfig = async () => {
    setLoading(true);
    try {
      const partnerConfigs = await resolvePartnerConfigsAccordingToDomain({
        hostname: window.location.hostname,
      });
      setClientConfig({ ...partnerConfigs });
      storage.setItem(
        'client-config',
        { ...partnerConfigs },
        moment()
          .add(50, 'minutes')
          .valueOf()
      );
    } catch (e) {
      console.log(e);
      setError(true);
    }
    setLoading(false);
  };

  const value = {
    loading,
    clientConfig,
  };

  useEffect(() => {
    if (!clientConfig) fetchConfig();
  }, [clientConfig]);

  const augmentedTheme = clientConfig
    ? {
        ...clientConfig,
        ...clientConfig[resolveColorsProfile(profileColors)],
      }
    : {};

  return (
    <ConfigContext.Provider value={value}>
      <ThemeProvider theme={augmentedTheme}>
        {loading && <FirstLoader />}
        {error && <ConfigErrorModal />}
        {clientConfig && (
          <>
            <Head title={clientConfig.vars.appName}>
              <link rel="shortcut icon" href={clientConfig.images.favIcon} />
            </Head>
            {children}
          </>
        )}
      </ThemeProvider>
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, ConfigContext, useConfigContext };

import React from "react";
import { withRouter } from "react-router-dom";

import { Container, DashboardWrapper } from "./styles";
import TabBar from "containers/TabBar";
import Content from "containers/Content";
import Header from "containers/Header";
import GlobalLoader from 'containers/GlobalLoader';


function Dashboard({ }) {
  return (
    <Container>
      <GlobalLoader >
        <Header />
        <DashboardWrapper>
          <TabBar />
          <Content />
        </DashboardWrapper>
      </GlobalLoader>

    </Container>
  );
}

export default withRouter(Dashboard);

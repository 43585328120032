import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 2;
  background-color: ${props => props.theme.backgroundSecundary};
  flex-direction: column;
  padding: 0px 0px 20px 0px;
  max-width: 240px;
  ${media.phone`
    padding: 0px 0px 0px 0px;
    overflow: auto;
    white-space: nowrap;
    max-width: 800px;
    flex-direction: row;
    flex:0.8;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 3px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

 `}
 // styling background-color on dropdowns on hover
  & .StyledItem-sc-1xeog7q-0.index__c-menu__item___36mO8.index__is-focused___yGFxo {
    background-color: ${props => props.theme.Cor_base_marca_fade_02};
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${props => props.theme.brandColor5};
`;

import React from 'react';
import styled from 'styled-components';

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'initial')};
`;

export const Text = styled.p`
  margin: 0px;
  color: ${props => props.theme.brandColor3};
  align-self: flex-start;
  font-family: 'SourceSansPro', Arial, sans-serif;
`;

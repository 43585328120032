import React, { useState, useEffect, useRef } from "react";
import { Container, CarouselItem } from "./styles";

function Carousel({
    contentArray = [],
    imageOnly = false,
    startIndex = 0,
    intervalTime = 3000,
    bgColor,
    ...props
}) {

    const [activeSlide, setActiveSlide] = useState(0);
    const [carouselItemList, setCarouselItemList] = useState([]);
    const [wasActive, setWasActive] = useState(null);
    const [keepActive, setKeepActive] = useState(null);
    const [clock, setClock] = useState(false);
    
    const containerRef = useRef(null);

    useEffect(() => {
        setCarouselItemList(contentArray ? contentArray : []);
        setActiveSlide(startIndex ? startIndex : 0);
    }, []);

    useEffect(() => {
        setActiveSlide(startIndex ? startIndex : 0);
    }, [startIndex]);

    useEffect(() => {
        setCarouselItemList(contentArray ? contentArray : []);
    }, [contentArray]);


  useEffect(() => {
    const timer = setTimeout(() => {
        setClock(!clock);
        handleNextSlide();
    }, intervalTime);
    return () => {
      clearTimeout(timer);
    };
  }, [clock]);

    const handleNextSlide = (slideTo = null) => {
        const newSlide = activeSlide + 1 >= carouselItemList.length ? 0 : activeSlide + 1; 
        setWasActive(activeSlide);
        setKeepActive(activeSlide);
        setActiveSlide(slideTo !== null ? slideTo : newSlide);
        setTimeout(() => {
            setWasActive(null);
        }, 1400);
        setTimeout(() => {
            setKeepActive(null);
        }, 800);
    }

    const getSize = (width = false) => {
        if (!containerRef || !containerRef.current) {
            return '100%';
        }
        return containerRef.current.getBoundingClientRect()[width ? 'width' : 'height'] + 'px';
    }

    return (
        <Container {...props} ref={containerRef} bgColor={bgColor}>
            {carouselItemList.map((item, index) => (
                <CarouselItem
                onClick={() => console.log(activeSlide, carouselItemList)}
                    key={`carousel-item-${index}`}
                    active={activeSlide === index}
                    keepActive={keepActive === index}
                    wasActive={wasActive === index}
                    {...(item.itemProps ? item.itemProps : {} )}
                >
                    {imageOnly ? (
                        <img
                            src={item.image}
                            style={{ width: getSize(true), height: getSize() }}
                            {...(item.imageProps ? item.imageProps : {} )}
                        />
                    ) : (
                        item
                    )}
                </CarouselItem>
            ))}
        </Container>
    );
}

export default Carousel;

import React from 'react';
import {
  Container,
  HelloMessage,
  PhraseOne,
  PhraseTwo,
  ByeMessage,
  Logo,
  DownloadPhrase,
  StoresBackground,
  GooglePlay,
  AppleStore,
} from './styles';
import MoboScreenLogo from 'assets/images/mobo-screen-logo.png';
import { useConfigContext } from 'providers/client-config-provider';

function Banner({}) {
  const { clientConfig } = useConfigContext();
  return (
    <Container>
      <HelloMessage>Oi, tudo bem?</HelloMessage>
      <PhraseOne>
        A partir daqui continuamos pelo nosso <strong>aplicativo</strong>!
      </PhraseOne>
      <PhraseTwo>
        Ele oferece mais conforto ao utilizar nossos serviços em seu celular.
      </PhraseTwo>
      <ByeMessage>Um abraço,</ByeMessage>
      {clientConfig && (
        <Logo src={clientConfig.images.brandNameImageOnLogin}></Logo>
      )}
      <DownloadPhrase>Quer baixar agora mesmo?</DownloadPhrase>
      {clientConfig && (
        <StoresBackground>
          <GooglePlay
            onClick={() => {
              window.open(`${clientConfig.vars.urls.androidAppDownloadUrl}`);
            }}
          />
          <AppleStore
            onClick={() => {
              window.open(`${clientConfig.vars.urls.iosAppDownloadUrl}`);
            }}
          />
        </StoresBackground>
      )}
    </Container>
  );
}

export default Banner;

import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { AnticipationService } from 'banking-service';
import { ThemeContext } from 'styled-components';
import { TableHeader, TableItem } from './Table';
import { FilterButton } from './filterButton';
import './popup.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import {
  Container,
  Text,
  SmallText,
  CustomAutocomplete,
  CustomAutocompleteStyles,
  RowWrapper,
  ColumnWrapper,
  ReceivableIcon,
  CalendarIcon,
} from './styles';
import { useAlert } from 'react-alert';
import FilterDatePicker from 'components/FilterDatePicker';
import InfoCard from 'components/InfoCard';
import Button from 'components/Button';
import SecondaryButton from 'components/SecondaryButton';
import { handleCurrency } from 'utils/functions/currency';
import { AntecipationContext } from 'providers/anticipation-provider';

const ACQUIRER_FILTER_NAME = 'ACQUIRER';
const DATE_FILTER_NAME = 'DATE';
const AMOUNT_FILTER_NAME = 'AMOUNT';

function calculateFilterByAcquirerOptions(schedules) {
  const availableAcquires = [...new Set(schedules.map((e) => e.acquirer))];

  return [
    ...availableAcquires.map((e) => ({
      value: e,
      label: e,
    })),
    { value: 'ALL', label: 'Todas' },
  ];
}

function calculateFilterByAmountOptions(schedules) {
  const arr = schedules.map((e) => e.value);
  const min = Math.min.apply(Math, arr);
  const max = Math.max.apply(Math, arr);

  return { min, max };
}

const initialDates = {
  startDate: moment(),
  endDate: moment(),
};

function SchedulesList({
  section = 'Antecipação de Recebíveis',
  name = 'Bem vindo',
  scheduleListReq = [],
  scheduleId = null,
  ...props
}) {
  const themeContext = useContext(ThemeContext);
  const alert = useAlert();

  const [initialSchedules, setInitialSchedules] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [filterByAcquirerOptions, setFilterByAcquirerOptions] = useState([]);
  const [filterByAmountOptions, setFilterByAmountOptions] = useState([]);
  const [isDateChanged, setDateChanged] = useState(false);

  const [
    selectedFilterByAcquirerOption,
    setSelectedFilterByAcquirerOption,
  ] = useState(null);

  const { setSimulation } = useContext(AntecipationContext);

  const [filterByDate, setFilterByDate] = useState(initialDates);

  const [filters, setFilters] = useState([
    {
      type: ACQUIRER_FILTER_NAME,
      value: 'ALL',
    },
    {
      type: DATE_FILTER_NAME,
      value: initialDates,
    },
    {
      type: AMOUNT_FILTER_NAME,
      value: null,
    },
  ]);

  useEffect(() => {
    const newSchedules = scheduleListReq.map((itm) => ({
      ...itm,
      acquirer: itm.acreditor,
      document: itm.acreditorCnpj,
      creditCardBrand: itm.issuer,
      selected: true,
    }));
    setSchedules([...newSchedules]);
    setInitialSchedules([...newSchedules]);
    setSelectedSchedules([...newSchedules]);
    setFilterByAcquirerOptions(
      calculateFilterByAcquirerOptions([...newSchedules])
    );
    setFilterByAmountOptions(calculateFilterByAmountOptions([...newSchedules]));
    setDateChanged(false);
  }, []);

  const applyFilterByAquirer = ({ item, filter }) => {
    return filter === 'ALL' || item.acquirer === filter;
  };

  const applyFilterByDate = ({ item, filter }) => {
    return moment(item.dueDate).isBetween(
      filter.startDate,
      filter.endDate,
      'days',
      '[]'
    );
  };

  const applyFilterByAmount = ({ item, filter }) => {
    return (
      filter === null || (item.value >= filter[0] && item.value <= filter[1])
    );
  };

  useEffect(() => {
    if (initialSchedules.length === 0) {
      return;
    }
    const newSchedulesList = [
      ...initialSchedules,
      ...selectedSchedules.filter(
        (e) => !initialSchedules.find((el) => el.id === e.id)
      ),
    ];
    const newSchedules = [
      ...newSchedulesList
        .filter((e) =>
          applyFilterByAquirer({
            item: e,
            filter: filters.find((e) => e.type === ACQUIRER_FILTER_NAME).value,
          })
        )
        .filter((e) =>
          isDateChanged
            ? applyFilterByDate({
                item: e,
                filter: filters.find((e) => e.type === DATE_FILTER_NAME).value,
              })
            : true
        )
        .filter((e) =>
          applyFilterByAmount({
            item: e,
            filter: filters.find((e) => e.type === AMOUNT_FILTER_NAME).value,
          })
        ),
    ];
    setSchedules(newSchedules);
  }, [filters]);

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  const applyFilter = (newFilter) => {
    setFilters([
      ...filters.filter((e) => !(e.type === newFilter.type)),
      { ...newFilter },
    ]);
  };

  const handleGetSimulation = async () => {
    setIsLoading(true);
    try {
      if (!selectedSchedules || !selectedSchedules.length) return;
      const simulations = await AnticipationService.getSimulation(
        selectedSchedules.map((itm) => itm.id),
        scheduleId
      );
      setIsLoading(false);
      if (!simulations) {
        setSimulation(null);
        return;
      }
      setSimulation(simulations);
      props.history.push('/anticipation/simulation');
    } catch (e) {
      alert.error(
        typeof e === 'string'
          ? e
          : e && e.message
          ? e.message
          : 'Ocorreu um erro ao tentar fazer a simulação!'
      );
      setIsLoading(false);
      setSimulation(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {}, []);

  const amountFilter = filters.find((e) => e.type === AMOUNT_FILTER_NAME);
  const dateFilter = filters.find((e) => e.type === DATE_FILTER_NAME);

  return (
    <Container>
      <Text>
        Selecione suas agendas para simular e antecipar. Você pode selecionar
        quantas agendas quiser. Abaixo você têm uma prévia do valor total das
        agendas selecionadas para simulação.
      </Text>
      <RowWrapper
        style={{
          marginTop: '15px',
          marginBottom: '20px',
        }}
      >
        <RowWrapper style={{ marginRight: '50px' }}>
          <SmallText style={{ marginRight: '15px' }}>Credenciadora</SmallText>
          <CustomAutocomplete
            value={filters.find((e) => e.type === ACQUIRER_FILTER_NAME)}
            isSearchable={false}
            id="filterOptionId"
            placeholder={''}
            options={filterByAcquirerOptions}
            onChange={(val) => {
              applyFilter({
                type: ACQUIRER_FILTER_NAME,
                value: val.value,
                label: val.label,
              });
            }}
            styles={CustomAutocompleteStyles(themeContext)}
          />
        </RowWrapper>
        <FilterButton
          themeContext={themeContext}
          buttonText={'Data da Antecipação'}
          headerText={'Data da Antecipação'}
          Icon={CalendarIcon}
          valueText={`${moment(
            dateFilter.value.startDate || initialDates.startDate
          ).format('DD/MM/YYYY')} -  ${moment(
            dateFilter.value.endDate || initialDates.endDate
          ).format('DD/MM/YYYY')}`}
          child={
            <FilterDatePicker
              initialDates={dateFilter.value || initialDates}
              style={{
                borderBottom: `3px solid ${themeContext.Cor_base_marca_01}`,
                backgroundColor: 'transparent',
              }}
              onDateChange={(newQuery) => {
                setDateChanged(true);
                applyFilter({
                  type: DATE_FILTER_NAME,
                  value: newQuery,
                });
              }}
              showDefaultInputIcon
            ></FilterDatePicker>
          }
        />
        <FilterButton
          themeContext={themeContext}
          buttonText={'Valor antecipado'}
          headerText={'Valor antecipado entre'}
          Icon={ReceivableIcon}
          valueText={`${handleCurrency({
            value:
              (amountFilter.value ? amountFilter.value[0] : 0) ||
              filterByAmountOptions.min,
          })} - ${handleCurrency({
            value:
              (amountFilter.value ? amountFilter.value[1] : 0) ||
              filterByAmountOptions.max,
          })}`}
          child={
            <ColumnWrapper style={{ width: '100%' }}>
              <Slider
                trackStyle={[
                  { backgroundColor: themeContext.Cor_base_marca_01 },
                ]}
                handleStyle={[
                  {
                    backgroundColor: themeContext.Cor_base_marca_01,
                    border: `2px solid ${themeContext.Cor_base_marca_01}`,
                  },
                  {
                    backgroundColor: themeContext.Cor_base_marca_01,
                    border: `2px solid ${themeContext.Cor_base_marca_01}`,
                  },
                ]}
                activeDotStyle={{
                  backgroundColor: themeContext.Cor_base_marca_01,
                  color: themeContext.Cor_base_marca_01,
                }}
                defaultValue={[
                  (amountFilter.value ? amountFilter.value[0] : 0) ||
                    filterByAmountOptions.min,
                  (amountFilter.value ? amountFilter.value[1] : 0) ||
                    filterByAmountOptions.max,
                ]}
                min={filterByAmountOptions.min}
                max={filterByAmountOptions.max}
                range
                onChange={(val) => {
                  applyFilter({
                    type: AMOUNT_FILTER_NAME,
                    value: val,
                  });
                }}
              />
              <RowWrapper
                style={{
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '5px',
                }}
              >
                <Text style={{ fontSize: '10px' }}>
                  {handleCurrency({
                    value:
                      (amountFilter.value ? amountFilter.value[0] : 0) ||
                      filterByAmountOptions.min,
                  })}
                </Text>
                <Text style={{ fontSize: '10px' }}>
                  {handleCurrency({
                    value:
                      (amountFilter.value ? amountFilter.value[1] : 0) ||
                      filterByAmountOptions.max,
                  })}
                </Text>
              </RowWrapper>
            </ColumnWrapper>
          }
        />
      </RowWrapper>
      <ColumnWrapper style={{ width: '100%' }}>
        <TableHeader
          checked={selectedSchedules.length === schedules.length}
          onSelect={() => {
            if (selectedSchedules.length === schedules.length) {
              setSelectedSchedules([]);
            } else {
              setSelectedSchedules([...schedules]);
            }
          }}
        ></TableHeader>
      </ColumnWrapper>
      <ColumnWrapper style={{ overflowY: 'auto' }}>
        {schedules.map((e) => {
          return (
            <TableItem
              key={Math.random()}
              data={{ ...e }}
              checked={!!selectedSchedules.find((el) => el.id === e.id)}
              outstadingDetails={{ ...e }}
              onSelect={({ id, checked }) => {
                setSchedules(
                  schedules.map((itm) => {
                    if (itm.id !== id) return itm;
                    return {
                      ...itm,
                      selected: checked,
                    };
                  })
                );
                if (checked) {
                  setSelectedSchedules((alreadySelecteds) => [
                    ...alreadySelecteds,
                    schedules.find((e) => e.id === id),
                  ]);
                } else {
                  setSelectedSchedules((alreadySelecteds) =>
                    alreadySelecteds.filter((e) => e.id !== id)
                  );
                }
              }}
            />
          );
        })}
        <Text
          style={{
            alignSelf: 'start',
            fontSize: '16px',
            marginTop: '5px',
            marginBottom: '5px',
            paddingLeft: '20px',
          }}
        >
          Você possui <strong>{schedules.length} agendas</strong> disponíveis
        </Text>
      </ColumnWrapper>
      <RowWrapper
        style={{
          margin: '45px 0px',
          alignItems: 'stretch',
          alignSelf: 'center',
        }}
      >
        <InfoCard
          title="Total de Agendas"
          value={handleCurrency({
            value: initialSchedules.reduce((acc, val) => acc + val.value, 0),
          })}
          style={{ marginLeft: '32px', padding: '0px' }}
        ></InfoCard>
        <InfoCard
          title="Total Disponível"
          value={handleCurrency({
            value:
              initialSchedules.reduce((acc, val) => acc + val.value, 0) -
              selectedSchedules.reduce((acc, val) => acc + val.value, 0),
          })}
          style={{ margin: '0px 32px', padding: '0px' }}
        ></InfoCard>
        <InfoCard
          title="Valor selecionado"
          value={handleCurrency({
            value: selectedSchedules.reduce((acc, val) => acc + val.value, 0),
          })}
          filled
          style={{ marginRight: '32px', padding: '0px' }}
        ></InfoCard>
      </RowWrapper>
      <SecondaryButton
        style={{ width: '225px', maxWidth: '225px', alignSelf: 'center' }}
        disabled={selectedSchedules.length === 0}
        onClick={handleGetSimulation}
        title="Simular"
        mediumFont
      ></SecondaryButton>
      <Text
        style={{ alignSelf: 'center', fontSize: '10px', marginTop: '30px' }}
      >
        Se você não deseja mais receber nossas ofertas exclusivas,{' '}
        <a href="any_link" target="_blank">
          solicite cancelamento
        </a>{' '}
        de sua autorização.
      </Text>
    </Container>
  );
}

export default SchedulesList;

import "array-flat-polyfill";
import moment from "moment";
import { FICHA_COMPENSACAO_TEXTS, CONCESSIONARIA_TEXTS } from 'constants/bankslip-informations-texts';

const resolveBankslipInformations = ({ data, editables, initialValuePayment, onPaymentValueChange, initialDueDate, onDueDateChange, initialCurrentDate, onCurrentDateChange, isSchedulingEnabled }) => {

    if (!data || typeof data !== "object") return [];

    if (!data.transaction || !data.bankSlip) return [];

    let iterable = data.bankSlip.serviceType === 'CONTA_CONCESSIONARIA' ? CONCESSIONARIA_TEXTS : FICHA_COMPENSACAO_TEXTS;

    const filledBankslip = iterable.map(e => ({
        ...e,
        ...e.fieldName && { value: e.fieldName === 'currentDate' ? moment(data.bankSlip[e.fieldName]).format('YYYY-MM-DD') : data.bankSlip[e.fieldName] },
        ...e.isEditable && {
            editable: editables && (isSchedulingEnabled && e.fieldName === 'currentDate') ? editables.isCurrentDateEditable : editables && e.fieldName === 'valuePayment' ? editables.isValuePaymentEditable : editables.isDueDateEditable,
            onValueChange: (isSchedulingEnabled && e.fieldName === 'currentDate') ? onCurrentDateChange : e.fieldName === 'valuePayment' ? onPaymentValueChange : onDueDateChange,
            initialValue: (isSchedulingEnabled && e.fieldName === 'currentDate') ? initialCurrentDate : e.fieldName === 'valuePayment' ? initialValuePayment : initialDueDate,
        }
    }));

    return filledBankslip;

}

export { resolveBankslipInformations };





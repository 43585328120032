import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  margin: 0;
  padding-top: 18px;
  padding-left: 19px;
  padding-bottom: 21px;
  max-width: 460px;
  margin-top: 15px;
  min-height: 55px;
`;

export const Title = styled.span`
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 0.88;
  text-align: left;
  color: ${props => props.theme.brandColor2};
  margin-top: 0;
  margin-bottom: 5px;
  float: none;
  margin-left: 0;
  text-transform: uppercase;
`;

export const Description = styled.span`
  font-family: "SourceSansPro-Regular", "source-regular";
  font-size: 12px;
  font-weight: normal;
  line-height: 1.17;
  color: ${props => props.theme.brandColor2};
  margin-top: 0;
  margin-bottom: 6px;
`;

export const Link = styled.a`
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: ${props => props.theme.brandColor2};
  text-decoration: underline;
  margin: 0;
  padding-right: 18px;
  cursor: pointer;
`;

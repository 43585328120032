// Render Prop
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import CustomDatePicker from 'components/CustomDatePicker';
import { parseBeneficiaries } from 'utils/transformers/beneficiaries';
import { initialInternalTransferData } from 'containers/Transfers/initialValues';
import { useConfigContext } from 'providers/client-config-provider';
import { DisplayFormikState } from './helper';
import { useTheme } from 'styled-components';

import * as Yup from 'yup';

import {
  BeneficiaryInput,
  AccountInformationsWrapper,
  BranchInput,
  AccountInput,
  DigitInput,
  DateAndValueWrapper,
  AmountInput,
  WeekendsAndHolidaysWarningLabel,
  CustomAutocomplete,
  PurposeInput,
  Form
} from './styles';

const branchMask = [/\d/, /\d/, /\d/, /\d/];

const accountMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const digitMask = [/\d/];

function k(i, callback, data) {
  var v = i.target.value.replace(/\D/g, '');
  //if (v > 25000000) v = 25000000;
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  i.value = v;
  callback({ values: { ...data, amount: `R$ ${i.value}` } });
  //callback('amount', `R$ ${i.value}`);
}

const TransferSchema = Yup.object().shape({
  branch: Yup.string().required('Required'),
  account: Yup.string().required('Required'),
  digit: Yup.string().required('Required'),
  amount: Yup.string().required('Required'),
  name: Yup.string().required('Required')
});

function InternalTransferForm({
  data,
  beneficiaries,
  formTouched,
  formErrors,
  handleFormChanges,
  handleFormErrorsChanges,
  handleFormTouchedChanges,
  handleFavoritesClick,
  isSchedulingEnabled
}) {
  const { clientConfig } = useConfigContext();
  const [parsedBeneficiaries, setParsedBeneficiaries] = useState([]);
  
  const theme = useTheme();

  function handleInputChange(inputValue, action, handleFormChanges) {
    if (
      action.action !== 'input-blur' &&
      action.action !== 'menu-close' &&
      action.action !== 'set-value'
    ) {
      handleFormChanges({
        values: {
          ...data,
          hasSelected: false,
          branch: '',
          account: '',
          digit: '',
          beneficiary: { name: inputValue }
        }
      });
      handleFormErrorsChanges({
        errors: { branch: false, account: false, digit: false }
      });
      handleFormTouchedChanges({
        touched: { branch: false, account: false, digit: false }
      });
    }
  }

  useEffect(() => {
    setParsedBeneficiaries(
      parseBeneficiaries({ data: beneficiaries, filter: { onlyTopazio: true } })
    );
  }, [beneficiaries]);

  return (
    <Form style={{ display: 'flex', flexDirection: 'column' }}>
      {data.hasSelected ? (
        <CustomAutocomplete
          styles={{
            option: (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              textTransform: 'uppercase',
              backgroundColor: isDisabled ? undefined : (
                isSelected ? theme.Cor_base_marca_01 : (
                  isFocused ? theme.Cor_base_marca_fade_02 : undefined
                )
              ),
              ':active': {
                ...provided[':active'],
                backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
              }
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              textTransform: 'uppercase'
            })
          }}
          value={{
            value: { ...data.beneficiary },
            label: data.beneficiary.name
          }}
          id="beneficiaryInputId"
          isClearable
          onInputChange={(inputValue, action) => {
            handleInputChange(inputValue, action, handleFormChanges);
          }}
          noOptionsMessage={({ inputValue }) =>
            `Nova transferência para ${inputValue}`
          }
          placeholder="Favorecido"
          name="beneficiary"
          options={parsedBeneficiaries}
          onChange={val => {
            if (!val) {
              handleFormChanges({ values: { ...initialInternalTransferData } });
            } else
              handleFormChanges({
                values: {
                  ...data,
                  beneficiary: val.value,
                  hasSelected: true,
                  branch: val.value.branch.toString(),
                  account: val.value.account.toString().slice(0, -1),
                  digit: val.value.account.toString().slice(-1)
                }
              });
          }}

          //hasErrors={touched.beneficiary && errors.beneficiary}
        />
      ) : (
        <CustomAutocomplete
          styles={{
            option: (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              textTransform: 'uppercase',
              backgroundColor: isDisabled ? undefined : (
                isSelected ? theme.Cor_base_marca_01 : (
                  isFocused ? theme.Cor_base_marca_fade_02 : undefined
                )
              ),
              ':active': {
                ...provided[':active'],
                backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
              }
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              textTransform: 'uppercase'
            })
          }}
          //value={{ value: { ...data.beneficiary }, label: data.beneficiary.name }}
          id="beneficiaryInputId"
          inputValue={data.beneficiary.name}
          onInputChange={(inputValue, action) => {
            handleInputChange(inputValue, action, handleFormChanges);
          }}
          noOptionsMessage={({ inputValue }) =>
            `Nova transferência para ${inputValue}`
          }
          placeholder="Favorecido"
          name="beneficiary"
          options={parsedBeneficiaries}
          onChange={val => {
            handleFormChanges({
              values: {
                ...data,
                beneficiary: val.value,
                hasSelected: true,
                branch: val.value.branch.toString(),
                account: val.value.account.toString().slice(0, -1),
                digit: val.value.account.toString().slice(-1)
              }
            });
          }}

          //hasErrors={touched.beneficiary && errors.beneficiary}
        />
      )}

       <button
        onClick={e => {
          e.preventDefault();
          handleFavoritesClick();
        }}
        style={{
          alignSelf: 'flex-end',
          border: 'none',
          outline: 'none',
          color: theme.Cor_base_marca_01,
          marginTop: '10px',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
      >
        Gerenciar Favoritos
      </button> 
      <br />
      <br />

      <AccountInformationsWrapper>
        <BranchInput
          disabled={data.hasSelected}
          value={data.branch}
          id="branchInputId"
          hasErrors={formErrors.branch && formTouched.branch}
          placeholder="Agência (s/dígito) *"
          guide={false}
          mask={branchMask}
          type="text"
          onChange={e => {
            handleFormChanges({ values: { ...data, branch: e.target.value } });
            handleFormTouchedChanges({
              touched: { ...formTouched, branch: true }
            });
            if (e.target.value === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: false }
              });
            }
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, branch: true }
            });
            if (data.branch === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: false }
              });
            }
          }}
        />
        <AccountInput
          disabled={data.hasSelected}
          value={data.account}
          id="accountInputId"
          hasErrors={formErrors.account && formTouched.account}
          placeholder="Conta *"
          guide={false}
          mask={accountMask}
          name="account"
          type="text"
          onChange={e => {
            handleFormChanges({ values: { ...data, account: e.target.value } });
            handleFormTouchedChanges({
              touched: { ...formTouched, account: true }
            });
            if (e.target.value === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: false }
              });
            }
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, account: true }
            });
            if (data.account === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: false }
              });
            }
          }}
        />
        <DigitInput
          disabled={data.hasSelected}
          value={data.digit}
          id="digitInputId"
          hasErrors={formErrors.digit && formTouched.digit}
          placeholder="Dígito *"
          guide={false}
          mask={digitMask}
          name="digit"
          type="text"
          onChange={e => {
            handleFormChanges({ values: { ...data, digit: e.target.value } });
            handleFormTouchedChanges({
              touched: { ...formTouched, digit: true }
            });
            if (e.target.value === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: false }
              });
            }
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, digit: true }
            });
            if (data.digit === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: false }
              });
            }
          }}
        />
      </AccountInformationsWrapper>
      <DateAndValueWrapper>
        <CustomDatePicker
          id="datePickerId"
          date={data.date}
          onChange={val =>
            handleFormChanges({ values: { ...data, date: val } })
          }
          disAllowFutureDates={!isSchedulingEnabled}
          disAllowPastDates
          disAllowWeekends
        />
        <AmountInput
          id="amountInputId"
          type="text"
          name="amount"
          hasErrors={
            (formErrors.amount && formTouched.amount) ||
            (data.amount === 'R$ 0,00' && formTouched.amount)
          }
          value={data.amount}
          onChange={e => {
            handleFormChanges({ values: { ...data, amount: e.target.value } });
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, amount: true }
            });
            if (data.amount === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, amount: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, amount: false }
              });
            }
          }}
          onKeyUp={e => k(e, handleFormChanges, data)}
          placeholder={'Valor *'}
        />
      </DateAndValueWrapper>
      <WeekendsAndHolidaysWarningLabel>
        * Transferências agendadas para finais de semana e feriados serão
        processadas apenas no próximo dia útil
      </WeekendsAndHolidaysWarningLabel>
      <PurposeInput
        value={data.comment}
        id="commentInputId"
        //hasErrors={errors.purpose && touched.purpose}
        placeholder="Motivo"
        guide={false}
        mask={false}
        type="text"
        maxLength={50}
        onChange={e => {
          handleFormChanges({ values: { ...data, comment: e.target.value } });
        }}
      />
      {(!data || (data.comment !== null && data.comment !== undefined)) && (
        <WeekendsAndHolidaysWarningLabel style={{ marginLeft: '8px' }}>
          {data.comment.length}/50
        </WeekendsAndHolidaysWarningLabel>
      )}
    </Form>
  );
}

export default InternalTransferForm;

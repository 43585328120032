import styled from 'styled-components';
import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 80px 10px 20px;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected
      ? props.theme.Cor_base_marca_02
      : props.theme.Cor_base_marca_03};
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.home.sideBarItemBackgroundWhenSelected.backgroundColor
      : props.theme.home.sideBarItemBackground.backgroundColor};
  &:hover {
    text-decoration: underline;
  }
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`;

export const TabDescription = styled.p`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 11px;
  color: ${(props) =>
    props.isSelected
      ? props.theme.home.sideBarItemLabelWhenSelected.color
      : props.theme.home.sideBarItemLabel.color};
  cursor: pointer;
  margin-left: 10px;
  text-transform: uppercase;
`;

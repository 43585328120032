import styled from "styled-components";
import Select from 'react-select';
import { ShareBox } from 'styled-icons/remix-fill/ShareBox';
import { Printer } from 'styled-icons/remix-fill/Printer';
import { FileExcel2 } from 'styled-icons/remix-fill/FileExcel2'


import media from "utils/media";

const fontFamily = "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.brandColor5};
  padding: 60px 0px 20px 0px;
  margin: 0px 60px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const Wrapper = styled.div`
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Label = styled.div`
  font-family: ${fontFamily};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.32px;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  text-transform: uppercase;

  strong {
    font-weight: 500;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
    

    
`;

export const Title = styled.label`
    margin: 0;
    font-family: ${fontFamily};
    font-size: 14px;
    font-weight: 550;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
`;

export const Value = styled.span`
    margin: 0;
    font-family: ${fontFamily};
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
    font-weight: 600;
`;

export const ActiveIcon = styled(ShareBox)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.success};
`;

export const NoItemsAvailableLabel = styled.span`

    align-self: 'center';
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: ${props => props.theme.brandColor3};
    margin-top: 30px;
    margin-bottom: 30px;
`

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  background: white;
  padding: ${props => props.isCard ? '15px 10px' : '25px 0px'};
  justify-content: space-between;
  border-left: ${props => props.isCard ? `4px solid ${props.theme.alert}` : 'none'};
  margin-bottom: ${props => props.isCard ? '30px' : 'none'};
`;

export const SectionWrapper = styled.div`
  ${media.bigBigDesktop`
    display: flex}
  `}

  ${media.bigDesktop`
    display: flex;
  `}

  ${media.desktop`
    display: ${props => props.hiddenOnSmallScreen ? 'none' : 'flex'};
  `}

  ${media.tablet`
    display: ${props => props.hiddenOnSmallScreen ? 'none' : 'flex'};
  `}

  display: 'flex';

  flex-direction: row;
  flex: 1;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

`;

export const BoxContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  border: ${props => props.isCard ? `solid 1px ${props.theme.brandColor1}` : `solid 4px ${props.theme.brandColor1}`};
  background-color: ${props => props.isCard ? props.theme.brandColor2 : props.theme.brandColor1};
  max-width: ${props => props.isCard ? '125px' : '200px'};
  width: 35%;
  justify-content: center;
  align-items: center;
  padding: 7px 4px;
`;

export const BoxTitle = styled.label`
    margin: 0;
    font-family: ${fontFamily};
    font-size: ${props => props.isCard ? '11px' : '12px'};
    font-weight: 550;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 5px 0;
    color: ${props => props.isCard ? props.theme.brandColor3 : props.theme.brandColor2};
`;

export const BoxValue = styled.span`
    margin: 0;
    font-family: ${ fontFamily};
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 5px 0;
    font-weight: 600;
    color: ${props => props.isCard ? props.theme.brandColor3 : props.theme.brandColor2};
`;


export const QueryFieldLabel = styled.label`
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.brandColor3};
`;

export const DownloadIconPDF = styled(Printer)`
    width: 25px;
    height: 25px;
    color: ${props => props.theme.customPurple};
    cursor: pointer;
    margin-right: 10px;
    padding: 3px 0px;
    font-weight: normal;
    align-self: flex-end;
`;

export const DownloadIconCSV = styled(FileExcel2)`
    width: 25px;
    height: 25px;
    color: ${props => props.theme.customPurple};
    cursor: pointer;
    margin-right: 10px;
    padding: 3px 0px;
    font-weight: normal;
    align-self: flex-end;
`;

import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import store from 'store';
import moment from 'moment';

import { HttpService } from 'banking-service/utils/HttpService';
import {
  Setup,
  UserService,
  AccountService,
  SensediaService,
} from 'banking-service';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import storage from 'utils/functions/storage';

export const AuthDataContext = createContext(null);

const initialAuthData = {};

const AuthDataProvider = (props) => {
  const [authData, setAuthData] = useState(initialAuthData);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenExpirationMessage, setTokenExpirationMessage] = useState(null);
  const {
    setSelectedAccountIndex,
    setHasUpdatedPermissions,
  } = useSwitchAccountDataContext();

  useEffect(() => {
    const currentAuthData = storage.getItem('user');
    async function setupUserFromStorage() {
      try {
        setIsLoading(true);
        Setup(Number(process.env.REACT_APP_API_ENUM), { tokenErrorCallback });
        const sensediaTokens = storage.getItem('sensedia');
        const clientConfig = storage.getItem('client-config');
        if (!sensediaTokens && !clientConfig) return;

        // if (!sensediaTokens) {
        //   const { access_token, refresh_token } = await SensediaService.getAccessToken({
        //     clientId: clientConfig.partner.clientId,
        //     clientSecret: clientConfig.partner.clientSecret,
        //   });
        //   storage.setItem('sensedia', { access_token, refresh_token }, moment().add(50, 'minutes'));
        //   console.log({ access_token, refresh_token })
        // }

        HttpService.defaultHeaders = {
          'Content-Type': 'application/json',
          Authorization: currentAuthData.token,
          client_id: clientConfig.partner.clientId,
          access_token: sensediaTokens.access_token,
        };
        console.log(HttpService.defaultHeaders);
        const { lastLoginAt, tokenExpiration } = currentAuthData;

        UserService._setExpirationTokenTime({
          time:
            moment().diff(moment(lastLoginAt)) > tokenExpiration
              ? 0
              : tokenExpiration - moment().diff(moment(lastLoginAt)),
        });
        UserService.setExpirationTimeCallbacks({
          callbacks: expirationCallbacks,
        });
        await UserService._getUserInfo(
          currentAuthData.accounts,
          currentAuthData
        );
        setIsLoading(false);
        setAuthData(currentAuthData);
      } catch (e) {
        setTokenExpirationMessage({
          title: 'Sessão Expirada',
          action: 'EXPIRED_USER_SESSION_TOKEN',
          type: 'error',
          message:
            'Sua sessão expirou. Você necessita efetuar seu login novamente.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          isCloseButtonDisabled: true,
        });
        setIsLoading(false);
      }
    }

    if (currentAuthData && currentAuthData.isLoggedIn) {
      setupUserFromStorage();
    } else {
      setTimeout(() => setIsLoading(false), 2000);
    }
  }, []);

  const expirationCallbacks = [
    {
      time: 8 * 60 * 1000,
      function: () => {
        setTokenExpirationMessage({
          title: 'Sessão Expirando',
          action: 'ALMOST_EXPIRING_USER_SESSION_TOKEN',
          type: 'alert',
          message:
            'sua sessão vai expirar em alguns instantes e você será desconectado(a).',
          attentionMessage:
            'se continuar, você poderá ser desconectado em meio a uma transação.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          extraButtonText: 'Continuar assim mesmo',
          onExtraButtonClick: () => {
            setTokenExpirationMessage(null);
          },
          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          name: UserService.user.name.split(' ')[0].toLowerCase(),
        });
      },
    },
    {
      time: 5 * 60 * 1000,
      function: () => {
        setTokenExpirationMessage({
          title: 'Sessão Expirando',
          action: 'ALMOST_EXPIRING_USER_SESSION_TOKEN',
          type: 'alert',
          message:
            'sua sessão vai expirar em alguns instantes e você será desconectado(a).',
          attentionMessage:
            'se continuar, você poderá ser desconectado em meio a uma transação.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          extraButtonText: 'Continuar assim mesmo',
          onExtraButtonClick: () => {
            setTokenExpirationMessage(null);
          },
          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          name: UserService.user.name.split(' ')[0].toLowerCase(),
        });
      },
    },
    {
      time: 3 * 60 * 1000,
      function: () => {
        setTokenExpirationMessage({
          title: 'Sessão Expirada',
          action: 'EXPIRED_USER_SESSION_TOKEN',
          type: 'error',
          message:
            'sua sessão expirou. Você necessita efetuar seu login novamente.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          name: UserService.user.name.split(' ')[0].toLowerCase(),
          isCloseButtonDisabled: true,
        });
      },
    },
  ];

  const expirationCallbacksToTest = [
    {
      time: 5 * 1000,
      function: () => {
        setTokenExpirationMessage({
          title: 'Sessão Expirando',
          action: 'ALMOST_EXPIRING_USER_SESSION_TOKEN',
          type: 'alert',
          message:
            'sua sessão vai expirar em alguns instantes e você será desconectado(a).',
          attentionMessage:
            'se continuar, você poderá ser desconectado em meio a uma transação.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          extraButtonText: 'Continuar assim mesmo',
          onExtraButtonClick: () => {
            setTokenExpirationMessage(null);
          },

          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          name: UserService.user.name.split(' ')[0].toLowerCase(),
        });
      },
    },
    {
      time: 10 * 1000,
      function: () => {
        setTokenExpirationMessage({
          title: 'Sessão Expirando',
          action: 'ALMOST_EXPIRING_USER_SESSION_TOKEN',
          type: 'alert',
          message:
            'sua sessão vai expirar em alguns instantes e você será desconectado(a).',
          attentionMessage:
            'se continuar, você poderá ser desconectado em meio a uma transação.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          extraButtonText: 'Continuar assim mesmo',
          onExtraButtonClick: () => {
            setTokenExpirationMessage(null);
          },

          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          name: UserService.user.name.split(' ')[0].toLowerCase(),
        });
      },
    },
    {
      time: 20 * 1000,
      function: () => {
        setTokenExpirationMessage({
          title: 'Sessão Expirada',
          action: 'EXPIRED_USER_SESSION_TOKEN',
          type: 'error',
          message:
            'sua sessão expirou. Você necessita efetuar seu login novamente.',
          greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
          onGreenButtonClick: () => {
            onLogout({ tokenError: true });
          },
          name: UserService.user.name.split(' ')[0].toLowerCase(),
          isCloseButtonDisabled: true,
        });
      },
    },
  ];

  const onLogout = async (options = {}) => {
    setAuthData(initialAuthData);
    storage.setItem('user', initialAuthData);
    storage.removeItem('sensedia');
    storage.removeItem('expired');
    window.stop();
    setSelectedAccountIndex(-1);
    setHasUpdatedPermissions(0);
    try {
      await UserService.logout();
    } catch (_) {}
    UserService._clean();
    setTokenExpirationMessage(null);
  };

  const onLogin = (newAuthData) => {
    setSelectedAccountIndex(0);
    setAuthData(newAuthData);
    storage.setItem('user', newAuthData);
  };

  const tokenErrorCallback = () => {
    console.log('tokenErrorCallback');
    storage.setItem('expired', true);
    setTokenExpirationMessage({
      title: 'Sessão Expirada',
      action: 'EXPIRED_USER_SESSION_TOKEN',
      type: 'error',
      message:
        'sua sessão expirou. Você necessita efetuar seu login novamente.',
      greenButtonText: 'EFETUAR LOGIN NOVAMENTE',
      onGreenButtonClick: () => {
        onLogout({ tokenError: true });
      },
      name: UserService.user.name.split(' ')[0].toLowerCase(),
      isCloseButtonDisabled: true,
    });
  };

  const authDataValue = useMemo(
    () => ({
      ...authData,
      isLoading,
      onLogin,
      onLogout,
      expirationCallbacks,
      expirationCallbacksToTest,
      tokenErrorCallback,
      tokenExpirationMessage,
    }),
    [authData, isLoading, tokenExpirationMessage]
  );

  return <AuthDataContext.Provider value={authDataValue} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;

import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import qs from 'query-string';

import useMobileDetect from 'use-mobile-detect-hook';
import MoboDownload from 'components/MoboDownload';
import { useAuthDataContext } from "providers/auth-provider";
import FirstLoader from 'components/FirstLoader';


function MobileDetection({ children, ...props }) {
    const detectMobile = useMobileDetect();
    const { isLoggedIn, isLoading } = useAuthDataContext();

    if (detectMobile.isMobile()) return <MoboDownload />

    if (isLoading) return <FirstLoader />

    const usernameParams = qs.parse(props.location.search, { ignoreQueryPrefix: true }).username;

    if (!isLoggedIn) return <Redirect to={usernameParams ? `/login?username=${usernameParams}` : '/login'} />

    return { ...children }

}

export default withRouter(MobileDetection);
import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;


export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100vh - 70px);
`;


import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #53565A;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  width: 480px;
  background-color: #fff;
  padding: 30px;
  color: #53565a;
  border-radius: 5px;
  border: solid 1px #707070;

  h2, p {
    margin: 0;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  p {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 300;
  }

  button {
    font-size: 20px;
    margin: auto;
    background-color: #53565A;
    margin-top: 25px;
    min-width: 230px;
    border: none;
    min-height: 50px;
    color: #fff;
    border-radius: 30px;
  }
`;
import React from 'react';

import { CustomDateInput } from './styles';

function MaskedInput({ mask, ...props }) {
    return (
        <CustomDateInput
            mask={mask}
            guide={false}
            //onChange={handleChangeTaxIdentifier}
            /*             onBlur={() => {
                            handleFormTouchedChanges({ touched: { ...formTouched, taxIdentifier: true } });
                            if (data.taxIdentifier.length !== 14 && data.taxIdentifier.length !== 18) {
                                handleFormErrorsChanges({ errors: { ...formErrors, taxIdentifier: true } })
                            } else {
                                handleFormErrorsChanges({ errors: { ...formErrors, taxIdentifier: false } })
                            }
                        }} */
            //hasErrors={formTouched.taxIdentifier && formErrors.taxIdentifier}
            {...props}
        />
    )
}


export default MaskedInput;
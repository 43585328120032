import React from 'react';

import { Wrapper, Text, Title } from './styles';

function SchedulesProcessing({ ...props }) {
  const schedulesAwaitIllustration = require('../../../../assets/images/anticipation_receivables/receivable_schedules_await.png');

  return (
    <Wrapper style={{ padding: '40px' }}>
      <Title>Suas agendas ainda não estão disponíveis</Title>
      <div style={{ display: 'flex', padding: '20px 0' }}>
        <img
          src={schedulesAwaitIllustration}
          style={{
            width: '31.672vw',
            height: 'auto',
            maxWidth: '500px',
          }}
          alt="Antecipação de recebíveis - Agendas Indisponíveis"
        />
        <Wrapper
          style={{
            marginLeft: '34px',
            alignItems: 'flex-start',
            marginTop: '56px',
          }}
        >
          <Text
            style={{ fontSize: '22px', marginBottom: '25px', fontWeight: 600 }}
          >
            Oh não!!!
          </Text>
          <Text>
            Não encontramos agendas para você no dia de hoje mas não se
            preocupe, você pode voltar amanhã para tentar novamente!
            <br />
            <br />
            Você também pode receber um e-mail caso novas agendas sejam
            encontradas durante o dia.
          </Text>
        </Wrapper>
      </div>
    </Wrapper>
  );
}

export default SchedulesProcessing;

import React from "react";
import { Container } from "./styles";

function FilterButton({ isSelected, onClick, timeInDays }) {
  return (
    <Container
      onClick={onClick}
      isSelected={isSelected}
    >{`${timeInDays} DIAS`}</Container>
  );
}

export default FilterButton;

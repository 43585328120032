import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { CSVLink, CSVDownload } from 'react-csv';
import PrintableWrapper from 'components/PrintableWrapper';
import { useAlert } from 'react-alert';
import { useConfigContext } from 'providers/client-config-provider';
import { parseTransactions } from 'utils/transformers/transactions';
import { useTheme } from 'styled-components';
import { AccountService } from 'banking-service';

import {
  BalanceWrapper,
  FiltersWrapper,
  AccountOption,
  Divisor,
  PeriodLabel,
  AccountSelectionWrapper,
  DownloadIconPDF,
  DownloadIconCSV,
} from './styles';
import Balance from 'components/Balance';
import FilterButton from 'components/FilterButton';
import ACCOUNT_TYPES from 'constants/accountTypes';
import FilterDatePicker from 'components/FilterDatePicker';

const resolveBlockedBalance = (account) => {
  if (account && account.balance && account.balance.blocked > 0) {
    return account.balance.blocked;
  }
  return null;
};

function AccountBalance({
  isSecurityAccount,
  data,
  onFilterChanged,
  filterOptions,
  isPeriodSelectionEnabled,
  changedAccountType,
  hasLinkedAccount,
  periodLabel = { startDate: null, endDate: null },
}) {
  const alert = useAlert();

  const theme = useTheme();
  const { clientConfig } = useConfigContext();

  const [selectedFilter, setSelectedFilter] = useState();

  const [isPrintingState, setIsPrintingState] = useState(false);
  const componentRef = useRef();
  const triggerRef = useRef();

  function renderPeriodLabelText({ startDate, endDate }) {
    if (!startDate || !endDate) {
      return '';
    } else {
      return `${moment(startDate).format('DD/MM/YY')} - ${moment(
        endDate
      ).format('DD/MM/YY')}`;
    }
  }

  const [selectedAccountType, setSelectedAccountType] = useState(
    ACCOUNT_TYPES.CURRENT_ACCOUNT
  );
  useEffect(() => {
    changedAccountType(selectedAccountType);
  }, [selectedAccountType]);

  const [parsedTransaction, setParsedTransactions] = useState([]);
  useEffect(() => {
    const parsed = parseTransactions(data);
    const blockedBalance = resolveBlockedBalance(AccountService.account);
    if (blockedBalance !== null) {
    setParsedTransactions([
      {
        date: moment().format('DD/MM'),
        dateWithYear: moment().format('YYYY-MM-DD'),
        value: blockedBalance,
        bold: true,
        description: 'Saldo Bloqueado',
      },
    ...parsed]);
    } else {
      setParsedTransactions(parsed);
    }
  }, [data]);

  useEffect(() => {
    if (isPrintingState) {
      triggerRef.current.click();
    }
  }, [isPrintingState]);

  return (
    <BalanceWrapper>
      <AccountSelectionWrapper isSelected>
        <AccountOption
          onClick={() => setSelectedAccountType(ACCOUNT_TYPES.CURRENT_ACCOUNT)}
          isSelected={selectedAccountType === ACCOUNT_TYPES.CURRENT_ACCOUNT}
        >
          {isSecurityAccount
            ? 'Extrato conta garantida'
            : 'Extrato conta corrente'}
        </AccountOption>
        {hasLinkedAccount && (
          <AccountOption
            onClick={() => setSelectedAccountType(ACCOUNT_TYPES.LINKED_ACCOUNT)}
            isSelected={selectedAccountType === ACCOUNT_TYPES.LINKED_ACCOUNT}
          >
            Extrato conta vinculada
          </AccountOption>
        )}
      </AccountSelectionWrapper>

      <FiltersWrapper>
        {filterOptions.map((e, i) => (
          <FilterButton
            key={i}
            isSelected={selectedFilter === e}
            timeInDays={e}
            onClick={() => {
              setSelectedFilter(e);
              onFilterChanged({
                startDate: moment().subtract(e, 'day'),
                endDate: moment(),
              });
            }}
          />
        ))}
        {isPeriodSelectionEnabled && (
          <FilterDatePicker
            disAllowFutureDates
            style={{ padding: 5, marginLeft: 10, border: '1px solid #eee' }}
            isSelected={false}
            firesWithButton
            onDateChange={({ startDate, endDate }) => {
              onFilterChanged({ startDate, endDate });
            }}
          />
        )}
      </FiltersWrapper>
      <Divisor />
      <div style={{ display: 'none' }}>
        <PrintableWrapper
          topLabelText={`EXTRATO DE ${
            isSecurityAccount
              ? 'CONTA GARANTIDA'
              : selectedAccountType === ACCOUNT_TYPES.CURRENT_ACCOUNT
              ? 'CONTA CORRENTE'
              : ACCOUNT_TYPES.LINKED_ACCOUNT
              ? 'CONTA VINCULADA'
              : 'EXTRATO'
          }`}
          periodLabel={`(Período: ${renderPeriodLabelText(periodLabel)})`}
          receiptContactInformations={
            clientConfig.vars.receiptContactInformations
          }
          logo={clientConfig.images.receiptsLogo}
          ref={componentRef}
        >
          {isPrintingState ? (
            <Balance isPrinting data={parsedTransaction}
             />
          ) : null}
        </PrintableWrapper>
      </div>
      <PeriodLabel>Período: {renderPeriodLabelText(periodLabel)}</PeriodLabel>  
      <Balance data={parsedTransaction}/>
      <div
        style={{
          display: 'flex',
          alignSelf: 'flex-end',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CSVLink
          filename={`EXTRATO DE ${
            isSecurityAccount
              ? 'CONTA GARANTIDA'
              : selectedAccountType === ACCOUNT_TYPES.CURRENT_ACCOUNT
              ? 'CONTA CORRENTE'
              : ACCOUNT_TYPES.LINKED_ACCOUNT
              ? 'CONTA VINCULADA'
              : 'EXTRATO'
          } - ${moment().format('DD/MM/YYYY - HH:mm')} - ${
            clientConfig.vars.appName
          }.csv`}
          data={[
            ...parsedTransaction.map((e) => ({
              dateWithYear: e.dateWithYear,
              description: e.description,
              value: `${e.type === 'D' ? '-' : ''}${e.value
                .toString()
                .replace('.', ',')}`,
            })),
          ]}
          separator={';'}
          headers={[
            { label: 'Data', key: 'dateWithYear' },
            { label: 'Lançamento', key: 'description' },
            { label: 'Valor', key: 'value' },
          ]}
        >
          <DownloadIconCSV
            style={{
              display: !data ? 'none' : 'initial',
              marginRight: '5px',
              color: theme.Cor_base_marca_01,
            }}
          />
        </CSVLink>

        <DownloadIconPDF
          onClick={() => {
            let count = 0;
            data.entries.forEach((element) => {
              count = count + element.data.length;
            });
            if (count > 2000) {
              alert.info(
                `Gerando PDF de ${count} registros. O processo poderá levar alguns minutos.`
              );
              setTimeout(() => {
                alert.success('Seu documento está sendo preparado...');
                setTimeout(() => {
                  setIsPrintingState(true);
                }, 500);
              }, 6000);
            } else {
              setIsPrintingState(true);
            }
          }}
          style={{
            display: !data ? 'none' : 'initial',
            marginRight: '5px',
            color: theme.Cor_base_marca_01,
          }}
        />
      </div>
      <ReactToPrint
        onAfterPrint={() => {
          setIsPrintingState(false);
        }}
        style={{ alignSelf: 'flex-end', margin: 'auto' }}
        trigger={() => <div ref={triggerRef}></div>}
        content={() => componentRef.current}
      />
    </BalanceWrapper>
  );
}

export default AccountBalance;

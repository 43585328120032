import styled from "styled-components";
import Select from 'react-select';
import { Close } from 'styled-icons/evil/Close';
import { ShareBox } from 'styled-icons/remix-fill/ShareBox';

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;


export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
 `;

export const CloseButton = styled(Close)`
    align-self: flex-end;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 2px;
  justify-content: space-between;
  margin-bottom: 30px;

`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
    

    
`;

export const Title = styled.label`
    margin: 0;
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
`;

export const Value = styled.span`
    margin: 0;
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
`;

export const ColoredValue = styled(Value)`
  color: ${props => props.status === 'Ativo' ? props.theme.success : props.theme.danger};
`;

export const ActiveIcon = styled(ShareBox)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.success};
`;

export const NoItemsAvailableLabel = styled.span`

    align-self: 'center';
    text-transform: uppercase;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: ${props => props.theme.brandColor3};
    margin-top: 30px;
    margin-bottom: 30px;
`



import React from "react";
import { parseText } from "utils/functions/text";

import {
  Container,
  Name,
  AccountInformationsWrapper,
  Agency,
  Account,
  LastAccess
} from "./styles";

function AccountBoxSelect({ name, agency, account, personType }) {
  return (
    <Container
      personType={personType}
      id="AccountBoxSelectDiv"
      onClick={() => { }}>
      <Name id="AccountBoxSelectAccountLabel" >{name ? parseText({ text: name, limit: 21 }) : ""}</Name>
      <AccountInformationsWrapper>
        <Agency id="AccountBoxSelectBranchLabel">AG {agency}</Agency>
        <Account id="AccountBoxSelectAccountLabel">CONTA {account}</Account>
      </AccountInformationsWrapper>
    </Container>
  );
}

export default AccountBoxSelect;

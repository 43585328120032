import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DocumentsUpdateService,
  ModalService,
  ToggleService,
  AccountService,
} from 'banking-service';
import { Router, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Home from 'containers/Home';
import TabBar from 'containers/TabBar';
import AccountBalance from 'containers/AccountBalance';
import AccountBalanceDetailed from 'containers/AccountBalanceDetailed';
import InvestmentsBalance from 'containers/InvestmentsBalance';
import InvestmentsBalanceDetailed from 'containers/InvestmentsBalanceDetailed';
import Payments from 'containers/Payments';
import Transfers from 'containers/Transfers';
import Receipts from 'containers/Receipts';
import Token from 'containers/Token';
import CloseAccount from 'containers/CloseAccount';
import ChangePasswords from 'containers/ChangePasswords';
import DocumentsUpdate from 'containers/DocumentsUpdate';
import SecurityAccountBalance from 'containers/SecurityAccountBalance';
import SecurityAccountBalanceDetailed from 'containers/SecurityAccountBalanceDetailed';
import SecurityAccountWithdraw from 'containers/SecurityAccountWithdraw';
import SecurityAccountPayment from 'containers/SecurityAccountPayment';
import Credit from 'containers/Credit';
import Anticipation from 'containers/Anticipation';

import Schedules from 'containers/Anticipation/Schedules';
import AntecipationSuccess from 'containers/Anticipation/Success';
import Simulation from 'containers/Anticipation/Simulation';

import OutstandingPayments from 'containers/OutstandingPayments';
import Operators from 'containers/Operators';
import CNABRequest from 'containers/CNAB/Request';
import CNABConsult from 'containers/CNAB/Consult';
import OfflineStatementRequest from 'containers/OfflineStatement/Request';
import OfflineStatementConsult from 'containers/OfflineStatement/Consult';
import ScheduledOperations from 'containers/ScheduledOperations';
import SummerWarningModal from 'components/SummerWarningModal';
import CashIn from 'containers/CashIn';
import ReportIncome from 'containers/ReportIncome';

import { useModalWithData } from 'components/CustomModal';
import CarnavalModal from 'components/CarnavalModal';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import { resolvePersonType } from 'utils/functions/user';

const PrivateRoute = ({ component, ...options }) => {
  return <Route {...options} component={component} />;
};

function Content({ ...props }) {
  const [hasShowDocumentsUpdateModal, setHasShowDocumentsUpdate] = useState(
    false
  );
  const { selectedAccountIndex } = useSwitchAccountDataContext();

  const [modals, setModals] = useState([]);
  const [modalsCurrentIndex, setModalsCurrentIndex] = useState(-1);

  const [showSummerWarningModal, hideSummerWarningModal] = useModalWithData(
    (modalParams) => () => (
      <SummerWarningModal
        {...modalParams}
        hideModalCallback={hideSummerWarningModal}
      />
    )
  );

  const [carnavalModalContent, hideCarnavalModal] = useModalWithData(
    (modalParams) => () => (
      <CarnavalModal {...modalParams} hideConfirm={hideCarnavalModal} />
    )
  );

  async function handleVerifyUserDocumentsUpdate() {
    const fetchDocumentsUpdateStatus = async () => {
      try {
        const res = await DocumentsUpdateService.fetchDocumentsStatus({});
        if (res.needCadastralUpdate) {
          showSummerWarningModal({
            onConfirmClick: () => {
              props.history.push('/documents-update');
              hideSummerWarningModal();
            },
            lastUpdate: res.lastUpdate,
            differenceInMonths: res.differenceInMonths,
          });
          setHasShowDocumentsUpdate(true);
        }
      } catch (e) {}
    };

    if (!hasShowDocumentsUpdateModal) {
      fetchDocumentsUpdateStatus();
    }
  }

  async function handleVerifyShouldShowModals({ action }) {
    const fetchModals = async () => {
      try {
        setModalsCurrentIndex(-1);
        const res = await ModalService.fetch({});
        let modals = [];
        if (AccountService.account.profile === 'OP') {
          modals = res.filter((e) => e.showForOperators === true);
        } else {
          modals = [...res];
        }
        if (res.length > 0) {
          console.log(
            resolvePersonType({
              taxIdentifier: AccountService.account.taxIdentifier,
            })
          );
          setModals([
            ...modals.filter((e) =>
              e.accountType.includes(
                resolvePersonType({
                  taxIdentifier: AccountService.account.taxIdentifier,
                })
              )
            ),
          ]);
          setModalsCurrentIndex(0);
        }
      } catch (e) {
        setModals([]);
        console.log({ e });
      }
    };
    fetchModals();
  }

  useEffect(() => {
    if (modalsCurrentIndex === -1) return;
    const modal = modals[modalsCurrentIndex];
    if (modals.length > 0) {
      carnavalModalContent({
        title: modal.title,
        body: modal.body,
        buttons: modal.buttons,
        hideModal: () => {
          hideCarnavalModal();
          if (modalsCurrentIndex + 1 < modals.length) {
            setModalsCurrentIndex(modalsCurrentIndex + 1);
          } else {
            //does nothing
          }
        },
        onAnyButtonClick: async ({ action }) => {
          try {
            hideCarnavalModal();
            if (modalsCurrentIndex + 1 < modals.length) {
              setModalsCurrentIndex(modalsCurrentIndex + 1);
            } else {
              //does nothing
            }
            await ModalService.save({ modal: modal.id, action });
          } catch (_) {}
        },
      });
    }
  }, [modalsCurrentIndex]);

  return (
    <Container>
      <PrivateRoute
        exact
        path="/"
        render={(props) => (
          <Home
            {...props}
            verifyUserDocumentsCallback={handleVerifyUserDocumentsUpdate}
            verifyShouldShowModals={() =>
              handleVerifyShouldShowModals({ action: 'changeAccounts' })
            }
          />
        )}
      />
      <PrivateRoute
        path="/account/account-balance"
        component={AccountBalance}
      />
      <PrivateRoute
        path="/account/account-balance-detailed"
        component={AccountBalanceDetailed}
      />

      <PrivateRoute
        path="/security-account/account-balance"
        component={SecurityAccountBalance}
      />

      <PrivateRoute
        path="/security-account/account-balance-detailed"
        component={SecurityAccountBalanceDetailed}
      />

      <PrivateRoute
        path="/security-account/withdraw"
        component={SecurityAccountWithdraw}
      />
      <PrivateRoute
        path="/security-account/payment"
        component={SecurityAccountPayment}
      />

      <PrivateRoute path="/credit/contract-history" component={Credit} />

      <PrivateRoute path="/anticipation/wellcome" component={Anticipation} />
      <PrivateRoute path="/anticipation/schedules" component={Schedules} />
      <PrivateRoute path="/anticipation/simulation" component={Simulation} />
      <PrivateRoute
        path="/anticipation/success"
        component={AntecipationSuccess}
      />

      <PrivateRoute
        path="/outstanding-payments"
        component={OutstandingPayments}
      />

      <PrivateRoute path="/cashin" component={CashIn} />

      <PrivateRoute
        path="/investments/balance"
        component={InvestmentsBalance}
      />
      <PrivateRoute
        path="/investments/balance-detailed"
        component={InvestmentsBalanceDetailed}
      />

      <PrivateRoute path="/receipts" component={Receipts} />

      <PrivateRoute path="/payments" component={Payments} />
      <PrivateRoute path="/transfers" component={Transfers} />

      <PrivateRoute
        path="/scheduled-operations"
        component={ScheduledOperations}
      />

      <PrivateRoute path="/services/operators" component={Operators} />
      <PrivateRoute path="/services/token" component={Token} />

      <PrivateRoute path="/services/close-account" component={CloseAccount} />
      <PrivateRoute path="/services/report-income" component={ReportIncome} />

      <PrivateRoute path="/change-password" component={ChangePasswords} />

      <PrivateRoute path="/documents-update" component={DocumentsUpdate} />

      <PrivateRoute path="/files/request" component={CNABRequest} />

      <PrivateRoute path="/files/consult" component={CNABConsult} />

      <PrivateRoute
        path="/files/offline-statement/request"
        component={OfflineStatementRequest}
      />

      <PrivateRoute
        path="/files/offline-statement/consult"
        component={OfflineStatementConsult}
      />
    </Container>
  );
}

export default withRouter(Content);

import React, { useState, useEffect } from 'react';
import { AnticipationService } from 'banking-service';
import CarnavalModal from 'components/CarnavalModal';
import Checkbox from 'components/Checkbox';
import { useModalWithData } from 'components/CustomModal';

import {
  Container,
  ContentWrapper,
  Title,
  Text,
  NoItemsAvailableLabel,
  Wrapper,
  CheckText,
  BtnContinue,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import Button from 'components/Button';

function Anticipation({
  section = 'Antecipação de Recebíveis',
  name = 'Bem vindo',
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const wellcomeIllustration = require('../../assets/images/anticipation_receivables/receivable_wellcome.png');

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [carnavalModalContent, hideCarnavalModal] = useModalWithData(
    (modalParams) => () => (
      <CarnavalModal {...modalParams} hideConfirm={hideCarnavalModal} />
    )
  );

  const handleClick = async () => {
    if (status) {
      props.history.push('/anticipation/schedules');
      return;
    }
    if (!isChecked) {
      return;
    }
    setIsLoading(true);
    try {
      await AnticipationService.authorize({});
      setIsLoading(false);
      alert.success('Autorização efetuada com sucesso!');
      props.history.push('/anticipation/schedules');
    } catch (e) {
      alert.error(e);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchOptinStatus = async () => {
      setIsLoading(true);
      setError('');
      try {
        const res = await AnticipationService.verify({});
        if (res.hasOptIn === true) {
          props.history.push('/anticipation/schedules');
        }
        setStatus(res.hasOptIn);
      } catch (e) {
        console.log({ e });

        setStatus(null);
      }
      setIsLoading(false);
    };

    fetchOptinStatus();
  }, []);

  const Item = () => (
    <Wrapper style={{ padding: '40px' }}>
      <Title>Seja bem vindo a Antecipação de Recebíveis</Title>
      <div style={{ display: 'flex', padding: '20px 0' }}>
        <img
          src={wellcomeIllustration}
          style={{
            width: '30.352vw',
            height: 'auto',
            maxWidth: '460px',
          }}
          alt="Antecipação de recebíveis - Bem Vindo"
        />
        <Wrapper style={{ marginLeft: '24px', alignItems: 'flex-start' }}>
          <Text>
            <strong>
              Já imaginou adiantar o valor das suas vendas e receber em um dia?
            </strong>
          </Text>
          <Text>
            Antecipação de recebíveis é um recurso financeiro que permite o
            adiantamento do recebimento de valores futuros, sendo uma ótima
            maneira de organizar e estabilizar o fluxo do caixa da sua empresa.
            <br />
            Por não se tratar de um empréstimo, é a saída ideal para manter o
            caixa do seu negócio sempre saudável e positivo.
          </Text>
          <Text>
            Antecipe seus recebíveis sem burocracia e com
            <br />
            segurança e obtenha capital de giro para a sua empresa.
          </Text>
          {status === false && (
            <div style={{ display: 'flex' }}>
              <Checkbox
                checked={isChecked}
                fullInput
                hover
                onChecked={(chkd) => setIsChecked(chkd)}
              />
              <CheckText onClick={() => setIsChecked(!isChecked)}>
                Eu Autorizo o Banco Topázio e Empresas do mesmo Grupo Econômico
                a visualizarem as agendas de recebíveis de cartões de todas as
                credenciadoras e me enviar as melhores ofertas.
              </CheckText>
            </div>
          )}
          <BtnContinue onClick={handleClick} disabled={!status && !isChecked}>
            continuar
          </BtnContinue>
        </Wrapper>
      </div>
    </Wrapper>
  );

  if (isLoading) {
    return <LoadingSpinner isLoading={isLoading}></LoadingSpinner>;
  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          {status === null ? (
            <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
              Ops! Tivemos um problema ao carregar as informações. Tente
              novamente em instantes.
            </NoItemsAvailableLabel>
          ) : (
            <Item />
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Anticipation;

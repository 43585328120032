import React from 'react';

import { Container } from './styles';

function RadioButton({ value, group, label, onSelect, checked, style, labelStyle, isDisabled, ...props }) {
    return (
        <Container isDisabled={isDisabled} style={style} onClick={() => onSelect(group, value)}>
            <label class="container">
                <input type="radio" checked={checked} name={group} />
                <span class="checkmark"></span>
                <span style={labelStyle || { fontFamily: '"SourceSansPro", Arial, sans-serif' }}>{label}</span>
            </label>
        </Container>
    )
}


export default RadioButton;
import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;

  .DateInput_input__focused {
    border-bottom: 2px solid ${(props) => props.theme.Cor_base_marca_01} !important;
  }

  .CalendarDay__selected_span,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${(props) =>
      lighten(0.1, props.theme.Cor_base_marca_01)} !important;
    border: 1px double
      ${(props) => lighten(0.1, props.theme.Cor_base_marca_01)}} !important;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${(props) => props.theme.Cor_base_marca_01} !important;
    border: 1px double ${(props) => props.theme.Cor_base_marca_01} !important;
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${(props) => lighten(0.4, props.theme.Cor_base_marca_01)};
    border: 1px solid ${(props) => lighten(0.1, props.theme.Cor_base_marca_01)};
    color: ${(props) => props.theme.Cor_auxiliar_02};
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  width: 100px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.Cor_base_marca_01};
  border-radius: 20px;
  background-color: ${(props) => props.theme.Cor_base_marca_01};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${(props) => props.theme.Cor_auxiliar_01};
  padding-top: 12px;
  padding-bottom: 8px;
  margin-left: 15px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 12px 8px 12px;
  outline: none;

  :disabled {
    opacity: 0.5;
  }
`;

import "array-flat-polyfill";

const parseInvestments = ({ data }) => {

    if (!data || typeof data !== "object") return [];

    if (!data.products) return [];


    return [...data.products];

}

export { parseInvestments };





import styled from 'styled-components';
import Select from 'react-select';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

export const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'initial')};
`;

export const Title = styled.label`
  margin: 0;
  font-family: 'MontserratMedium';
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.brandColor3};
  padding: 5px 0;
`;

export const Value = styled.span`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.brandColor3};
  padding: 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  padding: 10px 0px;
  border-radius: 2px;
  justify-content: space-between;
  border-bottom: ${(props) => (props.isItem ? '1px solid gray' : 'none')};
`;

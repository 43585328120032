// Render Prop
import React, { Fragment, useState, useEffect } from 'react';
import { AccountService } from 'banking-service';
import moment from 'moment';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { verifyUserPermission } from 'utils/functions/user';
import RadioButton from 'components/RadioButton';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CustomDatePicker from 'components/CustomDatePicker';
import { revertBrazilianCurrencyToFloat } from 'utils/functions/currency';
import { parseBanks } from 'utils/transformers/banks';
import { parsePurposes } from 'utils/transformers/purposes';
import { parseBeneficiaries } from 'utils/transformers/beneficiaries';
import {
  initialExternalTransferData,
  initialExternalTransferErrors,
  initialExternalTransferTouched
} from 'containers/Transfers/initialValues';
import { useConfigContext } from 'providers/client-config-provider';
import { useTheme } from 'styled-components';

import { DisplayFormikState } from './helper';

import * as Yup from 'yup';

import {
  BeneficiaryInput,
  TogglesWrapper,
  VerticalDivisor,
  AccountInformationsWrapper,
  BranchInput,
  AccountInput,
  DigitInput,
  DateAndValueWrapper,
  AmountInput,
  WeekendsAndHolidaysWarningLabel,
  CustomAutocomplete,
  CommentInput,
  TaxIdentifierInput
} from './styles';

const branchMask = [/\d/, /\d/, /\d/, /\d/];

const accountMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const digitMask = [/\d/];

const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/
];

const cpfMask2 = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

function k(i, callback, data) {
  var v = i.target.value.replace(/\D/g, '');
  //if (v > 25000000) v = 25000000;
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  i.value = v;
  callback({ values: { ...data, amount: `R$ ${i.value}` } });
  //callback('amount', `R$ ${i.value}`);
}

const TransferSchema = Yup.object().shape({
  branch: Yup.string().required('Required'),
  account: Yup.string().required('Required'),
  digit: Yup.string().required('Required'),
  amount: Yup.string().required('Required'),
  name: Yup.string().required('Required')
});

function ExternalTransferForm({
  data,
  banks,
  purposes,
  beneficiaries,
  formTouched,
  formErrors,
  handleFormChanges,
  handleFormErrorsChanges,
  handleFormTouchedChanges,
  isSchedulingEnabled,
  handleFavoritesClick
}) {
  const { clientConfig } = useConfigContext();
  const theme = useTheme();

  function handleInputChange(inputValue, action, handleFormChanges) {
    if (
      action.action !== 'input-blur' &&
      action.action !== 'menu-close' &&
      action.action !== 'set-value'
    ) {
      if (data.hasSelected) {
        handleFormChanges({
          values: {
            ...data,
            hasSelected: false,
            branch: '',
            account: '',
            digit: '',
            beneficiary: { name: inputValue },
            bank: '',
            taxIdentifier: ''
          }
        });
      } else {
        handleFormChanges({
          values: {
            ...data,
            beneficiary: { name: inputValue }
          }
        });
      }

      handleFormErrorsChanges({ errors: { ...initialExternalTransferErrors } });
      handleFormTouchedChanges({
        touched: { ...initialExternalTransferTouched }
      });
    }
  }

  const [parsedBanks, setParsedBanks] = useState([]);
  useEffect(() => {
    setParsedBanks(parseBanks({ data: banks }));
  }, [banks]);

  const [parsedBeneficiaries, setParsedBeneficiaries] = useState([]);
  useEffect(() => {
    setParsedBeneficiaries(
      parseBeneficiaries({
        data: beneficiaries,
        filter: { notTopazio: true, ownership: data.ownership }
      })
    );
  }, [beneficiaries, data.ownership]);

  const [parsedPurposes, setParsedPurposes] = useState([]);
  useEffect(() => {
    setParsedPurposes(parsePurposes({ data: purposes }));
  }, [purposes]);

  useEffect(() => {
    const value = data.taxIdentifier;
    if (value.length === 11 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cpfMask2, { guid: false });
      setMask(cpfMask);
      handleFormChanges({
        values: { ...data, taxIdentifier: conformedNumber.conformedValue }
      });
    } else if (value.length === 14 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cnpjMask, { guid: false });
      setMask(cnpjMask);
      handleFormChanges({
        values: { ...data, taxIdentifier: conformedNumber.conformedValue }
      });
    } else {
    }
  }, [data.taxIdentifier]);

  const [mask, setMask] = useState(false);

  function handleChangeTaxIdentifier(event) {
    const { value } = event.target;
    if (value.length === 11 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cpfMask2, { guid: false });
      setMask(cpfMask);
      handleFormChanges({
        values: { ...data, taxIdentifier: conformedNumber.conformedValue }
      });
      handleFormTouchedChanges({
        touched: { ...formTouched, taxIdentifier: true }
      });
      if (
        conformedNumber.conformedValue.length !== 14 &&
        conformedNumber.conformedValue.length !== 18
      ) {
        handleFormErrorsChanges({
          errors: { ...formErrors, taxIdentifier: true }
        });
      } else {
        handleFormErrorsChanges({
          errors: { ...formErrors, taxIdentifier: false }
        });
      }
    } else if (value.length === 14 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cnpjMask, { guid: false });
      setMask(cnpjMask);
      handleFormChanges({
        values: { ...data, taxIdentifier: conformedNumber.conformedValue }
      });
      handleFormTouchedChanges({
        touched: { ...formTouched, taxIdentifier: true }
      });
      if (
        conformedNumber.conformedValue.length !== 14 &&
        conformedNumber.conformedValue.length !== 18
      ) {
        handleFormErrorsChanges({
          errors: { ...formErrors, taxIdentifier: true }
        });
      } else {
        handleFormErrorsChanges({
          errors: { ...formErrors, taxIdentifier: false }
        });
      }
    } else {
      //define which mask to use
      if (value.replace(/\D/g, '').length > 11) {
        setMask(cnpjMask);
      } else if (value === '') {
        setMask(false);
      } else {
        setMask(cpfMask);
      }
      handleFormChanges({ values: { ...data, taxIdentifier: value } });
      handleFormTouchedChanges({
        touched: { ...formTouched, taxIdentifier: true }
      });
      if (value.length !== 14 && value.length !== 18) {
        handleFormErrorsChanges({
          errors: { ...formErrors, taxIdentifier: true }
        });
      } else {
        handleFormErrorsChanges({
          errors: { ...formErrors, taxIdentifier: false }
        });
      }
    }
  }

  return (
    <form style={{ display: 'flex', flexDirection: 'column' }}>
      <TogglesWrapper>
        <RadioButton
          onSelect={(group, value) => {
            handleFormChanges({ values: { ...data, [group]: value } });
          }}
          checked={data.operationType === 'ted'}
          label="TED"
          value={'ted'}
          group="operationType"
        />
        <VerticalDivisor />
        <RadioButton
          isDisabled={
            !verifyUserPermission({
              permission: 'TRANSFERS',
              arrOfPermissions: AccountService.account.permissions
            })
          }
          onSelect={(group, value) => {
            handleFormChanges({
              values: {
                ...initialExternalTransferData,
                operationType: data.operationType,
                [group]: value,
                ownershipLabel: 'Mesma Titularidade'
              }
            });
            handleFormErrorsChanges({
              errors: { ...initialExternalTransferErrors }
            });
            handleFormTouchedChanges({
              touched: { ...initialExternalTransferTouched }
            });
          }}
          checked={data.ownership === 'sameOwner'}
          label="Mesma titularidade"
          value={'sameOwner'}
          group="ownership"
        />
        <RadioButton
          isDisabled={
            !verifyUserPermission({
              permission: 'TRANSFERS',
              arrOfPermissions: AccountService.account.permissions
            })
          }
          onSelect={(group, value) => {
            handleFormChanges({
              values: {
                ...initialExternalTransferData,
                operationType: data.operationType,
                [group]: value,
                ownershipLabel: 'Outra titularidade'
              }
            });
            handleFormErrorsChanges({
              errors: { ...initialExternalTransferErrors }
            });
            handleFormTouchedChanges({
              touched: { ...initialExternalTransferTouched }
            });
          }}
          checked={data.ownership === 'other'}
          label="Outra Titularidade"
          value={'other'}
          group="ownership"
        />
      </TogglesWrapper>
      {data.hasSelected ||
      (parsedBeneficiaries.length === 0 && data.ownership === 'sameOwner') ? (
        <CustomAutocomplete
          styles={{
            option: (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              textTransform: 'uppercase',
              backgroundColor: isDisabled ? undefined : (
                isSelected ? theme.Cor_base_marca_01 : (
                  isFocused ? theme.Cor_base_marca_fade_02 : undefined
                )
              ),
              ':active': {
                ...provided[':active'],
                backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
              }
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              textTransform: 'uppercase'
            })
          }}
          isClearable
          value={{
            value: { ...data.beneficiary },
            label:
              parsedBeneficiaries.length === 0 && data.ownership === 'sameOwner'
                ? AccountService.account.name
                : data.beneficiary.name
          }}
          isDisabled={
            parsedBeneficiaries.length === 0 && data.ownership === 'sameOwner'
          }
          id="beneficiaryInputId"
          onInputChange={(inputValue, action) => {
            handleInputChange(inputValue, action, handleFormChanges);
          }}
          noOptionsMessage={({ inputValue }) =>
            `Nova transferência para ${inputValue}`
          }
          placeholder={'Favorecido'}
          name="beneficiary"
          options={parsedBeneficiaries}
          onChange={val => {
            if (!val) {
              handleFormChanges({
                values: {
                  ...initialExternalTransferData,
                  ownership: data.ownership
                }
              });
            } else
              handleFormChanges({
                values: {
                  ...data,
                  beneficiary: val.value,
                  hasSelected: true,
                  branch: val.value.branch.toString(),
                  account: val.value.account.toString().slice(0, -1),
                  digit: val.value.account.toString().slice(-1),
                  bank: val.value.bankCode,
                  bankDescription: `${val.value.bankCode} - ${val.value.bankDescription}`,
                  taxIdentifier: val.value.taxIdentifier.replace(/\D/g, '')
                }
              });
          }}

          //hasErrors={touched.beneficiary && errors.beneficiary}
        />
      ) : (
        <CustomAutocomplete
          styles={{
            option: (provided, { isDisabled, isFocused, isSelected }) => ({
              ...provided,
              textTransform: 'uppercase',
              backgroundColor: isDisabled ? undefined : (
                isSelected ? theme.Cor_base_marca_01 : (
                  isFocused ? theme.Cor_base_marca_fade_02 : undefined
                )
              ),
              ':active': {
                ...provided[':active'],
                backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
              }
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              textTransform: 'uppercase'
            })
          }}
          value={null}
          id="beneficiaryInputId"
          inputValue={data.beneficiary.name}
          onInputChange={(inputValue, action) => {
            handleInputChange(inputValue, action, handleFormChanges);
          }}
          noOptionsMessage={({ inputValue }) =>
            `Nova transferência para ${inputValue}`
          }
          placeholder={
            data.ownership === 'sameOwner'
              ? `Selecione uma conta salva`
              : 'Favorecido'
          }
          name="beneficiary"
          options={parsedBeneficiaries}
          onChange={val => {
            handleFormChanges({
              values: {
                ...data,
                beneficiary: val.value,
                hasSelected: true,
                branch: val.value.branch.toString(),
                account: val.value.account.toString().slice(0, -1),
                digit: val.value.account.toString().slice(-1),
                bank: val.value.bankCode,
                bankDescription: `${val.value.bankCode} - ${val.value.bankDescription}`,
                taxIdentifier: val.value.taxIdentifier.replace(/\D/g, '')
              }
            });
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, beneficiaryName: true }
            });
            if (
              data.ownership === 'other' &&
              (!data.beneficiary.name || data.beneficiary.name === '')
            ) {
              handleFormErrorsChanges({
                errors: { ...formErrors, beneficiaryName: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, beneficiaryName: false }
              });
            }
          }}
          hasErrors={formTouched.beneficiaryName && formErrors.beneficiaryName}
        />
      )}
       <button
        onClick={e => {
          e.preventDefault();
          handleFavoritesClick();
        }}
        style={{
          alignSelf: 'flex-end',
          border: 'none',
          outline: 'none',
          color: theme.Cor_base_marca_01,
          marginTop: '10px',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
      >
        Gerenciar Favoritos
      </button> 
      <br />
      <br />

      {data.ownership === 'other' && (
        <Fragment>
          <MaskedInput
            mask={mask}
            guide={false}
            onChange={handleChangeTaxIdentifier}
            onBlur={() => {
              handleFormTouchedChanges({
                touched: { ...formTouched, taxIdentifier: true }
              });
              if (
                data.taxIdentifier.length !== 14 &&
                data.taxIdentifier.length !== 18
              ) {
                handleFormErrorsChanges({
                  errors: { ...formErrors, taxIdentifier: true }
                });
              } else {
                handleFormErrorsChanges({
                  errors: { ...formErrors, taxIdentifier: false }
                });
              }
            }}
            hasErrors={formTouched.taxIdentifier && formErrors.taxIdentifier}
            placeholder="CPF/CPNJ"
            render={(ref, props) => (
              <TaxIdentifierInput
                value={data.taxIdentifier}
                id="taxIdentifierInputId"
                ref={Input => ref(Input)}
                {...props}
              />
            )}
          />
          <br />
          <br />
        </Fragment>
      )}

      <CustomAutocomplete
        isDisabled={data.hasSelected}
        value={parsedBanks.filter(e => e.value === data.bank)}
        placeholder="Banco..."
        name="bank"
        noOptionsMessage={({ inputValue }) => `Nenhum banco encontrado`}
        options={parsedBanks}
        onChange={val => {
          handleFormChanges({
            values: {
              ...data,
              bank: val.value,
              bankDescription: `${val.value} - ${val.label}`
            }
          });
        }}
        onBlur={() => {
          handleFormTouchedChanges({ touched: { ...formTouched, bank: true } });
          if (data.bank === '') {
            handleFormErrorsChanges({ errors: { ...formErrors, bank: true } });
          } else {
            handleFormErrorsChanges({ errors: { ...formErrors, bank: false } });
          }
        }}
        hasErrors={formTouched.bank && formErrors.bank}
        styles={{
          option: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            textTransform: 'uppercase',
            backgroundColor: isDisabled ? undefined : (
              isSelected ? theme.Cor_base_marca_01 : (
                isFocused ? theme.Cor_base_marca_fade_02 : undefined
              )
            ),
            ':active': {
              ...provided[':active'],
              backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
            }
          })
        }}
      />

      <AccountInformationsWrapper>
        <BranchInput
          disabled={data.hasSelected}
          value={data.branch}
          id="branchInputId"
          hasErrors={formErrors.branch && formTouched.branch}
          placeholder="Agência (s/dígito) *"
          guide={false}
          mask={branchMask}
          type="text"
          onChange={e => {
            handleFormChanges({ values: { ...data, branch: e.target.value } });
            handleFormTouchedChanges({
              touched: { ...formTouched, branch: true }
            });
            if (e.target.value === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: false }
              });
            }
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, branch: true }
            });
            if (data.branch === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, branch: false }
              });
            }
          }}
        />
        <AccountInput
          disabled={data.hasSelected}
          value={data.account}
          id="accountInputId"
          hasErrors={formErrors.account && formTouched.account}
          placeholder="Conta *"
          guide={false}
          mask={accountMask}
          name="account"
          type="text"
          onChange={e => {
            handleFormChanges({ values: { ...data, account: e.target.value } });
            handleFormTouchedChanges({
              touched: { ...formTouched, account: true }
            });
            if (e.target.value === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: false }
              });
            }
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, account: true }
            });
            if (data.account === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, account: false }
              });
            }
          }}
        />
        <DigitInput
          disabled={data.hasSelected}
          value={data.digit}
          id="digitInputId"
          hasErrors={formErrors.digit && formTouched.digit}
          placeholder="Dígito *"
          guide={false}
          mask={digitMask}
          name="digit"
          type="text"
          onChange={e => {
            handleFormChanges({ values: { ...data, digit: e.target.value } });
            handleFormTouchedChanges({
              touched: { ...formTouched, digit: true }
            });
            if (e.target.value === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: false }
              });
            }
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, digit: true }
            });
            if (data.digit === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, digit: false }
              });
            }
          }}
        />
      </AccountInformationsWrapper>
      <CustomAutocomplete
        value={parsedPurposes.filter(e => e.value === data.purpose)}
        placeholder="Finalidade"
        name="purpose"
        options={parsedPurposes}
        onChange={val => {
          handleFormChanges({ values: { ...data, purpose: val.value } });
        }}
        onBlur={() => {
          handleFormTouchedChanges({
            touched: { ...formTouched, purpose: true }
          });
          if (data.purpose === '') {
            handleFormErrorsChanges({
              errors: { ...formErrors, purpose: true }
            });
          } else {
            handleFormErrorsChanges({
              errors: { ...formErrors, purpose: false }
            });
          }
        }}
        hasErrors={formTouched.purpose && formErrors.purpose}
        styles={{
          option: (provided, { isDisabled, isFocused, isSelected }) => ({
            ...provided,
            textTransform: 'uppercase',
            backgroundColor: isDisabled ? undefined : (
              isSelected ? theme.Cor_base_marca_01 : (
                isFocused ? theme.Cor_base_marca_fade_02 : undefined
              )
            ),
            ':active': {
              ...provided[':active'],
              backgroundColor: isDisabled ? undefined : theme.Cor_base_marca_01,
            }
          })
        }}
      />
      <DateAndValueWrapper>
        <CustomDatePicker
          id="datePickerId"
          date={data.date}
          onChange={val =>
            handleFormChanges({ values: { ...data, date: val } })
          }
          disAllowPastDates
          disAllowFutureDates={!isSchedulingEnabled}
          disAllowWeekends
        />
        <AmountInput
          id="amountInputId"
          type="text"
          name="amount"
          hasErrors={
            (formErrors.amount && formTouched.amount) ||
            (data.amount === 'R$ 0,00' && formTouched.amount)
          }
          value={data.amount}
          onChange={e => {
            handleFormChanges({ values: { ...data, amount: e.target.value } });
          }}
          onBlur={() => {
            handleFormTouchedChanges({
              touched: { ...formTouched, amount: true }
            });
            if (data.amount === '') {
              handleFormErrorsChanges({
                errors: { ...formErrors, amount: true }
              });
            } else {
              handleFormErrorsChanges({
                errors: { ...formErrors, amount: false }
              });
            }
          }}
          onKeyUp={e => k(e, handleFormChanges, data)}
          placeholder={'Valor *'}
        />
      </DateAndValueWrapper>
      <WeekendsAndHolidaysWarningLabel>
        * Transferências agendadas para finais de semana e feriados serão
        processadas apenas no próximo dia útil
      </WeekendsAndHolidaysWarningLabel>
      <CommentInput
        value={data.comment}
        id="commentInputId"
        hasErrors={formErrors.comment && formTouched.comment}
        placeholder="Motivo *"
        guide={false}
        mask={false}
        type="text"
        maxLength={50}
        onChange={e => {
          handleFormChanges({ values: { ...data, comment: e.target.value } });
          handleFormTouchedChanges({
            touched: { ...formTouched, comment: true }
          });
          if (e.target.value === '') {
            handleFormErrorsChanges({
              errors: { ...formErrors, comment: true }
            });
          } else {
            handleFormErrorsChanges({
              errors: { ...formErrors, comment: false }
            });
          }
        }}
        onBlur={() => {
          handleFormTouchedChanges({
            touched: { ...formTouched, comment: true }
          });
          if (data.comment === '') {
            handleFormErrorsChanges({
              errors: { ...formErrors, comment: true }
            });
          } else {
            handleFormErrorsChanges({
              errors: { ...formErrors, comment: false }
            });
          }
        }}
      />
      {(!data || (data.comment !== null && data.comment !== undefined)) && (
        <WeekendsAndHolidaysWarningLabel style={{ marginLeft: '8px' }}>
          {data.comment.length}/50
        </WeekendsAndHolidaysWarningLabel>
      )}
    </form>
  );
}

export default ExternalTransferForm;

import React, { useContext } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';

function LoadingSpinner({ isLoading, text = '', children, backgroundColor }) {
  const themeContext = useContext(ThemeContext);
  return (
    <LoadingOverlay
      text={text}
      active={isLoading}
      styles={{
        overlay: base => ({
          ...base,
          background: backgroundColor || base.background
        }),
        wrapper: base => ({
          ...base,
          width: '100%',
          height: '100%',
          overflow: isLoading ? 'hidden' : 'auto'
        })
      }}
      spinner={
        <div id="loadingDiv">
          <PulseLoader color={themeContext.Cor_base_marca_01} />{' '}
        </div>
      }
    >
      {children}
    </LoadingOverlay>
  );
}

export default LoadingSpinner;

import React, { useState, useEffect } from 'react';
import { DocumentsUpdateService, BankService } from 'banking-service';
import { UnmountClosed } from 'react-collapse';
import { withRouter } from 'react-router-dom';
import Checkbox from 'components/Checkbox';
import TextInput from '../components/TextInput';

import moment from 'moment';
import styled from 'styled-components';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import {
  HeaderTitle,
  RowWrapper,
  ColumnWrapper,
  FieldLabel,
  IsOpenedIcon,
  IsNotOpenedIcon,
  NameTitle
} from './styles';

import LocalStatusComponent from '../components/LocalStatusComponent';

import { Delete } from 'styled-icons/material/Delete';
import { AddCircle } from 'styled-icons/material/AddCircle';
import { Cancel } from 'styled-icons/material/Cancel';
import { CheckCircle } from 'styled-icons/boxicons-solid/CheckCircle';
import { Alert } from 'styled-icons/octicons/Alert';

import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import RadioButton from '../components/RadioButton';
import SelectInput from '../components/SelectInput';
import MaskedInput from '../components/MaskedInput';
import Dropzone from '../components/Dropzone';
import { Text } from '../components/common';

import {
  cpfMask,
  dateMask,
  phoneMask,
  cepMask,
  cnpjMask
} from '../components/MaskedInput/masks';
import {
  mariageStatus,
  referenceTypes,
  productTypes,
  documentTypes,
  brazilianStates
} from '../options';
import {
  initialFormData,
  initialFormTouched,
  initialFormErrors,
  initialFormFiles
} from './initialData';

import {
  hasToUpdateAndHasCERButHasnTouched,
  hasToUpdateAndHasCNHButHasnTouched,
  hasTouchedFieldButHasnAddedFile,
  hasToSendCERAndHasntAddedDocument,
  hasToSendCNHAndHasntAddedDocument,
  hasAtLeastOneReference,
  hasAtLeastOneDocument
} from '../utils';

import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

export const CancelIcon = styled(Delete)`
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: ${props => props.theme.danger};
`;

export const AddIcon = styled(AddCircle)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

export const UnupdatedIcon = styled(Cancel)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.danger};
`;

export const RevisionIcon = styled(Alert)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.alert};
`;

export const UpdatedIcon = styled(CheckCircle)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

function FieldWrapper({
  isDisabled = false,
  fieldWidth = '50px',
  label,
  children,
  style,
  ...props
}) {
  return (
    <ColumnWrapper
      isDisabled={isDisabled}
      style={{
        alignItems: 'flex-start',
        marginRight: '80px',
        marginTop: '30px',
        ...style
      }}
    >
      <FieldLabel style={{ alignSelf: 'flex-start' }}>{label}</FieldLabel>
      {children
        ? React.cloneElement(children, {
            disabled: isDisabled,
            isDisabled
          })
        : null}
    </ColumnWrapper>
  );
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

function DocumentsUpdatePF({
  banks,
  initialData,
  index,
  isEditingMode,
  crudStatus,
  handleRemovePF,
  documentsStatus,
  formData,
  setFormData,
  formTouched,
  setFormTouched,
  isOpened,
  setFormIsOpened,
  formErrors,
  setFormErrors,
  formFiles,
  setFormFiles,
  personType,
  buttonDisability,
  isRemoveDisabled,
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [countries, setCountries] = useState([]);
  const fetchCountries = async () => {
    try {
      const res = await DocumentsUpdateService.fetchCountries();
      const parsedRes = res.map(e => ({
        label: e.name,
        value: e
      }));
      setCountries(parsedRes);
      return parsedRes;
    } catch (e) {
      setError(e.error);
    }
  };

  const loadCBOOptions = async (inputValue, callback) => {
    const res = await DocumentsUpdateService.fetchCBOs({ query: inputValue });
    callback(
      res.map(e => ({
        value: { ...e, familyTitle: formData.cbo.value.familyTitle },
        label: e.name
      }))
    );
  };

  const loadCities = async (inputValue, callback, uf) => {
    const res = await DocumentsUpdateService.fetchCities({
      uf,
      query: inputValue
    });
    callback(
      res.map(e => ({ value: { id: e.id, city: e.city }, label: e.city }))
    );
  };

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
    setFormTouched({ ...formTouched, [name]: true });

    if (name === 'complement') {
      setFormErrors({ ...formErrors, [name]: false });
    } else if (value === '' || (name === 'phoneNumber' && value === '() ')) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  }

  function handleSelectChange({ name, value }) {
    if (name === 'department') {
      setFormData({ ...formData, [name]: value, city: null });
    } else if (name === 'birthState') {
      setFormData({ ...formData, [name]: value, birthCity: null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setFormTouched({ ...formTouched, [name]: true });
    if (value === null) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  }

  function handleToggleChange({ name, value }) {
    setFormData({ ...formData, [name]: value });
    setFormTouched({ ...formTouched, [name]: true });
    if (value === null) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  }

  function handleChangeReferences({ index, field, value }) {
    setFormData({
      ...formData,
      references: formData.references.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            data: { ...value },
            touched: {
              ...e.touched,
              [field]: true
            },
            errors: {
              [field]: value[field] === '' || value[field] === null
            }
          };
        } else {
          return { ...e };
        }
      })
    });
  }

  function handleRemoveReference({ index }) {
    setFormData({
      ...formData,
      references: formData.references.filter((e, i) => i !== index)
    });
  }

  function handleChangeDocuments({ index, field, value }) {
    setFormData({
      ...formData,
      documents: formData.documents.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            data: { ...value },
            touched: {
              ...e.touched,
              [field]: true
            },
            errors: {
              [field]: value[field] === '' || value[field] === null
            }
          };
        } else {
          return { ...e };
        }
      })
    });
  }

  function handleRemoveDocuments({ index }) {
    setFormData({
      ...formData,
      documents: formData.documents.filter((e, i) => i !== index)
    });
  }

  const [warningMessage, setWarningMessage] = useState('');
  useEffect(() => {
    if (hasToUpdateAndHasCERButHasnTouched({ data: formData })) {
      setWarningMessage(
        '* Certifique-se de atualizar os dados do seu Comprovante de Residência (o mais atual possível)'
      );
    } else if (hasToUpdateAndHasCNHButHasnTouched({ data: formData })) {
      setWarningMessage('* Certifique-se de atualizar os dados da sua CNH');
    } else if (hasToSendCERAndHasntAddedDocument({ data: formData })) {
      setWarningMessage(
        '* Você deve adicionar um documento do tipo Comprovante de Residencia'
      );
    } else if (hasToSendCNHAndHasntAddedDocument({ data: formData })) {
      setWarningMessage('* Você deve adicionar um documento do tipo CNH');
    } else {
      setWarningMessage('');
    }
  }, [formData.documents]);

  const [warningMessageReferences, setWarningMessageReferences] = useState('');
  useEffect(() => {
    if (!hasAtLeastOneReference({ data: formData })) {
      setWarningMessageReferences(
        '* Você deve adicionar ao menos uma referência'
      );
    } else {
      setWarningMessageReferences('');
    }
  }, [formData.references]);

  const [warningMessageFiles, setWarningMessageFiles] = useState('');
  useEffect(() => {
    if (!hasAtLeastOneDocument({ data: formData })) {
      setWarningMessageFiles('* Você deve adicionar ao menos um documento');
    } else if (
      hasTouchedFieldButHasnAddedFile({ data: formData, files: formFiles })
    ) {
      setWarningMessageFiles(
        '* Verifique se você adicionou um comprovante para cada documento alterado ou adicionado'
      );
    } else {
      setWarningMessageFiles('');
    }
  }, [formData.documents, formFiles]);

  return (
    <ColumnWrapper
      id={`topContentId${index}`}
      style={{
        border: `1px solid ${theme.brandColor5}`,
        backgroundColor: theme.brandColor2,
        alignItems: 'flex-start',
        padding: personType === 'PF' ? '20px 0px' : '0px 0px',
        width: '100%'
      }}
    >
      <RowWrapper
        style={{
          height: '50px',
          width: '100%',
          flexWrap: 'nowrap',
          justifyContent: 'space-between'
        }}
      >
        <NameTitle
          style={{
            marginLeft: '20px',
            textDecoration: crudStatus === 'D' ? 'line-through' : 'none'
          }}
        >
          {formData.name}
        </NameTitle>
        <br />
        <RowWrapper>
          <RowWrapper style={{ flexWrap: 'nowrap' }}>
            {isEditingMode && (
              <LocalStatusComponent
                buttonDisability={buttonDisability}
                isEditingMode={isEditingMode}
                crudStatus={crudStatus}
              />
            )}
            {isOpened && personType === 'PJ' && crudStatus !== 'D' && (
              <IsOpenedIcon
                onClick={() => {
                  setFormIsOpened(!isOpened);
                }}
                style={{ marginRight: '50px' }}
              />
            )}
            {!isOpened && personType === 'PJ' && crudStatus !== 'D' && (
              <IsNotOpenedIcon
                onClick={() => {
                  setFormIsOpened(!isOpened);
                }}
                style={{ marginRight: '50px' }}
              />
            )}
          </RowWrapper>
        </RowWrapper>
      </RowWrapper>

      <UnmountClosed
        style={{ width: '100%' }}
        isOpened={personType === 'PF' ? true : isOpened}
      >
        <ColumnWrapper style={{ padding: '0px 25px' }}>
          <RowWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'NOME'}>
              <TextInput
                style={{
                  width: '350px'
                }}
                name={'name'}
                hasErrors={formErrors.name}
                value={formData.name}
                onChange={handleInputChange}
              />
            </FieldWrapper>
            <FieldWrapper label={'SEXO'}>
              <RowWrapper style={{ margin: '8px 0px' }}>
                <RadioButton
                  isDisabled={!isEditingMode}
                  name={'genderCode'}
                  checked={formData.genderCode === '1'}
                  value={'1'}
                  group="genderCode"
                  label="Feminino"
                  onSelect={() =>
                    handleToggleChange({ name: 'genderCode', value: '1' })
                  }
                />
                <RadioButton
                  isDisabled={!isEditingMode}
                  checked={formData.genderCode === '2'}
                  value={'2'}
                  group="genderCode"
                  label="Masculino"
                  onSelect={() =>
                    handleToggleChange({ name: 'genderCode', value: '2' })
                  }
                />
              </RowWrapper>
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode || crudStatus !== 'I'}
              placeholder={'-'}
              label={'CPF'}
            >
              <MaskedInput
                onChange={handleInputChange}
                name={'taxIdentifier'}
                hasErrors={formErrors.taxIdentifier}
                value={formData.taxIdentifier}
                mask={cpfMask}
                style={{ width: '140px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'PROFISSÃO'}
            >
              <SelectInput
                loadingMessage={() => 'Pesquise sua profissão ...'}
                placeholder={'Pesquise sua profiss...'}
                onChange={val =>
                  handleSelectChange({ name: 'cbo', value: val })
                }
                value={formData.cbo}
                noOptionsMessage={() => 'Pesquise sua profissão ...'}
                isAsync
                defaultOptions
                loadOptions={loadCBOOptions}
                style={{ width: '250px' }}
              />
            </FieldWrapper>

            <FieldWrapper isDisabled={!isEditingMode} label={'DATA DE NASCTO'}>
              <MaskedInput
                onChange={handleInputChange}
                name={'birthDate'}
                hasErrors={formErrors.birthDate}
                style={{ width: '90px' }}
                mask={dateMask}
                value={formData.birthDate}
                pipe={autoCorrectedDatePipe}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={true} label={'PAÍS DE NASCIMENTO'}>
              <SelectInput
                value={formData.birthCountryCode}
                options={[{ value: 'Brasil', label: 'Brasil' }]}
                style={{ width: '250px' }}
              />
            </FieldWrapper>

            <FieldWrapper isDisabled={!isEditingMode} label={'ESTADO'}>
              <SelectInput
                placeholder={'Selecione'}
                value={formData.birthState}
                onChange={val => {
                  handleSelectChange({
                    name: 'birthState',
                    value: val
                  });
                  //setFormData({ ...formData, birthCity: null });
                }}
                options={brazilianStates}
                style={{ width: '90px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode || !formData.birthState}
              label={'CIDADE DE NASCIMENTO'}
            >
              <SelectInput
                placeholder={'Pesquise uma cidad...'}
                value={formData.birthCity}
                onChange={val =>
                  handleSelectChange({ name: 'birthCity', value: val })
                }
                value={formData.birthCity}
                noOptionsMessage={() => '...'}
                isAsync
                defaultOptions
                loadOptions={(inputValue, callback) =>
                  loadCities(
                    inputValue,
                    callback,
                    !formData.birthState ? '' : formData.birthState.label
                  )
                }
                style={{ width: '250px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'ESTADO CIVIL'}>
              <SelectInput
                value={formData.maritalStatusCode}
                onChange={val =>
                  handleSelectChange({ name: 'maritalStatusCode', value: val })
                }
                noOptionsMessage={() => '...'}
                style={{ width: '120px' }}
                options={mariageStatus}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={
                !isEditingMode ||
                (formData.maritalStatusCode.value !== '1' &&
                  formData.maritalStatusCode.value !== '5')
              }
              label={'NOME DO CÔNJUGE'}
            >
              <TextInput
                value={formData.spouseName}
                onChange={handleInputChange}
                name={'spouseName'}
                hasErrors={formErrors.spouseName}
                style={{ width: '350px' }}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle personType={personType} style={{ marginTop: '35px' }}>
            FILIAÇÃO
          </HeaderTitle>
          <RowWrapper style={{ alignSelf: 'flex-start' }}>
            <FieldWrapper isDisabled={!isEditingMode} label={'NOME DA MÃE'}>
              <TextInput
                value={formData.motherName}
                onChange={handleInputChange}
                name={'motherName'}
                hasErrors={formErrors.motherName}
                style={{ width: '350px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'NOME DO PAI'}>
              <TextInput
                value={formData.fatherName}
                onChange={handleInputChange}
                name={'fatherName'}
                hasErrors={formErrors.fatherName}
                style={{ width: '350px' }}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle style={{ marginTop: '35px' }}>
            ENDEREÇO RESIDENCIAL
          </HeaderTitle>
          <RowWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              placeholder={'-'}
              label={'CEP'}
            >
              <MaskedInput
                onChange={handleInputChange}
                name={'zipCode'}
                hasErrors={formErrors.zipCode}
                value={formData.zipCode}
                mask={cepMask}
                style={{ width: '100px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'RUA / AV / LOGRADOURO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'address'}
                hasErrors={formErrors.address}
                value={formData.address}
                style={{ width: '180px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'NÚMERO'}>
              <TextInput
                onChange={handleInputChange}
                name={'number'}
                hasErrors={formErrors.number}
                value={formData.number}
                style={{ width: '60px' }}
                type={'number'}
                placeholder={''}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'COMPLEMENTO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'complement'}
                value={formData.complement}
                style={{ width: '140px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={true} label={'PAÍS'}>
              <SelectInput
                noOptionsMessage={() => '...'}
                value={formData.countryResidenceCode}
                options={[{ value: 'Brasil', label: 'Brasil' }]}
                style={{ width: '250px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'ESTADO'}>
              <SelectInput
                placeholder={'Selecione'}
                value={formData.department}
                onChange={val => {
                  handleSelectChange({
                    name: 'department',
                    value: val
                  });
                }}
                noOptionsMessage={() => '...'}
                options={brazilianStates}
                style={{ width: '90px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode || !formData.department}
              label={'CIDADE'}
            >
              <SelectInput
                placeholder={'Pesquise uma cidad...'}
                value={formData.city}
                onChange={val =>
                  handleSelectChange({ name: 'city', value: val })
                }
                noOptionsMessage={() => '...'}
                isAsync
                defaultOptions
                loadOptions={(inputValue, callback) =>
                  loadCities(
                    inputValue,
                    callback,
                    !formData.department ? '' : formData.department.label
                  )
                }
                style={{ width: '250px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'BAIRRO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'neighborhood'}
                hasErrors={formErrors.neighborhood}
                value={formData.neighborhood}
                style={{ width: '160px' }}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle style={{ marginTop: '35px' }}>CONTATO</HeaderTitle>
          <RowWrapper style={{ alignSelf: 'flex-start' }}>
            <FieldWrapper
              isDisabled={!isEditingMode}
              placeholder={'-'}
              label={'TELEFONE'}
            >
              <MaskedInput
                onChange={handleInputChange}
                name={'phoneNumber'}
                hasErrors={formErrors.phoneNumber}
                value={formData.phoneNumber}
                mask={phoneMask}
                style={{ width: '120px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'RAMAL'}
            >
              <TextInput
                placeholder={''}
                onChange={handleInputChange}
                name={'internTelephone'}
                value={formData.internTelephone}
                style={{ width: '60px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'EMAIL'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'email'}
                hasErrors={formErrors.email}
                value={formData.email}
                style={{ width: '250px' }}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle style={{ marginTop: '35px' }}>REFERÊNCIAS</HeaderTitle>
          <ColumnWrapper
            style={{ alignItems: 'flex-start', alignSelf: 'flex-start' }}
          >
            {formData.references.map((e, i) => (
              <>
                <Reference
                  isDisabled={!isEditingMode}
                  index={i}
                  data={e.data}
                  touched={e.touched}
                  errors={e.errors}
                  handleChange={handleChangeReferences}
                  handleRemove={handleRemoveReference}
                  arraySize={formData.references.length}
                  banks={banks}
                />
              </>
            ))}
            {isEditingMode && (
              <AddIcon
                onClick={() => {
                  setFormData({
                    ...formData,
                    references: [
                      ...formData.references,
                      {
                        data: {
                          referenceType: {
                            value: '1',
                            label: '1 - Bancária'
                          }
                        },
                        touched: {},
                        errors: {}
                      }
                    ]
                  });
                }}
                style={{ margin: '40px 0px', alignSelf: 'center' }}
              />
            )}
          </ColumnWrapper>

          <p
            style={{
              marginTop: '40px',
              textAlign: 'center',
              color: theme.danger,
              alignSelf: 'center'
            }}
          >
            {warningMessageReferences}
          </p>

          <HeaderTitle style={{ marginTop: '35px' }}>
            DOCUMENTO DE IDENTIFICAÇÃO
          </HeaderTitle>
          <ColumnWrapper
            style={{ alignItems: 'flex-start', alignSelf: 'flex-start' }}
          >
            {formData.documents.map((e, i) => (
              <>
                <Document
                  isDisabled={!isEditingMode}
                  index={i}
                  key={i}
                  data={e.data}
                  touched={e.touched}
                  errors={e.errors}
                  handleChange={handleChangeDocuments}
                  handleRemove={handleRemoveDocuments}
                  arraySize={formData.documents.length}
                  alreadySelectedOptions={formData.documents.map(
                    e => e.data.documentType
                  )}
                />
              </>
            ))}
            {formData.documents.length < 3 && isEditingMode && (
              <AddIcon
                onClick={() => {
                  setFormData({
                    ...formData,
                    documents: [
                      ...formData.documents,
                      {
                        data: {
                          documentType: {
                            value: null,
                            label: null
                          }
                        },
                        touched: {}
                      }
                    ]
                  });
                }}
                style={{ margin: '40px 0px', alignSelf: 'center' }}
              />
            )}
          </ColumnWrapper>
          <p
            style={{
              marginTop: '40px',
              textAlign: 'center',
              color: theme.danger,
              alignSelf: 'center'
            }}
          >
            {warningMessage}
          </p>

          {isEditingMode && (
            <FieldWrapper
              style={{ marginRight: '0px', width: '100%' }}
              label={'COMPROVAÇÃO DE DOCUMENTOS'}
            >
              <Dropzone
                acceptedFiles={formFiles}
                onChange={files => {
                  setFormFiles(files);
                }}
                style={{ width: '80%', marginRight: '0px', marginTop: '25px' }}
              />
            </FieldWrapper>
          )}

          <p
            style={{
              marginTop: '40px',
              textAlign: 'center',
              color: theme.danger,
              alignSelf: 'center'
            }}
          >
            {warningMessageFiles}
          </p>

          {personType === 'PJ' &&
            documentsStatus.status !== 'revision' &&
            isEditingMode &&
            formData.countryResidenceCode.value.id === 76 &&
            !isRemoveDisabled && (
              <button
                style={{
                  display: 'flex',
                  border: 'none',
                  outline: 'none',
                  margin: 0,
                  marginBottom: '10px',
                  alignSelf: 'flex-end',
                  textTransformation: 'underline',
                  color: theme.danger,
                  alignItems: 'center',
                  cursor: 'pointer',

                  fontFamily: '"SourceSansPro", Arial, sans-serif'
                }}
                onClick={handleRemovePF}
              >
                <CancelIcon style={{ fontSize: '14px', marginRight: '5px' }} />
                Excluir Sócio
              </button>
            )}

          {isEditingMode && (
            <RowWrapper
              style={{
                backgroundColor: theme.brandColor3,
                padding: '10px 0px 10px 10px',
                alignSelf: 'flex-start',
                cursor: 'pointer',
                alignSelf: 'flex-start',
                marginBottom: '30px',
                width: '100%'
              }}
              onClick={() => {
                setFormData({
                  ...formData,
                  isChecked: !formData.isChecked
                });
              }}
            >
              <Checkbox disabled={true} checked={formData.isChecked} />
              <Text
                style={{ fontSize: '14px', marginLeft: '7px', color: theme.brandColor2 }}
              >
                Verifiquei todos os dados cadastrais de{' '}
                <strong>{formData.name}</strong> e confirmo que estão
                devidamente atualizados.
              </Text>
            </RowWrapper>
          )}
        </ColumnWrapper>
      </UnmountClosed>
    </ColumnWrapper>
  );
}

export default withRouter(DocumentsUpdatePF);

function Reference({
  index,
  arraySize,
  data,
  errors,
  handleChange,
  handleRemove,
  banks,
  isDisabled
}) {
  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    handleChange({
      index,
      field: name,
      value: {
        ...data,
        [name]: typeof value === 'string' ? value.toUpperCase() : value
      }
    });
  }

  function handleSelectChange({ name, value, resetFields }) {
    if (resetFields) {
      handleChange({
        index,
        field: name,
        value: {
          [name]: value
        }
      });
    } else {
      handleChange({
        index,
        field: name,
        value: {
          ...data,
          [name]: value
        }
      });
    }
  }

  return (
    <RowWrapper
      key={index}
      style={{
        flexWrap: 'nowrap',
        backgroundColor: theme.brandColor5,
        marginTop: `${index !== 0 ? '20px' : '0px'}`,
        padding: '0px 10px 15px 15px',
        alignSelf: 'flex-start'
      }}
    >
      <RowWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'TIPO'}>
          <SelectInput
            noOptionsMessage={() => '...'}
            options={referenceTypes}
            style={{ width: '250px' }}
            value={data.referenceType}
            onChange={val =>
              handleSelectChange({
                name: 'referenceType',
                value: val,
                resetFields: true
              })
            }
          />
        </FieldWrapper>
        <RefSubComponent
          isDisabled={isDisabled}
          index={index}
          {...data}
          errors={errors}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          banks={banks}
        />
      </RowWrapper>
      {arraySize > 1 && !isDisabled && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <CancelIcon onClick={() => handleRemove({ index })} />
        </div>
      )}
    </RowWrapper>
  );
}

function RefSubComponent({
  index,
  referenceType,
  handleInputChange,
  handleSelectChange,
  banks,
  errors,
  isDisabled,
  ...props
}) {
  if (referenceType.label === '1 - Bancária') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'BANCO'}>
          <SelectInput
            onChange={val => handleSelectChange({ name: 'bank', value: val })}
            value={props.bank}
            options={banks}
            style={{ width: '250px' }}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'AGÊNCIA'}>
          <TextInput
            onChange={handleInputChange}
            name={'branchId'}
            hasErrors={errors.branchId}
            value={props.branchId}
            type={'number'}
            maxLength={4}
            placeholder={''}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'CONTA C/ DÍG.'}>
          <TextInput
            onChange={handleInputChange}
            name={'accountId'}
            hasErrors={errors.accountId}
            value={props.accountId}
            type={'number'}
            placeholder={''}
          />
        </FieldWrapper>

        <FieldWrapper isDisabled={isDisabled} label={'PRODUTO'}>
          <SelectInput
            onChange={val =>
              handleSelectChange({ name: 'product', value: val })
            }
            value={props.product}
            options={productTypes}
            style={{ width: '250px' }}
          />
        </FieldWrapper>
      </>
    );
  } else if (referenceType.label === '2 - Comercial') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'NOME DA INSTITUIÇÃO'}>
          <TextInput
            style={{ width: '250px' }}
            name={'name'}
            hasErrors={errors.name}
            value={props.name}
            onChange={handleInputChange}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} placeholder={'-'} label={'CNPJ'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'document'}
            hasErrors={errors.document}
            value={props.document}
            mask={cnpjMask}
            style={{ width: '160px' }}
          />
        </FieldWrapper>

        <FieldWrapper
          isDisabled={isDisabled}
          placeholder={'-'}
          label={'TELEFONE'}
        >
          <MaskedInput
            onChange={handleInputChange}
            name={'phoneNumber'}
            hasErrors={errors.phoneNumber}
            value={props.phoneNumber}
            mask={phoneMask}
            style={{ width: '120px' }}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} style={{}} label={'RAMAL'}>
          <TextInput
            onChange={handleInputChange}
            name={'extensionNumber'}
            value={props.extensionNumber}
            style={{ width: '60px' }}
          />
        </FieldWrapper>
      </>
    );
  } else if (referenceType.label === '3 - Pessoal') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'NOME'}>
          <TextInput
            style={{ width: '250px' }}
            name={'name'}
            hasErrors={errors.name}
            value={props.name}
            onChange={handleInputChange}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} placeholder={'-'} label={'CPF'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'document'}
            hasErrors={errors.document}
            value={props.document}
            mask={cpfMask}
            style={{ width: '160px' }}
          />
        </FieldWrapper>

        <FieldWrapper
          isDisabled={isDisabled}
          placeholder={'-'}
          label={'TELEFONE'}
        >
          <MaskedInput
            onChange={handleInputChange}
            name={'phoneNumber'}
            hasErrors={errors.phoneNumber}
            value={props.phoneNumber}
            mask={phoneMask}
            style={{ width: '120px' }}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} style={{}} label={'RAMAL'}>
          <TextInput
            onChange={handleInputChange}
            name={'extensionNumber'}
            value={props.extensionNumber}
            style={{ width: '60px' }}
          />
        </FieldWrapper>
      </>
    );
  } else {
    return null;
  }
}

function Document({
  index,
  arraySize,
  data,
  errors,
  handleChange,
  handleRemove,
  alreadySelectedOptions,
  isDisabled
}) {
  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    handleChange({
      index,
      field: name,
      value: {
        ...data,
        [name]: value
      }
    });
  }

  function handleSelectChange({ name, value, resetFields }) {
    if (resetFields) {
      handleChange({
        index,
        field: name,
        value: {
          [name]: value
        }
      });
    } else {
      handleChange({
        index,
        field: name,
        value: {
          ...data,
          [name]: value
        }
      });
    }
  }

  return (
    <RowWrapper
      style={{
        flexWrap: 'nowrap',
        backgroundColor: theme.brandColor5,
        marginTop: `${index !== 0 ? '20px' : '0px'}`,
        padding: '0px 10px 15px 15px'
      }}
    >
      <RowWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'TIPO'}>
          <SelectInput
            isOptionDisabled={option =>
              alreadySelectedOptions.find(e => e.label === option.label)
            }
            noOptionsMessage={() => '...'}
            options={documentTypes}
            style={{ width: '250px' }}
            value={data.documentType}
            onChange={val =>
              handleSelectChange({
                name: 'documentType',
                value: val,
                resetFields: true
              })
            }
          />
        </FieldWrapper>
        <DocSubComponent
          index={index}
          {...data}
          errors={errors}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          isDisabled={isDisabled}
        />
      </RowWrapper>
      {arraySize > 1 && !isDisabled && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <CancelIcon onClick={() => handleRemove({ index })} />
        </div>
      )}
    </RowWrapper>
  );
}

function DocSubComponent({
  index,
  documentType,
  handleInputChange,
  handleSelectChange,
  isDisabled,
  errors,
  ...props
}) {
  if (documentType.label === 'RG') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'NÚMERO DO DOCUMENTO'}>
          <TextInput
            onChange={handleInputChange}
            name={'document'}
            hasErrors={errors.document}
            value={props.document}
            type={'number'}
            maxLength={10}
            placeholder={''}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'DATA DE EMISSÃO'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'emissionDate'}
            hasErrors={errors.emissionDate}
            style={{ width: '90px' }}
            mask={dateMask}
            value={props.emissionDate}
            pipe={autoCorrectedDatePipe}
          />
        </FieldWrapper>

        <FieldWrapper isDisabled={isDisabled} label={'ÓRGÃO EXPEDIDOR'}>
          <TextInput
            style={{ width: '90px' }}
            name={'issuingCompany'}
            hasErrors={errors.issuingCompany}
            value={props.issuingCompany}
            onChange={handleInputChange}
          />
        </FieldWrapper>
      </>
    );
  } else if (documentType.label === 'CNH') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'NÚMERO DO DOCUMENTO'}>
          <TextInput
            onChange={handleInputChange}
            name={'document'}
            hasErrors={errors.document}
            value={props.document}
            type={'number'}
            placeholder={''}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'DATA DE EMISSÃO'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'emissionDate'}
            hasErrors={errors.emissionDate}
            style={{ width: '90px' }}
            mask={dateMask}
            value={props.emissionDate}
            pipe={autoCorrectedDatePipe}
          />
        </FieldWrapper>

        <FieldWrapper isDisabled={isDisabled} label={'ÓRGÃO EXPEDIDOR'}>
          <TextInput
            style={{ width: '90px' }}
            name={'issuingCompany'}
            hasErrors={errors.issuingCompany}
            value={props.issuingCompany}
            onChange={handleInputChange}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'DATA DE VENCIMENTO'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'documentValidity'}
            hasErrors={errors.documentValidity}
            style={{ width: '90px' }}
            mask={dateMask}
            value={props.documentValidity}
            pipe={autoCorrectedDatePipe}
          />
        </FieldWrapper>
      </>
    );
  } else if (documentType.label === 'Comprovante de Endereço') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'DATA DE EMISSÃO'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'emissionDate'}
            hasErrors={errors.emissionDate}
            style={{ width: '90px' }}
            mask={dateMask}
            value={props.emissionDate}
            pipe={autoCorrectedDatePipe}
          />
        </FieldWrapper>
      </>
    );
  } else {
    return null;
  }
}

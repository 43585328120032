import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 35px 60px 20px 20px;
  ${media.phone`
    padding: 40px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  ${media.phone`
    flex-direction: column;
    margin-top: 25px;
  `}
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.brandColor2};
  border-radius: 2px;
  margin-right: 25px;
  border-top: 3px solid ${props => props.theme.brandColor1};
  ${media.phone`
    flex: 0.3;
  `};
`;

export const SecondRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 450px;
  min-height: 420px;
`;

export const BannersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10;
  justify-content: flex-start;

`;

export const FiltersWrapper = styled.div`
  display: flex;
  padding: 30px 20px 20px 20px;
`;

export const Title = styled.span`
  text-transform: uppercase;
  align-self: center;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  color: ${props => props.theme.brandColor3};
  font-weight: 300;
  margin-top: 15px;
`;

export const Divisor = styled.div`
  margin: 0px;
  border-bottom: ${props => props.theme.brandColor5};
  margin-bottom: 10px;
`;

export const PeriodLabel = styled.span`
  font-size: 10px;
  margin: 5px 0px 5px 5px;
`;

import React from 'react';
import { Overlay, Modal } from './styles';
import Button from 'components/Button';

const ConfigErrorModal = () => (
  <>
    <Overlay>
      <Modal>
        <h2>Ops...</h2>
        <p>Tivemos um problema ao carregar o site.</p>
        <p>Tente novamente em alguns instantes.</p>
        <Button
          title="OK"
          onClick={() => window.location.reload()}
        />
      </Modal>
    </Overlay>
  </>
);

export default ConfigErrorModal;
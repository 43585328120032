import React from "react";
import { TitleContainer, Title, Subtitle, NormalValues } from "./styles";

import ReactTable from "react-table";
import ValueOnTables from "components/ValueOnTables";

const columns = [
  {
    Header: "Título",
    accessor: "title", // String-based value accessors!
    Cell: props => {
      return (
        <TitleContainer>
          <Title>{props.original.title}</Title>
          <Subtitle>{props.original.subtitle}</Subtitle>
        </TitleContainer>
      );
    },
    headerClassName: 'rt-th-hide-header'
  },
  {
    Header: "Valor",
    accessor: "value",
    textAlign: "right",
    Cell: props => (
      props.original.type === 'normal' ? (<div style={{ textAlign: 'right' }}><NormalValues>{props.value}</NormalValues></div>) : <ValueOnTables value={props.value} type={props.original.type} />
    ),
    // Custom cell components!
    headerClassName: 'rt-th-hide-header'
  }
];

function AccountBalanceScreenTable({ data }) {
  return (
    <ReactTable
      noDataText="Nenhum dado disponível"
      showPagination={false}
      data={data}
      columns={columns}
      pageSize={data ? data.length : 0}
      getTrProps={(state, rowInfo, column) => {
        return {
          style: {
            //replace here depending on states
            //background: rowInfo.row.value > 20 ? "green" : "red"
          }
        };
      }}
    />
  );
}

export default AccountBalanceScreenTable;

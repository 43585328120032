import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Logo from 'assets/images/logo-topazio-receipt.png';
import { resolvePixResumeInformations } from 'utils/transformers/pix-receipt';
import { handleCurrency } from 'utils/functions/currency';
import ReactToPrint from 'react-to-print';
import { ouvidoriaInformations } from 'constants/ouvidoriaInformations';
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  ContentWrapper,
  ItemContainer,
  PortfolioName,
  TopLabel,
  CheckMarkIcon,
  AditionalInformationsWrapper,
  TransactionDescription,
  InformationWrapper,
  InformationLabel,
  InformationValue,
  DownloadIcon,
} from './styles';

const TRANSFER_TYPES = {
  TOPAZIO: 'IBTR',
  SAME_OWNER: 'IBTD',
  OTHER_BANKS: 'IBTC',
};

function PixReceipt({ data }) {
  const componentRef = useRef();
  const [parsedData, setParsedDate] = useState([]);
  const { clientConfig } = useConfigContext();

  useEffect(() => {
    let subParsedData;
    subParsedData = {
      ...data,
      situation: data.status || 'SUCESSO',
      beneficiaryName: data.thirdPartName,
      beneficiaryTaxIdentifier: data.thirdPartDocument,
      beneficiaryBankDescription: `${data.thirdPartIspb}`,
      date: data.createdAt,
      value: handleCurrency({ value: data.amount }),
    };
    setParsedDate(
      resolvePixResumeInformations({
        data: subParsedData,
      })
    );
  }, [data]);

  class RenderReceipt extends React.Component {
    render() {
      return (
        <ContentWrapper id="capture">
          <img
            style={{ width: '160px', marginTop: '20px' }}
            src={clientConfig.images.receiptsLogo}
          />
          <p>{clientConfig.vars.appSigner}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              marginTop: '10px',
              marginBottom: '0px',
            }}
          >
            <CheckMarkIcon />
            <TopLabel>COMPROVANTE DE TRANSAÇÃO PIX</TopLabel>
          </div>
          <TransactionDescription>
            {data.operationTypeDescription}
          </TransactionDescription>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '30px 100px',
              width: '100%',
            }}
          >
            {parsedData.map((e) => (
              <ItemContainer
                id="transferInformationItemId"
                isAmountField={e.field === 'VALOR'}
              >
                <PortfolioName isAmountField={e.field === 'VALOR'}>
                  {e.field}
                </PortfolioName>
                <PortfolioName isAmountField={e.field === 'VALOR'}>
                  {e.value}
                </PortfolioName>
              </ItemContainer>
            ))}
            <AditionalInformationsWrapper>
              <InformationWrapper>
                <InformationLabel>EFETIVAÇÃO DO PAGAMENTO</InformationLabel>
                <InformationValue>
                  {data.createdAt
                    ? moment(data.createdAt).format('DD/MM/YYYY')
                    : 'NÃO DISPONÍVEL'}
                </InformationValue>
              </InformationWrapper>
              <InformationWrapper>
                <InformationLabel>IDENTIFICADOR</InformationLabel>
                <InformationValue id="transactionIdentifierLabelId">
                  {data.transactionId}
                </InformationValue>
              </InformationWrapper>
              <InformationWrapper>
                <InformationLabel>AUTENTICAÇÃO</InformationLabel>
                <InformationValue id="transactionAuthenticationLabelId">
                  {data.transactionId}
                </InformationValue>
              </InformationWrapper>
            </AditionalInformationsWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'start',
              marginTop: '15px',
            }}
          >
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.baseLine}
            </InformationValue>
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.line01}
            </InformationValue>
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.line02}
            </InformationValue>
            {/* {ouvidoriaInformations.map((e) => (
              <InformationValue style={{ fontSize: '10px' }}>
                {e}
              </InformationValue>
            ))} */}
          </div>
        </ContentWrapper>
      );
    }
  }

  return (
    <Container>
      <ReactToPrint
        style={{ alignSelf: 'flex-end' }}
        trigger={() => <DownloadIcon />}
        content={() => componentRef.current}
      />
      <RenderReceipt ref={componentRef} />
    </Container>
  );
}

export default PixReceipt;

export default {
  brandColor1: '#51C3C7',
  brandColor2: '#FFFFFF',
  brandColor3: '#53565A',
  brandColor4: '#51C3C7',
  brandColor5: '#E2E2E2',
  brandColor6: '#51C3C7',
  brandColor7: '#FCA043',
  backgroundPrimary: '#F8F8F8',
  backgroundSecundary: '#FFFFFF',
  backgroundHeader: '#51C3C7',
  menuItemBackground: '#51C3C7',
  notificationsBg: '#CBF5F6',
  alert: '#FCA043',
  black: '#000000',
  info: '#303ECB',
  success: '#26CC3A',
  danger: '#CE3D3E',
  customPurple: '#7b4c74',

  Cor_base_marca_01: '#e39500',
  Cor_base_marca_02: '#070707',
  Cor_base_marca_03: '#070707',
  Cor_base_marca_04: '#FFFFFF',
  Cor_base_marca_05: '#FFFFFF',
  Cor_base_marca_06: '#e39500',
  Cor_base_marca_07: '#e39500',
  Cor_base_marca_fade: '#f8e5cb',
  Cor_base_marca_fade_02: '#f7e1c4',
  Cor_auxiliar_01: '#ffffff',
  Cor_auxiliar_02: '#070707',
  Cor_auxiliar_03: '#E2E2E2',
};

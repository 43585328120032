import 'array-flat-polyfill';
import moment from 'moment';
import { TOPAZIO_TEXT, OTHERBANKS_TEXT } from 'constants/pix-receipt-texts';
import { resolvePersonType } from 'utils/functions/user';
import { conformToMask } from 'react-text-mask';
import {
  cnpjMask,
  cpfMask,
  cpfMaskWithasterisks,
} from 'containers/DocumentsUpdate/components/MaskedInput/masks';

const TRANSFER_TYPES = {
  TOPAZIO: 'IBTR',
  SAME_OWNER: 'IBTD',
  OTHER_BANKS: 'IBTC',
};

const parseValues = ({ fieldName, value }) => {
  let parsedValue = '';
  if (fieldName === 'date') {
    parsedValue = moment(value).format('DD/MM/YYYY');
  } else if (fieldName === 'clientDocument') {
    if (resolvePersonType({ taxIdentifier: value }) === 'PF') {
      parsedValue = conformToMask(value, cpfMaskWithasterisks, { guid: false })
        .conformedValue;
    } else if (resolvePersonType({ taxIdentifier: value }) === 'PJ') {
      parsedValue = conformToMask(value, cnpjMask, { guid: false })
        .conformedValue;
    } else {
      parsedValue = value;
    }
  } else if (fieldName === 'beneficiaryTaxIdentifier') {
    if (resolvePersonType({ taxIdentifier: value }) === 'PF') {
      parsedValue = conformToMask(value, cpfMaskWithasterisks, { guid: false })
        .conformedValue;
    } else if (resolvePersonType({ taxIdentifier: value }) === 'PJ') {
      parsedValue = conformToMask(value, cnpjMask, { guid: false })
        .conformedValue;
    } else {
      parsedValue = value;
    }
  } else {
    parsedValue = value;
  }

  return parsedValue;
};
const resolvePixResumeInformations = ({ data, selectedType }) => {
  if (!data || typeof data !== 'object') return [];

  let texts = OTHERBANKS_TEXT;

  const filledTransferInformations = texts
    .map((e) => ({
      ...(data.hasOwnProperty(e.fieldName) && {
        field: e.title,
        value: parseValues({
          fieldName: e.fieldName,
          value: data[e.fieldName],
        }),
      }),
    }))
    .filter((value) => Object.keys(value).length !== 0);

  return filledTransferInformations;
};

export { resolvePixResumeInformations };

export default [
  {
    title: "CONTA GARANTIDA",
    imgUrl: require("assets/images/banner_01.png"),
    description: "Limite extra sempre disponível na sua conta.",
    link: "https://www.bancotopazio.com.br/para-sua-empresa/credito/conta-garantida/?utm_source=ib&utm_medium=banner&utm_campaign=conta-garantida&utm_content=fevereiro.2020",
    visibleTo: ['PJ'],
    type: 'guaranteedAccount'
  },
  {
    title: "CAPITAL DE GIRO",
    imgUrl: require("assets/images/banner_02.png"),
    description: "Crédito fácil para empresas credenciadas Ticket.",
    link: "https://www.bancotopazio.com.br/para-sua-empresa/credito/capital-de-giro/?utm_source=ib&utm_medium=banner&utm_campaign=capital-giro&utm_content=fevereiro.2020",
    visibleTo: ['PJ'],
    type: 'workingCapital',
  },
  {
    title: "ANTECIPAÇÃO DE RECEBÍVEIS",
    imgUrl: require("assets/images/banner_03.png"),
    description: "Receba antecipado suas vendas Visa e Master feitas à prazo.",
    link: "https://www.bancotopazio.com.br/credito-antecipacao-recebiveis-pj/?utm_source=ib&utm_medium=banner&utm_campaign=antecipacao-recebiveis&utm_content=fevereiro.2020",
    visibleTo: ['PJ'],
    type: 'antecipationReceivables',
    isInternalLink: true,
  },
];

export default [
  {
    hostname: 'localhost',
    name: 'ibank',
  },
  {
    hostname: 'ms.fea.btfinanceira.com.br',
    name: 'aletha',
  },
  {
    hostname: 'empresa01.btfinanceira.com.br',
    name: 'ibank',
  },
  {
    hostname: 'empresa02.btfinanceira.com.br',
    name: 'aletha',
  },
  {
    hostname: 'empresa03.btfinanceira.com.br',
    name: 'punto',
  },
  {
    hostname: 'ibank.bancotopazio.com.br',
    name: 'ibank',
  },
  {
    hostname: 'ibank.alethadigital.com.br',
    name: 'aletha',
  },
  {
    hostname: 'punto.bancotopazio.com.br',
    name: 'punto',
  },
];

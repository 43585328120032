import styled from 'styled-components';
import Select from 'react-select';
import { Download } from 'styled-icons/boxicons-regular/Download';
import { Copy } from 'styled-icons/feather/Copy';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${props => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  border-radius: 2px;
  background-color: ${props => props.theme.brandColor2};
  border: solid 1px ${props => props.theme.brandColor5};
  padding: 0;
  margin: 30px 0px 0px 0px;
  padding: 40px 20px 40px 70px;
  box-sizing: border-box;
  ${media.phone`
    padding: 0px;
  `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 2px;
  justify-content: space-between;
  margin: 30px 0px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;

  a {
    color: ${props => props.theme.brandColor4};
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.label`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  padding: 5px 0;
`;

export const Value = styled.span`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  padding: 5px 0;
`;

export const ColoredValue = styled(Value)`
  color: ${props =>
    props.colored
      ? props.theme.success
      : props.status === 'PROCESSANDO'
      ? props.theme.alert
      : props.status === 'AGENDADO'
      ? props.theme.black
      : props.status === 'CONCLUIDO'
      ? props.theme.success
      : props.status === 'CANCELADO'
      ? props.theme.danger
      : props.theme.black};
`;

export const DownloadIcon = styled(Download)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 7px;
  color: ${props => props.theme.brandColor1};
  opacity: 0.6;
`;

export const CustomAutocomplete = styled(Select)`
  width: 20%;
  margin-left: 35px;
  margin-right: 15px;
  border: ${props => (props.hasErrors ? '1px solid red' : 'none')};
  border-radius: ${props => (props.hasErrors ? '5px' : 'none')};
`;

export const DownloadButton = styled.a`
  display: flex;
  height: 40px;
  width: ${props => (props.sm ? 0.5 : 1)};
  justify-content: center;
  align-items: center;
  border: 2px solid ${props => props.theme.brandColor1};
  border-radius: 20px;
  background-color: ${props => props.theme.brandColor2};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: ${props => props.theme.brandColor1};
  margin-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0px 50px;

  :focus {
    outline: 0px;
  }

  :hover {
    text-decoration: none;
    opacity: 0.9;
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const AmountInput = styled.input`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;
  border-bottom-color: ${props => (props.hasErrors ? props.theme.danger : props.theme.brandColor5)};

  border-width: 0px 0px 1px 0px;
  color: ${props => props.theme.brandColor3};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
  :-ms-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
`;

export const CopyToCliboardIcon = styled(Copy)`
  width: 25px;
  height: 25px;
  margin-left: 10px;
  color: ${props => props.theme.brandColor1};
  cursor: pointer;
`;

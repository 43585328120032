import React from 'react';
import styled from 'styled-components';
import { Alert } from 'styled-icons/octicons/Alert';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  flex-direction: column;
  flex: 1;

  p {
    color: green;
  }
`;
export const AlertIcon = styled(Alert)`
  width: 27px;
  height: 27px;
  cursor: pointer;
  color: ${props => props.theme.alert};
`;

function LocalErrorHandler({ firstLineMessage, secondLineMessage }) {
  return (
    <Container>
      <AlertIcon />{' '}
      <p style={{ color: theme.alert, fontSize: '18px' }}>{firstLineMessage}</p>
      <p style={{ color: theme.alert, fontSize: '18px' }}>{secondLineMessage}</p>
    </Container>
  );
}

export default LocalErrorHandler;

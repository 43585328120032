import styled from 'styled-components';

import { PhoneAlt } from 'styled-icons/fa-solid/PhoneAlt';
import { UserCircle } from 'styled-icons/boxicons-solid/UserCircle';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';
import { Notifications } from 'styled-icons/material/Notifications';
import { Circle } from 'styled-icons/boxicons-solid/Circle';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 0px 0px;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: ${(props) => props.theme.home.header.backgroundColor};
`;

export const LogoIcon = styled.img`
  display: flex;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '90px')};
  object-fit: contain;
  margin-right: 40px;
  padding-left: 30px;
  ${media.phone`
    width: 140px;
    height: 25px;
 `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  // styling background-color on dropdowns on hover
  &
    .StyledItem-sc-1xeog7q-0.index__c-menu__item___36mO8.index__is-focused___yGFxo {
    background-color: ${(props) => props.theme.Cor_base_marca_fade_02};
  }
`;

export const UserOptionsSelectionWrapper = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
`;

export const PhoneButtonContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  font-size: 1.4em;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  color: ${(props) => props.theme.home.headerIcons.color};
  cursor: pointer;
  border-left: 1px solid
    ${(props) =>
      props.personType === 'PF'
        ? props.theme.home.headerIcons.color
        : props.theme.home.headerIcons.color};
  ${media.phone`   
    display: none;
 `};
`;

export const UserButtonContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  font-size: 1.4em;
  width: 1.6em;
  text-align: center;
  line-height: 1.6em;
  color: ${(props) => props.theme.home.headerIcons.color};
  cursor: pointer;
  border-left: 1px solid
    ${(props) =>
      props.personType === 'PF'
        ? props.theme.home.headerIcons.color
        : props.theme.home.headerIcons.color};
`;

export const ProfileIcon = styled(UserCircle)`
  color: ${(props) => props.theme.home.headerIcons.color};
`;

export const PhoneIcon = styled(PhoneAlt)`
  color: ${(props) => props.theme.home.headerIcons.color};
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
  display: flex;
  align-self: center;
  width: 26px;
  color: ${(props) => props.theme.home.headerIcons.color};
  margin-right: 5px;
`;

export const NotificationIcon = styled(Notifications)`
  display: flex;
  align-self: center;
  width: 26px;
  color: ${(props) => props.theme.home.headerIcons.color};
  margin-right: 5px;
`;

export const CircleIcon = styled(Circle)`
  width: 21px;
  height: 21px;
  color: ${(props) => props.color || props.theme.danger};
  align-self: 'center';
`;

export const mariageStatus = [
  {
    value: "1",
    label: "Casado"
  },
  {
    value: "2",
    label: "Divorciado"
  },
  {
    value: "3",
    label: "Separado"
  },
  {
    value: "4",
    label: "Solteiro"
  },
  {
    value: "5",
    label: "União Estável"
  },
  {
    value: "6",
    label: "Viúvo"
  }
];

export const referenceTypes = [
  {
    value: "1",
    label: "1 - Bancária"
  },
  {
    value: "2",
    label: "2 - Comercial"
  },
  {
    value: "3",
    label: "3 - Pessoal"
  }
];

export const productTypes = [
  {
    value: 1,
    label: "Conta Corrente"
  },
  {
    value: 2,
    label: "Empréstimo"
  },
  {
    value: 3,
    label: "Aplicação"
  },
  {
    value: 4,
    label: "Seguro"
  },
  {
    value: 5,
    label: "Outros"
  }
];

export const documentTypes = [
  {
    value: "RG",
    label: "RG"
  },
  {
    value: "CNH",
    label: "CNH"
  },
  {
    value: "CER",
    label: "Comprovante de Endereço"
  }
];

export const brazilianStates = [
  {
    label: "AC",
    value: { department: "ACRE", uf: "AC" }
  },
  {
    label: "AL",
    value: { department: "ALAGOAS", uf: "AL" }
  },
  {
    label: "AM",
    value: { department: "AMAZONAS", uf: "AM" }
  },
  {
    label: "AP",
    value: { department: "AMAPA", uf: "AP" }
  },
  {
    label: "BA",
    value: { department: "BAHIA", uf: "BA" }
  },
  {
    label: "CE",
    value: { department: "CEARA", uf: "CE" }
  },
  {
    label: "DF",
    value: { department: "DISTRITO FEDERAL", uf: "DF" }
  },
  {
    label: "ES",
    value: { department: "ESPIRITO SANTO", uf: "ES" }
  },
  {
    label: "GO",
    value: { department: "GOIAS", uf: "GO" }
  },
  {
    label: "MA",
    value: { department: "MARANHAO", uf: "MA" }
  },
  {
    label: "MG",
    value: { department: "MINAS GERAIS", uf: "MG" }
  },
  {
    label: "MS",
    value: { department: "MATO GROSSO DO SUL", uf: "MS" }
  },
  {
    label: "MT",
    value: { department: "MATO GROSSO", uf: "MT" }
  },
  {
    label: "PA",
    value: { department: "PARA", uf: "PA" }
  },
  {
    label: "PB",
    value: { department: "PARAIBA", uf: "PB" }
  },
  {
    label: "PE",
    value: { department: "PERNAMBUCO", uf: "PE" }
  },
  {
    label: "PI",
    value: { department: "PIAUI", uf: "PI" }
  },
  {
    label: "PR",
    value: { department: "PARANA", uf: "PR" }
  },
  {
    label: "RJ",
    value: { department: "RIO DE JANEIRO", uf: "RJ" }
  },
  {
    label: "RN",
    value: { department: "RIO GRANDE DO NORTE", uf: "RN" }
  },
  {
    label: "RO",
    value: { department: "RONDONIA", uf: "RO" }
  },
  {
    label: "RR",
    value: { department: "RORAIMA", uf: "RR" }
  },
  {
    label: "RS",
    value: { department: "RIO GRANDE DO SUL", uf: "RS" }
  },
  {
    label: "SC",
    value: { department: "SANTA CATARINA", uf: "SC" }
  },
  {
    label: "SE",
    value: { department: "SERGIPE", uf: "SE" }
  },
  {
    label: "SP",
    value: { department: "SAO PAULO", uf: "SP" }
  },
  {
    label: "TO",
    value: { department: "TOCANTINS", uf: "TO" }
  }
];

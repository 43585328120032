import React, { useRef, useState, useEffect, useCallback } from 'react';
import { NoEncryption } from 'styled-icons/material';
import {
  KeyboardMainContainer,
  InputContainer,
  KeyboardInput,
  KeyboardCloseButton,
  CloseIcon,
  BottomOptionsContainer,
  ConfirmButton,
  ClearButton,
  KeyboardInnerContainer,
  KeysMainContainer,
  KeysLineContainer,
  KeyItem,
} from './styles';

const DigitalKeyboardNumeric = ({
  placeholder = '',
  currentValue = null,
  closeAction = () => { },
  onChange = () => { },
  onKeyPress = () => { },
  setArrayPassword = () => { },
  currentOptionsItem,
  keyboardProps = {},
  layoutName = 'default',
  layout = 'default',
  visible = false,
  isInteger = false,
  inputType = 'text',
  randomKeyboard = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(currentValue);
  const [containerHeight, setContainerHeight] = useState(0);
  const [optionsList, setOptionsList] = useState([]);
  const [oldOptions, setOldOptions] = useState([]);
  const [keys, setKeys] = useState({
    firstHalf: [],
    secondHalf: []
  });
  const containerRef = useRef(null);
  const keyboardRef = useRef(null);

  useEffect(() => {
    setArrayOption();
    setInputValue(currentValue);
    if (containerRef && containerRef.current) {
      setContainerHeight(containerRef.current.getBoundingClientRect().height + 8);
    }
  }, []);

  useEffect(() => {
    setInputValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    handleUpdateOptions();
  }, [currentOptionsItem]);

  const shuffleArray = (arr) => {
    if (!randomKeyboard) return arr;
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  const setArrayOption = () => {
    let arrayNumber = [...Array(10).keys()];
    let currentIndex = null;
    arrayNumber = shuffleArray(arrayNumber).map((item, index) => {
      if (currentIndex === index) {
        currentIndex = null;
        return [];
      }
      currentIndex = index + 1;
      return [item.toString(), arrayNumber[index + 1].toString()];
    }).filter(item => item.length);
    setKeys({
      firstHalf: arrayNumber.slice(0, 3),
      secondHalf: [...arrayNumber.slice(3, 5), 'delete']
    });
  }

  const handleUpdateOptions = () => {
    if (!currentOptionsItem || !currentOptionsItem.length) {
      setOptionsList([]);
      return;
    }
    let newOptions = currentOptionsItem;
    newOptions = [...newOptions];
    setOptionsList(newOptions);
  }

  const handleInputChange = value => {
    onChange(value);
    setInputValue(value);
  }

  const handleClose = () => {
    closeAction();
    setInputValue(null);
    setOptionsList([]);
    setOldOptions([]);
  };

  const handleClear = () => {
    handleInputChange('');
    setOptionsList([]);
    setOldOptions([]);
    setArrayPassword([]);
  };

  const handlePressItem = (currentOptionsItem = []) => {
    if (currentOptionsItem === 'delete') {
      if (oldOptions.length === 0) return;
      const oldOptionsList = [...oldOptions];
      const newOldList = oldOptionsList.pop();
      setOldOptions(oldOptionsList);
      setOptionsList(newOldList);
      setArrayPassword(newOldList);
      return;
    }
    if (!optionsList || !optionsList.length) {
      setOptionsList(currentOptionsItem);
      setArrayPassword(currentOptionsItem);
      return;
    }
    const newOptions = [];
    currentOptionsItem.forEach(currentItem => {
      optionsList.forEach(item => {
        newOptions.push(`${item}${currentItem}`);
      });
    });
    const oldOptionsList = [...oldOptions];
    oldOptionsList.push(optionsList);
    setOldOptions(oldOptionsList);
    setOptionsList(newOptions);
    setArrayPassword(newOptions);
  }

  const handleKeyPress = currentOptionsItem => {
    if (currentOptionsItem !== 'delete' && optionsList.length >= 256) return;
    onKeyPress(currentOptionsItem === 'delete' ? currentOptionsItem : '0');
    handleInputChange(currentOptionsItem === 'delete' ? (
      !inputValue || !inputValue.length ? '' : (
        inputValue.slice(0, inputValue.length - 1)
      )
    ) : (
      !inputValue || !inputValue.length ? '1' : (
        inputValue + '1'
      )
    ));
    handlePressItem(currentOptionsItem);
    setArrayOption();
  };

  return (
    <>
      <KeyboardMainContainer
        visible={visible}
        height={visible ? containerHeight : 0}
        {...props}
      >
        <KeyboardInnerContainer ref={containerRef}>
          <KeysMainContainer>
            <KeysLineContainer>
              {keys.firstHalf.map((item, index) => (
                <KeyItem
                  key={`${index}-keyboard-numeric-key`}
                  onClick={() => handleKeyPress(item)}
                  style={{
                    pointerEvents: item === 'delete' || optionsList.length < 256 ? 'all' : 'none',
                    opacity: item === 'delete' || optionsList.length < 256 ? 1 : 0.7,
                  }}
                >
                  {item === 'delete' ? '⌫' : `${item[0]} ou ${item[1]}`}
                </KeyItem>
              ))}
            </KeysLineContainer>
            <KeysLineContainer>
              {keys.secondHalf.map((item, index) => (
                <KeyItem
                  key={`${index}-keyboard-numeric-key`}
                  onClick={() => handleKeyPress(item)}
                  style={{
                    pointerEvents: item === 'delete' || optionsList.length < 256 ? 'all' : 'none',
                    opacity: item === 'delete' || optionsList.length < 256 ? 1 : 0.7,
                    fontSize: item === 'delete' ? '17px' : '12px',
                    paddingTop: item === 'delete' ? '14px' : '18px',
                    paddingBottom: item === 'delete' ? '14px' : '18px',
                  }}
                >
                  {item === 'delete' ? '⌫' : `${item[0]} ou ${item[1]}`}
                </KeyItem>
              ))}
            </KeysLineContainer>
          </KeysMainContainer>
          <BottomOptionsContainer>
            <ClearButton onClick={handleClear}>
              Limpar
            </ClearButton>
            <ConfirmButton onClick={handleClose}>
              Confirmar
            </ConfirmButton>
          </BottomOptionsContainer>
        </KeyboardInnerContainer>
      </KeyboardMainContainer>
    </>
  );
};

export default DigitalKeyboardNumeric;
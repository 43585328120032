import React, { useContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import { ThemeContext } from "styled-components";
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';

function GlobalLoader({ children, backgroundColor }) {
  const themeContext = useContext(ThemeContext);
  const { isLoading } = useSwitchAccountDataContext();

  return (
    <LoadingOverlay
      active={isLoading}
      styles={{
        overlay: base => ({
          ...base,
          background: backgroundColor || base.background,
        }),
        wrapper: base => ({
          ...base,
          width: "100%",
          height: "100%",
        })
      }}

    >
      {children}
    </LoadingOverlay>
  );
}

export default GlobalLoader;

import styled, { css } from 'styled-components';
import media from 'utils/media';
import { Check } from 'styled-icons/boxicons-regular';
import Button from 'components/Button';
import { Keyboard } from 'styled-icons/fa-regular/Keyboard';

export const Logo = styled('img')`
  width: 300px;
  position: absolute;
  left: 6.25vw;
  top: 93px;
  ${media.phone`
    top: 44px;
    width: 150px;
 `}

  @media (max-width: 1400px) {
    top: 50px;
  }
`;

export const FormButton = styled(Button)`
  align-self: flex-end;
  width: 220px;
  padding: 0;
  display: block;
  font-family: MontserratMedium;
  font-size: 18px;
  margin: 0;
  border-radius: 17px;
  height: 65px;
  background-color: ${({ theme }) =>
    theme.forgotPassword.confirmationButton.backgroundColor};
  color: ${({ theme }) => theme.forgotPassword.confirmationButton.color};
  border: 1px solid
    ${({ theme }) => theme.forgotPassword.confirmationButton.borderColor};

  /* @media (min-width: 1600px) {
    height: 80px;
    font-size: 22px;
  } */
`;

export const BackButton = styled(FormButton)`
  width: 140px;
  background-color: ${({ theme }) =>
    theme.forgotPassword.backButton.backgroundColor};
  color: ${({ theme }) => theme.forgotPassword.backButton.color};
  border: 1px solid
    ${({ theme }) => theme.forgotPassword.backButton.borderColor};
  margin: 0 15px 0 0;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  background-image: ${({ loginImage }) => `url(${loginImage})`};
  background-size: cover;
  background-repeat: no-repeat;

  &:before {
    content: '';
    /* background: rgb(81 195 199 / 20%); */
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const LoginContainer = styled.div`
  /* width: ${({ isLogin }) => (isLogin ? '360px' : '450px')}; */
  position: absolute;
  right: 15vw;
  top: 60%;
  transform: translateY(-50%);

  @media (min-width: 1400px) {
    transform: translateY(0);
    top: 74px;
  }

  @media (max-width: 1399px) {
    transform: translateY(0);
    top: 50px;
    right: 13vw;
  }
  ${media.phone`
    margin: 0 auto 0 auto;
    right: 0;
    left: 0;
    padding: 0 6vw;
 `}
`;
export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const FormTab = styled.div`
  height: ${({ active }) => (active ? '80px' : '70px')};
  opacity: ${({ active }) => (active ? 0.85 : 0.35)};
  background-color: ${({ wasDone, theme }) =>
    wasDone ? theme.Cor_base_marca_01 : theme.Cor_base_marca_02};
  width: 48%;
  cursor: ${({ wasDone }) => (wasDone ? 'pointer' : 'initial')};
  border-radius: 20px 20px 0 0;
  padding: 16px 0 16px 16px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    color: ${({ active, theme, wasDone }) =>
    wasDone
      ? theme.Cor_base_marca_02
      : active
        ? theme.Cor_base_marca_01
        : theme.Cor_base_marca_04};
    font-family: MontserratXLight;
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
    line-height: 1.24;
    letter-spacing: 0.5px;
  }

  p {
    color: ${({ active, theme, wasDone }) =>
    wasDone
      ? theme.Cor_base_marca_02
      : active
        ? theme.Cor_base_marca_01
        : theme.Cor_base_marca_04};
    font-family: MontserratXLight;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.38;
    letter-spacing: 0.32px;
  }

  @media (min-width: 1600px) {
    height: ${({ active }) => (active ? '100px' : '90px')};
    padding: 23px 0 23px 23px;

    h3 {
      font-size: 25px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const TabIcon = styled(Check)`
  width: 36px;
  color: #67ff00;
  position: ABSOLUTE;
  right: 5px;
  top: 5px;

  @media (min-width: 1600px) {
    top: 57%;
    transform: translateY(-50%);
  }
`;

export const LoginHeadingText = styled.h2`
  margin-bottom: 14px;
  font-size: 20px;
  font-family: MontserratLight;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0.1px;
  text-align: left;
  color: ${(props) => props.theme.login.formHeadLabel.color};
  padding-left: 8px;

  @media (max-width: 1400px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const FormHeading = styled.p`
  margin: 0 0 14px 0;
  font-size: 18px;
  font-family: MontserratXLight;
  line-height: 0.88;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.Cor_base_marca_04};

  @media (min-width: 1600px) {
    margin: 0 0 28px 0;
    font-size: 25px;
  }
`;

export const InputLabel = styled.label`
  font-family: MontserratMedium;
  font-size: ${({ primary }) => (primary ? '20px' : '18px')};
  color: ${({ theme, primary }) =>
    primary
      ? theme.login.usernameAndPasswordLabel.color
      : theme.Cor_base_marca_03};
  font-weight: 600;
  line-height: ${({ primary }) => (primary ? '22px' : '20px')};
  letter-spacing: 0.05px;
  text-align: left;

  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;

export const InputContainer = styled.div`
  padding: 24px 23px 45px;
  border-radius: 20px;
  background-color: transparent;
  position: relative;
`;

export const InputContainerBackground = styled.div`
  border-radius: 20px;
  background-color: ${(props) =>
    props.theme.login.formContainer.backgroundColor};
  position: absolute;
  opacity: 0.85;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const RedefinePasswordContainer = styled(InputContainer)`
  border-radius: 0 0 20px 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  ${media.tablet`
    width: 70%;
  `}
  ${media.phone`
    width: 100%;
  `}
  @media (min-width: 577px) and (max-width: 769px) {
    width: 98%;
  }
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 330px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const FormWrapper = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const slidein = css`
  @keyframes slidein {
    0% {
      padding-left: 100px;
    }
    20% {
      padding-left: 100px;
    }
    40% {
      padding-right: 0px;
      font-size: 13px;
      color: ${({ theme }) => theme.alert};
    }
    100% {
      padding-right: 0px;
      font-size: 14px;
      color: ${({ theme }) => theme.alert};
    }
  }
`;

export const AnimatedFormWrapper = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 50px;

  ${slidein}
  animation: slidein 1.5s;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? '15px' : '0px')};

  @media (min-width: 1600px) {
    margin-bottom: ${(props) => (props.marginBottom ? '40px' : '0px')};
  }
`;

export const InputItemContainer = styled.div`
  display: flex;
  position: relative;
`;

export const Input = styled.input`
  transition: 225ms;
  display: block;
  box-sizing: border-box;
  font-family: MontserratLight;
  font-size: ${({ primary }) => (primary ? '16px' : '16px')};
  border-width: 0px 0px 2px 0px;
  border-color: ${(props) =>
    props.theme.login.usernameAndPasswordInput.borderColor}${(props) => (props.isFocused ? '66' : '')};
  color: ${(props) => props.theme.login.usernameAndPasswordInput.color};
  background-color: transparent;
  margin: 0;
  padding: 10px 8px;
  min-width: 24.265vw;
  width: 100%;
  ${(props) => (props.hasIcon ? 'padding-right: 28px;' : '')};
  margin-bottom: ${({ bottomSpacing }) => (bottomSpacing ? '28px' : '0px')};
  :focus {
    outline: 0px;
    border-color: ${(props) =>
    props.theme.login.usernameAndPasswordInput.focusBorderColor};
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${({ primary }) => (primary ? '16px' : '16px')};
    font-weight: 500;
    color: ${(props) =>
    props.theme.login.usernameAndPasswordInput[
    '-webkit-input-placeholder_color'
    ]};
    margin-bottom: 0px;
  }
  :-ms-input-placeholder {
    font-size: ${({ primary }) => (primary ? '16px' : '16px')};
    font-weight: 400;
    color: ${(props) =>
    props.theme.login.usernameAndPasswordInput[
    '-ms-input-placeholder_color'
    ]};
    margin-bottom: 0px;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const LoginButton = styled.button`
  transition: max-height  100ms, padding 100ms, margin-top 100ms, opacity 100ms;
  padding: 18px 50px;
  border-radius: 20px;
  background-color: ${({ theme }) =>
    theme.login.formSubmitButton.backgroundColor};
  align-self: center;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.Cor_auxiliar_01};
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  font-family: MontserratMedium;
  font-size: 20px;
  margin: 45px auto 0 auto;
  :focus {
    outline: 0px;
  }
  :disabled {
    opacity: 0.6;
  }

  ${({ activeKeyboard }) => !activeKeyboard ? '' : `
    max-height: 0;
    overflow: hidden;
    margin-top: 0;
    padding: 0;
    opacity: 0;
  `}

  @media (max-width: 1400px) {
    margin-top: 20px;
  }
`;

export const ForgotPasswordText = styled.a`
  margin: 0px;
  color: ${(props) => props.theme.login.forgotPasswordLabel.color};
  cursor: pointer;
  text-decoration: underline;
  font-family: MontserratLight;
  font-size: 16px;
  display: block;
  text-align: center;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.4px;
  margin-top: 28px;

  @media (min-width: 1600px) {
    font-size: 20px;
  }
`;

export const BackFirstLoginStepButton = styled.button`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-weight: 1000;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_01};
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.brandColor3};
`;

export const RulesText = styled.span`
  font-size: 8px;
  color: ${(props) => props.theme.Cor_base_marca_05};
  opacity: 0.8;
`;

export const KeyboardIcon = styled(Keyboard)`
  display: flex;
  align-self: center;
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.login.passwordInputIcon.color};
  position: absolute;
  right: 0;
  bottom: 8px;
  pointer-events: none;
`;

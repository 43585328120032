import styled from 'styled-components';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const Title = styled.label`
  margin: 0;
  font-family: 'MontserratBold';
  font-size: 18px;
  line-height: 16px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 24.487vw;
  align-self: flex-start;
  color: #707070;
`;

export const Text = styled.label`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  max-width: 31.965vw;

  strong {
    font-family: 'MontserratMedium';
  }
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckText = styled.a`
  font-family: 'MontserratXLight';
  font-size: 10px;
  margin-left: 10px;
  line-height: 12px;
  color: #707070 !important;
  max-width: 30.499vw;
  text-transform: uppercase;
  text-decoration: none !important;
  &:hover {
    cursor: pointer;
  }
`;

export const BtnContinue = styled.button`
  transition: 225ms ease-in;
  padding: 16px 40px;
  border-radius: 40px;
  background: none !important;
  background-color: ${(props) => props.theme.Cor_base_marca_01} !important;
  margin-top: 28px;
  margin-left: 74px;
  width: auto;
  font-size: 12px;
  line-height: 14px;
  font-family: 'MontserratLight';
  color: #fff;
  opacity: 1;
  ${(props) => (!props.disabled ? '' : 'opacity: 0.56;')}
  pointer-events: ${(props) => (!props.disabled ? 'all' : 'none')};
  outline: none !important;
  border: none !important;
  ${(props) =>
    !props.outline
      ? ''
      : `
    background-color: #fff !important;
    border: 1px solid ${(props) => props.theme.Cor_base_marca_01} !important;
    color: ${(props) => props.theme.Cor_base_marca_01};;
  `}
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  ${(props) =>
    !props.outline
      ? ''
      : `
    background-color: rgb(80, 195, 199, 0.1) !important
  `}
  }
`;

export const BottomInfo = styled.span`
  font-family: 'MontserratXLight';
  font-size: 14px;
  line-height: 17px;
  color: #707070 !important;
  align-self: center;
  text-decoration: none;
  margin-top: 10px;
  & > a {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`;

import styled from 'styled-components';
import { Close } from 'styled-icons/evil/Close';
import { AlertCircle } from 'styled-icons/feather/AlertCircle';

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
`;

export const CloseButton = styled(Close)`
  font-weight: bold;
  cursor: pointer;
`;

export const AlertIcon = styled(AlertCircle)`
  margin-bottom: 25px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const Text = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: ${(props) => props.theme.brandColor3};
  margin-bottom: 25px;
  align-self: flex-start;

  strong {
    text-transform: capitalize;
  }
`;

export const Title = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin: 0px;
  color: ${(props) =>
    props.type === 'error'
      ? props.theme.danger
      : props.type === 'alert'
      ? props.theme.alert
      : props.theme.black};
`;

export const AttentionMessage = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: ${(props) => props.theme.danger};
  margin-bottom: 25px;
  align-self: flex-start;
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const GreenButton = styled.button`
  width: 291px;
  height: 50px;
  border-radius: 30px;
  background-color: ${(props) => props.theme.Cor_base_marca_01};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.Cor_auxiliar_01};
  outline: none;
  cursor: pointer;
`;

export const ExtraButton = styled.button`
  width: 291px;
  height: 50px;
  border: none;
  background-color: ${(props) => props.theme.Cor_auxiliar_01};
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
  color: ${(props) => props.theme.Cor_base_marca_01};
  outline: none;
  cursor: pointer;
`;

export const RedButton = styled.button`
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 88px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 2px;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  color: ${(props) => props.theme.danger};
`;

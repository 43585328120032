export const OUTSTANDING_PAYMENTS_TEXT = [
  {
    title: 'TIPO DE OPERAÇÃO',
    fieldName: 'service',
  },
  {
    title: 'STATUS',
    fieldName: 'statusText',
  },
  {
    title: 'QTD APROVAÇÕES',
    fieldName: 'qtyApprovals',
  },
  {
    title: 'DATA DE PAGAMENTO',
    fieldName: 'operationDate',
  },
  /*   {
    title: 'HISTÓRICO',
    fieldName: 'history',
  }, */
  /*   {
    title: 'CPF/CNPJ BENEFICIÁRIO',
    fieldName: 'beneficiaryDocument',
  }, */
  {
    title: 'NOME BENEFICIÁRIO',
    fieldName: 'beneficiary',
  },
  {
    title: 'OPERADOR RESPONSÁVEL',
    fieldName: 'userRequest',
  },
  {
    title: 'VALOR',
    fieldName: 'value',
  },
];

const colorsExpectedStructure = {
  default: {
    pageTitle: {
      color: 'string',
    },
  },
  login: {
    numericInput: {
      backgroundColor: 'string',
      borderColor: 'string',
      color: 'string',
    },
    numericInputConfirmationButton: {
      backgroundColor: 'string',
      color: 'string',
      borderColor: 'string',
    },
    numericInputClearButton: {
      backgroundColor: 'string',
      color: 'string',
      borderColor: 'string',
    },
    forgotPasswordLabel: {
      color: 'string',
    },
    passwordInputIcon: {
      color: 'string',
    }
  },
  forgotPassword: {
    confirmationButton: {
      backgroundColor: 'string',
      color: 'string',
      borderColor: 'string',
    },
    backButton: {
      backgroundColor: 'string',
      color: 'string',
      borderColor: 'string',
    },
  },
  bankStatement: {
    topLabelTitle: {
      color: 'string',
    },
  },
  home: {
    header: {
      backgroundColor: 'string',
    },
    headerAccountLabel: {
      color: 'string',
    },
    headerIcons: {
      color: 'string',
    },
    sideBarItemBackgroundWhenSelected: {
      backgroundColor: 'string',
    },
    sideBarItemBackground: {
      backgroundColor: 'string',
    },
    sideBarItemLabelWhenSelected: {
      color: 'string',
    },
    sideBarItemLabel: {
      color: 'string',
    },
    sideBarItemIconWhenSelected: {
      color: 'string',
    },
    sideBarItemIcon: {
      color: 'string',
    },
    wellcomTitle: {
      color: 'string',
    },
  },
};

export function validateColorsStructure(
  obj,
  structure = colorsExpectedStructure
) {
  for (const key in structure) {
    if (structure.hasOwnProperty(key)) {
      const expectedType = structure[key];
      const value = obj[key];

      if (typeof expectedType === 'object') {
        // Se o elemento da estrutura for um objeto, valide recursivamente
        if (
          typeof value !== 'object' ||
          !validateColorsStructure(value, expectedType)
        ) {
          return false;
        }
      } else {
        // Verificar se a propriedade existe no objeto e se é do tipo esperado
        if (value === undefined || typeof value !== expectedType) {
          return false;
        }
      }
    }
  }
  return true;
}

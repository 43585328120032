export const CREDITS_TEXT = [
    {
        title: "FORMA DE PAGAMENTO",
        fieldName: 'paymentMethod',
    },
    {
        title: "MODALIDADE DOS JUROS",
        fieldName: 'taxModality',
    },
    {
        title: "SITUAÇÃO DO CONTRATO",
        fieldName: 'status',
    },
    {
        title: "INDEXADOR",
        fieldName: 'indexer',
    },
    {
        title: "CLIENTE",
        fieldName: 'customerName',
    },
    {
        title: '% INDEXADOR',
        fieldName: 'indexerPercentage'
    },
    {
        title: "CONTA",
        fieldName: 'account',
    },
    {
        title: "TAXA DE JUROS EFETIVA",
        fieldName: 'taxInterest',
    },
    {
        title: "CONTRATO RENEGOCIADO",
        fieldName: 'renegotiation',
    },
];
import styled from 'styled-components';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

const BoldTextStyle = styled.h1`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${props => props.theme.brandColor3};
  margin: 0px;
  font-family: 'SourceSansPro', Arial, sans-serif;
`;

export const HeaderTitle = styled(BoldTextStyle)`
  font-size: 15px;
`;

export const NameTitle = styled(BoldTextStyle)`
  align-self: ${props => (props.personType === 'PF' ? 'flex-start' : 'center')};
  font-size: 15px;
  color: ${props => props.theme.brandColor3};
  font-family: 'SourceSansPro', Arial, sans-serif;
`;

export const FieldLabel = styled(BoldTextStyle)`
  font-size: 14px;
  color: ${props => props.theme.brandColor3};
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'initial')};
`;

export const TextInput = styled.input.attrs({ type: 'text', key: 'name' })`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 14px;
  font-weight: 300;
  border-width: 0px 0px 0px 0px;
  border-bottom: ${props => (props.isDisabled ? 'none' : `solid 2px ${props.theme.brandColor5}`)};
  color: ${props => props.theme.brandColor3};
  background-color: transparent;
  text-align: left;
  padding-left: 8px;
  padding-bottom: 7px;
  padding-top: 5px;
  margin-top: 20px;
  margin: 0;
  text-transform: uppercase;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'initial')};

  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
  :-ms-branchinput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.brandColor3};
    margin-bottom: 0px;
  }
`;

export const IsOpenedIcon = styled(ArrowDropUp)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

export const IsNotOpenedIcon = styled(ArrowDropDown)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

import React, { useState, useEffect } from 'react';

import { Container, ItemContainer, PortfolioName, TopLabel, ButtonsWrapper } from './styles';
import Button from 'components/Button';
import { resolveTransfersResumeInformations } from 'utils/transformers/transfers';



function TransferResumeList({ data = [], selectedType, isSecurityAccountReview }) {

    const [parsedData, setParsedDate] = useState([]);

    useEffect(() => {
        if (isSecurityAccountReview) {
            setParsedDate([...data]);

        } else {
            setParsedDate(resolveTransfersResumeInformations({ data, selectedType }))
        }
    },
        [data]
    )
    return (
        <Container>
            <TopLabel>{`CONFIRME OS DADOS DA SUA SOLICITAÇÃO DE ${isSecurityAccountReview? 'OPERAÇÃO' : 'TRANSFERÊNCIA'} ABAIXO:`}</TopLabel>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '40px 100px', width: '80%', maxWidth: '1000px' }}>
                {parsedData.map(e => (
                    <ItemContainer id="transferInformationItemId">
                        <PortfolioName>{e.field}</PortfolioName>
                        <PortfolioName>{e.value}</PortfolioName>
                    </ItemContainer>
                ))}
            </div>
        </Container>

    )
}

export default TransferResumeList;
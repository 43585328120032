import styled from "styled-components";

export const Value = styled.div`
  text-align: right;
  color: ${props =>
    props.value
      ? props.value.startsWith("-")
        ? "red"
        : props.theme.success
      : props.theme.black};
`;

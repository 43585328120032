import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Text = styled.span`
  color: ${props => props.theme.home.wellcomTitle.color};
  font-family: 'Segoe UI',Tahoma,Geneva,Verdana,sans-serif;
  font-weight: 300;
  font-size: 24px;
`;

export const BoldedText = styled(Text)`
  text-transform: capitalize;
  font-weight: bold`;

import styled from 'styled-components';

export const Container = styled.div`
    pointer-events: ${props => props.isDisabled ? 'none' : 'initial'};
    opacity: ${props => props.isDisabled ? 0.4 : 1.0};

    .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    margin-right: 15px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: ${props => props.theme.brandColor5};
    border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
    background-color: ${props => props.theme.brandColor5};
    }

    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
    background-color: ${props => props.theme.brandColor6};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
    display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 7px;
        left: 7px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: white;
    }
`;
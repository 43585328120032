import React from 'react';

import { RowWrapper, ColumnWrapper, Text } from '../common';
import { RevisionIcon, UpdatedIcon, UnupdatedIcon } from './styles';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const LocalStatusComponent = ({
  isEditingMode,
  personType,
  buttonDisability,
  crudStatus
}) => (
  <RowWrapper
    style={{
      cursor: 'pointer',
      width: '100%',
      marginRight: '10px'
    }}
  >
    {!buttonDisability ? (
      <>
        <RevisionIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.alert }}>
          {' '}
          Aguardando Envio
        </Text>
      </>
    ) : crudStatus === 'D' ? (
      <>
        <UnupdatedIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.danger }}>
          {' '}
          Sócio excluído
        </Text>
      </>
    ) : (
      <>
        <UnupdatedIcon style={{ fontSize: '8px', marginRight: '2px' }} />
        <Text style={{ fontWeight: 'bold', color: theme.danger }}>
          {' '}
          Aguardando Atualização
        </Text>
      </>
    )}
  </RowWrapper>
);

export default LocalStatusComponent;

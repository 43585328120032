import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { AccountService, CashInService } from 'banking-service';
import Button from 'components/Button';

import { Text } from 'tpz-shared-components';
import CustomDatePicker from 'components/CustomDatePicker';
import {
  revertBrazilianCurrencyToFloat,
  handleCurrency
} from 'utils/functions/currency';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  ContentWrapper,
  NoItemsAvailableLabel,
  ActionsWrapper,
  ItemContainer,
  ColumnWrapper,
  RowWrapper,
  Title,
  DownloadButton,
  ColoredValue,
  DownloadIcon,
  AmountInput,
  CopyToCliboardIcon
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';

const DEFAULT_MAX_AMOUNT = 5000

function k(i, callback, form, maxValue) {
  var v = i.target.value.replace(/\D/g, '');
  if (v > maxValue * 100) v = maxValue * 100;
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  i.value = v;
  callback({ ...form, data: { ...form.data, amount: `R$ ${i.value}` } });
}

const LIMIT_IN_YEARS = 2;

const currentYear = new Date().getFullYear();
const optionsGenerator = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const options = optionsGenerator(
  currentYear - 1,
  currentYear - LIMIT_IN_YEARS,
  -1
).map(e => ({ value: e, label: e }));

function CashIn({ section = 'Depósitos', name = 'DEPÓSITO POR BOLETO' }) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [generatedBankSlip, setGeneratedBankSlip] = useState({});

  const [form, setForm] = useState({
    data: { amount: '', date: moment().add(1, 'days') },
    errors: { amount: true, date: false },
    touched: { amount: false, date: false }
  });

  function copyToClipboard(e) {
    var textField = document.createElement('textarea');
    textField.innerText = generatedBankSlip.typeableLine;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setCopySuccess('Copied!');
  }

  const [copySuccess, setCopySuccess] = useState(null);
  useEffect(() => {
    if (copySuccess)
      alert.success('Linha digitável copiada com sucesso', {
        timeout: 4000,
        onClose: () => {
          setCopySuccess(null);
        }
      });
  }, [copySuccess]);

  const downloadRef = useRef();

  const handleGenerateBankslip = async () => {
    setIsLoading(true);
    try {
      const res = await CashInService.generateBankslip({
        value: Number(revertBrazilianCurrencyToFloat(form.data.amount)),
        dueDate: moment(form.data.date).format('YYYY-MM-DD'),
        channel: 'IB'
      });
      setGeneratedBankSlip({ ...res });
      setHasGenerated(true);
    } catch (e) {
      setGeneratedBankSlip({});
      setError(e);
    }
    setIsLoading(false);
  };

  const [bankSlipBase64, setBankSlipBase64] = useState(null);
  const handleDownloadBase64 = async () => {
    setIsLoading(true);
    try {
      if (!bankSlipBase64) {
        const res = await CashInService.getBankslipInfo({
          transaction_id: generatedBankSlip.transaction_id
        });
        console.log({ res });
        setBankSlipBase64(res.base64pdf);
      }
      downloadRef.current.click();
    } catch (e) {
      setBankSlipBase64(null);
      setError(e);
    }
    setIsLoading(false);
  };

  const [statistics, setStatistics] = useState({
    config: { amountMax: DEFAULT_MAX_AMOUNT, },
    hasLoadedFromApi: false,
  })

  useEffect(() => {
    const fetchStatistics = async () => {
      setIsLoading(true);

      try {
        const res = await CashInService.fetchStatistics({})
        setStatistics({ ...res, hasLoadedFromApi: true })

      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    }

    fetchStatistics()

  }, [])


  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          {!hasGenerated && (
            <>
              <Text
                style={{ letterSpacing: '0.32px', lineHeight: '1.35px' }}
                fontFamily="segoeui"
                color={theme.brandColor3}
              >
                DADOS DO BOLETO
              </Text>
              <ActionsWrapper style={{ marginBottom: '40px' }}>
                <ColumnWrapper style={{ flex: 1.0 }}>
                  <Text style={{ whiteSpace: 'nowrap' }} fontWeight={'bold'}>
                    Valor do Boleto:
                  </Text>
                  <Text style={{ whiteSpace: 'nowrap' }} fontWeight={'bold'}>
                    Data de Vencimento:{' '}
                  </Text>
                </ColumnWrapper>

                <ColumnWrapper style={{ flex: 1 }}>
                  <AmountInput
                    style={{ width: '125px', margin: '16px 0px 16px 40px' }}
                    id="amountInputId"
                    type="text"
                    name="amount"
                    hasErrors={form.errors.amount && form.touched.amount}
                    value={form.data.amount}
                    onChange={e => {
                      setForm({
                        ...form,
                        data: { ...form.data, amount: e.target.value },
                        touched: { ...form.touched, amount: true },
                        errors: {
                          ...form.errors,
                          amount:
                            e.target.value === '' ||
                            Number(
                              revertBrazilianCurrencyToFloat(e.target.value)
                            ) < 20 ||
                            Number(
                              revertBrazilianCurrencyToFloat(e.target.value)
                            ) > statistics.config.amountMax
                        }
                      });
                    }}
                    onKeyUp={e => k(e, setForm, form, statistics.config.amountMax)}
                    placeholder={'R$ *'}
                  />
                  <CustomDatePicker
                    style={{ margin: '0px 0px 0px 20px' }}
                    id="datePickerId"
                    date={form.data.date}
                    onChange={newDate =>
                      setForm(val => ({
                        ...val,
                        data: { ...val.data, date: newDate }
                      }))
                    }
                    disAllowFutureDates={false}
                    disAllowPastDates
                  />
                </ColumnWrapper>
                <ColumnWrapper style={{ alignSelf: 'center', flex: 3 }}>
                  {statistics.hasLoadedFromApi && <Text size={'x-small'} style={{ marginLeft: '40px' }}>
                    BOLETOS EMITIDOS NO MÊS: <strong>{statistics.totalGenerated}</strong>
                  </Text>}
                  {statistics.hasLoadedFromApi && <Text size={'x-small'} style={{ marginLeft: '40px' }}>
                    MÁXIMO DE EMISSÕES NO MÊS: <strong>{statistics.quota}</strong>
                  </Text>}
                  <Text size={'x-small'} style={{ marginLeft: '40px' }}>
                    VALOR PERMITIDO: ENTRE <strong>R$20,00</strong> E{' '}
                    <strong>{handleCurrency({ value: statistics.config.amountMax })}</strong>
                  </Text>
                </ColumnWrapper>
              </ActionsWrapper>
              <Button
                disabled={Object.values(form.errors).some(e => e) || statistics.totalGenerated >= statistics.quota}
                onClick={() => handleGenerateBankslip()}
                title={'GERAR BOLETO'}
                style={{
                  marginLeft: '10px',
                  width: '200px',
                  alignSelf: 'flex-end'
                }}
              />
            </>
          )}

          {hasGenerated && (
            <ColumnWrapper>
              <ActionsWrapper
                style={{
                  marginBottom: '40px',
                  width: '100%',
                  alignItems: 'flex-start'
                }}
              >
                <ColumnWrapper
                  style={{
                    color: theme.brandColor3,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    alignItems: 'flex-end',
                    flex: 0
                  }}
                >
                  <Text size={'small'}>Favorecido:</Text>
                  <Text size={'small'}>Valor do Boleto:</Text>
                  <Text size={'small'}>Vencimento:</Text>
                  <Text size={'small'}>Boleto:</Text>
                </ColumnWrapper>
                <ColumnWrapper
                  style={{
                    fontWeight: 'bold',
                    color: theme.black,
                    marginLeft: '20px',
                    flex: 3
                  }}
                >
                  <Text size={'small'}>{AccountService.account.name}</Text>
                  <Text size={'small'}>
                    {handleCurrency({
                      value: Number(
                        parseFloat(generatedBankSlip.value).toFixed(2)
                      )
                    })}
                  </Text>
                  <Text size={'small'}>
                    {moment(generatedBankSlip.dueDate).format('DD/MM/YYYY')}
                  </Text>
                  <RowWrapper style={{ marginTop: '10px' }}>
                    <Text m={0} size={'small'}>
                      {generatedBankSlip.typeableLine}
                    </Text>
                    <CopyToCliboardIcon
                      onClick={copyToClipboard}
                    ></CopyToCliboardIcon>
                  </RowWrapper>
                </ColumnWrapper>
              </ActionsWrapper>
              <Button
                onClick={() => handleDownloadBase64()}
                title={'DOWNLOAD DO BOLETO'}
                style={{
                  marginLeft: '10px',
                  width: '280px',
                  alignSelf: 'flex-end'
                }}
              />
              <a
                style={{ display: 'none' }}
                ref={downloadRef}
                href={'data:application/octet-stream;base64,' + bankSlipBase64}
                download={`DEPOSITO_POR_BOLETO_${moment().format(
                  'DD-MM-YYYY'
                )}_BANCOTOPÁZIO.pdf`}
              ></a>
            </ColumnWrapper>
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default CashIn;

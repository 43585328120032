import React, { useState } from "react";
import {
  Container,
  Value,
  Text,
  ToggleContainer,
  ToggleText,
  ToggleIcon
} from "./styles";

import { handleCurrency } from "utils/functions/currency";

function CurrentAccountValue({ title, value }) {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Container isVisible={isVisible}>
      <Text>{title}</Text>
      <ToggleContainer
        onClick={() => {
          setIsVisible(!isVisible);
        }}
      >
        <ToggleText>{isVisible ? "ocultar" : "exibir"}</ToggleText>
        <ToggleIcon size={16} />
        {isVisible ? <Value>{handleCurrency({ value })}</Value> : null}
      </ToggleContainer>
    </Container>
  );
}

export default CurrentAccountValue;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import {
  DocumentsUpdateService,
  BankService,
  AccountService,
  TokenService
} from 'banking-service';
import { useAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import DocumentsUpdatePF from './DocumentsUpdatePF';
import DocumentsUpdatePJ from './DocumentsUpdatePJ';
import ContentLoader from 'react-content-loader';
import LocalErrorHandler from './components/LocalErrorHandler';
import GeneralStatusComponent from './components/GeneralStatusComponent';
import { ColumnWrapper, RowWrapper, Text } from './components/common';
import { hasActiveToken } from 'utils/functions/token';

import Button from 'components/Button';
import { useModalWithData, ModalWrapper } from 'components/CustomModal';
import ConfirmModal from 'components/ConfirmModal';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

import {
  hasToUpdateAndHasCERButHasnTouched,
  hasToUpdateAndHasCNHButHasnTouched,
  hasTouchedFieldButHasnAddedFile,
  hasToSendCERAndHasntAddedDocument,
  hasToSendCNHAndHasntAddedDocument,
  hasTouchedAnyFieldButHasnAddedFile,
  hasAtLeastOneReference,
  hasAtLeastOneDocument,
  resolvePayloadPF,
  resolvePayloadPJ,
  resolveErrors,
  resolveFilename
} from './utils';

import {
  Container,
  ContentWrapper,
  Divisor,
  CustomButton,
  EditIcon,
  AddIcon
} from './styles';
import { parseUserInformations, parsePjInformations } from './utils';

const MyLoader = () => (
  <ContentLoader
    style={{ flex: 1 }}
    height={600}
    width={800}
    speed={2}
    primaryColor={theme.brandColor5}
    secondaryColor={theme.brandColor5}
  >
    <rect x="3" y="134" rx="3" ry="3" width="245" height="17" />
    <rect x="82" y="196" rx="3" ry="3" width="572" height="13" />
    <rect x="197" y="249" rx="3" ry="3" width="350" height="14" />
    <rect x="288" y="305" rx="3" ry="3" width="175" height="15" />
  </ContentLoader>
);

function DocumentsUpdate({
  section = 'Serviços',
  name = 'Dados Cadastrais',
  ...props
}) {
  const [confirm, hideConfirm] = useModalWithData(modalParams => () => (
    <ConfirmModal {...modalParams} hideConfirm={hideConfirm} />
  ));
  const { clientConfig } = useConfigContext();

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [showTokenValidation, hideTokenValidation] = useModalWithData(
    modalParams => () => (
      <ConfirmModal {...modalParams} hideConfirm={hideConfirm} />
    )
  );

  useEffect(() => {
    let tokenOtp = '';
    const showAlert = ({ token }) => {

      showTokenValidation({
        type: 'alert',
        title: 'Validação por Token',
        messageLineOne:
          'Para maior segurança dos seus dados cadastrais, solicitamos a validação por Token antes de acessar esta página.',
        messageLineTwo:
          `É só você gerar um Token através do aplicativo ${clientConfig.partner.brand_handle === 'M' ? 'do' : 'da'} ${clientConfig ? clientConfig.partner.name : ''} e digitá-lo abaixo:`,
        children: (
          <input
            onChange={ev => {
              tokenOtp = ev.target.value;
            }}
            style={{
              border: 'none',
              borderBottom: `solid 2px ${theme.brandColor5}`,
              outline: 'none',
              color: theme.brandColor3,
              textAlign: 'center',
              fontSize: '16px',
              maxWidth: '90px'
            }}
          ></input>
        ),
        onConfirmClick: async () => {
          try {
            const res = await TokenService.validate(token.serial, tokenOtp);
            setShouldFetchData(true);
            hideTokenValidation();
          } catch (e) {
            setError(
              e.error && e.error.length > 0
                ? e.error[0]
                : 'Ocorreu um erro na verificação do seu token'
            );
          }
        },
        confirmText: 'VALIDAR TOKEN',
        onCancelClick: () => {
          hideTokenValidation();
          // props.history.push('/');
        },
        cancelText: 'CANCELAR'
      });
    };

    const fetchTokenInfo = async () => {
      try {
        setInitialLoading({ ...isLoading, informations: true });
        const res = await TokenService.getInfo();
        const token = hasActiveToken({ data: res });
        if (!!token) {
          setShouldFetchData(true);

          //showAlert({ token });
        } else {
          setShouldFetchData(true);
        }
      } catch (e) {
        setDependenciesError(true);
      }
    };

    fetchTokenInfo();
  }, []);

  const alert = useAlert();
  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [initialLoading, setInitialLoading] = useState({
    informations: false,
    banks: false
  });

  const [loadingText, setLoadingText] = useState('');
  const [isLoading, setIsLoading] = useState({
    isSubmitingForm: false
  });

  const [dependenciesError, setDependenciesError] = useState(false);
  const [banks, setBanks] = useState([]);
  const [forms, setForms] = useState([]);
  const [formsBackup, setFormsBackup] = useState([]);
  const [documentsStatus, setDocumentsStatus] = useState({});

  const resolvePersonType = () => {
    return AccountService.account.taxIdentifier.length > 11 ? 'PJ' : 'PF';
  };

  const handleSetFormIsOpened = (index, value) => {
    setForms(val =>
      val.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            isOpened: value
          };
        } else {
          return {
            ...e,
            isOpened: false
          };
        }
      })
    );
  };

  const handleSetFormData = ({ index, newValue, objectName }) => {
    setForms(val =>
      val.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            crudStatus: resolveCrudStatus(e.crudStatus),
            [objectName]:
              objectName === 'files' ? [...newValue] : { ...newValue },
            buttonDisability: resolveButtonDisability({
              data: objectName === 'data' ? { ...newValue } : e.data,
              files: objectName === 'files' ? [...newValue] : e.files,
              touched: objectName === 'touched' ? { ...newValue } : e.touched,
              errors: objectName === 'errors' ? { ...newValue } : e.errors,
              type: e.type
            })
          };
        } else {
          return { ...e };
        }
      })
    );
  };

  function resolveCrudStatus(oldStatus) {
    if (oldStatus === 'E') {
      return 'U';
    } else return oldStatus;
  }

  useEffect(() => {
    const fetchPJDependencies = async () => {
      setInitialLoading(loading => ({ ...loading, informations: true }));
      try {
        const banks = await fetchBanks();
        setBanks(banks);
        const status = await fetchDocumentsUpdateStatus({});
        setDocumentsStatus(status);
        const pjInfos = await fetchPjInformations({ banks });
        const userInfos = await fetchUserInformations({ banks });
        setForms([{ ...pjInfos }, ...userInfos]);
        setFormsBackup([{ ...pjInfos }, ...userInfos]);
      } catch (e) {
        setError(e);
        setDependenciesError(true);
      }

      setInitialLoading(loading => ({ ...loading, informations: false }));
    };

    const fetchPFDependencies = async () => {
      setInitialLoading(loading => ({ ...loading, informations: true }));
      try {
        const banks = await fetchBanks();
        setBanks(banks);
        const status = await fetchDocumentsUpdateStatus({});
        setDocumentsStatus(status);
        const userInfos = await fetchUserInformations({ banks });
        setForms([{ ...userInfos[0] }]);
      } catch (e) {
        setDependenciesError(true);
      }

      setInitialLoading(loading => ({ ...loading, informations: false }));
    };
    const fetchBanks = async () => {
      const res = await BankService.getBanks();
      const parsedRes = res.map(e => ({
        label: e.name,
        value: e
      }));
      return parsedRes;
    };

    const fetchDocumentsUpdateStatus = async ({}) => {
      const res = await DocumentsUpdateService.fetchDocumentsStatus({});
      return res;
    };

    const fetchUserInformations = async ({ banks }) => {
      const res = await DocumentsUpdateService.fetchUserInformations({});

      const parsedRes = res
        .map(e => ({
          data: parseUserInformations({ data: e, banks }),
          files: [],
          type: 'PF',
          touched: {},
          errors: resolveErrors(parseUserInformations({ data: e, banks })),
          crudStatus: 'E'
        }))
        .map((e, index) => ({
          ...e,
          buttonDisability: resolveButtonDisability({
            data: e.data,
            files: [],
            touched: {},
            type: 'PF',
            errors: e.errors
          })
        }));
      return parsedRes;
    };

    const fetchPjInformations = async ({ banks }) => {
      const res = await DocumentsUpdateService.fetchPjInformations({});

      const parsedPayload = parsePjInformations({ data: res, banks });

      const parsedRes = {
        files: [],
        data: { ...parsedPayload },
        buttonDisability: false,
        type: 'PJ',
        touched: {},
        errors: resolveErrors(parsedPayload)
      };
      return parsedRes;
    };

    if (shouldFetchData) {
      if (AccountService.account.taxIdentifier.length > 11) {
        fetchPJDependencies();
      } else {
        fetchPFDependencies();
      }
    }
  }, [shouldFetchData]);

  function resolveButtonDisability({ data, touched, files, type, errors }) {
    if (type === 'PF') {
      return (
        !data.isChecked ||
        documentsStatus.status === 'revision' ||
        Object.entries(errors).some(([key, value]) => value === true) ||
        data.countryResidenceCode.value.id !== 76 ||
        hasToUpdateAndHasCERButHasnTouched({ data }) ||
        hasToUpdateAndHasCNHButHasnTouched({ data }) ||
        hasToSendCNHAndHasntAddedDocument({ data }) ||
        hasToSendCERAndHasntAddedDocument({ data }) ||
        hasTouchedFieldButHasnAddedFile({ data, files }) ||
        !hasAtLeastOneReference({ data }) ||
        !hasAtLeastOneDocument({ data })
      );
    } else {
      return (
        !data.isChecked ||
        Object.entries(errors).some(([key, value]) => value === true) ||
        !hasAtLeastOneReference({ data }) ||
        hasTouchedAnyFieldButHasnAddedFile({ touched, files })
      );
    }
  }

  function resolveHighLevelButtonDisability({ forms }) {
    return forms.some(e => e.buttonDisability === true);
  }

  async function handleSubmitFormPF(e) {
    const sanitizedPayload = resolvePayloadPF({
      data: { ...forms[0].data },
      files: [...forms[0].files]
    });

    setIsLoading(val => ({ ...val, isSubmitingForm: true }));
    try {
      const formRes = await DocumentsUpdateService.postUserInformations({
        payload: sanitizedPayload
      });

      if (forms.some(e => e.files.length > 0))
        setLoadingText('Enviando arquivos. Isso pode demorar um pouco');

      if (forms[0].files.length > 0) {
        const filesRes = await DocumentsUpdateService.postUserFiles({
          files: forms[0].files.map((e, i) => ({
            filename: resolveFilename({
              type: 'PF',
              index: i,
              filename: e.filename
            }),
            base64: e.base64
          })),
          requestId: formRes.request
        });
      }

      alert.success(
        'Atualização Cadastral efetuada com sucesso! Em caso de inconsistências entraremos em contato.',
        { timeout: 0 }
      );
      props.history.push('/');
    } catch (e) {
      setError(e);
    }
    setLoadingText('');

    setIsLoading(val => ({ ...val, isSubmitingForm: false }));
  }

  console.log(forms);

  function handleAddPF() {
    alert.show('Sócio adicionado com sucesso');
    setForms(val => [
      ...val,
      {
        data: {
          ...parseUserInformations({
            data: {
              birthCountryCode: 76,
              countryResidenceCode: 76,
              nacionality: 'BRASILEIRA',
              birthCountry: 'BRASIL'
            },
            banks
          })
        },
        isEditingMode: true,
        files: [],
        type: 'PF',
        touched: {},
        errors: resolveErrors(
          parseUserInformations({
            data: {
              birthCountryCode: 76,
              countryResidenceCode: 76
            },
            banks
          })
        ),
        crudStatus: 'I'
      }
    ]);
  }

  function handleRemovePF(index, crudStatus) {
    confirm({
      title: 'Exclusão de Pessoa Física',
      messageLineOne:
        'Você tem certeza que deseja excluir o cadastro deste sócio?',
      onConfirmClick: () => {
        if (crudStatus === 'I') {
          setForms(val => val.filter((e, i) => i !== index));
        } else {
          setForms(val =>
            val.map((e, i) => {
              if (i === index) {
                return {
                  ...e,
                  crudStatus: 'D',
                  buttonDisability: false,
                  isOpened: false
                };
              } else {
                return { ...e };
              }
            })
          );
        }
      },
      confirmText: 'SIM, QUERO EXCLUIR',
      onCancelClick: () => {},
      cancelText: 'CANCELAR'
    });
  }

  async function handleSubmitFormPJ() {
    const sanitizedPayloadPJ = resolvePayloadPJ({
      data: { ...forms[0].data },
      files: [...forms[0].files]
    });

    const persons = forms
      .filter(e => e.type === 'PF' && e.crudStatus !== 'D')
      .map(e =>
        resolvePayloadPF({
          data: e.data,
          files: e.files
        })
      );

    const personsDeleted = forms
      .filter(e => e.type === 'PF' && e.crudStatus === 'D')
      .map(e => {
        const search = formsBackup.find(
          el => el.data.taxIdentifier === e.data.taxIdentifier
        );
        console.log(search);
        return {
          name: search.data.name,
          taxIdentifier: e.data.taxIdentifier
        };
      });

    const promisesArray = [];

    setIsLoading(val => ({ ...val, isSubmitingForm: true }));
    const payload = { ...sanitizedPayloadPJ, persons, personsDeleted };
    console.log({ sentPayload: payload });
    try {
      const formRes = await DocumentsUpdateService.postPJInformations({
        payload
      });

      if (forms[0].files.length > 0) {
        promisesArray.push(
          DocumentsUpdateService.postPJFiles({
            files: forms[0].files.map((e, i) => ({
              filename: resolveFilename({
                type: 'PJ',
                index: i,
                filename: e.filename
              }),
              base64: e.base64
            })),
            requestId: formRes.request
          })
        );
      }

      const pjFiles = forms
        .filter(
          e => e.files.length > 0 && e.type === 'PF' && e.crudStatus !== 'D'
        )
        .forEach(async (e, index) =>
          promisesArray.push(
            DocumentsUpdateService.postPJFiles({
              files: e.files.map((e, i) => ({
                filename: resolveFilename({
                  type: `PF_${index}`,
                  index: i,
                  filename: e.filename
                }),
                base64: e.base64
              })),
              requestId: formRes.request
            })
          )
        );

      const filesResult = await Promise.all([...promisesArray]);
      props.history.push('/');

      alert.success(
        'Atualização Cadastral efetuada com sucesso! Em caso de inconsistências entraremos em contato.',
        { timeout: 0 }
      );
    } catch (e) {
      setError(e);
    }
    setIsLoading(val => ({ ...val, isSubmitingForm: false }));
  }

  if (Object.entries(initialLoading).some(([key, value]) => value === true)) {
    return <MyLoader />;
  } else if (dependenciesError) {
    return (
      <LocalErrorHandler
        firstLineMessage={
          'Infelizmente não conseguimos carregar todos os dados necessários'
        }
        secondLineMessage={'Tente novamente mais tarde'}
      />
    );
  } else if (
    forms.some(
      e =>
        e.data &&
        (e.data.countryResidenceCode.value.id !== 76 ||
          (e.data.birthCountryCode && e.data.birthCountryCode.value.id !== 76))
    )
  ) {
    return (
      <LocalErrorHandler
        firstLineMessage={
          'Você, sua empresa ou um dos sócios não consta como domiciliado(a) no Brasil'
        }
        secondLineMessage={
          'Pedimos que entre em contato com nosso SAC para atualizar seu cadastro'
        }
      />
    );
  } else {
    return (
      <LoadingSpinner
        text={loadingText}
        isLoading={Object.entries(isLoading).some(
          ([key, value]) => value === true
        )}
      >
        <Container id={'topContentId'}>
          <ScreenTitle section={section} title={name}></ScreenTitle>
          <ContentWrapper personType={resolvePersonType()}>
            {resolvePersonType() === 'PJ' && forms.length !== 0 && (
              <>
                <ColumnWrapper
                  style={{ backgroundColor: theme.brandColor5, padding: '15px 25px' }}
                >
                  <RowWrapper
                    style={{
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <ColumnWrapper>
                      <GeneralStatusComponent
                        documentsStatus={{ ...documentsStatus }}
                      />
                      <Text
                        style={{
                          fontSize: '14px',
                          color: theme.brandColor5,
                          marginTop: '4px'
                        }}
                      >
                        Última atualização:{' '}
                        {moment(documentsStatus.lastUpdate).format(
                          'DD/MM/YYYY'
                        )}
                      </Text>
                    </ColumnWrapper>
                    <CustomButton
                      disabled={
                        forms.every(e => e.isEditingMode === true) ||
                        documentsStatus.status === 'revision' ||
                        forms.some(e => e.data.countryResidenceCode === 76)
                      }
                      onClick={() => {
                        setForms(val =>
                          val.map(e => ({ ...e, isEditingMode: true }))
                        );
                        alert.show(
                          'Você pode começar a editar seus dados cadastrais'
                        );
                      }}
                    >
                      <EditIcon
                        style={{ fontSize: '14px', marginRight: '5px' }}
                      />
                      Editar Dados Cadastrais
                    </CustomButton>
                  </RowWrapper>
                  <Divisor />
                  <Text style={{ marginBottom: '25px' }}>Pessoa Jurídica</Text>
                  <DocumentsUpdatePJ
                    personType={resolvePersonType()}
                    key={0}
                    index={0}
                    isOpened={forms[0].isOpened}
                    isEditingMode={forms[0].isEditingMode}
                    setFormIsOpened={value => handleSetFormIsOpened(0, value)}
                    buttonDisability={forms[0].buttonDisability}
                    documentsStatus={documentsStatus}
                    formData={forms[0].data}
                    formFiles={forms[0].files}
                    formTouched={forms[0].touched}
                    formErrors={forms[0].errors}
                    setFormData={newValue =>
                      handleSetFormData({
                        index: 0,
                        newValue,
                        objectName: 'data'
                      })
                    }
                    setFormTouched={newValue =>
                      handleSetFormData({
                        index: 0,
                        newValue,
                        objectName: 'touched'
                      })
                    }
                    setFormErrors={newValue =>
                      handleSetFormData({
                        index: 0,
                        newValue,
                        objectName: 'errors'
                      })
                    }
                    setFormFiles={newValue =>
                      handleSetFormData({
                        index: 0,
                        newValue,
                        objectName: 'files'
                      })
                    }
                    banks={banks}
                  />
                </ColumnWrapper>
                <ColumnWrapper
                  style={{ backgroundColor: theme.brandColor5, padding: '15px 25px' }}
                >
                  <Text style={{ marginBottom: '25px' }}>
                    Sócios Pessoa Física
                  </Text>
                  {forms.every(e => e.isEditingMode === true) && (
                    <CustomButton
                      style={{
                        marginBottom: '10px',
                        alignSelf: 'flex-start',
                        paddingLeft: '0px'
                      }}
                      disabled={forms.every(e => e.isEditingMode === false)}
                      onClick={handleAddPF}
                    >
                      <AddIcon
                        style={{ fontSize: '14px', marginRight: '5px' }}
                      />
                      Adicionar Sócio
                    </CustomButton>
                  )}
                  {forms.map((e, index) => {
                    if (index === 0) {
                      return null;
                    } else {
                      return (
                        <>
                          <DocumentsUpdatePF
                            personType={
                              AccountService.account.taxIdentifier.length > 11
                                ? 'PJ'
                                : 'PF'
                            }
                            key={index}
                            isOpened={e.isOpened}
                            isEditingMode={
                              e.isEditingMode && e.crudStatus !== 'D'
                            }
                            setFormIsOpened={value =>
                              handleSetFormIsOpened(index, value)
                            }
                            isRemoveDisabled={
                              forms.filter(
                                e => e.type === 'PF' && e.crudStatus !== 'D'
                              ).length <= 1
                            }
                            crudStatus={e.crudStatus}
                            index={index}
                            buttonDisability={e.buttonDisability}
                            documentsStatus={documentsStatus}
                            formData={e.data}
                            formFiles={e.files}
                            formTouched={e.touched}
                            formErrors={e.errors}
                            handleRemovePF={() =>
                              handleRemovePF(index, e.crudStatus)
                            }
                            setFormData={newValue =>
                              handleSetFormData({
                                index,
                                newValue,
                                objectName: 'data'
                              })
                            }
                            setFormTouched={newValue =>
                              handleSetFormData({
                                index,
                                newValue,
                                objectName: 'touched'
                              })
                            }
                            setFormErrors={newValue =>
                              handleSetFormData({
                                index,
                                newValue,
                                objectName: 'errors'
                              })
                            }
                            setFormFiles={newValue =>
                              handleSetFormData({
                                index,
                                newValue,
                                objectName: 'files'
                              })
                            }
                            banks={banks}
                          />
                          <Divisor
                            style={{
                              backgroundColor:
                                index === forms.length - 1
                                  ? theme.brandColor2
                                  : theme.brandColor5,
                              height: '20px'
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </ColumnWrapper>
              </>
            )}
            {resolvePersonType() === 'PF' && forms.length !== 0 && (
              <ColumnWrapper>
                <ColumnWrapper style={{ width: '100%' }}>
                  <RowWrapper
                    style={{
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <ColumnWrapper>
                      <GeneralStatusComponent
                        documentsStatus={{ ...documentsStatus }}
                      />
                      <Text
                        style={{
                          fontSize: '14px',
                          color: theme.brandColor5,
                          marginTop: '4px'
                        }}
                      >
                        Última atualização:{' '}
                        {moment(documentsStatus.lastUpdate).format(
                          'DD/MM/YYYY'
                        )}
                      </Text>
                    </ColumnWrapper>
                    <CustomButton
                      disabled={
                        forms[0].isEditingMode ||
                        documentsStatus.status === 'revision' ||
                        forms[0].data.countryResidenceCode === 76
                      }
                      onClick={() => {
                        setForms(val =>
                          val.map(e => ({ ...e, isEditingMode: true }))
                        );
                        alert.show(
                          'Você pode começar a editar seus dados cadastrais'
                        );
                      }}
                    >
                      <EditIcon
                        style={{ fontSize: '14px', marginRight: '5px' }}
                      />
                      Editar Dados Cadastrais
                    </CustomButton>
                  </RowWrapper>
                  <Divisor />
                  <Text style={{ marginBottom: '25px' }}>Pessoa Física</Text>
                </ColumnWrapper>
                <DocumentsUpdatePF
                  personType={
                    AccountService.account.taxIdentifier.length > 11
                      ? 'PJ'
                      : 'PF'
                  }
                  key={0}
                  isOpened={forms[0].isOpened}
                  isEditingMode={forms[0].isEditingMode}
                  setFormIsOpened={value => handleSetFormIsOpened(0, value)}
                  index={0}
                  buttonDisability={forms[0].buttonDisability}
                  documentsStatus={documentsStatus}
                  formData={forms[0].data}
                  formFiles={forms[0].files}
                  formTouched={forms[0].touched}
                  formErrors={forms[0].errors}
                  setFormData={newValue =>
                    handleSetFormData({
                      index: 0,
                      newValue,
                      objectName: 'data'
                    })
                  }
                  setFormTouched={newValue =>
                    handleSetFormData({
                      index: 0,
                      newValue,
                      objectName: 'touched'
                    })
                  }
                  setFormErrors={newValue =>
                    handleSetFormData({
                      index: 0,
                      newValue,
                      objectName: 'errors'
                    })
                  }
                  setFormFiles={newValue =>
                    handleSetFormData({
                      index: 0,
                      newValue,
                      objectName: 'files'
                    })
                  }
                  banks={banks}
                />
                <Divisor />
              </ColumnWrapper>
            )}
            <Button
              //disabled={false}
              disabled={resolveHighLevelButtonDisability({ forms })}
              onClick={
                resolvePersonType() === 'PJ'
                  ? handleSubmitFormPJ
                  : handleSubmitFormPF
              }
              style={{ alignSelf: 'flex-end', marginTop: '40px' }}
              title={'ATUALIZAR CADASTRO'}
            />
          </ContentWrapper>
        </Container>
      </LoadingSpinner>
    );
  }
}

export default withRouter(DocumentsUpdate);

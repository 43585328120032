import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
`;

export const TogglesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.Cor_auxiliar_03};
`;

export const TransferTypeToggle = styled.button`
    display: inline-block;
    border-radius: 30px;
    width: 250px;
    border: solid 2px ${props => props.theme.Cor_base_marca_01};
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: ${props => props.isSelected ? props.theme.Cor_auxiliar_01 : props.theme.Cor_base_marca_01};
    text-transform: uppercase;
    height: 40px;
    transition: 300ms ease-out;
    outline: none;
    background-color: ${props => props.isSelected ? props.theme.Cor_base_marca_01 : props.theme.Cor_auxiliar_01};
    pointer-events: ${props => props.isDisabled ? 'none' : 'initial'};
    opacity: ${props => props.isDisabled ? 0.4 : 1.0};
    cursor: pointer;

`;



export const TransferTypeToggleLeft = styled(TransferTypeToggle)`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
`;

export const TransferTypeToggleRight = styled(TransferTypeToggle)`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

export const TimeInformationText = styled.span`
    margin-top: 10px;
    color: ${props => props.theme.Cor_base_marca_03};
    font-size: 12px;
    font-family: 'source-bold, source-regular, source-semibold';
    align-self: center;
`;

export const TopLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-top: 40px;
    margin-bottom: 14px;
    align-self: center;
    
`;


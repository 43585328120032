import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  border-radius: 2px;
  background-color: ${props => props.theme.brandColor2};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px ${props => props.theme.brandColor5};
  padding: 0;
  margin: 30px 0px 0px 0px;
  padding: 20px 15px 20px 15px;
  box-sizing: border-box;
  ${media.phone`
    padding: 0px;
  `}
`;

export const TextLabel = styled.span`

  a {
    color: ${props=>props.theme.brandColor4};
  };

    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 12px;
    color: ${props => props.theme.success};
    align-self: center;
    text-align: left;
    align-self: flex-start;d
`;

export const TableLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    margin-top: 0;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
`;



import styled from 'styled-components';


import Select from 'react-select';
import AsyncSelect from 'react-select/async';


export const CustomSelect = styled(Select)`
    width: 100%;
    border: ${ props => props.hasErrors ? '1px solid red' : 'none'};
    border-radius: ${ props => props.hasErrors ? '5px' : 'none'};
`;

export const AsynchronousSelect = styled(AsyncSelect)`
    width: 100%;
    border: ${ props => props.hasErrors ? '1px solid red' : 'none'};
    border-radius: ${ props => props.hasErrors ? '5px' : 'none'};
`;
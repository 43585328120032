export const FICHA_COMPENSACAO_TEXTS = [
  {
    title: "DADOS DO PAGAMENTO:",
    bold: true,
  },
  {
    title: "CODIGO DE BARRAS",
    bold: false,
    fieldName: 'digitableLine',
  },
  {
    title: "EMISSOR",
    fieldName: 'assignor',
  },
  {
    title: "BENEFICIÁRIO:",
    bold: true,
  },
  {
    title: "NOME",
    fieldName: 'beneficiaryName',
  },
  {
    title: "CPF/CNPJ",
    fieldName: 'beneficiaryDocument',
  },
  {
    title: "PAGADOR:",
    bold: true,
  },
  {
    title: "NOME",
    fieldName: 'draweeName',
  },
  {
    title: "CPF/CNPJ",
    fieldName: 'draweeDocument',
  },
  {
    title: "VALORES:",
    bold: true,
  },
  {
    title: "VALOR DO DOCUMENTO",
    fieldName: 'grossValue',
  },
  {
    title: "DESCONTO",
    fieldName: 'discount',
  },
  {
    title: "ENCARGOS",
    fieldName: 'additionalValueTotal',
  },
  {
    title: "ABATIMENTO",
    fieldName: 'totalRebateValue',
  },
  {
    title: "VALOR A PAGAR",
    fieldName: 'valuePayment',
    isEditable: true,
  },
  {
    title: "DATAS:",
    bold: true,
  },
  {
    title: "DATA DE VENCIMENTO",
    fieldName: 'dueDate',
    isEditable: true,

  },
  {
    title: "DATA DE PAGAMENTO",
    fieldName: 'currentDate',
    isEditable: true,
  }
];


export const CONCESSIONARIA_TEXTS = [
  {
    title: "DADOS DO PAGAMENTO:",
    bold: true,
  },
  {
    title: "CODIGO DE BARRAS",
    bold: false,
    fieldName: 'digitableLine',
  },
  {
    title: "EMISSOR",
    fieldName: 'assignor',
  },
  {
    title: "VALORES:",
    bold: true,
  },
  {
    title: "VALOR A PAGAR",
    fieldName: 'valuePayment',
    isEditable: true,
  },
  {
    title: "DATAS:",
    bold: true,
  },
  {
    title: "DATA DE VENCIMENTO",
    fieldName: 'dueDate',
    isEditable: true,

  },
  {
    title: "DATA DE PAGAMENTO",
    fieldName: 'currentDate',
    isEditable: true,
  }
];
import styled from 'styled-components';
import { InfoCircle } from 'styled-icons/boxicons-solid/InfoCircle';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 18px;
  margin-left: 8px;
  & > div {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  & > .simulation-item-head {
    padding: 13px 18px;
    background-color: ${(props) =>
      props.theme.anticipation.simulationItemHeader.backgroundColor};
  }
  & > .simulation-item-body {
    padding: 20px 40px 20px 21px;
    background-color: ${(props) =>
      props.theme.anticipation.simulationItemBody.backgroundColor};
    border-radius: 6px;
    overflow: hidden;
  }
`;

export const TextItem = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.22px;
    color: ${(props) =>
      props.theme.anticipation.simulationItemBodyColumnsTitle.color};
    font-family: 'MontserratBold';
    &:last-child {
      margin-top: 15px;
    }
    &.simulation-text-value {
      font-family: 'MontserratXLight';
      font-weight: 400;
      &:last-child {
        margin-top: 4px;
      }
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  & > span {
    font-family: 'MontserratMedium';
    color: ${(props) => props.theme.Cor_base_marca_01};
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.22px;
    margin-right: 20px;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
`;

export const AcceptButton = styled.a`
  transition: 125ms;
  padding: 12px 36px;
  font-family: 'MontserratMedium';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.22px;
  color: ${(props) => (!props.active ? '#a8a8a8' : '#fff')};
  background-color: ${(props) =>
    !props.active ? '#d9d9d9' : props.decline ? '#e35b5c' : '#2caa3b'};
  text-decoration: none;
  opacity: 1;
  &:hover {
    cursor: pointer;
    opacity: 0.86;
  }
  &:active {
    opacity: 0.66;
  }
`;

export const InformationIcon = styled(InfoCircle)`
  width: 16px;
  margin-left: 15px;
  color: ${(props) => props.theme.anticipation.simulationItemTooltip.color};
`;

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  margin-top: -5px;
`;

export const TooltipText = styled.span`
  width: 250px;
  font-size: 10px;
  visibility: hidden;
  background-color: ${(props) =>
    props.theme.anticipation.simulationItemTooltipTextBox.backgroundColor};
  color: ${(props) =>
    props.theme.anticipation.simulationItemTooltipTextBox.color};
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: calc(100% + 10px); /* Ajuste a posição para à esquerda do botão */
  top: 50%; /* Centralize verticalmente */
  transform: translateY(-50%); /* Centralize verticalmente */
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px; /* Posicione a seta à esquerda do tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) =>
      `transparent ${props.theme.anticipation.simulationItemTooltipTextBox.backgroundColor} transparent transparent`}; /* Ajuste as cores para a seta à esquerda */
  }

  ${Tooltip}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export const SimulationNetValueContainer = styled.div`
  display: flex;
  justify-content: 'flex-end';
`;

export const SimulationNetValueText = styled.span`
  width: 100%;
  text-align: end;
  padding: 15px 45px 15px 0px;
  font-family: 'MontserratBold';
  font-weight: 600;
  background-color: ${(props) =>
    props.theme.anticipation.simulationItemNetValueText.backgroundColor};
  color: ${(props) =>
    props.theme.anticipation.simulationItemNetValueText.color};
`;

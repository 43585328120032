import styled from 'styled-components';
import { Close } from 'styled-icons/evil/Close';
import { AlertCircle } from 'styled-icons/feather/AlertCircle';

export const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px 10px 10px;
 `;

export const CloseButton = styled(Close)`
    font-weight: bold;
    cursor: pointer;
`;

export const AlertIcon = styled(AlertCircle)`
    margin-bottom: 25px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
`;

export const Text = styled.span`
      font-family: "SourceSansPro", Arial, sans-serif;
      font-size: 14px;
      color: rgb(165, 165, 165);
      margin: 0px 0px 3px 0px;

      a {
          font-family: "SourceSansPro", Arial, sans-serif;
          font-size: 14px;
          color: #51C3C7;
          margin: 0px 0px 0px 0px;
          text-decoration: underline;
      }

      strong {
        font-family: "SourceSansPro", Arial, sans-serif;
        color: #53565A;
        font-weight: bold;
      }

      br {
          margin-bottom: 15px;
      }
    }
`;


export const Title = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: 0px;
    color: ${props => props.type === 'error' ? props.theme.danger : props.type === 'alert' ? props.theme.alert : props.theme.black};
`;

export const AttentionMessage = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: ${props => props.theme.danger};
    margin-bottom: 25px;
    align-self: flex-start;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    margin-top: 30px;
    align-self: flex-end;
`;

export const ActionButton = styled.button`
    border: 1px solid transparent;
    border-radius: 20px;
    background-color: ${props => props.theme.Cor_base_marca_01};
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: ${props => props.theme.brandColor2};
    padding: 12px 18px 12px 18px;
    transition: 300ms ease-in;
    margin-right: 15px;
    text-transform: uppercase;
    cursor: pointer;
    `;

export const BackButton = styled.button`
    font-size: 10px;
    font-family: "SourceSansPro", Arial, sans-serif;
    background-color: ${props => props.theme.brandColor2};
    border: none;
    color: ${props => props.theme.Cor_base_marca_01};
    cursor: pointer;
    transition: 300ms ease-in;
    outline: none;


    `

export const ExtraButton = styled.button`
    width: 291px;
    height: 50px;
    border: none;
    background-color: ${props => props.theme.brandColor2};
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    text-decoration: underline;
    color: ${props => props.theme.brandColor1};
    outline: none;
    `;


export const RedButton = styled.button`
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 88px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 2px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    color: ${props => props.theme.danger};
`;

export const HelpAnchorText = styled.span`
    color: ${props => props.theme.Cor_base_marca_01}
`;
import React, { useState, useEffect } from "react";
import {
  Container,
  ContentWrapper,
  AccountBalanceTableWrapper
} from "./styles";
import { AccountService } from "banking-service";
import { useAlert } from "react-alert";
import LoadingSpinner from "components/LoadingSpinner";
import ScreenTitle from "components/ScreenTitle";
import CurrentAccountValue from "components/CurrentAccountValue";
import AccountBalanceScreenTable from "components/AccountBalanceScreenTable";
import AccountBalanceTexts from "constants/account-balance-table-texts";

function AccountBalance({ section = "Conta Corrente", name = "Saldo" }) {

  const alert = useAlert();

  const [error, setError] = useState("");
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  const [balance, setBalance] = useState(null);
  const [balanceInformations, setBalanceInformations] = useState([]);

  useEffect(() => {

    const mapBalanceInformations = ({ res }) => {
      return AccountBalanceTexts.map(e => ({
        ...e,
        value: res[e.fieldName]
      }));
    };

    const fetchAccountBalance = async () => {
      setIsLoading(loading => ({ ...loading, transactions: true }));
      setError("");
      try {
        const res = await AccountService._getBalance();
        setBalance(AccountService.account.balance.available);
        setBalanceInformations(mapBalanceInformations({ res: AccountService.account.balance }));
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        setError(e.message);
      }
    };
    fetchAccountBalance();
  }, []);



  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <CurrentAccountValue title={"saldo conta corrente"} value={balance}></CurrentAccountValue>
          <AccountBalanceTableWrapper>
            <AccountBalanceScreenTable
              data={balanceInformations}
            ></AccountBalanceScreenTable>
          </AccountBalanceTableWrapper>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default AccountBalance;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import '@zendeskgarden/react-dropdowns/dist/styles.css';
import TimeAgo from 'javascript-time-ago';
import pt from 'javascript-time-ago/locale/pt';
import { Text } from 'tpz-shared-components';
import useInterval from 'use-interval';
import { useTheme } from 'styled-components';
import { useConfigContext } from 'providers/client-config-provider';

import { ThemeProvider } from '@zendeskgarden/react-theming';
import {
  Dropdown,
  Menu,
  Item,
  Trigger,
  Separator,
} from '@zendeskgarden/react-dropdowns';

import { resolvePersonType } from 'utils/functions/user';
import storage from 'utils/functions/storage';
import { useAuthDataContext } from 'providers/auth-provider';
import { useSwitchAccountDataContext } from 'providers/switch-account-provider';
import {
  AccountService,
  NotificationsService,
  ToggleService,
} from 'banking-service';

import LogoPF from 'assets/images/header_logo_pf.svg';
import LogoPJ from 'assets/images/header_logo_pj.svg';
import { useModalWithData, ModalWrapper } from 'components/CustomModal';
import ConfirmModal from 'components/ConfirmModal';

import CurrentAccount from 'components/CurrentAcount';

import {
  Container,
  LogoIcon,
  ActionsWrapper,
  UserButtonContainer,
  ProfileIcon,
  PhoneIcon,
  NotificationIcon,
  PhoneButtonContainer,
  UserOptionsSelectionWrapper,
  ArrowDropDownIcon,
  CircleIcon,
} from './styles';
import AccountBoxSelect from 'components/AccountBoxSelect';
import AccountBoxOption from 'components/AccountBoxOption';

TimeAgo.addLocale(pt);
const timeAgo = new TimeAgo('pt-BR');

const Badge = styled.span`
  position: relative;
  line-height: 0px;
  font-size: 11px;
  padding: 7px 9px;
  color: ${(props) => props.theme.Cor_auxiliar_01};
  border-radius: 2px;
  left: -5px;
  background-color: ${(props) => props.theme.danger};
`;

const VerticalLine = ({ style, ...props }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignSelf: 'center',
      }}
    >
      <div style={{ borderRight: `1px solid ${theme.Cor_auxiliar_03}` }}></div>
      <div style={{ borderLeft: `1px solid ${theme.Cor_auxiliar_03}` }}></div>
    </div>
  )
};

const NotificationItem = ({
  title = 'TRANSFERENCIA',
  message = 'Sua transferência foi concluída com sucesso.\nR$ 2.538,90',
  data,
  read,
  createdAt,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: read
          ? theme.Cor_auxiliar_03
          : theme.Cor_base_marca_fade,
        padding: '0px 10px 0px 10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 4,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <VerticalLine style={{ height: '10px' }} />
          <CircleIcon
            color={data ? data.color || theme.danger : theme.danger}
          />
          <VerticalLine style={{ height: '100%' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '7px 0px 10px 0px',
          }}
        >
          <Text
            style={{
              fontFamily: '"SourceSansPro", Arial, sans-serif',
              fontSize: '11px',
              textTransform: 'uppercase',
            }}
            margin="-3px 0px 0px 0px"
            fontWeight="bold"
            color={data ? data.color || theme.danger : theme.danger}
          >
            {title}
          </Text>
          {message.split('\n').map((item, i) => {
            return (
              <Text
                lineHeight={1.25}
                color={theme.Cor_base_marca_03}
                style={{
                  fontFamily: '"SourceSansPro", Arial, sans-serif',
                  minWidth: '300px',
                }}
                size="x-small"
                margin="1px 0px"
                key={i}
              >
                {item}
              </Text>
            );
          })}
          {createdAt && (
            <Text
              lineHeight={1.25}
              color={theme.Cor_base_marca_03}
              style={{
                fontFamily: '"SourceSansPro", Arial, sans-serif',
                textTransform: 'lowercase',
                fontSize: '8px',
              }}
              size="x-small"
              margin="1px 0px"
            >
              {createdAt ? timeAgo.format(new Date(createdAt)) : ''}
            </Text>
          )}
        </div>
      </div>
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-center',
            flex: 1,
          }}
        >
          {data.route && (
            <Text
              lineHeight={1.25}
              color={theme.Cor_base_marca_01}
              style={{
                fontFamily: '"SourceSansPro", Arial, sans-serif',
                textTransform: 'lowercase',
                alignSelf: 'center',
                marginLeft: '15px',
              }}
              size="medium"
              margin="1px 0px"
            >
              >
            </Text>
          )}
          {!data.route && (
            <div style={{ width: '150px', height: '100%' }}></div>
          )}
        </div>
      }
    </div>
  );
};

function Header(props) {
  const [confirm, hideConfirm] = useModalWithData((modalParams) => () => (
    <ConfirmModal {...modalParams} hideConfirm={hideConfirm} />
  ));
  const { clientConfig } = useConfigContext();
  const theme = useTheme();

  const { onLogout } = useAuthDataContext();
  const {
    setIsSwitchingAccounts,
    switchAccounts,
    fetchUserPermissions,

    selectedAccountIndex,
    setSelectedAccountIndex,
  } = useSwitchAccountDataContext();
  const { accounts } = AccountService;

  const [notifications, setNotifications] = useState([]);

  const handleUpdateNotifications = async () => {
    try {
      const unreadArray = notifications.filter((e) => !e.read);
      const allRead = notifications.map((e) => ({ ...e, read: true }));
      setNotifications([...allRead]);
      unreadArray.forEach((e) => NotificationsService.patch({ id: e._id }));
    } catch (e) {}
  };

  useEffect(() => {
    const hasLoggedIn = storage.getItem('hasLoggedIn');
    if (accounts.some((e) => e.taxIdentifier.length > 11) && !hasLoggedIn) {
      storage.setItem('hasLoggedIn', true);
    }
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const sessionExpired = storage.getItem('expired');
        if (!sessionExpired) {
          const { branch, account } = accounts[selectedAccountIndex];

          const res = await NotificationsService.fetch({ branch, account });
          setNotifications([...res]);
        }
      } catch (e) {}
    };
    fetchNotifications();
  }, [props.location.pathname]);

  useInterval(() => {
    const fetchNotifications = async () => {
      try {
        const sessionExpired = storage.getItem('expired');
        if (!sessionExpired) {
          const { branch, account } = accounts[selectedAccountIndex];

          const res = await NotificationsService.fetch({ branch, account });
          setNotifications([...res]);
        }
      } catch (e) {}
    };
    fetchNotifications();
  }, 20000);

  useEffect(() => {
    const executeSwitchAccounts = async () => {
      setIsSwitchingAccounts(true);
      const account = AccountService.accounts[selectedAccountIndex];
      switchAccounts(account);

      try {
        await fetchUserPermissions();
        props.history.push('/');
        setIsSwitchingAccounts(false);
      } catch (e) {
        console.log(e);
        setIsSwitchingAccounts(false);
      }
    };
    executeSwitchAccounts();
  }, [selectedAccountIndex]);

  const [hasDocumentUpdateFeature, setHasDocumentUpdateFeature] = useState(
    false
  );
  useEffect(() => {
    const verifyToken = async () => {
      try {
        const toggleRes = await ToggleService.fetch({
          feature: 'DOCUMENTS_UPDATE',
        });
        if (toggleRes && toggleRes.find) {
          setHasDocumentUpdateFeature(true);
        } else {
          setHasDocumentUpdateFeature(false);
        }
      } catch (e) {
        setHasDocumentUpdateFeature(false);
      }
    };

    verifyToken();
  }, [selectedAccountIndex]);

  if (!accounts) return null;

  return (
    <ThemeProvider>
      <Container
        personType={resolvePersonType({
          taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
        })}
      >
        <LogoIcon
          src={
            resolvePersonType({
              taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
            }) === 'PF'
              ? clientConfig.images.headerLogoImageOnHomePf
              : clientConfig.images.headerLogoImageOnHomePj
          }
          maxWidth={clientConfig.vars.headerLogoSize}
        />
        <ActionsWrapper>
          <Dropdown
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewport',
              },
            }}
            onSelect={(value) => {}}
          >
            <Trigger>
              <div style={{ display: 'flex', cursor: 'pointer' }}>
                <AccountBoxSelect
                  personType={resolvePersonType({
                    taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
                  })}
                  name={accounts[selectedAccountIndex].name}
                  agency={accounts[selectedAccountIndex].branch}
                  account={accounts[selectedAccountIndex].account}
                  profile={accounts[selectedAccountIndex].profile}
                  taxIdentifier={accounts[selectedAccountIndex].taxIdentifier}
                />
                <ArrowDropDownIcon />
              </div>
            </Trigger>
            <Menu id="topBarUserOptionsDiv" placement="bottom">
              {accounts.map((e, i) => (
                <Item value={i}>
                  <AccountBoxOption
                    id={i}
                    name={e.name}
                    agency={e.branch}
                    account={e.account}
                    profile={resolvePersonType({
                      taxIdentifier: e.taxIdentifier,
                    })}
                    taxIdentifier={e.taxIdentifier}
                    onSelect={(id) => {
                      storage.setItem('fromChangeAccount', true);
                      setSelectedAccountIndex(id);
                    }}
                    isSelected={selectedAccountIndex === i}
                  ></AccountBoxOption>
                </Item>
              ))}
            </Menu>
          </Dropdown>

          <UserOptionsSelectionWrapper id="notificationSelectionDiv">
            <Dropdown
              onStateChange={(changes, stateAndActions) => {
                if (changes.isOpen === false) {
                  console.log('closed');
                  handleUpdateNotifications();
                }
              }}
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewport',
                },
              }}
              onSelect={(value) => {}}
            >
              <Trigger>
                <UserButtonContainer
                  personType={resolvePersonType({
                    taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
                  })}
                >
                  <div style={{ display: 'flex' }}>
                    <NotificationIcon
                      style={{
                        position: 'relative',
                        left:
                          notifications.filter((e) => !e.read).length !== 0
                            ? '10px'
                            : '0px',
                      }}
                      size={24}
                    />
                  </div>
                  {notifications.filter((e) => !e.read).length !== 0 && (
                    <Badge>
                      {notifications.filter((e) => !e.read).length > 99
                        ? '99+'
                        : notifications.filter((e) => !e.read).length}
                    </Badge>
                  )}
                </UserButtonContainer>
              </Trigger>
              <Menu
                style={{ paddingTop: '20px' }}
                id="topNotificationOptionsDiv"
                placement="bottom-end"
              >
                {notifications.length === 0 ? (
                  <>
                    <Item
                      style={{ padding: '0px 20px 0px 20px' }}
                      id={`${'notificationItem-'}${0}`}
                      key={0}
                      //onClick={async () => await onLogout()}
                      value={`${'notification-option-'}${0}`}
                    >
                      <>
                        <NotificationItem
                          title={'Ops...'}
                          data={{ color: theme.success }}
                          message={'Você não possui notificações'}
                          read={true}
                        />
                      </>
                    </Item>
                  </>
                ) : (
                  notifications.map((e, i) => (
                    <>
                      <Item
                        onClick={() => {
                          if (e.data.route) props.history.push(e.data.route);
                        }}
                        style={{ padding: '0px 15px 0px 15px' }}
                        id={`${'notificationItem-'}${i}`}
                        key={i}
                        //onClick={async () => await onLogout()}
                        value={`${'notification-option-'}${i}`}
                      >
                        <>
                          <NotificationItem {...e} />
                          <div
                            style={{
                              display: 'flex',
                              height: '3px',
                              width: '100%',
                              backgroundColor: theme.Cor_auxiliar_01,
                            }}
                          >
                            <VerticalLine
                              style={{
                                height: '100%',
                                marginLeft: '19.5px',
                              }}
                            />
                          </div>
                        </>
                      </Item>
                    </>
                  ))
                )}
              </Menu>
            </Dropdown>
          </UserOptionsSelectionWrapper>

          {clientConfig && (
            <PhoneButtonContainer
              id="phoneButton"
              personType={resolvePersonType({
                taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
              })}
              onClick={() =>
                window.open(`${clientConfig.partner.contact}`, '_blank')
              }
            >
              <PhoneIcon size={18} />
            </PhoneButtonContainer>
          )}

          <UserOptionsSelectionWrapper id="userOptionSelectionDiv">
            <Dropdown
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewport',
                },
              }}
              onSelect={(value) => {}}
            >
              <Trigger>
                <UserButtonContainer
                  personType={resolvePersonType({
                    taxIdentifier: accounts[selectedAccountIndex].taxIdentifier,
                  })}
                >
                  <ProfileIcon size={24} />
                </UserButtonContainer>
              </Trigger>
              <Menu id="topBarUserOptionsDiv" placement="start-bottom">
                <Item id="companyInformationsLabel" value="option-1">
                  <CurrentAccount
                    name={accounts[selectedAccountIndex].name}
                    agency={accounts[selectedAccountIndex].branch}
                    account={accounts[selectedAccountIndex].account}
                    lastAccess={moment().format('DD/MM - HH:mm')}
                  ></CurrentAccount>
                </Item>
                <Separator />

                <Item
                  id="changePasswordButton"
                  onClick={() => props.history.push('/change-password')}
                  value="option-2"
                >
                  Alterar Senha
                </Item>
                <Separator />

                {hasDocumentUpdateFeature && (
                  <>
                    <Item
                      id="documentsUpdateButton"
                      onClick={() => props.history.push('/documents-update')}
                      value="option-3"
                    >
                      Atualização Cadastral
                    </Item>
                    <Separator />
                  </>
                )}

                <Item
                  id="logoutButton"
                  onClick={async () => await onLogout()}
                  value="option-4"
                >
                  Desconectar
                </Item>
              </Menu>
            </Dropdown>
          </UserOptionsSelectionWrapper>
        </ActionsWrapper>
      </Container>
    </ThemeProvider>
  );
}

export default withRouter(Header);

import styled from 'styled-components';

export const TopLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.bankStatement.topLabelTitle.color}; 
    align-self: center;
    margin-right: 5px;
`;

export const PeriodLabel = styled.span`
  font-size: 10px;
  margin: 5px 0px 5px 5px;
`;

export const InformationsLabel = styled.label`
    font-size: 10px;

    strong {
        color: black;
        font-weight: 600;
    }
`;
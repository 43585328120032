import styled from 'styled-components';

import { Field } from 'formik';
import MaskedInput from "react-text-mask";
import Select from 'react-select'

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${props => props.theme.Cor_base_marca_03};
`;

export const TransferTypeToggle = styled(Field).attrs({ type: 'radio' })`

`;

export const TogglesWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
`

export const TransferTypeToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const VerticalDivisor = styled.div`
    margin-left: 30px;
    border-left: 1px solid ${props => props.theme.Cor_auxiliar_03};
    height: 100%;
    padding-right: 30px;
`;

export const TransferTypeLabel = styled.label`
    margin: 0px;
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-left: 5px;
`;

export const CustomAutocomplete = styled(Select)`
    border: ${props => props.hasErrors ? '1px solid red' : 'none'};
    border-radius: ${props => props.hasErrors ? '5px' : 'none'};
    & .css-1pahdxg-control,
    & .css-1pahdxg-control:hover {
      outline: none !important;
      border-color: ${props => props.theme.Cor_base_marca_01};
      box-shadow: 0 0 0 1px ${props => props.theme.Cor_base_marca_01};
    }
`;

export const Form = styled.form`
`;


export const AccountInformationsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 45px;
    margin-bottom: 45px;
`;

export const CustomInput = styled(MaskedInput)`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;

  border-width: 0px 0px 1px 0px;
  color: ${props => props.theme.Cor_base_marca_03};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  :-ms-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
`;

export const BranchInput = styled(CustomInput)`
    display: flex;
    flex: 4;
    margin-right: 50px;
    border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
`;

export const AccountInput = styled(CustomInput)`
    display: flex;
    flex: 4;
    margin-right: 50px;
    border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
`;

export const DigitInput = styled(CustomInput)`
    display: flex;
    flex: 2;
    border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
`;

export const PurposeInput = styled(CustomInput)`
    display: flex;
    width: 300px;
    border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
    margin-top: 50px;
`;

export const BeneficiaryInput = styled.input`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;
  border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};

  border-width: 0px 0px 1px 0px;
  color: ${props => props.theme.Cor_base_marca_03};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  :-ms-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }`;

export const DateAndValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`


export const AmountInput = styled.input`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;
  border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};

  border-width: 0px 0px 1px 0px;
  color: ${props => props.theme.Cor_base_marca_03};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  :-ms-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }`;


export const WeekendsAndHolidaysWarningLabel = styled.span`
    margin-top: 10px;
    color: ${props => props.theme.Cor_base_marca_03};
    font-size: 10px;
    font-family: 'source-bold, source-regular, source-semibold';
    align-self: flex-start;
`;

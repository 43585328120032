import React from "react";
import { parseText } from "utils/functions/text";

import {
  Container,
  Name,
  AccountInformationsWrapper,
  Agency,
  Account,
  LastAccess
} from "./styles";

function CurrentAccount({ name, agency, account, lastAccess }) {
  return (
    <Container
      id="companyInformationsDiv">
      <Name id="companyNameLabel" >{name ? parseText({ text: name, limit: 21 }) : ""}</Name>
      <AccountInformationsWrapper>
        <Agency id="branchLabel">ag {agency}</Agency>
        <Account id="accountLabel">conta {account}</Account>
      </AccountInformationsWrapper>
      {lastAccess && <LastAccess id="lastAccessLabel">Último acesso em {lastAccess}</LastAccess>}
    </Container>
  );
}

export default CurrentAccount;

import React, { useState, useEffect } from 'react';
import {
  Container,
  ContentWrapper,
  ActionsWrapper,
  TextLabel,
  TableLabel,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import Button from 'components/Button';

import ScreenTitle from 'components/ScreenTitle';
import FilterDatePicker from 'components/FilterDatePicker';
import InvestmentPortfolioList from 'components/InvestmentPortfolioList';
import { CNABService } from 'banking-service';
import moment from 'moment';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const initialDates = {
  startDate: moment().subtract(2, 'day'),
  endDate: moment(),
};

function CNABRequest({
  section = 'Arquivos',
  name = 'Solicitar Extrato Padrão CNAB240',
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState(initialDates);

  const handleSubmitButton = async () => {
    setIsLoading(true);
    setError('');
    try {
      const res = await CNABService.requestDocument(query);
      alert.success(
        'CNAB requisitado com sucesso. Verifique o resultado na aba Consultar.',
        { timeout: 0 }
      );
    } catch (e) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  function resolveButtonDisability() {
    return !query || !query.startDate || !query.endDate;
  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <ActionsWrapper>
            <TextLabel
              style={{
                color: theme.black,
                marginTop: '10px',
                marginBottom: '30px',
              }}
            >
              Os extratos são gerados conforme layout atual. Para mais
              informações{' '}
              <a
                href="https://www.bancotopazio.com.br/storage/CNAB240_layout-2.pdf"
                target="_blank"
              >
                clique aqui
              </a>
            </TextLabel>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '40px',
              }}
            >
              <TableLabel style={{ marginTop: '3px' }}>
                SELECIONE UMA DATA:{' '}
              </TableLabel>
              <FilterDatePicker
                allowedRangeInDays={3}
                disAllowFutureDates
                style={{}}
                initialDates={initialDates}
                onDateChange={(newQuery) => setQuery(newQuery)}
                showDefaultInputIcon
              ></FilterDatePicker>
            </div>
            <TextLabel
              style={{
                color: theme.black,
                marginTop: '40px',
                fontSize: '10px',
              }}
            >
              * Período máximo de solicitação de 3 dias
            </TextLabel>
            <TextLabel
              style={{
                color: theme.black,
                marginBottom: '50px',
                fontSize: '10px',
              }}
            >
              Estão disponíveis para consulta lançamentos a partir de 01/01/2019
            </TextLabel>
            <Button
              onClick={handleSubmitButton}
              disabled={resolveButtonDisability()}
              style={{ alignSelf: 'center' }}
              title={'Solicitar'}
            />
          </ActionsWrapper>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default CNABRequest;

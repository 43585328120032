import "array-flat-polyfill";
import moment from "moment";
import { FICHA_COMPENSACAO_TEXT, CONTA_CONCESSIONARIA_TEXT } from 'constants/payments-receipt-texts';

const PAYMENT_TYPES = {
    FICHA_COMPENSACAO: 'FICHA_COMPENSACAO',
    CONTA_CONCESSIONARIA: 'CONTA_CONCESSIONARIA',
}


const resolvePaymentsResumeInformations = ({ data, selectedType }) => {

    if (!data || typeof data !== "object") return [];

    let texts = selectedType === PAYMENT_TYPES.FICHA_COMPENSACAO ? FICHA_COMPENSACAO_TEXT : CONTA_CONCESSIONARIA_TEXT;

    const filledTransferInformations = texts.map(e => ({
        ...(data.hasOwnProperty(e.fieldName)) && { field: e.title, value: e.fieldName === 'date' ? moment(data[e.fieldName]).format('DD/MM/YYYY') : data[e.fieldName] },
    })).filter(value => Object.keys(value).length !== 0);


    return filledTransferInformations;

}

export { resolvePaymentsResumeInformations };





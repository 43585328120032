import React, { useState, useEffect } from 'react';
import moment from 'moment';
import '../../react-table.css';
import ReactTable from 'react-table';
import { Container, Title } from './styles';
import ValueOnTables from 'components/ValueOnTables';

const columns = [
  {
    Header: 'Nº',
    accessor: 'installment',
    Cell: (props) => (
      <div style={{ textAlign: 'center', flex: 0.5 }}>
        <span>{props.value}</span>
      </div>
    ),
  },
  {
    Header: 'Vencimento',
    accessor: 'dueDate',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{moment(props.value).format('DD/MM/YYYY')}</span>
      </div>
    ),
  },
  {
    Header: 'Valor da Parcela',
    accessor: 'installmentTotal',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <ValueOnTables value={props.value} type={props.original.type} />
      </div>
    ),
  },
  {
    Header: 'Principal antes pgto.',
    accessor: 'payments',
    Cell: (props) =>
      props.original.payments.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          {props.value.map((e) => (
            <ValueOnTables value={e.principalBeforePayment} />
          ))}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <ValueOnTables
            value={props.original.principalBeforePayment}
            type={props.original.type}
          />
        </div>
      ),
  },
  {
    Header: 'Encargos',
    accessor: 'payments',
    Cell: (props) =>
      props.original.payments.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          {props.value.map((e) => (
            <ValueOnTables value={e.paymentCharges} />
          ))}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <ValueOnTables
            value={props.original.chargesBeforePayment}
            type={props.original.type}
          />
        </div>
      ),
  },
  {
    Header: 'Data de Pagamento',
    accessor: 'payments',
    Cell: (props) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        {props.value.map((e) => (
          <span>{moment(e.paymentDate).format('DD/MM/YYYY')}</span>
        ))}
      </div>
    ),
  },
  {
    Header: 'Principal pago',
    accessor: 'payments',
    Cell: (props) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        {props.value.map((e) => (
          <ValueOnTables value={e.principal} />
        ))}
      </div>
    ),
  },
  {
    Header: 'Encargos pagos',
    accessor: 'payments',
    Cell: (props) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        {props.value.map((e) => (
          <ValueOnTables
            value={
              Math.round((e.paymentValue - e.principal) * 100) / 100 < 0
                ? 0
                : Math.round((e.paymentValue - e.principal) * 100) / 100
            }
          />
        ))}
      </div>
    ),
  },
  {
    Header: 'Valor pago',
    accessor: 'payments',
    Cell: (props) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        {props.value.map((e) => (
          <ValueOnTables value={e.paymentValue} />
        ))}
      </div>
    ),
  },
  {
    Header: 'Saldo devedor da parcela',
    accessor: 'payments',
    Cell: (props) =>
      props.original.payments.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          {props.value.map((e) => (
            <ValueOnTables value={e.paymentBalance} />
          ))}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <ValueOnTables
            value={props.original.valueBeforePayment}
            type={props.original.type}
          />
        </div>
      ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{props.value}</span>
      </div>
    ),
  },
];

function CreditTransactionsList({ data }) {
  return (
    <Container>
      <Title>Confira as parcelas do seu contrato abaixo: </Title>
      <ReactTable
        style={{ display: 'flex', flex: 1, width: '100%' }}
        noDataText="Nenhum dado disponível"
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={data ? data.length : 0}
        //className="-striped -highlight"
      />
    </Container>
  );
}

export default CreditTransactionsList;

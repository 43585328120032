import "array-flat-polyfill";
import moment from "moment";
import { TOPAZIO_TEXT, OTHERBANKS_TEXT } from 'constants/transfers-receipt-texts';

const TRANSFER_TYPES = {
    TOPAZIO: 'IBTR',
    SAME_OWNER: 'IBTD',
    OTHER_BANKS: 'IBTC',
}


const resolveTransfersResumeInformations = ({ data, selectedType }) => {

    if (!data || typeof data !== "object") return [];

    let texts = selectedType === TRANSFER_TYPES.TOPAZIO ? TOPAZIO_TEXT : (selectedType === TRANSFER_TYPES.OTHER_BANKS || selectedType === TRANSFER_TYPES.SAME_OWNER) ? OTHERBANKS_TEXT : [];

    const filledTransferInformations = texts.map(e => ({
        ...(data.hasOwnProperty(e.fieldName)) && { field: e.title, value: e.fieldName === 'date' ? moment(data[e.fieldName]).format('DD/MM/YYYY') : data[e.fieldName] },
    })).filter(value => Object.keys(value).length !== 0);


    return filledTransferInformations;

}

export { resolveTransfersResumeInformations };





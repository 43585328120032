import styled from 'styled-components';

export const Container = styled.button`
  transition: 125ms;
  transform: scale(1);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.Cor_base_marca_01};
  border-radius: 20px;
  background-color: ${(props) =>
    props.outline ? '#fff' : props.theme.Cor_base_marca_01};
  font-family: ${(props) =>
    props.mediumFont ? 'MontserratMedium' : 'MontserratXLight'};
  font-size: 12px;
  line-height: 14px;
  color: ${(props) =>
    !props.outline ? '#fff' : props.theme.Cor_base_marca_01};
  padding: 12px 50px 12px 50px;
  outline: none !important;
  padding: 16px 52px;
  height: auto;

  :hover {
    opacity: 0.86;
    cursor: pointer;
    transform: scale(1.03);
    background-color: rgb(
      80,
      195,
      199,
      0 ${(props) => (props.outline ? '0' : '')}8
    );
  }

  :active {
    opacity: 0.76;
    transform: scale(0.93);
  }

  :disabled {
    opacity: 0.66;
    transform: scale(1);
    background-color: ${(props) =>
      props.outline ? '#fff' : props.theme.Cor_base_marca_01};
  }
`;

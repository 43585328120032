import styled from 'styled-components';
import { Close } from 'styled-icons/evil/Close';


export const Container = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-top: 5px;
    width: 100%;
`;

export const TopLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.alert};
    margin-top: 50px;
    margin-bottom: 14px;
    align-self: flex-start;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%; 
    max-height: 20px;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 0px;
    padding-bottom: 13px;
    padding-right: 0px;
    background-color: ${props => props.theme.brandColor2};
    margin: 0;
    border: 0;
    color: ${props => props.theme.brandColor3};
    border-bottom: 1px solid #d8d8d8;
 `;

export const PortfolioName = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
    margin: 0px 20px;
 `;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-top: 20px;
`;
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.label`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  max-width: 33.065vw;

  strong {
    font-family: 'MontserratMedium';
  }
`;

export const Title = styled.label`
  margin: 0;
  font-family: 'MontserratBold';
  font-size: 16px;
  margin-bottom: 60px;
  line-height: 16px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  margin-left: 16.422vw;
  align-self: flex-start;
  color: #707070;
`;

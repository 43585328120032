import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  position: relative;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: ${(props) => !props.fullInput ? 1 : 16 }px;
  height: ${(props) => !props.fullInput ? 1 : 16 }px;
  position: ${(props) => !props.fullInput ? 'absolute' : 'relative' };
  ${(props) => !props.fullInput ? '' : `
    opacity: 0;
  `}
  &:hover {
    ${(props) => !props.hover ? '' : `
      cursor: pointer;
    `}
  }
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? props.theme.Cor_base_marca_01 : props.theme.Cor_auxiliar_03)};
  border-radius: 3px;
  transition: all 150ms;
  pointer-events: ${(props) => !props.fullInput ? 'all' : 'none' };
  position: ${(props) => !props.fullInput ? 'relative' : 'absolute' };
  ${(props) => !props.fullInput ? '' : `
    top: 0;
    left: 0;
  `}


  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${(props) => !props.fullInput ? 'pink' : 'transparent' };
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Checkbox = ({ className, checked, onChecked=()=>{}, fullInput=false, ...props }) => {

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        checked={checked}
        onClick={(checkRef) => onChecked(checkRef.target.checked)}
        fullInput={fullInput}
        {...props}
      />
      <StyledCheckbox
        checked={checked}
        fullInput={fullInput}
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;

import styled from "styled-components";

import { RemoveRedEye } from 'styled-icons/material/RemoveRedEye';

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  min-height: 40px;
  flex: 1;
  border-radius: 2px;
  padding: 0px 15px 0px 15px;
  ${media.phone`
  padding: 0px 15px 0px 15px;
  `};
  color: ${props => (props.isVisible ? props.theme.Cor_auxiliar_01 : props.theme.Cor_base_marca_03)};
  font-family: "SourceSansPro", Arial, sans-serif;
  background: ${props =>
    props.isVisible
      ? props.theme.Cor_base_marca_01
      : `linear-gradient(to right, ${props.theme.Cor_auxiliar_03}, ${props.theme.Cor_auxiliar_03})`};
`;

export const Text = styled.span`
  margin: 0px;

  text-transform: uppercase;
  text-align: left;
  justify-self: flex-start;
  line-height: 28px;
  font-size: 12px;

  ${media.phone`
    font-size: 10px;
  `};
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-end;
  cursor: pointer;
`;

export const ToggleText = styled.span`
  font-size: 10px;
  margin-right: 5px;
`;

export const ToggleIcon = styled(RemoveRedEye)`
  padding: 0px 12px 0px 0px;
`;

export const Value = styled.span`
  text-transform: uppercase;
  line-height: 26px;
  color: ${props => props.theme.brandColor2};
  ${media.phone`
    font-size: 10px;
  `};
`;

import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title, children }) => {
  return (
    <Helmet>
      {children}
      <title>Internet Banking - {title}</title>
    </Helmet>
  );
};

export default Head;

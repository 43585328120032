import styled from 'styled-components';
import Select from 'react-select';
import { Close } from 'styled-icons/evil/Close';
import { ShareBox } from 'styled-icons/remix-fill/ShareBox';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.label`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.brandColor3};
  padding: 5px 0;
`;

export const Value = styled.span`
  margin: 0;
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.brandColor3};
  padding: 5px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ColoredValue = styled(Value)`
  color: ${(props) =>
    props.colored ? props.theme.success : props.theme.black};
`;

export const IsOpenedIcon = styled(ArrowDropUp)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${(props) => props.theme.brandColor3};
`;

export const IsNotOpenedIcon = styled(ArrowDropDown)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: ${(props) => props.theme.brandColor3};
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 20px;
`;

export const TableLabel = styled.span`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 17px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.brandColor3};
  text-transform: uppercase;
  align-self: flex-start;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
  margin-bottom: 30px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  background: white;
box-shadow: ${(props) =>
  props.isChecked
    ? `1px 1px 2px 1px ${props.theme.Cor_base_marca_01};`
    : `0 1px 2px 0 rgba(0, 0, 0, 0.2);`}
  padding: 10px 0px;
  border-radius: 2px;
  justify-content: space-between;
`;

export const SelectAllButton = styled.a`
  font-family: 'SourceSansPro', Arial, sans-serif;
  font-size: 10px;
  margin-top: 0;
  text-decoration: underline;
  margin-bottom: 17px;
  margin-left: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.Cor_base_marca_01};
  text-transform: uppercase;
  align-self: flex-start;
  cursor: pointer;
`;

export const ButtonDisabledReasonText = styled.span`
  font-size: 18px;
  margin-top: 25px;
  font-family: 'SourceSansPro, Arial, sans-serif';
  color: ${(props) => props.theme.alert};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

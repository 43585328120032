import React, { useState, useEffect } from 'react';
import ReactModal from "react-modal";


import { Container, ItemContainer, PortfolioName, ModalContainer, CloseButton } from './styles';
import Button from 'components/Button';
import { useModal } from "react-modal-hook";
import { CREDITS_TEXT } from 'constants/credits-resume-texts';
import CreditTransactionsList from './CreditTransactionsList';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';


function ModalContent({ onCloseClick, children }) {
    return (
        <ModalContainer>
            <CloseButton size={20} onClick={onCloseClick} />
            {children}
        </ModalContainer>
    )
};


function CreditResumeList({ contractResumeListData = [], contractDetailedData }) {
    const { clientConfig } = useConfigContext();

    const [parsedData, setParsedDate] = useState([]);

    const [showModal, hideModal] = useModal(() => (
        <ReactModal
            style={{
                overlay: {
                    zIndex: '11',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    padding: '50px',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    backgroundColor:  theme.brandColor2,
                    width: '60%',
                }
            }}

            isOpen>
            <ModalContent onCloseClick={hideModal}>
                <CreditTransactionsList data={contractDetailedData} />
            </ModalContent>
        </ReactModal>
    ));

    useEffect(() => {
        const parsedDate = CREDITS_TEXT.map(e => ({
            field: e.title,
            value: contractResumeListData[e.fieldName]
        }));
        setParsedDate(parsedDate)
    },
        [contractResumeListData]
    )
    return (
        <Container>
            {parsedData.map(e => (
                <ItemContainer id="transferInformationItemId">
                    <PortfolioName>{e.field}</PortfolioName>
                    <PortfolioName>{e.value}</PortfolioName>
                </ItemContainer>
            ))}
            <ItemContainer style={{ maxHeight: '40px', justifyContent: 'center' }} id="transferInformationItemId">
                <Button onClick={() => showModal()} style={{ background: 'transparent', color: theme.brandColor1, margin: '10px 0px' }} title={'VER PARCELAS'} />
            </ItemContainer>

        </Container>

    )
}

export default CreditResumeList;
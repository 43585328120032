import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Logo from 'assets/images/logo-topazio-receipt.png';
import { resolvePaymentsResumeInformations } from 'utils/transformers/payments-receipt';
import { handleCurrency } from 'utils/functions/currency';
import ReactToPrint from 'react-to-print';
import { ouvidoriaInformations } from 'constants/ouvidoriaInformations';
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  ContentWrapper,
  ItemContainer,
  PortfolioName,
  TopLabel,
  CheckMarkIcon,
  AditionalInformationsWrapper,
  TransactionDescription,
  InformationWrapper,
  InformationLabel,
  InformationValue,
  DownloadIcon,
} from './styles';

const resolveStatus = ({ status }) => {
  switch (status) {
    case 'PAID':
      return (status = 'SUCESSO');
    case 'PROCESSING':
      return (status = 'PROCESSANDO');
    case 'CANCELED':
      return (status = 'CANCELADO');
    case 'NOT_PAID':
      return (status = 'NÃO PAGO');
    case 'ERRO':
      return (status = 'NÃO PROCESSADO');
    default:
      return (status = 'INDEFINIDO');
  }
};

const PAYMENT_TYPES = {
  FICHA_COMPENSACAO: 'FICHA_COMPENSACAO',
  CONTA_CONCESSIONARIA: 'CONTA_CONCESSIONARIA',
};

function PaymentReceipt({ data, selectedType }) {
  const componentRef = useRef();
  const [parsedData, setParsedDate] = useState([]);
  const { clientConfig } = useConfigContext();

  useEffect(() => {
    let subParsedData;
    if (selectedType === PAYMENT_TYPES.FICHA_COMPENSACAO) {
      subParsedData = {
        ...data.person,
        ...data.bankSlip,
        dueDate: moment(data.bankSlip.dueDate).format('DD/MM/YYYY'),
        grossValue: handleCurrency({ value: data.bankSlip.grossValue }),
        discountValue: handleCurrency({ value: data.bankSlip.discountValue }),
        additionalValue: handleCurrency({
          value: data.bankSlip.additionalValue,
        }),
        fineCalculatedValue: handleCurrency({
          value: data.bankSlip.fineCalculatedValue,
        }),
        additionalTotalValue: handleCurrency({
          value: data.bankSlip.additionalTotalValue,
        }),
        valuePayment: handleCurrency({ value: data.bankSlip.valuePayment }),
      };
    } else if (selectedType === PAYMENT_TYPES.CONTA_CONCESSIONARIA) {
      subParsedData = {
        ...data.person,
        ...data.bankSlip,
        dueDate: moment(data.bankSlip.dueDate).format('DD/MM/YYYY'),
        grossValue: handleCurrency({ value: data.bankSlip.grossValue }),
        discountValue: handleCurrency({ value: data.bankSlip.discountValue }),
        additionalValue: handleCurrency({
          value: data.bankSlip.additionalValue,
        }),
        fineCalculatedValue: handleCurrency({
          value: data.bankSlip.fineCalculatedValue,
        }),
        additionalTotalValue: handleCurrency({
          value: data.bankSlip.additionalTotalValue,
        }),
        valuePayment: handleCurrency({ value: data.bankSlip.valuePayment }),
      };
    }
    setParsedDate(
      resolvePaymentsResumeInformations({ data: subParsedData, selectedType })
    );
  }, [data]);

  class RenderReceipt extends React.Component {
    render() {
      return (
        <ContentWrapper id="capture">
          <img
            style={{ width: '160px', height: '68px', marginTop: '5px' }}
            src={clientConfig.images.receiptsLogo}
          />
          <p>{clientConfig.vars.appSigner}</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              marginTop: '10px',
              marginBottom: '0px',
            }}
          >
            <CheckMarkIcon />
            <TopLabel>COMPROVANTE DE TRANSAÇÃO</TopLabel>
          </div>
          <TransactionDescription>PAGAMENTO DE CONTAS</TransactionDescription>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '30px 100px',
              width: '100%',
            }}
          >
            {parsedData.map((e) => (
              <ItemContainer
                id="transferInformationItemId"
                isAmountField={e.field === 'VALOR A PAGAR'}
              >
                <PortfolioName isAmountField={e.field === 'VALOR A PAGAR'}>
                  {e.field}
                </PortfolioName>
                <PortfolioName isAmountField={e.field === 'VALOR A PAGAR'}>
                  {e.value}
                </PortfolioName>
              </ItemContainer>
            ))}
            <AditionalInformationsWrapper>
              <InformationWrapper>
                <InformationLabel>EFETIVAÇÃO DO PAGAMENTO</InformationLabel>
                <InformationValue>
                  {data.receipt.paymentDate
                    ? moment(data.receipt.paymentDate).format('DD/MM/YYYY')
                    : 'NÃO DISPONÍVEL'}
                </InformationValue>
              </InformationWrapper>
              <InformationWrapper>
                <InformationLabel>SITUAÇÃO</InformationLabel>
                <InformationValue>
                  {resolveStatus({ status: data.status })}
                </InformationValue>
              </InformationWrapper>
              <InformationWrapper>
                <InformationLabel>IDENTIFICADOR</InformationLabel>
                <InformationValue id="transactionIdentifierLabelId">
                  {data.transaction}
                </InformationValue>
              </InformationWrapper>
            </AditionalInformationsWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'start',
              marginTop: '15px',
            }}
          >
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.baseLine}
            </InformationValue>
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.line01}
            </InformationValue>
            <InformationValue style={{ fontSize: '10px' }}>
              {clientConfig.vars.receiptContactInformations.footer.line02}
            </InformationValue>
            {/* {ouvidoriaInformations.map((e) => (
              <InformationValue style={{ fontSize: '10px' }}>
                {e}
              </InformationValue>
            ))} */}
          </div>
        </ContentWrapper>
      );
    }
  }

  return (
    <Container>
      <ReactToPrint
        style={{ alignSelf: 'flex-end' }}
        trigger={() => <DownloadIcon />}
        content={() => componentRef.current}
      />
      <RenderReceipt ref={componentRef} />
    </Container>
  );
}

export default PaymentReceipt;

import React, { useState, useEffect } from "react";
import moment from 'moment';
import { TokenService } from 'banking-service';
import { useModal } from "react-modal-hook";
import {
  Container,
  ContentWrapper,
  ModalContainer,
  CloseButton,
  ItemContainer,
  ColumnWrapper,
  Title,
  Value,
  ColoredValue,
  NoItemsAvailableLabel,
  ActiveIcon
} from "./styles";
import { useAlert } from "react-alert";
import LoadingSpinner from "components/LoadingSpinner";
import ScreenTitle from "components/ScreenTitle";
import ReactModal from "react-modal";
import TokenActivation from 'components/TokenActivation';
import { useConfigContext } from 'providers/client-config-provider';
import { useTheme } from "styled-components";


function Token({ section = 'Serviços', name = "Token" }) {
  const alert = useAlert();
  const theme = useTheme

  const [error, setError] = useState("");
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);
  const [cronImage, setCronImage] = useState(null);
  const [isPingingToken, setIsPingingToken] = useState(null);

  const [tokens, setTokens] = useState([]);
  const [showModal, hideModal] = useModal(() => (
    <ReactModal
      onRequestClose={() => {
        hideModal()
        setIsPingingToken({ status: false, serial: null, hasClosedModal: true });
      }}
      style={{
        overlay: {
          zIndex: '11',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          backgroundColor: theme.Cor_base_marca_01,
        }
      }}
      isOpen >
      <TokenActivation cronImage={cronImage} />
    </ReactModal >
  ), [cronImage, tokens]);



  useEffect(() => {
    const fetchTokens = async () => {
      setIsLoading(true);
      setError("");
      try {
        const res = await TokenService.getInfo();
        setTokens([...res.filter(e => e.status !== 'Inativo')]);
      } catch (e) {
      }
      setIsLoading(false);
    };
    fetchTokens();
  },
    []);



  const Item = ({ status, serial, attributionDate, onClick, }) => (
    <ItemContainer>
      <ColumnWrapper>
        <Title>Status</Title>
        <ColoredValue status={status}>{status !== 'Ativo' ? 'Desativado' : status}</ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper>
        <Title>Número de série</Title>
        <Value>{serial}</Value>
      </ColumnWrapper>
      <ColumnWrapper>
        <Title>Data de atribuição</Title>
        <Value>{moment(attributionDate).format('DD/MM/YYYY')}</Value>
      </ColumnWrapper>
      {
        !(status === 'Ativo') && (
          <ColumnWrapper>
            <Title>Ativar</Title>
            <ActiveIcon onClick={() => onClick({ serial })} />
          </ColumnWrapper>
        )
      }

    </ItemContainer>
  )

  useEffect(() => {
    const fetchTokens = async (val) => {
      setError("");
      try {
        return await TokenService.getInfo();
      } catch (e) {
        return [];
      }
    };

    if (isPingingToken && isPingingToken.count && isPingingToken.count < 30) {
      setTimeout(async () => {
        const result = await fetchTokens(isPingingToken.count);
        setTokens([...result.filter(e => e.status !== 'Inativo')]);
        const tokenStatus = result.find(e => e.serial === isPingingToken.serial).status === 'Ativo';
        if (!tokenStatus) {
          setIsPingingToken(isPingingToken => ({ ...isPingingToken, count: isPingingToken.count + 1 }))
        }
        else {
          hideModal();
          alert.success('Token ativado com sucesso');

          //setIsPingingToken(isPingingToken => ({ count: 0, serial: '' }))

        }
      }, 1000)
    } else if(isPingingToken && isPingingToken.hasClosedModal){
      setIsPingingToken(false)
    }
    else if (isPingingToken && isPingingToken.count >= 30) {
      hideModal();
      alert.info('Tempo para a ativação do token expirou. Tente novamente.');
      //setIsPingingToken(isPingingToken => ({ count: 0, serial: '' }))
    }
    else {

    }
  },
    [isPingingToken])


  const fetchCronImage = async ({ serial }) => {
    setIsLoading(true);
    setError("");
    try {
      const res = await TokenService.fetchCronImage({ serial });
      setCronImage(res.image);
      setIsPingingToken({ count: 1, serial });
      showModal();


    } catch (e) {
    }
    setIsLoading(false);
  };


  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          {tokens.length === 0 ?
            (<NoItemsAvailableLabel style={{ alignSelf: 'center' }}>Nenhum token disponível</NoItemsAvailableLabel>) :

            tokens.map(e => (<Item {...e} onClick={async ({ serial }) => await fetchCronImage({ serial })} />))
          }

        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Token;

import styled from "styled-components";

import media from "utils/media";
import Select from 'react-select';

export const Container = styled.div`
  display: flex;
  width: ${props => props.isReviewType ? '60%' : '90%'};
  height: 100%;
  flex-direction: column;
  margin-top: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  border-bottom: ${props => props.isReviewType ? 'none' : `1px solid ${props.theme.brandColor5}`};
  padding: 7px;
  background-color: ${props => (props.isReviewType && props.bold) ? props.theme.brandColor5 : props.bold ? props.theme.brandColor5 : props.theme.brandColor2};
`;

export const Title = styled.span`
  font-family: "SourceSansPro", Arial, sans-serif;;
  font-size: 12px;
  font-weight: ${props => props.isReviewType ? 0 : 600};
  text-align: left;
  color: black;
  margin: 0;
  text-transform: uppercase;
  padding: 10px;

`;

export const Value = styled.div`
  font-family: "SourceSansPro", Arial, sans-serif;;
  font-size: 12px;
  text-align: left;
  margin: 0;
  text-transform: uppercase;
  padding: 10px;
`;

export const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const AmountInput = styled.input`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 14px;
  text-align: right;
  border: none;
  /* width: 100%; */
  color: #d69106;
  background-color: transparent;
  padding-right: 10px;
  :focus {
    outline: none;
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #d69106;
    text-align: right;
  }
  :-ms-BranchInput-placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #d69106;
    text-align: right;
  }`;

export const AmountInputSelect = styled(Select)`
  border: none;
  border-radius: 5px;
  & .css-1pahdxg-control,
  & .css-1pahdxg-control:hover {
    outline: none !important;
    border-color: ${props => props.theme.Cor_base_marca_01};
    box-shadow: 0 0 0 1px ${props => props.theme.Cor_base_marca_01};
  }
  flex: 1;
  width: 350px
  height: 25px;
  margin-left: 15px;
  text-align: right;
`;


export const DueDateField = styled.div`
  display: flex;
  flex:1;
  height: 28px;
  background-color: ${props => props.theme.brandColor2};
  justify-content: space-between;
  align-items: center;
  padding: 7px 5px;
`;

export const DueDateText = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: black;
    margin: 0;
    text-transform: uppercase;
`;

export const EditableAlert = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const EditableAlertImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 20px;
`;

export const EditableAlertContainer = styled.div`
  flex: 1;
  padding: 14px;
  border: 1px solid ${props => props.theme.brandColor1};
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  &::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 10px solid ${props => props.theme.brandColor1};
    border-bottom: 5px solid transparent;
  }
`;

export const EditableAlertText = styled.span`
  font-size: 11px;
  line-height: 22px;
  color: ${props => props.theme.black};
`;

import styled from "styled-components";
import { Printer } from 'styled-icons/remix-fill/Printer';
import { FileExcel2 } from 'styled-icons/remix-fill/FileExcel2';
import media from "utils/media";

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.Cor_auxiliar_01};
  border-radius: 2px;
  margin-right: 25px;

  ${media.phone`
    flex: 0.3;
    margin-right: 0px;
  `};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px 20px 20px;

  ${media.phone`
    flex-wrap: wrap;
  `}
`;

export const AccountSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;


export const AccountOption = styled.div`
  display: flex;
  flex: 1;
  text-transform: uppercase;
  justify-content: center;
  cursor: pointer;
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  color: ${props => props.isSelected ? props.theme.Cor_base_marca_03 : props.theme.Cor_base_marca_03};
  font-weight: 300;
  padding: 10px 0px;
  border-top: 3px solid ${props => props.isSelected ? props.theme.Cor_base_marca_01 : props.theme.Cor_base_marca_01};
  background-color: ${props => props.isSelected ? props.theme.Cor_auxiliar_01 : props.theme.Cor_auxiliar_03};
`;

export const Divisor = styled.div`
  margin: 0px;
  border-bottom: 1px solid ${props => props.theme.Cor_auxiliar_03};
  margin-bottom: 10px;
`;

export const PeriodLabel = styled.span`
  font-size: 10px;
  margin: 5px 0px 5px 5px;
`;

export const DownloadIconPDF = styled(Printer)`
    width: 21px;
    height: 21px;
    color: ${props => props.theme.Cor_auxiliar_03};
    cursor: pointer;
    margin-right: 10px;
    padding: 3px 0px;
    font-weight: normal;
    align-self: flex-end;
`;

export const DownloadIconCSV = styled(FileExcel2)`
    width: 21px;
    height: 21px;
    color: ${props => props.theme.Cor_auxiliar_03};
    cursor: pointer;
    margin-right: 10px;
    padding: 3px 0px;
    font-weight: normal;
    align-self: flex-end;
`;

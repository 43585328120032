import React from 'react';
import {
  ModalContainer,
  BackButton,
  Title,
  HeaderWrapper,
  Text,
  ActionsWrapper,
  ActionButton,
  HelpAnchorText
} from './styles';
import { useConfigContext } from 'providers/client-config-provider'


import { tokenActivationReturns } from '../../utils/functions/token';

function tokenActivationRequired({helpCenterUrl}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Text style={{ marginBottom: '5px' }}>
        Você precisa ativar o Token para a realização de transações.
      </Text>
      <Text style={{ marginBottom: '20px' }}>
        Para ativá-lo, acesse o menu <strong>Serviços > Token</strong>. É
        rapidinho!
      </Text>
      <Text>
        Em caso de dúvidas entre em contato. Acesse nossa{' '}
        <strong>
        <a
            href={helpCenterUrl}
            target="_blank"
            style={{textDecoration: 'none'}}
          >
            <HelpAnchorText>
             Central de Ajuda.
            </HelpAnchorText>
          </a>
        </strong>
      </Text>
    </div>
  );
}

function tokenRequestRequired({helpCenterUrl}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <Text style={{ marginBottom: '5px' }}>
        Para sua segurança, você precisa ter seu Token ativado.
      </Text>
      <Text style={{ marginBottom: '20px' }}>
        Para solicitá-lo, por favor entre em contato com o nosso{' '}
        <strong>SAC</strong>. É rapidinho!
      </Text>
      <Text>
        Em caso de dúvidas entre em contato. Acesse nossa{' '}
        <strong>
          <a
            href={helpCenterUrl}
            target="_blank"
            style={{textDecoration: 'none'}}
          >
            <HelpAnchorText>
             Central de Ajuda.
            </HelpAnchorText>
          </a>
        </strong>
      </Text>
    </div>
  );
}

function TokenActivationModal({
  onActiveClick,
  onBackHomeClick,
  hideModalCallback,
  backButtonText,
  type,
}) {
  const { clientConfig } = useConfigContext();

  return (
    <ModalContainer id="tokenExpirationDiv">
      <HeaderWrapper>
        <Title type={'error'}>{'Ativação do Token'}</Title>
      </HeaderWrapper>
      {type === tokenActivationReturns.SHOULD_UNBLOCK_TOKEN
        ? tokenActivationRequired({helpCenterUrl: clientConfig.vars.urls.helpCenter})
        : tokenRequestRequired({helpCenterUrl: clientConfig.vars.urls.helpCenter})}

      <ActionsWrapper>
        {type === tokenActivationReturns.SHOULD_UNBLOCK_TOKEN ? (
          <ActionButton
            id="tokenExpirationLogoutButton"
            onClick={() => {
              onActiveClick();
              hideModalCallback();
            }}
          >
            ATIVAR TOKEN AGORA
          </ActionButton>
        ) : (
          <a
            href={clientConfig.vars.urls.support}
            target="_blank"
          >
            <ActionButton id="tokenExpirationLogoutButton" onClick={() => {}}>
              SOLICITAR TOKEN AGORA
            </ActionButton>
          </a>
        )}

        <BackButton
          onClick={() => {
            onBackHomeClick();
            hideModalCallback();
          }}
        >
          {backButtonText}
        </BackButton>
      </ActionsWrapper>
    </ModalContainer>
  );
}

export default TokenActivationModal;

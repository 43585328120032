import React, { Fragment, useState, useEffect } from 'react';
import Button from 'components/Button';
import MaskedInput, { conformToMask } from 'react-text-mask';
import Checkbox from 'components/Checkbox';
import { useAlert } from 'react-alert';
import { Collapse } from 'react-collapse';
import {
  initialApisAvailable,
  hiddenApiGroups,
  hiddenApis,
  disabledApis,
} from '../constants';
import { useTheme } from 'styled-components';
import { useConfigContext } from 'providers/client-config-provider';

import {
  Container,
  TogglesWrapper,
  TransferTypeToggleLeft,
  TransferTypeToggleRight,
  NameInput,
  DocumentInput,
  InputsWrapper,
  PermissionsContainer,
  Section,
  Title,
  SectionItem,
  ItemDescription,
  PasswordLabel,
  AccountSelectionWrapper,
  ItemContainer,
  FieldsWrapper,
  ColumnWrapper,
  Value,
  IsOpenedIcon,
  IsNotOpenedIcon,
  CollapseContainer,
} from './styles';

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function AccountPermissions({
  el,
  index,
  data,
  handleCheckBoxClicked,
  action,
}) {
  const [isOpened, setIsOpened] = useState(false);

  const theme = useTheme();
  return (
    <ItemContainer>
      <FieldsWrapper>
        <ColumnWrapper style={{ flex: 0.9, alignItems: 'flex-start' }}>
          <Value
            style={{ fontSize: '10px', marginLeft: '15px' }}
          >{`PERMISSÕES DA CONTA ${'1'} / ${el.account}`}</Value>
        </ColumnWrapper>
        <ColumnWrapper style={{ flex: 0.1 }}>
          {isOpened && (
            <IsOpenedIcon
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                setIsOpened((isOpened) => !isOpened);
              }}
            />
          )}
          {!isOpened && (
            <IsNotOpenedIcon
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                setIsOpened((isOpened) => !isOpened);
              }}
            />
          )}
        </ColumnWrapper>
      </FieldsWrapper>
      <div style={{ width: '100%' }}>
        <Collapse style={{ width: '100%' }} isOpened={isOpened}>
          <CollapseContainer
            style={{ justifyContent: 'flex-start', marginTop: '40px' }}
          >
            {data.apis.functionalities
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((elem) => (
                <Section>
                  <SectionItem
                    isDisabled={
                      action === 'VIEW' || elem.requiredForOperators === true
                    }
                    onClick={(e) => {
                      handleCheckBoxClicked({ index, id: elem.id });
                    }}
                  >
                    <Checkbox
                      checked={!!el.apisAvailable.find((e) => e.id === elem.id)}
                    />
                    <ItemDescription style={{ maxWidth: '60px' }}>
                      {elem.name.toUpperCase()}
                    </ItemDescription>
                  </SectionItem>
                </Section>
              ))}
          </CollapseContainer>
        </Collapse>
      </div>
    </ItemContainer>
  );
}

const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];

const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const cpfMask2 = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const INFORMATIONS_TYPE = {
  INFORMATIONS: 'INFORMATIONS',
  PERMISSIONS: 'PERMISSIONS',
};

const initialFormErrors = {
  name: false,
  email: false,
  document: false,
  accessPassword: false,
  confirmAccessPassword: false,
  transactionPassword: false,
  confirmTransactionPassword: false,
};

const initialFormTouched = {
  name: false,
  email: false,
  document: false,
  accessPassword: false,
  confirmAccessPassword: false,
  transactionPassword: false,
  confirmTransactionPassword: false,
  permissions: false,
};

function Form({ data, action, onBackClick, onSaveClick, ...props }) {
  const alert = useAlert();

  const [formData, setFormData] = useState({ ...data });
  const [formErrors, setFormErrors] = useState({ ...initialFormErrors });
  const [formTouched, setFormTouched] = useState({ ...initialFormTouched });
  const [selectedType, setSelectedType] = useState(
    INFORMATIONS_TYPE.INFORMATIONS
  );

  useEffect(() => {
    const value = data.document;
    if (value.length === 11 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cpfMask2, { guid: false });
      setMask(cpfMask);
      setFormData({ ...data, document: conformedNumber.conformedValue });
    } else if (value.length === 14 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cnpjMask, { guid: false });
      setMask(cnpjMask);
      setFormData({ ...data, document: conformedNumber.conformedValue });
    } else {
    }
  }, [data.document]);

  const [mask, setMask] = useState(false);

  function handleChangeDocument(event) {
    const { value } = event.target;
    if (value.length === 11 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cpfMask2, { guid: false });
      setMask(cpfMask);
      setFormData({ ...formData, document: conformedNumber.conformedValue });
      setFormTouched({ ...formTouched, document: true });
      if (
        conformedNumber.conformedValue.length !== 14 &&
        conformedNumber.conformedValue.length !== 18
      ) {
        setFormErrors({ ...formErrors, document: true });
      } else {
        setFormErrors({ ...formErrors, document: false });
      }
    } else if (value.length === 14 && !value.includes('.')) {
      const conformedNumber = conformToMask(value, cnpjMask, { guid: false });
      setMask(cnpjMask);
      setFormData({ ...formData, document: conformedNumber.conformedValue });
      setFormTouched({ ...formTouched, document: true });
      if (
        conformedNumber.conformedValue.length !== 14 &&
        conformedNumber.conformedValue.length !== 18
      ) {
        setFormErrors({ ...formErrors, document: true });
      } else {
        setFormErrors({ ...formErrors, document: false });
      }
    } else {
      //define which mask to use
      if (value.replace(/\D/g, '').length > 11) {
        setMask(cnpjMask);
      } else if (value === '') {
        setMask(false);
      } else {
        setMask(cpfMask);
      }
      setFormData({ ...formData, document: value });
      setFormTouched({ ...formTouched, document: true });
      if (value.length !== 14 && value.length !== 18) {
        setFormErrors({ ...formErrors, document: true });
      } else {
        setFormErrors({ ...formErrors, document: false });
      }
    }
  }

  function handleButtonDisability() {
    if (action === 'EDIT') {
      return (
        Object.values(formErrors).some((e) => e === true) ||
        Object.values(formTouched).every((e) => e === false) ||
        formData.accessPassword !== formData.confirmAccessPassword ||
        formData.transactionPassword !== formData.confirmTransactionPassword
      );
    } else {
      const { permissions, ...sanitizedFormTouched } = { ...formTouched };
      return (
        Object.values(formErrors).some((e) => e === true) ||
        Object.values(sanitizedFormTouched).some((e) => e === false) ||
        formData.accessPassword !== formData.confirmAccessPassword ||
        formData.transactionPassword !== formData.confirmTransactionPassword
      );
    }
  }

  function isAccountSelected({ accountToVerify }) {
    let isSelected = false;
    formData.associatedAccounts.forEach((el) => {
      if (
        accountToVerify.account === el.account &&
        accountToVerify.branch === el.branch
      ) {
        isSelected = true;
      }
    });
    return isSelected;
  }

  function handleAccountClicked({ account }) {
    if (isAccountSelected({ accountToVerify: account })) {
      setFormData({
        ...formData,
        associatedAccounts: formData.associatedAccounts.filter(
          (e) => !(e.account === account.account && e.branch === account.branch)
        ),
      });
    } else {
      setFormData({
        ...formData,
        associatedAccounts: [
          ...formData.associatedAccounts,
          {
            ...account,
            apisAvailable: [
              ...formData.apis.functionalities.filter(
                (e) => e.requiredForOperators === true
              ),
            ],
          },
        ],
      });
    }
    if (!formTouched.permissions) {
      alert.info(
        'Suas permissões só serão salvas ao clicar em Salvar na aba Informações'
      );
    }
    setFormTouched({ ...formTouched, permissions: true });
  }

  function handleCheckBoxClicked({ index, id }) {
    if (
      formData.associatedAccounts[index].apisAvailable.find((e) => e.id === id)
    ) {
      setFormData({
        ...formData,
        associatedAccounts: formData.associatedAccounts.map((e, i) =>
          i !== index
            ? e
            : {
                ...e,
                apisAvailable: formData.associatedAccounts[
                  i
                ].apisAvailable.filter((el) => el.id !== id),
              }
        ),
      });
    } else {
      setFormData({
        ...formData,
        associatedAccounts: formData.associatedAccounts.map((e, i) =>
          i !== index
            ? e
            : {
                ...e,
                apisAvailable: [
                  ...formData.associatedAccounts[i].apisAvailable,
                  ...formData.apis.functionalities.filter(
                    (element) => element.id === id
                  ),
                ],
              }
        ),
      });
    }
    if (!formTouched.permissions) {
      alert.info(
        'Suas permissões só serão salvas ao clicar em Salvar na aba Informações'
      );
    }
    setFormTouched({ ...formTouched, permissions: true });
  }

  const theme = useTheme();

  return (
    <Container>
      <TogglesWrapper>
        <TransferTypeToggleLeft
          onClick={() => setSelectedType(INFORMATIONS_TYPE.INFORMATIONS)}
          isSelected={selectedType === INFORMATIONS_TYPE.INFORMATIONS}
        >
          INFORMAÇÕES
        </TransferTypeToggleLeft>
        <TransferTypeToggleRight
          onClick={() => setSelectedType(INFORMATIONS_TYPE.PERMISSIONS)}
          isSelected={selectedType === INFORMATIONS_TYPE.PERMISSIONS}
        >
          PERMISSÕES
        </TransferTypeToggleRight>
      </TogglesWrapper>
      {selectedType === INFORMATIONS_TYPE.INFORMATIONS ? (
        <InputsWrapper>
          <div
            style={{
              display: 'flex',
              marginTop: '50px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              value={formData.name}
              id="nameInputId"
              hasErrors={formErrors.name && formTouched.name}
              placeholder="Nome"
              guide={false}
              mask={false}
              type="text"
              maxlength={200}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
                setFormTouched({ ...formTouched, name: true });
                if (e.target.value === '') {
                  setFormErrors({ ...formErrors, name: true });
                } else {
                  setFormErrors({ ...formErrors, name: false });
                }
              }}
              onBlur={() => {
                setFormTouched({ ...formTouched, name: true });
                if (formData.name === '') {
                  setFormErrors({ ...formErrors, name: true });
                } else {
                  setFormErrors({ ...formErrors, name: false });
                }
              }}
            />
            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              value={formData.birthDate}
              id="birthDateInputId"
              hasErrors={formErrors.birthDate && formTouched.birthDate}
              placeholder="Data de nascimento"
              guide={false}
              mask={false}
              type="date"
              maxlength={200}
              onChange={(e) => {
                setFormData({ ...formData, birthDate: e.target.value });
                setFormTouched({ ...formTouched, birthDate: true });
                if (e.target.value === '') {
                  setFormErrors({ ...formErrors, birthDate: true });
                } else {
                  setFormErrors({ ...formErrors, birthDate: false });
                }
              }}
              onBlur={() => {
                setFormTouched({ ...formTouched, birthDate: true });
                if (formData.birthDate === '') {
                  setFormErrors({ ...formErrors, birthDate: true });
                } else {
                  setFormErrors({ ...formErrors, birthDate: false });
                }
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '50px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MaskedInput
              style={{ width: '' }}
              mask={mask}
              guide={false}
              disabled={action === 'VIEW' || action === 'EDIT'}
              onChange={handleChangeDocument}
              onBlur={() => {
                setFormTouched({ ...formTouched, document: true });
                if (
                  formData.document.length !== 14 &&
                  formData.document.length !== 18
                ) {
                  setFormErrors({ ...formErrors, document: true });
                } else {
                  setFormErrors({ ...formErrors, document: false });
                }
              }}
              hasErrors={formTouched.document && formErrors.document}
              placeholder="CPF"
              render={(ref, props) => (
                <DocumentInput
                  disabled={action === 'VIEW'}
                  maxLength={14}
                  value={formData.document}
                  id="documentInputId"
                  ref={(Input) => ref(Input)}
                  {...props}
                />
              )}
            />

            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              style={{ marginTop: '0px', marginLeft: '20px' }}
              value={formData.email}
              id="emailInputId"
              hasErrors={formErrors.email && formTouched.email}
              placeholder="Email"
              guide={false}
              mask={false}
              type="text"
              maxlength={20}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value.trim(),
                });
                setFormTouched({ ...formTouched, email: true });
                if (e.target.value === '' || !validateEmail(formData.email)) {
                  setFormErrors({ ...formErrors, email: true });
                } else {
                  setFormErrors({ ...formErrors, email: false });
                }
              }}
              onBlur={() => {
                setFormTouched({ ...formTouched, email: true });
                if (formData.email === '' || !validateEmail(formData.email)) {
                  setFormErrors({ ...formErrors, email: true });
                } else {
                  setFormErrors({ ...formErrors, email: false });
                }
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '70px',
              alignItems: 'center',
              justifyContent: 'space-between',
              alignSelf: 'flex-start',
            }}
          >
            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              style={{
                marginTop: '0px',
              }}
              value={formData.accessPassword}
              id="accessPasswordInputId"
              hasErrors={
                formErrors.accessPassword && formTouched.accessPassword
              }
              placeholder="Senha de acesso"
              guide={false}
              mask={false}
              type="password"
              minLength={8}
              maxLength={8}
              onChange={(e) => {
                setFormData({ ...formData, accessPassword: e.target.value });
                setFormTouched({ ...formTouched, accessPassword: true });
                if (action === 'CREATE') {
                  if (e.target.value === '' || e.target.value.length !== 8) {
                    setFormErrors({ ...formErrors, accessPassword: true });
                  } else {
                    setFormErrors({ ...formErrors, accessPassword: false });
                  }
                }
              }}
              onBlur={() => {
                setFormTouched({ ...formTouched, accessPassword: true });
                if (action === 'CREATE') {
                  if (
                    formData.accessPassword === '' ||
                    formData.accessPassword.length !== 8
                  ) {
                    setFormErrors({ ...formErrors, accessPassword: true });
                  } else {
                    setFormErrors({ ...formErrors, accessPassword: false });
                  }
                }
              }}
            />
            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              style={{
                marginTop: '0px',
                marginLeft: '20px',
                display: action === 'VIEW' ? 'none' : 'flex',
              }}
              minLength={8}
              maxLength={8}
              value={formData.confirmAccessPassword}
              id="confirmAccessPasswordInputId"
              hasErrors={
                formErrors.confirmAccessPassword &&
                formTouched.confirmAccessPassword
              }
              placeholder="Confirmar Senha de Acesso"
              guide={false}
              mask={false}
              type="password"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  confirmAccessPassword: e.target.value,
                });
                setFormTouched({ ...formTouched, confirmAccessPassword: true });
                if (action === 'CREATE') {
                  if (e.target.value === '' || e.target.value.length !== 8) {
                    setFormErrors({
                      ...formErrors,
                      confirmAccessPassword: true,
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      confirmAccessPassword: false,
                    });
                  }
                }
              }}
              onBlur={() => {
                setFormTouched({ ...formTouched, confirmAccessPassword: true });
                if (action === 'CREATE') {
                  if (
                    formData.confirmAccessPassword === '' ||
                    formData.confirmAccessPassword.length !== 8
                  ) {
                    setFormErrors({
                      ...formErrors,
                      confirmAccessPassword: true,
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      confirmAccessPassword: false,
                    });
                  }
                }
              }}
            />
          </div>
          <span style={{ color: 'gray', marginTop: '10px', fontSize: '8px' }}>
            A senha deve conter pelo menos 8 digitos numéricos
          </span>
          <div
            style={{
              display: 'flex',
              marginTop: '70px',
              alignItems: 'center',
              justifyContent: 'space-between',
              alignSelf: 'flex-start',
            }}
          >
            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              style={{
                marginTop: '0px',
              }}
              value={formData.transactionPassword}
              id="transactionPasswordInputId"
              hasErrors={
                formErrors.transactionPassword &&
                formTouched.transactionPassword
              }
              placeholder="Senha de Transação"
              guide={false}
              mask={false}
              type="password"
              minLength={4}
              maxLength={4}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  transactionPassword: e.target.value,
                });
                setFormTouched({ ...formTouched, transactionPassword: true });
                if (action === 'CREATE') {
                  if (e.target.value === '' || e.target.value.length !== 4) {
                    setFormErrors({ ...formErrors, transactionPassword: true });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      transactionPassword: false,
                    });
                  }
                }
              }}
              onBlur={() => {
                setFormTouched({ ...formTouched, transactionPassword: true });
                if (action === 'CREATE') {
                  if (
                    formData.transactionPassword === '' ||
                    formData.transactionPassword.length !== 4
                  ) {
                    setFormErrors({ ...formErrors, transactionPassword: true });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      transactionPassword: false,
                    });
                  }
                }
              }}
            />
            <NameInput
              disabled={action === 'VIEW' || action === 'EDIT'}
              style={{
                marginTop: '0px',
                marginLeft: '20px',
                display: action === 'VIEW' ? 'none' : 'flex',
              }}
              value={formData.confirmTransactionPassword}
              id="confirmTransactionPasswordInputId"
              hasErrors={
                formErrors.confirmTransactionPassword &&
                formTouched.confirmTransactionPassword
              }
              placeholder="Confirmar Senha de Transação"
              guide={false}
              mask={false}
              type="password"
              minLength={4}
              maxLength={4}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  confirmTransactionPassword: e.target.value,
                });
                setFormTouched({
                  ...formTouched,
                  confirmTransactionPassword: true,
                });
                if (action === 'CREATE') {
                  if (e.target.value === '' || e.target.value.length !== 4) {
                    setFormErrors({
                      ...formErrors,
                      confirmTransactionPassword: true,
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      confirmTransactionPassword: false,
                    });
                  }
                }
              }}
              onBlur={() => {
                setFormTouched({
                  ...formTouched,
                  confirmTransactionPassword: true,
                });
                if (action === 'CREATE') {
                  if (
                    formData.confirmTransactionPassword === '' ||
                    formData.confirmTransactionPassword.length !== 4
                  ) {
                    setFormErrors({
                      ...formErrors,
                      confirmTransactionPassword: true,
                    });
                  } else {
                    setFormErrors({
                      ...formErrors,
                      confirmTransactionPassword: false,
                    });
                  }
                }
              }}
            />
          </div>
          <span style={{ color: 'gray', marginTop: '10px', fontSize: '8px' }}>
            A senha deve conter pelo menos 4 digitos numéricos
          </span>
        </InputsWrapper>
      ) : (
        <PermissionsContainer>
          <AccountSelectionWrapper>
            <PasswordLabel style={{ fontSize: '14px', marginBottom: '15px' }}>
              Quais das suas contas este operador tem acesso?
            </PasswordLabel>
            {formData.accounts.map((e) => (
              <SectionItem
                onClick={() => {
                  handleAccountClicked({ account: e });
                }}
                style={{
                  borderBottom: 'none',
                  pointerEvents: action === 'VIEW' ? 'none' : 'initial',
                }}
              >
                <Checkbox
                  disabled={action === 'VIEW'}
                  checked={isAccountSelected({ accountToVerify: e })}
                />
                <ItemDescription>{`AG / CONTA: 1 / ${e.account}`}</ItemDescription>
              </SectionItem>
            ))}
          </AccountSelectionWrapper>
          {formData.associatedAccounts.map((el, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <AccountPermissions
                el={el}
                index={index}
                data={{ ...formData }}
                handleCheckBoxClicked={handleCheckBoxClicked}
                action={action}
              />
            </div>
          ))}
        </PermissionsContainer>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '50px',
        }}
      >
        {selectedType !== INFORMATIONS_TYPE.PERMISSIONS && (
          <Button
            onClick={onBackClick}
            style={{ backgroundColor: 'transparent', color: theme.Cor_base_marca_01 }}
            title={'Voltar'}
          />
        )}
        {selectedType !== INFORMATIONS_TYPE.PERMISSIONS && (
          <Button
            disabled={handleButtonDisability()}
            onClick={() => onSaveClick({ formData, action })}
            style={{ marginLeft: '15px' }}
            title={'Salvar'}
          />
        )}
      </div>
    </Container>
  );
}

export default Form;

import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import moment from 'moment';
import { CNABService } from 'banking-service';
import { OfflineStatementService } from 'banking-service';

import ACCOUNT_TYPES from 'constants/accountTypes';
import LoadingSpinner from 'components/LoadingSpinner';
import { Container, ContentWrapper, RefreshIcon } from './styles';
import ScreenTitle from 'components/ScreenTitle';
import ControlledComponent from './ControlledComponent';
import { Text } from 'tpz-shared-components';
import ChunkDateRange from 'chunk-date-range';
import { ExportToCsv } from 'export-to-csv';

var data = [
  {
    name: 'Test 1',
    age: 13,
    average: 8.2,
    approved: true,
    description: "using 'Content here, content here' ",
  },
  {
    name: 'Test 2',
    age: 11,
    average: 8.2,
    approved: true,
    description: "using 'Content here, content here' ",
  },
  {
    name: 'Test 4',
    age: 10,
    average: 8.2,
    approved: true,
    description: "using 'Content here, content here' ",
  },
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: true,
  title: 'My Awesome CSV',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const CHUNK_IN_DAYS = 7;

const calculatesPartitions = ({ startDate, endDate }) => {
  const diffInDays = moment(endDate).diff(startDate, 'days');
  const partitions = ChunkDateRange(
    moment(startDate).format('M/DD/YYYY'),
    moment(endDate).format('M/DD/YYYY'),
    Math.ceil(diffInDays / CHUNK_IN_DAYS)
  ).map((e, i) => ({
    startDate:
      i > 0
        ? moment(e.start)
            .add(1, 'day')
            .format('YYYY-MM-DD')
        : moment(e.start).format('YYYY-MM-DD'),
    endDate: moment(e.end).format('YYYY-MM-DD'),
  }));

  return partitions;
};

const mockedResponse = [
  {
    id: '0211202103112021140',
    startDate: '2021-11-02',
    endDate: '2021-11-03',
    status: 'FINISHED',
    needsPartition: true,
    createdAt: '2021-11-03T13:47:19.536Z',
  },
  {
    id: '1409202018112020140',
    startDate: '2020-09-14',
    endDate: '2020-11-18',
    status: 'FINISHED',
    needsPartition: true,
    createdAt: '2021-10-28T20:58:07.897Z',
  },
  {
    id: '0108202103082021140',
    startDate: '2021-08-01',
    endDate: '2021-08-03',
    status: 'FINISHED',
    createdAt: '2021-11-03T13:52:37.860Z',
  },
  {
    id: '0111202001112021140',
    startDate: '2020-11-01',
    endDate: '2021-11-01',
    status: 'PROCESSING',
    createdAt: '2021-11-03T13:20:32.392Z',
  },
  {
    id: '0110202101112021140',
    startDate: '2021-10-01',
    endDate: '2021-11-01',
    status: 'FINISHED',
    createdAt: '2021-11-03T13:27:13.724Z',
  },
  {
    id: '0108202101112021140',
    startDate: '2021-08-01',
    endDate: '2021-11-01',
    status: 'FINISHED',
    createdAt: '2021-11-03T13:29:18.657Z',
  },
];

function Consult({ section = 'Arquivos', name = 'Consultar Extrato' }) {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    setIsLoading(true);
    setError('');
    try {
      const res = await OfflineStatementService.fetchDocuments();
      setDocuments(res);
    } catch (e) {
      setDocuments([]);
      setError(e);
    }
    setIsLoading(false);
  };

  function mountTxtStructure({ data }) {
    let allContent = null;
    let fileToDownload = null;
    fileToDownload = { ...data };
    let lotDetails = '';
    fileToDownload.lotDetails.forEach((element) => {
      lotDetails += element + '\r\n';
    });
    allContent = fileToDownload.header + '\r\n';
    allContent += fileToDownload.lotHeader + '\r\n';
    allContent += lotDetails;
    allContent += fileToDownload.lotTrailer + '\r\n';
    allContent += fileToDownload.trailer;

    setTimeout(() => {
      downloadFile({ filename: 'CNAB240', content: allContent });
    }, 300);
  }

  function downloadFile({ filename, content }) {
    {
      var element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
      );
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }

  async function handleFileDownload({ file, partition }) {
    console.log({ file, partition });
    const csvExporter = new ExportToCsv({ ...csvOptions, filename: partition });

    setIsLoading(true);
    setError('');
    try {
      if (partition) {
        const res = await OfflineStatementService.fetchByPartition({
          file,
          partition,
        });
        csvExporter.generateCsv(res);
      }

      const needsPartition = await OfflineStatementService.needsPartition({
        file,
      });

      if (needsPartition) {
        alert.info(
          'Devido ao grande número de transações, o seu arquivo precisou ser particionado. Por favor, faça o download das partes uma a uma.',
          { timeout: 10000 }
        );
        setDocuments(
          documents.map((e) => {
            if (e.id === file && e.needsPartition) {
              return { ...e };
            } else if (e.id === file) {
              return {
                ...e,
                needsPartition: true,
                partitions: calculatesPartitions({
                  startDate: e.startDate,
                  endDate: e.endDate,
                }),
              };
            } else {
              return { ...e };
            }
          })
        );
      } else {
        const res = await OfflineStatementService.fetchEntireFile({ file });
        csvExporter.generateCsv(res);
      }
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <br />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '20px',
            }}
          >
            <Text marginRight="15px" marginTop="5px">
              {'Verificar novos status:'}
            </Text>
            <RefreshIcon onClick={() => fetchDocuments()} />
            <br />
          </div>

          <ControlledComponent
            data={documents.map((e) => ({ ...e, onClick: handleFileDownload }))}
          />
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Consult;

import styled from 'styled-components';
import Select from 'react-select';
import { Close } from 'styled-icons/evil/Close';
import { ShareBox } from 'styled-icons/remix-fill/ShareBox';
import { ArrowDropUp } from 'styled-icons/material/ArrowDropUp';
import { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';

import media from 'utils/media';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const OutherContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  padding: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const NoItemsAvailableLabel = styled.span`
  align-self: 'center';
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.brandColor3};
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const Text = styled.label`
  margin: 0;
  font-family: 'MontserratXLight';
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;

  strong {
    font-family: 'MontserratMedium';
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SimulationListCont = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 22px;
`;

export const InfoListCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: stretch;
  margin-top: 56px;
  margin-bottom: 32px;
`;

export const CancelText = styled.span`
  align-self: center;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 44px;
  font-family: 'MontserratXLight';
  font-size: 14px;
  line-height: 17px;
  padding: 0 16px;
  color: #707070;
  & > a {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
`;

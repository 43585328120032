import styled from "styled-components";


import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.brandColor2};
    margin-top: 35px;
`;

export const ButtonDisabledReasonText = styled.span`
    font-size: 18px;
    margin-top: 25px;
    font-family: 'SourceSansPro, Arial, sans-serif';
    color: ${props => props.theme.alert};
`;

import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const WhiteSpace = styled.div`
  display: flex;
  background: ${props => props.theme.Cor_auxiliar_01};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 2px;
  height: 100%;
  flex-direction: column;
  width: 80%;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-self: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;


export const TogglesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.Cor_auxiliar_03};
    margin-bottom: 0px;
`;

export const TransferTypeToggle = styled.button`
    display: inline-block;
    border-radius: 30px;
    width: 250px;
    border: solid 2px ${props => props.theme.Cor_base_marca_01};
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: ${props => props.isSelected ? props.theme.Cor_auxiliar_01 : props.theme.Cor_base_marca_01};
    text-transform: uppercase;
    height: 40px;
    transition: 300ms ease-out;
    outline: none;
    background-color: ${props => props.isSelected ? props.theme.Cor_base_marca_01 : props.theme.Cor_auxiliar_01};
`;

export const TransferTypeToggleLeft = styled(TransferTypeToggle)`
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
`;

export const TransferTypeToggleRight = styled(TransferTypeToggle)`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

export const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    margin-bottom: 45px;
`;

export const CustomInput = styled.input.attrs({ maxLength: 15, type: 'password' })`
  display: flex;
  flex: 1;
  height: 25px;
  font-size: 16px;

  border-width: 0px 0px 1px 0px;
  color: ${props => props.theme.Cor_base_marca_03};
  background-color: transparent;
  :focus {
    outline: 0px; /* oranges! yey */
  }
  ::placeholder,
  ::-webkit-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
  :-ms-BranchInput-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.Cor_base_marca_03};
    margin-bottom: 0px;
  }
`;

export const OldPasswordInput = styled(CustomInput)`
    display: flex;
    flex: 4;
    margin-right: 50px;
    margin-bottom: 30px;
    border-bottom-color: ${props => props.hasErrors ? props.theme.danger : props.theme.Cor_auxiliar_03};
`;

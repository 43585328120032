import React, { useState, useEffect } from 'react';
import { DocumentsUpdateService, BankService } from 'banking-service';
import { UnmountClosed } from 'react-collapse';
import TextInput from '../components/TextInput';

import styled from 'styled-components';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import {
  HeaderTitle,
  RowWrapper,
  ColumnWrapper,
  FieldLabel,
  NameTitle,
  IsOpenedIcon,
  IsNotOpenedIcon
} from './styles';
import LocalStatusComponent from '../components/LocalStatusComponent';
import { Text } from '../components/common';
import Checkbox from 'components/Checkbox';

import { Delete } from 'styled-icons/material/Delete';
import { AddCircle } from 'styled-icons/material/AddCircle';

import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import RadioButton from '../components/RadioButton';
import SelectInput from '../components/SelectInput';
import MaskedInput from '../components/MaskedInput';
import Dropzone from '../components/Dropzone';
import Button from 'components/Button';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

import {
  cpfMask,
  dateMask,
  phoneMask,
  cepMask,
  cnpjMask
} from '../components/MaskedInput/masks';
import {
  mariageStatus,
  referenceTypes,
  productTypes,
  documentTypes,
  brazilianStates
} from '../options';
import {
  initialFormData,
  initialFormTouched,
  initialFormErrors,
  initialFormFiles
} from './initialData';

import {
  hasTouchedAnyFieldButHasnAddedFile,
  hasAtLeastOneReference
} from '../utils';

export const CancelIcon = styled(Delete)`
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: ${props => props.theme.danger};
`;

export const AddIcon = styled(AddCircle)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${props => props.theme.brandColor1};
`;

function k(i, callback, data) {
  var v = i.target.value.replace(/\D/g, '');
  //if (v > 25000000) v = 25000000;
  v = (v / 100).toFixed(2) + '';
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  i.value = v;
  callback({ ...data, billing: `R$ ${i.value}` });
  //callback('amount', `R$ ${i.value}`);
}

function FieldWrapper({
  isDisabled = false,
  fieldWidth = '50px',
  label,
  children,
  style,
  ...props
}) {
  return (
    <ColumnWrapper
      isDisabled={isDisabled}
      style={{
        alignItems: 'flex-start',
        marginRight: '80px',
        marginTop: '30px',
        ...style
      }}
    >
      <FieldLabel style={{ alignSelf: 'flex-start' }}>{label}</FieldLabel>
      {children
        ? React.cloneElement(children, {
            disabled: isDisabled,
            isDisabled
          })
        : null}
    </ColumnWrapper>
  );
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

function DocumentsUpdatePJ({
  banks,
  index,
  initialData,
  isOpened,
  isEditingMode,
  setFormIsOpened,
  documentsStatus,
  formData,
  setFormData,
  formTouched,
  setFormTouched,
  formErrors,
  setFormErrors,
  formFiles,
  setFormFiles,
  personType,
  buttonDisability,
  ...props
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    k(
      { target: { value: formData.billing.toString() } },
      setFormData,
      formData
    );
  }, []);

  const [countries, setCountries] = useState([]);
  const fetchCountries = async () => {
    //setIsLoading(loading => ({ ...loading, screen: true }));
    try {
      const res = await DocumentsUpdateService.fetchCountries();
      const parsedRes = res.map(e => ({
        label: e.name,
        value: e
      }));
      setCountries(parsedRes);
      return parsedRes;
    } catch (e) {
      setError(e.error);
    }
    //setIsLoading(loading => ({ ...loading, screen: false }));
  };

  const loadCBOOptions = async (inputValue, callback) => {
    const res = await DocumentsUpdateService.fetchCBOs({ query: inputValue });
    callback(
      res.map(e => ({
        value: { ...e, familyTitle: formData.cbo.value.familyTitle },
        label: e.name
      }))
    );
  };

  const loadCities = async (inputValue, callback, uf) => {
    const res = await DocumentsUpdateService.fetchCities({
      uf,
      query: inputValue
    });
    callback(
      res.map(e => ({ value: { id: e.id, city: e.city }, label: e.city }))
    );
  };

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
    setFormTouched({ ...formTouched, [name]: true });

    if (name === 'complement') {
      setFormErrors({ ...formErrors, [name]: false });
    } else if (value === '' || (name === 'phoneNumber' && value === '() ')) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  }

  function handleSelectChange({ name, value }) {
    if (name === 'department') {
      setFormData({ ...formData, [name]: value, city: null });
    } else if (name === 'birthState') {
      setFormData({ ...formData, [name]: value, birthCity: null });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setFormTouched({ ...formTouched, [name]: true });
    if (value === null) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  }

  function handleToggleChange({ name, value }) {
    setFormData({ ...formData, [name]: value });
    setFormTouched({ ...formTouched, [name]: true });
    if (value === null) {
      setFormErrors({ ...formErrors, [name]: true });
    } else {
      setFormErrors({ ...formErrors, [name]: false });
    }
  }

  function handleChangeReferences({ index, field, value }) {
    setFormData({
      ...formData,
      references: formData.references.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            data: { ...value },
            touched: {
              ...e.touched,
              [field]: true
            },
            errors: {
              ...e.errors,
              [field]: value[field] === '' || value[field] === null
            }
          };
        } else {
          return { ...e };
        }
      })
    });
  }

  function handleRemoveReference({ index }) {
    setFormData({
      ...formData,
      references: formData.references.filter((e, i) => i !== index)
    });
  }

  function handleChangeDocuments({ index, field, value }) {
    setFormData({
      ...formData,
      documents: formData.documents.map((e, i) => {
        if (i === index) {
          return {
            ...e,
            data: { ...value },
            touched: {
              ...e.touched,
              [field]: true
            },
            errors: {
              [field]: value[field] === '' || value[field] === null
            }
          };
        } else {
          return { ...e };
        }
      })
    });
  }

  function handleRemoveDocuments({ index }) {
    setFormData({
      ...formData,
      documents: formData.documents.filter((e, i) => i !== index)
    });
  }

  const [warningMessage, setWarningMessage] = useState('');
  useEffect(() => {
    if (
      hasTouchedAnyFieldButHasnAddedFile({
        touched: formTouched,
        files: formFiles
      })
    ) {
      setWarningMessage(
        '* Certifique-se de adicionar seu Contrato Social conforme suas alterações'
      );
    } else {
      setWarningMessage('');
    }
  }, [formTouched, formFiles]);

  const [warningMessageReferences, setWarningMessageReferences] = useState('');
  useEffect(() => {
    if (!hasAtLeastOneReference({ data: formData })) {
      setWarningMessageReferences(
        '* Você deve adicionador ao menos uma referência'
      );
    } else {
      setWarningMessageReferences('');
    }
  }, [formData.references]);

  return (
    <ColumnWrapper
      id={`topContentId${index}`}
      style={{
        width: '100%',
        border: `1px solid ${theme.brandColor5}`,
        alignItems: 'flex-start',
        padding: '0px 0px',
        backgroundColor: theme.brandColor2
      }}
    >
      <RowWrapper
        style={{
          width: '100%',
          justifyContent: 'space-between',
          height: '50px'
        }}
      >
        <NameTitle style={{ marginLeft: '20px' }}>
          {formData.corporateName}
        </NameTitle>
        <RowWrapper style={{ flexWrap: 'nowrap' }}>
          {isEditingMode && (
            <LocalStatusComponent
              buttonDisability={buttonDisability}
              isEditingMode={isEditingMode}
            />
          )}
          {isOpened && (
            <IsOpenedIcon
              onClick={() => {
                setFormIsOpened(!isOpened);
              }}
              style={{ marginRight: '50px' }}
            />
          )}
          {!isOpened && (
            <IsNotOpenedIcon
              onClick={() => {
                setFormIsOpened(!isOpened);
              }}
              style={{ marginRight: '50px' }}
            />
          )}
        </RowWrapper>
      </RowWrapper>

      <UnmountClosed
        style={{ width: '100%' }}
        isOpened={personType === 'PF' ? true : isOpened}
      >
        <ColumnWrapper style={{ padding: '0px 25px' }}>
          <RowWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'NOME FANTASIA'}>
              <TextInput
                style={{ width: '250px' }}
                name={'tradingName'}
                hasErrors={formErrors.tradingName}
                value={formData.tradingName}
                onChange={handleInputChange}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={true} placeholder={'-'} label={'CNPJ'}>
              <MaskedInput
                onChange={handleInputChange}
                name={'taxIdentifier'}
                hasErrors={formErrors.taxIdentifier}
                value={formData.document}
                mask={cnpjMask}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              label={'CÓDIGO. ATIVIDADE PRINCIPAL'}
            >
              <TextInput
                style={{ width: '100px' }}
                name={'cnae'}
                hasErrors={formErrors.cnae}
                value={formData.cnae}
                onChange={handleInputChange}
              />
            </FieldWrapper>

            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'RAZÃO SOCIAL'}
            >
              <TextInput
                style={{ width: '250px' }}
                name={'corporateName'}
                hasErrors={formErrors.corporateName}
                value={formData.corporateName}
                onChange={handleInputChange}
              />
            </FieldWrapper>

            <FieldWrapper
              isDisabled={!isEditingMode}
              label={'DATA DE CONSTITUIÇÃO'}
            >
              <MaskedInput
                onChange={handleInputChange}
                name={'contitutionDate'}
                hasErrors={formErrors.contitutionDate}
                style={{ width: '90px' }}
                mask={dateMask}
                value={formData.contitutionDate}
                pipe={autoCorrectedDatePipe}
              />
            </FieldWrapper>

            <FieldWrapper isDisabled={true} label={'Nº DA CONTA'}>
              <TextInput
                onChange={handleInputChange}
                name={'account'}
                hasErrors={formErrors.account}
                value={formData.account}
                type={'number'}
                placeholder={''}
              />
            </FieldWrapper>

            <FieldWrapper isDisabled={true} label={'ABERTURA DA CONTA'}>
              <MaskedInput
                onChange={handleInputChange}
                name={'openingDate'}
                hasErrors={formErrors.openingDate}
                style={{ width: '90px' }}
                mask={dateMask}
                value={formData.openingDate}
                pipe={autoCorrectedDatePipe}
              />
            </FieldWrapper>

            <FieldWrapper
              isDisabled={!isEditingMode}
              label={'FATUR. MÉDIO MENSAL'}
            >
              <TextInput
                style={{ width: '150px' }}
                name={'billing'}
                hasErrors={formErrors.billing}
                value={formData.billing}
                onChange={handleInputChange}
                onKeyUp={e => k(e, setFormData, formData)}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle style={{ marginTop: '35px' }}>
            ENDEREÇO COMERCIAL
          </HeaderTitle>
          <RowWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              placeholder={'-'}
              label={'CEP'}
            >
              <MaskedInput
                onChange={handleInputChange}
                name={'zipCode'}
                hasErrors={formErrors.zipCode}
                value={formData.zipCode}
                mask={cepMask}
                style={{ width: '100px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'RUA / AV / LOGRADOURO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'address'}
                hasErrors={formErrors.address}
                value={formData.address}
                style={{ width: '180px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'NÚMERO'}>
              <TextInput
                onChange={handleInputChange}
                name={'number'}
                hasErrors={formErrors.number}
                value={formData.number}
                style={{ width: '60px' }}
                type={'number'}
                placeholder={''}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'COMPLEMENTO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'complement'}
                value={formData.complement}
                style={{ width: '140px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={true} label={'PAÍS'}>
              <SelectInput
                noOptionsMessage={() => '...'}
                value={formData.countryResidenceCode}
                options={[{ value: 'Brasil', label: 'Brasil' }]}
                style={{ width: '250px' }}
              />
            </FieldWrapper>
            <FieldWrapper isDisabled={!isEditingMode} label={'ESTADO'}>
              <SelectInput
                placeholder={'Selecione'}
                value={formData.department}
                onChange={val => {
                  handleSelectChange({
                    name: 'department',
                    value: val
                  });
                  //setFormData({ ...formData, city: null });
                }}
                noOptionsMessage={() => '...'}
                options={brazilianStates}
                style={{ width: '90px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode || !formData.department}
              label={'CIDADE'}
            >
              <SelectInput
                placeholder={'Pesquise uma cidad...'}
                value={formData.city}
                onChange={val =>
                  handleSelectChange({ name: 'city', value: val })
                }
                noOptionsMessage={() => '...'}
                isAsync
                defaultOptions
                loadOptions={(inputValue, callback) =>
                  loadCities(
                    inputValue,
                    callback,
                    !formData.department ? '' : formData.department.label
                  )
                }
                style={{ width: '250px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'BAIRRO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'neighborhood'}
                hasErrors={formErrors.neighborhood}
                value={formData.neighborhood}
                style={{ width: '160px' }}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle style={{ marginTop: '35px' }}>CONTATO</HeaderTitle>
          <RowWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              placeholder={'-'}
              label={'TELEFONE'}
            >
              <MaskedInput
                onChange={handleInputChange}
                name={'phoneNumber'}
                hasErrors={formErrors.phoneNumber}
                value={formData.phoneNumber}
                mask={phoneMask}
                style={{ width: '120px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'RAMAL'}
            >
              <TextInput
                placeholder={'-'}
                onChange={handleInputChange}
                name={'internTelephone'}
                value={formData.internTelephone}
                style={{ width: '60px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'NOME DO CONTATO'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'name'}
                hasErrors={formErrors.name}
                hasErrors={formErrors.name}
                value={formData.name}
                style={{ width: '250px' }}
              />
            </FieldWrapper>
            <FieldWrapper
              isDisabled={!isEditingMode}
              style={{}}
              label={'EMAIL'}
            >
              <TextInput
                onChange={handleInputChange}
                name={'email'}
                hasErrors={formErrors.email}
                value={formData.email}
                style={{ width: '250px' }}
              />
            </FieldWrapper>
          </RowWrapper>

          <HeaderTitle style={{ marginTop: '35px' }}>REFERÊNCIAS</HeaderTitle>
          <ColumnWrapper style={{ alignItems: 'flex-start' }}>
            {formData.references.map((e, i) => (
              <>
                <Reference
                  isDisabled={!isEditingMode}
                  index={i}
                  data={e.data}
                  touched={e.touched}
                  errors={e.errors}
                  handleChange={handleChangeReferences}
                  handleRemove={handleRemoveReference}
                  arraySize={formData.references.length}
                  banks={banks}
                />
              </>
            ))}
            {isEditingMode && (
              <AddIcon
                onClick={() => {
                  setFormData({
                    ...formData,
                    references: [
                      ...formData.references,
                      {
                        data: {
                          referenceType: {
                            value: '1',
                            label: '1 - Bancária'
                          }
                        },
                        touched: {},
                        errors: {}
                      }
                    ]
                  });
                }}
                style={{ margin: '40px 0px', alignSelf: 'center' }}
              />
            )}
          </ColumnWrapper>

          <p
            style={{
              marginTop: '40px',
              textAlign: 'center',
              color: theme.danger,
              alignSelf: 'center'
            }}
          >
            {warningMessageReferences}
          </p>

          {isEditingMode && (
            <FieldWrapper
              style={{ marginRight: '0px', width: '100%' }}
              label={'COMPROVAÇÃO DE DOCUMENTOS'}
            >
              <Dropzone
                acceptedFiles={formFiles}
                onChange={files => {
                  setFormFiles(files);
                }}
                style={{ width: '80%', marginRight: '0px', marginTop: '25px' }}
              />
            </FieldWrapper>
          )}

          <p
            style={{
              marginTop: '40px',
              textAlign: 'center',
              color: theme.danger,
              alignSelf: 'center'
            }}
          >
            {warningMessage}
          </p>
          {isEditingMode && (
            <RowWrapper
              style={{
                backgroundColor: theme.customGray3,
                padding: '10px 0px 10px 10px',
                alignSelf: 'flex-start',
                cursor: 'pointer',
                alignSelf: 'flex-start',
                marginBottom: '30px',
                width: '100%'
              }}
              onClick={() => {
                setFormData({
                  ...formData,
                  isChecked: !formData.isChecked
                });
              }}
            >
              <Checkbox checked={formData.isChecked} />
              <Text
                style={{ fontSize: '14px', marginLeft: '7px', color: theme.brandColor2 }}
              >
                Verifiquei todos os dados cadastrais de{' '}
                <strong>{formData.corporateName}</strong> e confirmo que estão
                devidamente atualizados.
              </Text>
            </RowWrapper>
          )}

          <RowWrapper
            style={{
              flexWrap: 'noWrap',
              marginTop: '70px',
              justifyContent: 'flex-end'
            }}
          ></RowWrapper>
        </ColumnWrapper>
      </UnmountClosed>
    </ColumnWrapper>
  );
}

export default DocumentsUpdatePJ;

function Reference({
  index,
  arraySize,
  data,
  handleChange,
  handleRemove,
  banks,
  errors,
  isDisabled
}) {
  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    handleChange({
      index,
      field: name,
      value: {
        ...data,
        [name]: typeof value === 'string' ? value.toUpperCase() : value
      }
    });
  }

  function handleSelectChange({ name, value, resetFields }) {
    if (resetFields) {
      handleChange({
        index,
        field: name,
        value: {
          [name]: value
        }
      });
    } else {
      handleChange({
        index,
        field: name,
        value: {
          ...data,
          [name]: value
        }
      });
    }
  }

  return (
    <RowWrapper
      key={index}
      style={{
        flexWrap: 'nowrap',
        backgroundColor: theme.brandColor5,
        marginTop: `${index !== 0 ? '20px' : '0px'}`,
        padding: '0px 10px 15px 15px'
      }}
    >
      <RowWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'TIPO'}>
          <SelectInput
            noOptionsMessage={() => '...'}
            options={referenceTypes}
            style={{ width: '250px' }}
            value={data.referenceType}
            onChange={val =>
              handleSelectChange({
                name: 'referenceType',
                value: val,
                resetFields: true
              })
            }
          />
        </FieldWrapper>
        <RefSubComponent
          isDisabled={isDisabled}
          index={index}
          {...data}
          errors={errors}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          banks={banks}
        />
      </RowWrapper>
      {arraySize > 1 && !isDisabled && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <CancelIcon onClick={() => handleRemove({ index })} />
        </div>
      )}
    </RowWrapper>
  );
}

function RefSubComponent({
  index,
  referenceType,
  handleInputChange,
  handleSelectChange,
  banks,
  errors,
  isDisabled,
  ...props
}) {
  if (referenceType.label === '1 - Bancária') {
    return (
      <>
        <FieldWrapper isDisabled={isDisabled} label={'BANCO'}>
          <SelectInput
            onChange={val => handleSelectChange({ name: 'bank', value: val })}
            value={props.bank}
            options={banks}
            style={{ width: '250px' }}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'AGÊNCIA'}>
          <TextInput
            onChange={handleInputChange}
            name={'branchId'}
            hasErrors={errors.branchId}
            value={props.branchId}
            type={'number'}
            maxLength={4}
            placeholder={''}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} label={'CONTA C/ DÍG.'}>
          <TextInput
            onChange={handleInputChange}
            name={'accountId'}
            hasErrors={errors.accountId}
            value={props.accountId}
            type={'number'}
            placeholder={''}
          />
        </FieldWrapper>

        <FieldWrapper isDisabled={isDisabled} label={'PRODUTO'}>
          <SelectInput
            onChange={val =>
              handleSelectChange({ name: 'product', value: val })
            }
            value={props.product}
            options={productTypes}
            style={{ width: '250px' }}
          />
        </FieldWrapper>
      </>
    );
  } else if (referenceType.label === '2 - Comercial') {
    return (
      <>
        <FieldWrapper
          isDisabled={isDisabled}
          style={{ width: '20%' }}
          label={'NOME DA INSTITUIÇÃO'}
        >
          <TextInput
            style={{ width: '250px' }}
            name={'name'}
            hasErrors={errors.name}
            value={props.name}
            onChange={handleInputChange}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} placeholder={'-'} label={'CNPJ'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'document'}
            hasErrors={errors.document}
            value={props.document}
            mask={cnpjMask}
            style={{ width: '160px' }}
          />
        </FieldWrapper>

        <FieldWrapper
          isDisabled={isDisabled}
          placeholder={'-'}
          label={'TELEFONE'}
        >
          <MaskedInput
            onChange={handleInputChange}
            name={'phoneNumber'}
            hasErrors={errors.phoneNumber}
            value={props.phoneNumber}
            mask={phoneMask}
            style={{ width: '120px' }}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} style={{}} label={'RAMAL'}>
          <TextInput
            onChange={handleInputChange}
            name={'extensionNumber'}
            hasErrors={errors.extensionNumber}
            value={props.extensionNumber}
            style={{ width: '60px' }}
          />
        </FieldWrapper>
      </>
    );
  } else if (referenceType.label === '3 - Pessoal') {
    return (
      <>
        <FieldWrapper
          isDisabled={isDisabled}
          style={{ width: '20%' }}
          label={'NOME'}
        >
          <TextInput
            style={{ width: '250px' }}
            name={'name'}
            hasErrors={errors.name}
            value={props.name}
            onChange={handleInputChange}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} placeholder={'-'} label={'CPF'}>
          <MaskedInput
            onChange={handleInputChange}
            name={'document'}
            hasErrors={errors.document}
            value={props.document}
            mask={cpfMask}
            style={{ width: '160px' }}
          />
        </FieldWrapper>

        <FieldWrapper
          isDisabled={isDisabled}
          placeholder={'-'}
          label={'TELEFONE'}
        >
          <MaskedInput
            onChange={handleInputChange}
            name={'phoneNumber'}
            hasErrors={errors.phoneNumber}
            value={props.phoneNumber}
            mask={phoneMask}
            style={{ width: '120px' }}
          />
        </FieldWrapper>
        <FieldWrapper isDisabled={isDisabled} style={{}} label={'RAMAL'}>
          <TextInput
            onChange={handleInputChange}
            hasErrors={errors.extensionNumber}
            name={'extensionNumber'}
            value={props.extensionNumber}
            style={{ width: '60px' }}
          />
        </FieldWrapper>
      </>
    );
  } else {
    return null;
  }
}

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import '../react-table.css';
import ReactTable from 'react-table';
import { Container, Title, ViewButton } from './styles';
import ValueOnTables from 'components/ValueOnTables';

const resolveColor = ({ status, type }) => {
  if (type === 'payment') {
    switch (status) {
      case 'PAID':
        return 'green';
      case 'PROCESSING':
        return '#b99f1a';
      case 'CANCELED':
        return 'red';
      case 'NOT_PAID':
        return 'red';
      case 'ERRO':
        return 'red';
      default:
        return 'inherit';
    }
  } else if (type === 'transfer') {
    switch (status) {
      case 'SUCESSO':
        return 'green';
      case 'ERRO':
        return 'red';
      default:
        return 'inherit';
    }
  } else {
    switch (status) {
      case 'SUCCESS':
        return 'green';
      default:
        return 'inherit';
    }
  }
};

const resolveStatus = ({ status, type }) => {
  if (type === 'payment') {
    switch (status) {
      case 'PAID':
        return 'SUCESSO';
      case 'PROCESSING':
        return 'PROCESSANDO';
      case 'CANCELED':
        return 'CANCELADO';
      case 'NOT_PAID':
        return 'NÃO PAGO';
      case 'ERRO':
        return 'NÃO PROCESSADO';
      default:
        return 'INDEFINIDO';
    }
  } else if (type === 'transfer') {
    switch (status) {
      case 'ERRO':
        return 'NÃO PROCESSADO';
      default:
        return status;
    }
  } else {
    switch (status) {
      case 'SUCCESS':
        return 'SUCESSO';
      default:
        return status;
    }
  }
};

const columns = [
  {
    Header: 'Data',
    accessor: 'date',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{moment(props.value).format('DD/MM/YYYY')}</span>
      </div>
    ),
  },
  {
    Header: 'Descrição',
    accessor: 'description',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{props.value}</span>
      </div>
    ),
  },
  {
    Header: 'Situação',
    accessor: 'situation',
    Cell: (props) => (
      <div
        style={{
          textAlign: 'center',
          color: resolveColor({
            status: props.value,
            type: props.original.type,
          }),
        }}
      >
        <span>
          {resolveStatus({ status: props.value, type: props.original.type })}
        </span>
      </div>
    ),
  },
  {
    Header: 'Valor',
    accessor: 'value',
    Cell: (props) => (
      <ValueOnTables
        style={{ textAlign: 'center' }}
        value={props.value}
        type={props.original.type}
      />
    ),
  },
  {
    Header: 'Identificador',
    accessor: 'identifier',
    Cell: (props) => (
      <div style={{ textAlign: 'center' }}>
        <span>{props.value}</span>
      </div>
    ),
  },
  {
    Header: 'Comprovante',
    accessor: 'onClick',
    Cell: (props) =>
      props.original.situation ? (
        <div style={{ textAlign: 'center' }}>
          <ViewButton
            disabled={
              (props.original.type === 'payment' &&
                props.original.situation !== 'PAID') ||
              (props.original.type === 'transfer' &&
                props.original.situation !== 'SUCESSO') ||
              (props.original.type === 'pix' &&
                props.original.situation !== 'SUCCESS')
            }
            onClick={
              props.original.type === 'transfer'
                ? () =>
                    props.value({
                      type: props.original.type,
                      identifier: props.original.identifier,
                      date: props.original.date,
                      historyCode: props.original.historyCode,
                    })
                : props.original.type === 'transfer'
                ? () =>
                    props.value({
                      identifier: props.original.payment,
                      type: props.original.type,
                    })
                : () =>
                    props.value({
                      identifier: props.original.identifier,
                      type: props.original.type,
                    })
            }
          >
            Visualizar
          </ViewButton>
        </div>
      ) : null,
  },
];

function ReceiptList({ portfolioName, data }) {
  return (
    <Container>
      <Title>
        Confira abaixo suas transações. Filtre-as clicando no cabeçalho de cada
        item <strong>{portfolioName}</strong>
      </Title>
      <ReactTable
        noDataText="Nenhum dado disponível"
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={data ? data.length : 0}
        //className="-striped -highlight"
      />
    </Container>
  );
}

export default ReceiptList;

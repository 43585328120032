import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.Cor_base_marca_01};
  border-radius: 20px;
  background-color: ${props =>
    props.isSelected ? props.theme.Cor_base_marca_01 : props.theme.Cor_auxiliar_01};
  font-family: "SourceSansPro", Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: ${props => (props.isSelected ? props.theme.Cor_auxiliar_01 : props.theme.Cor_base_marca_01)};
  padding-top: 12px;
  padding-bottom: 8px;
  transition: 300ms ease-in;
  margin-right: 15px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 12px 8px 12px;
`;

import React from "react";

import { Value } from "./styles";
import { handleCurrency } from "utils/functions/currency";

function ValueOnTables({ value, type, ...props }) {
  return (
    <Value {...props} value={handleCurrency({ value, type })}>
      {handleCurrency({ value, type })}
    </Value>
  );
}

export default ValueOnTables;

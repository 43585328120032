import "array-flat-polyfill";

const parseBanks = ({ data }) => {

    if (!data || typeof data !== "object") return [];

    return data.map(e => ({
        value: e.code,
        label: `${e.code} - ${e.shortName}`
    }));

}

export { parseBanks };





import styled from "styled-components";
import { Download } from 'styled-icons/remix-line/Download';
import media from "utils/media";

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.brandColor2};
  border-radius: 2px;
  margin-right: 25px;

  ${media.phone`
    flex: 0.3;
    margin-right: 0px;
  `};
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px 20px 20px;

  ${media.phone`
    flex-wrap: wrap;
  `}
`;

export const AccountSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;


export const Divisor = styled.div`
  margin: 0px;
  border-bottom: 1px solid ${props => props.theme.brandColor5};
  margin-bottom: 10px;
`;

export const PeriodLabel = styled.span`
  font-size: 10px;
  margin: 5px 0px 5px 5px;
`;

export const TopLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.success};
    align-self: center;
    margin-right: 5px;
`;

export const Value = styled.span`
    margin: 0;
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    padding: 5px 0;
`;

export const ColoredValue = styled(Value)`
  color: ${props => props.status === '1' ? props.theme.success : props.theme.alert};
`;

export const IconWrapper = styled.button`
  margin: 0px;
  padding: 0px;
  border: 0px solid black;
  text-align: center;

  :disabled{
    opacity: 0.1;
  };
`;

export const DownloadIcon = styled(Download)`

  cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};
  text-align: center;

  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.85);
`;
import React from 'react';
import { Container, AlertIcon } from './styles';


function SchedulingWarning({ message }) {
    return (
        <Container>
            <AlertIcon />
            {message}
        </Container>
    )

}

export default SchedulingWarning;
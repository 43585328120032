import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ScheduledOperationsService } from 'banking-service';
import { handleCurrency } from 'utils/functions/currency';

import {
  Container,
  ContentWrapper,
  NoItemsAvailableLabel,
  ActionsWrapper,
  ItemContainer,
  ColumnWrapper,
  Title,
  Value,
  ColoredValue,
  CancelIcon,
  CustomAutocomplete,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import FilterDatePicker from 'components/FilterDatePicker';

const options = [
  {
    value: '',
    label: 'Todos',
  },
  {
    value: 'INTERNAL_TRANSFER',
    label: 'Transferências Internas',
  },
  {
    value: 'TRANSFER',
    label: 'TEDs',
  },
  {
    value: 'PAYMENT',
    label: 'Pagamentos',
  },
  {
    value: 'PIX',
    label: 'PIX',
  },
];

const initialDates = {
  startDate: moment(),
  endDate: moment().add('90', 'days'),
};

function ScheduledOperations({ section = '', name = 'Agendamentos' }) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [scheduledOperations, setScheduledOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [query, setQuery] = useState({ ...initialDates });
  const [scheduledOperationType, setScheduledOperationType] = useState(
    options[0]
  );
  useEffect(() => {
    const fetchScheduledOperations = async () => {
      setIsLoading(true);
      try {
        const res = await ScheduledOperationsService.fetch({
          ...query,
          scheduledOperationType: scheduledOperationType.value,
        });
        setScheduledOperations([
          ...res.sort(function(a, b) {
            var x = a.status.toLowerCase();
            var y = b.status.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          }),
        ]);
      } catch (e) {
        setError('Não conseguimos buscar seus agendamentos para esta data');
      }
      setIsLoading(false);
    };
    if (query) {
      fetchScheduledOperations();
    }
  }, [query, scheduledOperationType]);

  const deleteScheduledOperation = async ({ typeScheduling, schedulingId }) => {
    setIsLoading(true);
    try {
      const res = await ScheduledOperationsService.delete({
        typeScheduling,
        schedulingId,
      });
      alert.success('Agendamento cancelado com sucesso');
      setScheduledOperations((scheduledOperations) =>
        scheduledOperations.map((e) => {
          if (e.schedulingId === schedulingId) {
            return { ...e, status: 'CANCELADO' };
          } else {
            return { ...e };
          }
        })
      );
    } catch (e) {
      setError(
        'Não conseguimos remover o seu agendamento. Por favor, tente novamente'
      );
    }
    setIsLoading(false);
  };

  const Item = ({
    status,
    scheduledTo,
    schedulingId,
    typeScheduling,
    value,
    beneficiary,
    onClick,
  }) => (
    <ItemContainer>
      <ColumnWrapper style={{ flex: 0.3 }}>
        <Title>Status</Title>
        <ColoredValue status={status}>{status}</ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.2 }}>
        <Title>Tipo</Title>
        <ColoredValue>
          {typeScheduling === 'PAYMENT'
            ? 'PAGAMENTO'
            : typeScheduling === 'TRANSFER'
            ? 'TED'
            : typeScheduling === 'INTERNAL_TRANSFER'
            ? 'TRANSF. TOPÁZIO'
            : typeScheduling === 'PIX'
            ? 'PIX'
            : 'INDISP'}
        </ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.7 }}>
        <Title>Beneficiário</Title>
        <ColoredValue>{beneficiary}</ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.2 }}>
        <Title>Valor</Title>
        <ColoredValue colored>{handleCurrency({ value: value })}</ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.3 }}>
        <Title>Agendado para</Title>
        <Value>{moment(scheduledTo).format('DD/MM/YYYY')}</Value>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.3 }}>
        <Title>Transação</Title>
        <Value>{schedulingId}</Value>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.2 }}>
        <CancelIcon
          status={status}
          onClick={() => onClick({ typeScheduling, schedulingId })}
        />
      </ColumnWrapper>
    </ItemContainer>
  );
  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <>
            <ActionsWrapper style={{ marginBottom: '40px' }}>
              <CustomAutocomplete
                value={scheduledOperationType}
                isSearchable={false}
                id="scheduledOperationTypeSelectId"
                //noOptionsMessage={({ inputValue }) => `Nova transferência para ${inputValue}`}
                placeholder={'Tipo de Agendamento'}
                options={options}
                onChange={(val) => {
                  setScheduledOperationType({ ...val });
                }}
              />
              <FilterDatePicker
                initialDates={initialDates}
                onDateChange={(newQuery) => setQuery(newQuery)}
                showDefaultInputIcon
              ></FilterDatePicker>
            </ActionsWrapper>

            {scheduledOperations.length === 0 ? (
              <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
                Nenhum agendamento disponível
              </NoItemsAvailableLabel>
            ) : (
              scheduledOperations.map((e) => (
                <Item
                  {...e}
                  onClick={async ({ typeScheduling, schedulingId }) =>
                    await deleteScheduledOperation({
                      typeScheduling,
                      schedulingId,
                    })
                  }
                />
              ))
            )}
          </>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default ScheduledOperations;

import styled from "styled-components";

import media from "utils/media";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.backgroundPrimary};
  padding: 30px 60px 20px 20px;
  ${media.phone`
    padding: 30px 10px 10px 10px;
  `};
`;

export const ContentWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  flex: 1;
  flex-direction: column;
  ${media.phone`
    margin-top: 25px;
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 2px;
  background-color: ${props => props.theme.brandColor2};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px ${props => props.theme.brandColor5};
  padding: 0;
  margin: 30px 0px 0px 0px;
  padding: 20px 5px 20px 5px;
  box-sizing: border-box;
  ${media.phone`
    padding: 0px;
  `}
`;



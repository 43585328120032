import React, { useState, useEffect, Fragment } from "react";
import { AccountService, UserService } from 'banking-service';
import {
  Container,
  ContentWrapper,
  WhiteSpace,
  FormWrapper,
  ActionsWrapper,
  TogglesWrapper,
  TransferTypeToggleLeft,
  TransferTypeToggleRight,
  FieldsWrapper,
  OldPasswordInput,


} from "./styles";
import { useAlert } from "react-alert";
import LoadingSpinner from "components/LoadingSpinner";
import ScreenTitle from "components/ScreenTitle";
import Button from "components/Button";


const PASSWORD_TYPES = {
  ACCOUNT: 'ACCOUNT',
  TRANSACTION: 'TRANSACTION',
}




function ChangePasswords({ section = "Segurança", name = 'Alterar Senha' }) {
  const alert = useAlert();

  const [error, setError] = useState("");
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedType, setSelectedType] = useState(PASSWORD_TYPES.ACCOUNT);

  const initialFormFilledAccount = {
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
  }

  const initialFormErrorsAccount = {
    oldPassword: false,
    newPassword: false,
    newPasswordAgain: false,
  }

  const initialFormTouchedAccount = {
    oldPassword: false,
    newPassword: false,
    newPasswordAgain: false,
  }


  const initialFormFilledTransaction = {
    oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
  }

  const initialFormErrorsTransaction = {
    oldPassword: false,
    newPassword: false,
    newPasswordAgain: false,
  }

  const initialFormTouchedTransaction = {
    oldPassword: false,
    newPassword: false,
    newPasswordAgain: false,
  }


  const [formFilledAccount, setFormFilledAccount] = useState({ ...initialFormFilledAccount });
  const [formErrorsAccount, setFormErrorsAccount] = useState({ ...initialFormErrorsAccount });
  const [formTouchedAccount, setFormTouchedAccount] = useState({ ...initialFormTouchedAccount });

  const [formFilledTransaction, setFormFilledTransaction] = useState({ ...initialFormFilledTransaction });
  const [formErrorsTransaction, setFormErrorsTransaction] = useState({ ...initialFormErrorsTransaction });
  const [formTouchedTransaction, setFormTouchedTransaction] = useState({ ...initialFormTouchedTransaction });



  async function onSubmitClick() {

    const confirmChangePasswordAccount = async () => {
      setIsLoading(true);
      setError("");
      try {
        const res = await UserService.changePasswordAccount({ ...formFilledAccount });
        alert.success('Senha de acesso alterada com sucesso!');
        setFormFilledAccount({ ...initialFormFilledAccount });

      } catch (e) {
        setError(e && e.error && e.error.length > 0 ? e.error[0] : { message: 'Um erro inesperado aconteceu. Por favor, tente novamente.' });

      }
      setIsLoading(false);
    }

    const confirmChangePasswordTransaction = async () => {
      setIsLoading(true);
      setError("");
      try {
        const res = await UserService.changePasswordTransaction({ ...formFilledTransaction });
        alert.success('Senha de transação alterada com sucesso!');
        setFormFilledTransaction({ ...initialFormFilledTransaction });

      } catch (e) {
        setError(e && e.error && e.error.length > 0 ? e.error[0] : { message: 'Um erro inesperado aconteceu. Por favor, tente novamente.' });

      }
      setIsLoading(false);
    }


    if (selectedType === PASSWORD_TYPES.ACCOUNT) {
      await confirmChangePasswordAccount();
    }
    else {
      confirmChangePasswordTransaction()
    }
  };

  function handleButtonDisability() {
    if (selectedType === PASSWORD_TYPES.ACCOUNT) {
      if (formFilledAccount.oldPassword === '' ||
        formFilledAccount.newPassword === '' ||
        formFilledAccount.newPasswordAgain === '' ||
        formFilledAccount.newPassword !== formFilledAccount.newPasswordAgain
      ) {
        return true;
      } else {
        return false;
      }
    }
    else {
      if (formFilledTransaction.oldPassword === '' ||
        formFilledTransaction.newPassword === '' ||
        formFilledTransaction.newPasswordAgain === '' ||
        formFilledTransaction.newPassword !== formFilledTransaction.newPasswordAgain
      ) {
        return true;
      } else {
        return false;
      }
    }

  }

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <WhiteSpace>
            <FormWrapper>
              <TogglesWrapper>
                <TransferTypeToggleLeft onClick={() => setSelectedType(PASSWORD_TYPES.ACCOUNT)} isSelected={selectedType === PASSWORD_TYPES.ACCOUNT}>ACESSO</TransferTypeToggleLeft>
                <TransferTypeToggleRight onClick={() => setSelectedType(PASSWORD_TYPES.TRANSACTION)} isSelected={selectedType === PASSWORD_TYPES.TRANSACTION} >TRANSAÇÃO</TransferTypeToggleRight>
              </TogglesWrapper>
              <FieldsWrapper>
                {
                  selectedType === PASSWORD_TYPES.ACCOUNT &&
                  <Fragment>
                    <OldPasswordInput
                      value={formFilledAccount.oldPassword}
                      id='oldPasswordInputId'
                      hasErrors={formErrorsAccount.oldPassword && formTouchedAccount.oldPassword}
                      placeholder="Senha de acesso atual *"
                      type="text"
                      onChange={e => {
                        setFormFilledAccount({ ...formFilledAccount, oldPassword: e.target.value });
                        setFormTouchedAccount({ ...formTouchedAccount, oldPassword: true });
                        if (e.target.value === '') {
                          setFormErrorsAccount({ ...formErrorsAccount, oldPassword: true })
                        } else {
                          setFormErrorsAccount({ ...formErrorsAccount, oldPassword: false })

                        }
                      }}
                      onBlur={() => {
                        setFormTouchedAccount({ ...formTouchedAccount, oldPassword: true });
                        if (formFilledAccount.oldPassword === '') {
                          setFormErrorsAccount({ ...formErrorsAccount, oldPassword: true })
                        } else {
                          setFormErrorsAccount({ ...formErrorsAccount, oldPassword: false })

                        }
                      }}
                    />
                    <OldPasswordInput
                      value={formFilledAccount.newPassword}
                      id='newPasswordInputId'
                      hasErrors={formErrorsAccount.newPassword && formTouchedAccount.newPassword}
                      placeholder="Nova senha de acesso *"
                      type="text"
                      onChange={e => {
                        setFormFilledAccount({ ...formFilledAccount, newPassword: e.target.value });
                        setFormTouchedAccount({ ...formTouchedAccount, newPassword: true });
                        if (e.target.value === '') {
                          setFormErrorsAccount({ ...formErrorsAccount, newPassword: true })
                        } else {
                          setFormErrorsAccount({ ...formErrorsAccount, newPassword: false })

                        }
                      }}
                      onBlur={() => {
                        setFormTouchedAccount({ ...formTouchedAccount, newPassword: true });
                        if (formFilledAccount.newPassword === '') {
                          setFormErrorsAccount({ ...formErrorsAccount, newPassword: true })
                        } else {
                          setFormErrorsAccount({ ...formErrorsAccount, newPassword: false })

                        }
                      }}
                    />
                    <OldPasswordInput
                      value={formFilledAccount.newPasswordAgain}
                      id='newPasswordAgainInputId'
                      hasErrors={formErrorsAccount.newPasswordAgain && formTouchedAccount.newPasswordAgain}
                      placeholder="Confirmar nova senha de acesso *"
                      type="text"
                      onChange={e => {
                        setFormFilledAccount({ ...formFilledAccount, newPasswordAgain: e.target.value });
                        setFormTouchedAccount({ ...formTouchedAccount, newPasswordAgain: true });
                        if (e.target.value === '') {
                          setFormErrorsAccount({ ...formErrorsAccount, newPasswordAgain: true })
                        } else {
                          setFormErrorsAccount({ ...formErrorsAccount, newPasswordAgain: false })

                        }
                      }}
                      onBlur={() => {
                        setFormTouchedAccount({ ...formTouchedAccount, newPasswordAgain: true });
                        if (formFilledAccount.newPasswordAgain === '') {
                          setFormErrorsAccount({ ...formErrorsAccount, newPasswordAgain: true })
                        } else {
                          setFormErrorsAccount({ ...formErrorsAccount, newPasswordAgain: false })

                        }
                      }}
                    />
                  </Fragment>
                }





                {
                  selectedType === PASSWORD_TYPES.TRANSACTION &&
                  <Fragment>
                    <OldPasswordInput
                      value={formFilledTransaction.oldPassword}
                      id='oldPasswordInputId'
                      hasErrors={formErrorsTransaction.oldPassword && formTouchedTransaction.oldPassword}
                      placeholder="Senha de transação atual *"
                      type="text"
                      onChange={e => {
                        setFormFilledTransaction({ ...formFilledTransaction, oldPassword: e.target.value });
                        setFormTouchedTransaction({ ...formTouchedTransaction, oldPassword: true });
                        if (e.target.value === '') {
                          setFormErrorsTransaction({ ...formErrorsTransaction, oldPassword: true })
                        } else {
                          setFormErrorsTransaction({ ...formErrorsTransaction, oldPassword: false })

                        }
                      }}
                      onBlur={() => {
                        setFormTouchedTransaction({ ...formTouchedTransaction, oldPassword: true });
                        if (formFilledTransaction.oldPassword === '') {
                          setFormErrorsTransaction({ ...formErrorsTransaction, oldPassword: true })
                        } else {
                          setFormErrorsTransaction({ ...formErrorsTransaction, oldPassword: false })

                        }
                      }}
                    />
                    <OldPasswordInput
                      value={formFilledTransaction.newPassword}
                      id='newPasswordInputId'
                      hasErrors={formErrorsTransaction.newPassword && formTouchedTransaction.newPassword}
                      placeholder="Nova senha de transação *"
                      type="text"
                      onChange={e => {
                        setFormFilledTransaction({ ...formFilledTransaction, newPassword: e.target.value });
                        setFormTouchedTransaction({ ...formTouchedTransaction, newPassword: true });
                        if (e.target.value === '') {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPassword: true })
                        } else {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPassword: false })

                        }
                      }}
                      onBlur={() => {
                        setFormTouchedTransaction({ ...formTouchedTransaction, newPassword: true });
                        if (formFilledTransaction.newPassword === '') {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPassword: true })
                        } else {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPassword: false })

                        }
                      }}
                    />
                    <OldPasswordInput
                      value={formFilledTransaction.newPasswordAgain}
                      id='newPasswordAgainInputId'
                      hasErrors={formErrorsTransaction.newPasswordAgain && formTouchedTransaction.newPasswordAgain}
                      placeholder="Confirmar nova senha de transação *"
                      type="text"
                      onChange={e => {
                        setFormFilledTransaction({ ...formFilledTransaction, newPasswordAgain: e.target.value });
                        setFormTouchedTransaction({ ...formTouchedTransaction, newPasswordAgain: true });
                        if (e.target.value === '') {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPasswordAgain: true })
                        } else {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPasswordAgain: false })

                        }
                      }}
                      onBlur={() => {
                        setFormTouchedTransaction({ ...formTouchedTransaction, newPasswordAgain: true });
                        if (formFilledTransaction.newPasswordAgain === '') {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPasswordAgain: true })
                        } else {
                          setFormErrorsTransaction({ ...formErrorsTransaction, newPasswordAgain: false })

                        }
                      }}
                    />
                  </Fragment>
                }

              </FieldsWrapper>
              <ActionsWrapper>
                <Button onClick={onSubmitClick} disabled={handleButtonDisability()} style={{ padding: '12px 100px 12px 100px', maxWidth: '300px' }} title={'Salvar'} />
                <br />
              </ActionsWrapper>
            </FormWrapper>
          </WhiteSpace>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default ChangePasswords;

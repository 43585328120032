export const SecurityAccountBalanceTexts01 = [
  {
    title: 'LIMITE CONCEDIDO',
    subtitle: '',
    fieldName: 'limit',
  },
  {
    title: 'LIMITE DISPONÍVEL',
    subtitle: '',
    fieldName: 'balanceAvailable',
  },
];

export const SecurityAccountBalanceTexts02 = [
  {
    title: 'Agência/Conta Garantida',
    subtitle: '',
    fieldName: 'branchaccount',
    type: 'normal',
  },
  {
    title: 'Indexador',
    subtitle: '',
    fieldName: 'index',
    type: 'normal',
  },
  {
    title: '% Indexador',
    subtitle: '',
    fieldName: 'indexPercentage',
    type: 'normal',
  },
  {
    title: '% Taxa pré-fixada',
    subtitle: '',
    fieldName: 'rate',
    type: 'normal',
  },
  {
    title: 'Limite Utilizado',
    subtitle: '',
    fieldName: 'balance',
  },
  {
    title: 'Abertura do limite',
    subtitle: '',
    fieldName: 'initialDate',
    type: 'normal',
  },
  {
    title: 'Vencimento do limite',
    subtitle: '',
    fieldName: 'dueDate',
    type: 'normal',
  },
  {
    title: 'Status',
    subtitle: '',
    fieldName: 'status',
    type: 'normal',
  },
];

export const SecurityAccountBalanceTexts03 = [
  {
    title: 'Limite Utilizado',
    subtitle: '',
    fieldName: 'balance',
  },
  {
    title: 'Encargos acumulado até a data',
    subtitle: '',
    fieldName: 'interest',
  },
  {
    title: 'IOF acumulado até a data',
    subtitle: '',
    fieldName: 'taxFinancialValue',
  },
  /*   {
    title: 'Valor da multa de atraso',
    subtitle: '',
    fieldName: 'totalFine',
  }, */
  {
    title: 'Saldo atual + encargos e IOF provisionados ',
    subtitle: '',
    fieldName: 'grossTotalBalance',
  },
  {
    title: 'Limite disponível para saque',
    subtitle: '',
    fieldName: 'balanceAvailable',
  },
];

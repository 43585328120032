import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ReportIncomeService } from 'banking-service';
import Button from 'components/Button';

import { Text } from 'tpz-shared-components';

import {
  Container,
  ContentWrapper,
  NoItemsAvailableLabel,
  ActionsWrapper,
  ItemContainer,
  ColumnWrapper,
  Title,
  DownloadButton,
  ColoredValue,
  DownloadIcon,
  CustomAutocomplete
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import theme from 'constants/theme';
import { useConfigContext } from 'providers/client-config-provider';

const LIMIT_IN_YEARS = 2;

const currentYear = new Date().getFullYear();
const optionsGenerator = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const options = optionsGenerator(
  currentYear - 1,
  currentYear - LIMIT_IN_YEARS,
  -1
).map(e => ({ value: e, label: e }));

function ReportIncome({
  section = 'Serviços',
  name = 'INFORME DE RENDIMENTOS'
}) {
  const alert = useAlert();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [reportsIncome, setReportsIncome] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState(options[0]);
  const [hasFetchedOnce, setHasFetchedOnce] = useState(false);

  const fetchReportIncome = async () => {
    setHasFetchedOnce(true);
    setIsLoading(true);
    try {
      const res = await ReportIncomeService.fetch({
        year: query.value
      });
      setReportsIncome({ ...res });
    } catch (e) {
      setReportsIncome(null);
      setError('Não conseguimos buscar informes de rendimento para esta data');
    }
    setIsLoading(false);
  };

  return (
    <LoadingSpinner isLoading={isLoading}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          <>
            <Text
              style={{ letterSpacing: '0.32px', lineHeight: '1.35px' }}
              fontFamily="segoeui"
              color={theme.brandColor3}
            >
              INFORME DE RENDIMENTOS
            </Text>
            <ActionsWrapper style={{ marginBottom: '40px' }}>
              <CustomAutocomplete
                value={query}
                isSearchable={false}
                id="reportIncomeSelect"
                placeholder={'Ano Fiscal'}
                options={options}
                onChange={val => {
                  setQuery({ ...val });
                  setReportsIncome(null);
                  setHasFetchedOnce(false);
                }}
              />
              <Button
                onClick={() => fetchReportIncome()}
                title={'BUSCAR'}
                style={{ marginLeft: '10px' }}
              />
            </ActionsWrapper>

            {hasFetchedOnce && (
              <>
                <Text
                  style={{ letterSpacing: '0.32px', lineHeight: '1.35px' }}
                  fontFamily="segoeui"
                  color={theme.brandColor3}
                >
                  RESULTADOS DA BUSCA:
                </Text>
                {!reportsIncome ? (
                  <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
                    Nenhum arquivo disponível
                  </NoItemsAvailableLabel>
                ) : (
                  <Item
                    year={query.value}
                    base64={reportsIncome.reportIncome}
                  />
                )}
              </>
            )}
          </>
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

const Item = ({ year, base64 }) => {
  return (
    <ItemContainer style={{ fontFamily: 'segoui' }}>
      <ColumnWrapper style={{ flex: 0.3 }}>
        <Title>Tipo de Documento</Title>
        <ColoredValue>INFORME DE RENDIMENTOS</ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.3 }}>
        <Title>Ano Fiscal</Title>
        <ColoredValue>{year}</ColoredValue>
      </ColumnWrapper>
      <ColumnWrapper style={{ flex: 0.3 }}>
        <a
          href={'data:application/octet-stream;base64,' + base64}
          download={`INFORME_DE_RENDIMENTOS_${year}_BANCOTOPÁZIO.pdf`}
        >
          <DownloadButton>
            Download PDF <DownloadIcon />
          </DownloadButton>
        </a>
      </ColumnWrapper>
    </ItemContainer>
  );
};

export default ReportIncome;

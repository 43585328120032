import styled from 'styled-components';
import { CheckCircle } from 'styled-icons/boxicons-solid/CheckCircle';
import { Download } from 'styled-icons/boxicons-regular/Download';


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ContentWrapper = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
`;

export const TopLabel = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.success};
`;

export const TransactionDescription = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
`;



export const CheckMarkIcon = styled(CheckCircle)`
    width: 20px;
    height: 20px;
    color: ${props => props.theme.success};
    margin-right: 12px;
`

export const ItemContainer = styled.div`
    display: flex;
    flex: 1;
    width: 80%; 
    max-height: 20px;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-left: 19px;
    padding-bottom: 13px;
    padding-right: 12px;
    background-color: ${props => props.isAmountField ? props.theme.Cor_base_marca_01 : props.theme.Cor_auxiliar_01};
    margin: 0;
    border: 0;
    color: ${props => props.isAmountField ? props.theme.Cor_auxiliar_01 : props.theme.Cor_base_marca_03};
    border-bottom: 1px solid ${props => props.theme.Cor_auxiliar_03};
 `;

export const PortfolioName = styled.span`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.isAmountField ? props.theme.brandColor2 : props.theme.brandColor3};
    text-transform: uppercase;
 `;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-top: 20px;
`;

export const AditionalInformationsWrapper = styled.div`
    display: flex;
    width: 80%; 
    flex-direction: row;
    padding-right: 50px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

export const InformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const InformationLabel = styled.label`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 11px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
`;

export const InformationValue = styled.label`
    font-family: "SourceSansPro", Arial, sans-serif;
    font-size: 11px;
    font-weight: 300;
    margin-top: 5px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${props => props.theme.brandColor3};
    text-transform: uppercase;
`;

export const DownloadIcon = styled(Download)`
    width: 35px;
    height: 35px;
    color: ${props => props.theme.brandColor3};
    cursor: pointer;
    font-weight: normal;
`;

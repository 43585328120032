import React from "react";
import { parseText } from "utils/functions/text";

import {
  Container,
  Name,
  AccountInformationsWrapper,
  Agency,
  Account,
  ProfileInformations
} from "./styles";

function AccountBoxOption({ id, name, agency, account, profile, taxIdentifier, isSelected, onSelect }) {
  return (
    <Container
      id="AccountBoxOptionDiv"
      isSelected={isSelected}
      onClick={() => { onSelect(id) }}>
      <Name id="AccountBoxOptionAccountLabel" >{name ? parseText({ text: name, limit: 21 }) : ""}</Name>
      <AccountInformationsWrapper>
        <Agency id="AccountBoxOptionBranchLabel">AG {agency}</Agency>
        <Account id="AccountBoxOptionAccountLabel">CONTA {account}</Account>
      </AccountInformationsWrapper>
      {profile && <ProfileInformations id="lastAccessLabel">
        {profile === 'PF' ? `CPF ${taxIdentifier}` : `CNPJ ${taxIdentifier}`}
      </ProfileInformations>}
    </Container>
  );
}

export default AccountBoxOption;

import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import {
    Container,
    HeaderWrapper,
    HeaderText,
    ClockIcon,
    PhraseText,
    PaymentIdentifierWrapper,
    PaymentIdentifierText,
    CopyToCliboardIcon
} from './styles';
import Button from 'components/Button';

function PaymentFeedback({ transactionId, onButtonClick }) {

    const [copySuccess, setCopySuccess] = useState(null);
    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        var textField = document.createElement('textarea')
        textField.innerText = transactionId;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove();
        setCopySuccess('Copied!');
    };

    const alert = useAlert();
    useEffect(() => {
        if (copySuccess)
            alert.success('Identificador copiado com sucesso', { timeout: 4000, onClose: () => { setCopySuccess(null) } });

    }, [copySuccess])

    return (
        <Container>
            <HeaderWrapper>
                <ClockIcon />
                <HeaderText>Pagamento em análise</HeaderText>
            </HeaderWrapper>
            <PhraseText style={{ marginBottom: '5px', }}>Seu pagamento foi efetuado com sucesso e está <strong>em análise</strong></PhraseText>
            <PhraseText>Uma vez efetuado, você poderá conferir o status no menu Comprovantes.</PhraseText>
            <PaymentIdentifierWrapper>
                <PaymentIdentifierText ref={textAreaRef}>Identificador do pagamento <strong>{transactionId}</strong></PaymentIdentifierText>
                <CopyToCliboardIcon onClick={copyToClipboard}></CopyToCliboardIcon>
            </PaymentIdentifierWrapper>
            <Button onClick={onButtonClick} title={'IR PARA COMPROVANTES'} />
        </Container>
    )

};

export default PaymentFeedback;
import React, { useState, useEffect } from 'react';
import {
  ReceiptsService,
  TransfersService,
  PaymentService,
  AccountService,
  ToggleService,
} from 'banking-service';
import { useModal } from 'react-modal-hook';
import {
  Container,
  ContentWrapper,
  ActionsWrapper,
  CustomAutocomplete,
  ModalContainer,
  CloseButton,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import CurrentAccountValue from 'components/CurrentAccountValue';
import FilterDatePicker from 'components/FilterDatePicker';

import ReceiptList from 'components/ReceiptList';

import TransferReceipt from 'components/TransferReceipt';
import PaymentReceipt from 'components/PaymentReceipt';
import PixReceipt from 'components/PixReceipt';

import ReactModal from 'react-modal';
import moment from 'moment';
import { useTheme } from 'styled-components';

const resolveTransferFields = (el) => {
  return {
    ...el,
    type: 'transfer',
  };
};

const resolvePaymentFields = (el) => {
  return {
    ...el,
    date: el.paymentDate,
    situation: el.status,
    value: el.paymentValue,
    identifier: el.payment,
    type: 'payment',
  };
};

const resolvePixFields = (el) => {
  return {
    ...el,
    identifier: el.transactionId,
    value: el.paymentValue,
    type: 'pix',
    description: `PIX - ${el.thirdPartName}`,
    date: moment(el.createdAt).format('YYYY-MM-DD'),
    situation: el.status || 'SUCCESS',
  };
};

const options = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'transfers',
    label: 'Transferências',
  },
  {
    value: 'payments',
    label: 'Pagamentos',
  },
  {
    value: 'pix',
    label: 'Pix',
  },
];

const initialReceiptType = options[0];

const initialDates = {
  startDate: moment().subtract(30, 'day'),
  endDate: moment(),
};

function ReceiptsWithScheduling({ name = 'Comprovantes' }) {
  const alert = useAlert();
  const theme = useTheme();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState(false);

  function ModalContent({ onCloseClick, children }) {
    return (
      <ModalContainer>
        <CloseButton size={20} onClick={onCloseClick} />
        {children}
      </ModalContainer>
    );
  }

  const [balance, setBalance] = useState(null);
  useEffect(() => {
    const fetchAccountBalance = async () => {
      setIsLoading((loading) => ({ ...loading, balance: true }));
      setError('');
      try {
        const res = await AccountService._getBalance();
        setBalance(AccountService.account.balance.available);
      } catch (e) {
        setError(
          'Não conseguimos recuperar todos os seus saldos. Por favor, recarregue a página.'
        );
      }
      setIsLoading((loading) => ({ ...loading, balance: false }));
    };
    fetchAccountBalance();
  }, []);

  const [query, setQuery] = useState(initialDates);
  const [receipts, setReceipts] = useState([]);
  const [receiptType, setReceiptType] = useState({ ...initialReceiptType });
  const [receipt, setReceipt] = useState(null);

  useEffect(() => {
    const fetchTransfers = async () => {
      return await ReceiptsService.fetchTransferReceiptsWithScheduling(query);
    };

    const fetchPayments = async () => {
      const payments = await ReceiptsService.fetchPaymentReceiptsWithScheduling(
        query
      );
      return payments;
    };

    const fetchPix = async () => {
      const pix = await ReceiptsService.fetchPix(query);
      return pix;
    };

    const fetchReceipts = async () => {
      try {
        setIsLoading((loading) => ({ ...loading, receipts: true }));
        setError('');

        if (receiptType.value === 'all') {
          let transfers = [];
          let payments = [];
          let pix = [];
          try {
            transfers = await fetchTransfers();
          } catch (e) {
            setError({
              message: 'Tivemos um problema ao carregar suas transferencias',
            });
          }
          try {
            payments = await fetchPayments();
          } catch (e) {
            setError({
              message: 'Tivemos um problema ao carregar seus pagamentos',
            });
          }
          try {
            pix = await fetchPix();
          } catch (e) {
            setError({
              message: 'Tivemos um problema ao carregar os comprovantes de PIX',
            });
          }

          console.log({ transfers });
          console.log({ pix });
          setReceipts([
            ...transfers.map(resolveTransferFields),
            ...payments.map(resolvePaymentFields),
            ...pix
              .filter((e) => e.transactionType === 'DEBIT')
              .map(resolvePixFields),
          ]);
        } else if (receiptType.value === 'transfers') {
          const res = await fetchTransfers();
          setReceipts(res.map(resolveTransferFields));
        } else if (receiptType.value === 'payments') {
          const res = await fetchPayments();
          setReceipts(res.map(resolvePaymentFields));
        } else {
          const res = await fetchPix();
          setReceipts(
            res
              .filter((e) => e.transactionType === 'DEBIT')
              .map(resolvePixFields)
          );
        }
      } catch (e) {
        setReceipts([]);
        setError(
          e.error
            ? e.error
            : e.error && e.error.length > 0
            ? e.error[0]
            : 'Erro inesperado. Certifique-se da sua conexão'
        );
      }
      setIsLoading((loading) => ({ ...loading, receipts: false }));
    };

    if (query) {
      fetchReceipts();
    }
  }, [receiptType, query]);

  const [showModal, hideModal] = useModal(
    () => (
      <ReactModal onRequestClose={() => hideModal()} isOpen>
        <ModalContent onCloseClick={hideModal}>
          {receipt.type === 'transfer' && (
            <TransferReceipt
              data={receipt}
              selectedType={receipt.operationTypeCode}
            />
          )}
          {receipt.type === 'payment' && (
            <PaymentReceipt data={receipt} selectedType={receipt.serviceType} />
          )}
          {receipt.type === 'pix' && (
            <PixReceipt data={receipt} selectedType={receipt.serviceType} />
          )}
        </ModalContent>
      </ReactModal>
    ),
    [receipt, receiptType]
  );

  async function onItemClicked({ type, identifier, date, historyCode }) {
    const fetchTransfer = async () => {
      setIsLoading((loading) => ({ ...loading, receipt: true }));
      setError('');
      try {
        const res = await TransfersService.fetchTransferReceiptWithScheduling({
          identifier,
          date,
          historyCode,
        });
        setIsLoading((loading) => ({ ...loading, receipt: false }));
        if (Array.isArray(res) && res.length === 0) {
          setError({
            message:
              'Ainda não conseguimos carregar todas as informações do seu comprovante. Tente novamente em alguns minutos.',
          });
        } else {
          setReceipt({ ...res, type: 'transfer' });
          showModal();
        }
      } catch (e) {
        setReceipt(null);
        setError(
          e.error
            ? e.error
            : e.error && e.error.length > 0
            ? e.error[0]
            : 'Erro inesperado. Certifique-se da sua conexão'
        );
      }
      setIsLoading((loading) => ({ ...loading, receipt: false }));
    };

    const fetchPayment = async () => {
      setIsLoading((loading) => ({ ...loading, receipt: true }));
      setError('');

      try {
        const res = await PaymentService.fetchPaymentReceiptWithScheduling({
          identifier,
        });

        setIsLoading((loading) => ({ ...loading, receipt: false }));
        setReceipt({ ...res, type: 'payment' });
        showModal();
      } catch (e) {
        setReceipt(null);
        setError(
          e.error
            ? e.error
            : e.error && e.error.length > 0
            ? e.error[0]
            : 'Erro inesperado. Certifique-se da sua conexão'
        );
      }
      setIsLoading((loading) => ({ ...loading, receipt: false }));
    };

    const fetchPix = async () => {
      setIsLoading((loading) => ({ ...loading, receipt: true }));
      setError('');

      try {
        const res = await ReceiptsService.fetchPixDetail({
          identifier,
        });

        console.log({ identifier });

        setIsLoading((loading) => ({ ...loading, receipt: false }));
        setReceipt({ ...res, type: 'pix' });
        showModal();
      } catch (e) {
        console.log({ e });
        setReceipt(null);
        setError(
          e.error
            ? e.error
            : e.error && e.error.length > 0
            ? e.error[0]
            : 'Erro inesperado. Certifique-se da sua conexão'
        );
      }
      setIsLoading((loading) => ({ ...loading, receipt: false }));
    };

    if (type === 'transfer') {
      await fetchTransfer();
    } else if (type === 'payment') {
      await fetchPayment();
    } else {
      await fetchPix();
    }
  }

  return (
    <LoadingSpinner
      isLoading={isLoading.balance || isLoading.receipts || isLoading.receipt}
    >
      <Container>
        <ScreenTitle title={name}></ScreenTitle>
        <ContentWrapper>
          <CurrentAccountValue
            title={'saldo conta corrente'}
            value={balance}
          ></CurrentAccountValue>
          <ActionsWrapper>
            <CustomAutocomplete
              value={receiptType}
              isSearchable={false}
              id="receiptTypeSelectId"
              //noOptionsMessage={({ inputValue }) => `Nova transferência para ${inputValue}`}
              placeholder={'Tipo de comprovante'}
              options={options}
              onChange={(val) => {
                setReceiptType({ ...val });
              }}
              styles={{
                option: (provided, { isDisabled, isFocused, isSelected }) => ({
                  ...provided,
                  backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? theme.Cor_base_marca_01
                    : isFocused
                    ? theme.Cor_base_marca_fade_02
                    : undefined,
                  ':active': {
                    ...provided[':active'],
                    backgroundColor: isDisabled
                      ? undefined
                      : theme.Cor_base_marca_01,
                  },
                }),
                valueContainer: (provided, state) => ({
                  ...provided,
                }),
              }}
            />
            <FilterDatePicker
              disAllowFutureDates
              initialDates={initialDates}
              onDateChange={(newQuery) => setQuery(newQuery)}
              showDefaultInputIcon
              firesWithButton
            ></FilterDatePicker>
          </ActionsWrapper>
          <ReceiptList
            data={receipts.map((e) => ({
              ...e,
              onClick: onItemClicked,
            }))}
          />
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default ReceiptsWithScheduling;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  AccountService,
  OperatorsService,
  TokenService,
} from 'banking-service';
import { useModal } from 'react-modal-hook';
import { Collapse } from 'react-collapse';
import ValueOnTables from 'components/ValueOnTables';
import ReactModal from 'react-modal';
import Button from 'components/Button';
import PasswordVerification from 'components/PasswordVerification';
import { hasActiveToken } from 'utils/functions/token';
import Form from './Form';
import Toggle from 'react-toggle';
import { initialApisAvailable, fixedApis } from './constants';

import {
  Container,
  ContentWrapper,
  ItemContainer,
  ColumnWrapper,
  Title,
  Value,
  NoItemsAvailableLabel,
  EditIcon,
  ViewIcon,
  Wrapper,
  FieldsWrapper,
} from './styles';
import { useAlert } from 'react-alert';
import LoadingSpinner from 'components/LoadingSpinner';
import ScreenTitle from 'components/ScreenTitle';
import { handleCurrency } from 'utils/functions/currency';
import OutstandingResumeList from 'components/OutstandingResumeList';
import { useTheme } from 'styled-components';
import { useConfigContext } from 'providers/client-config-provider';

function Operators({ section = 'Serviços', name = 'Operadores', ...props }) {
  const alert = useAlert();

  const theme = useTheme();

  const [error, setError] = useState('');
  useEffect(() => {
    if (error) alert.error(error.message);
  }, [error]);

  const [isLoading, setIsLoading] = useState({
    balance: false,
    operators: false,
  });
  const [operators, setOperators] = useState([]);
  const [apis, setApis] = useState({ functionalities: [] });

  const Item = ({
    data,
    outstadingDetails,
    onToggleSwitch,
    onSelectOperator,
  }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
      <ItemContainer>
        <FieldsWrapper>
          <ColumnWrapper>
            <Title>Nome</Title>
            <Value>{data.name}</Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title>Login</Title>
            <Value>{data.document}</Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <Title>CPF</Title>
            <Value>{data.document}</Value>
          </ColumnWrapper>
          <ColumnWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <EditIcon
                style={{ marginRight: '25px' }}
                onClick={() => onSelectOperator({ action: 'EDIT', data })}
              />
              <ViewIcon
                onClick={() => onSelectOperator({ action: 'VIEW', data })}
              />
            </div>
          </ColumnWrapper>
        </FieldsWrapper>
      </ItemContainer>
    );
  };

  const initialSelectedOperator = ({ apis }) => {
    return {
      action: 'NONE',
      data: {
        active: true,
        name: '',
        email: '',
        birthDate: null,
        document: '',
        accessPassword: '',
        confirmAccessPassword: '',
        transactionPassword: '',
        confirmTransactionPassword: '',
        accounts: AccountService.accounts.map((e) => ({
          branch: e.branch,
          account: e.account,
        })),
        associatedAccounts: [
          {
            account: AccountService.account.account,
            branch: AccountService.account.branch,
            apisAvailable: [
              ...apis.functionalities.filter(
                (e) => e.requiredForOperators === true
              ),
            ],
          },
        ],
        taxIdentifier: '',
      },
    };
  };

  const [selectedOperator, setSelectedOperator] = useState({
    ...initialSelectedOperator({ apis: { functionalities: [] } }),
  });

  useEffect(() => {
    const fetchApis = async () => {
      const apis = await OperatorsService.fetchApis();
      setApis(apis);
    };

    fetchApis();
  }, []);

  async function handleSelectOperator({ action, data }) {
    setIsLoading((isLoading) => ({ ...isLoading, operators: true }));
    setError('');
    try {
      setSelectedOperator({
        action,
        data: {
          ...data,
          apis,
        },
      });
    } catch (e) {
      setError({
        message:
          'Não conseguimos processar todos os dados deste operador. Por favor, tente novamente',
      });
    }
    setIsLoading((isLoading) => ({ ...isLoading, operators: false }));
  }

  async function onToggleSwitch({ checked, username }) {
    setIsLoading((isLoading) => ({ ...isLoading, operators: true }));
    setError('');
    try {
      const res = await OperatorsService.updateActive({ username }, checked);
      setOperators([...res]);
    } catch (e) {
      setError({ message: e.message });
    }
    setIsLoading((isLoading) => ({ ...isLoading, operators: false }));
  }

  const [shouldFetchOperators, setShouldFetchOperators] = useState(0);

  useEffect(() => {
    const fetchOperadors = async () => {
      setIsLoading((isLoading) => ({ ...isLoading, operators: true }));
      setError('');
      try {
        const res = await OperatorsService.fetch();
        const parsedOperators = res.map((op) => {
          return {
            ...op,
            accounts: AccountService.accounts.map((e) => ({
              account: e.account,
            })),
            associatedAccounts: op.accounts
              .filter((e) => e.active)
              .map((e) => {
                return {
                  ...e,
                  apisAvailable: [...new Set([...e.functionalities])],
                };
              }),
          };
        });
        setOperators([...parsedOperators]);
      } catch (e) {
        setOperators([]);
      }
      setIsLoading((isLoading) => ({ ...isLoading, operators: false }));
    };

    fetchOperadors();
  }, [shouldFetchOperators]);

  async function handleSaveButtonClick({ formData, action }) {
    setIsLoading((isLoading) => ({ ...isLoading, operators: true }));
    setError('');

    let accounts = [];

    const allAccounts = AccountService.accounts.map((e) => ({
      branch: e.branch,
      account: e.account,
    }));

    const unToggledAccounts = allAccounts
      .filter(
        (e) =>
          !formData.associatedAccounts.some((el) => e.account === el.account)
      )
      .map((e) => ({
        account: e.account,
        functionalities: apis.functionalities
          .filter((e) => e.requiredForOperators === true)
          .map((e) => e.id),
        active: false,
      }));

    const toggledAccounts = formData.associatedAccounts.map((e) => ({
      account: e.account,
      functionalities: e.apisAvailable.map((e) => e.id),
      active: true,
    }));

    const selectedAccounts = [...unToggledAccounts, ...toggledAccounts];

    try {
      const payload = {
        document: formData.document.replace(/[^\w\s]/gi, ''),
        name: formData.name,
        email: formData.email,
        accessPassword: action === 'CREATE' ? formData.accessPassword : '-',
        transactionPassword:
          action === 'CREATE' ? formData.transactionPassword : '-',
        birthDate: formData.birthDate,
        accounts: selectedAccounts,
      };

      let res;
      if (action === 'CREATE') {
        res = await OperatorsService.create({ ...payload });
        alert.success('Operador adicionado com sucesso');
      } else {
        res = await OperatorsService.update({ ...payload });
        alert.success('Operador alterado com sucesso');
      }
      // console.log('HERE', res);

      setShouldFetchOperators((shouldFetchOperators) => !shouldFetchOperators);
      setSelectedOperator({
        ...initialSelectedOperator({ apis: { functionalities: [] } }),
      });
    } catch (e) {
      let errorMessage = `Ocorreu um erro ao ${action === 'CREATE' ? 'cadastrar' : 'atualizar'} o operador!`;
      if (e && e.message) {
        if (e.message !== 'Internal server error') {
          errorMessage = e.message;
        }
      }
      setError({message: errorMessage});
    }
    setIsLoading((isLoading) => ({ ...isLoading, operators: false }));
  }

  if (selectedOperator.action !== 'NONE') {
    return (
      <LoadingSpinner isLoading={isLoading.operators}>
        <Container>
          <ScreenTitle section={section} title={name}></ScreenTitle>
          <ContentWrapper>
            <Form
              action={selectedOperator.action}
              data={selectedOperator.data}
              onBackClick={() =>
                setSelectedOperator({
                  ...initialSelectedOperator({ apis: { functionalities: [] } }),
                })
              }
              onSaveClick={handleSaveButtonClick}
            />
          </ContentWrapper>
        </Container>
      </LoadingSpinner>
    );
  }

  return (
    <LoadingSpinner isLoading={isLoading.operators}>
      <Container>
        <ScreenTitle section={section} title={name}></ScreenTitle>
        <ContentWrapper>
          {operators.length === 0 ? (
            <NoItemsAvailableLabel style={{ alignSelf: 'center' }}>
              Você ainda não possui nenhum operador.{' '}
              <strong
                onClick={() =>
                  handleSelectOperator({
                    action: 'CREATE',
                    data: { ...initialSelectedOperator({ apis }).data },
                  })
                }
                style={{
                  cursor: 'pointer',
                  color: theme.Cor_base_marca_01,
                  textDecoration: 'underline',
                }}
              >
                Comece adicionando um.
              </strong>
            </NoItemsAvailableLabel>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <NoItemsAvailableLabel
                style={{ marginBottom: '0px', alignSelf: 'flex-start' }}
              >
                <strong
                  onClick={() =>
                    handleSelectOperator({
                      action: 'CREATE',
                      data: { ...initialSelectedOperator({ apis }).data },
                    })
                  }
                  style={{
                    cursor: 'pointer',
                    color: theme.Cor_base_marca_01,
                    textDecoration: 'underline',
                  }}
                >
                  Adicionar um Operador
                </strong>
              </NoItemsAvailableLabel>
              <Wrapper>
                {operators.map((e) => {
                  return (
                    <Item
                      key={Math.random()}
                      data={{ ...e }}
                      outstadingDetails={{ ...e }}
                      onToggleSwitch={onToggleSwitch}
                      onSelectOperator={handleSelectOperator}
                    />
                  );
                })}
              </Wrapper>
            </div>
          )}
        </ContentWrapper>
      </Container>
    </LoadingSpinner>
  );
}

export default Operators;
